import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    cleanActiveFilters,
    setCount,
    setCountPosition,
    setHeaderButtons,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    setTableType,
} from "../../../actions/tableActions";
import _ from "lodash";
import { ShopContext } from "contexts/Sales/Shop";
import NewLabel from "hooks/GraphqlCalls/Sales/modals/NewLabel";

import UseSectionHeader from "components/useSectionHeader";
import UseTable from "components/Table/index";
import UpdateLabels from "hooks/GraphqlCalls/Settings/UpdateLabels";
import ErrorInfo from "components/ErrorInfo";
import Loading from "components/Loading";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useSalesModalVendureTranslations } from "components/Section/Services/Sales/modals/useSalesModalVendureTranslations";
import { TYPE_QUERY } from "components/Section/Services/Sales/utils/QueriesTranslations";

const sectionName = `list-labels`;

const ListLabels = () => {
    const { token, labels } = useContext(ShopContext);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { currentPage, perPage, activeFilters, field, criteria } = useSelector((state) => state.table);
    const { open: openModalVendureTranslations } = useSalesModalVendureTranslations();

    const search = activeFilters?.search;
    const isReady = labels?.ready;
    const data = labels?.data;

    const [items, setItems] = useState([]);
    const [rows, setRows] = useState([]);

    // on load
    useEffect(() => {
        if (token && labels) {
            labels.load();
        }
    }, [token, labels]);

    // on search
    useEffect(() => {
        if (items?.length > 0) {
            const result = searchItems(items, search);

            setRows(result);
        }
    }, [search]);

    // on page change
    useEffect(() => {
        const result = paginateItems(rows, currentPage, perPage);
        dispatch(setTableResults(result));
        dispatch(setCount(rows?.length || 0));
        dispatch(setLoading(false));
    }, [rows, currentPage]);

    // on sort
    useEffect(() => {
        if (rows?.length > 0) {
            let itemsFilteredClone = [...rows];
            const criteriaField = field || "id";
            const criteriaValue = criteria || "desc";
            itemsFilteredClone = _.orderBy(itemsFilteredClone, [criteriaField], [criteriaValue]);
            dispatch(setLoading(false));
            setRows(itemsFilteredClone);
        }
    }, [criteria]);

    // on ready
    useEffect(() => {
        if (isReady) {
            dispatch(setTableType(sectionName));
            dispatch(setSearchable(true));
            dispatch(
                setHeaders([
                    { name: "name", sortName: "name", icon: { name: "table-sort" }, specialWidth: "w-11/12" },
                    { name: "", specialWidth: "w-1/12" },
                ])
            );
            dispatch(setCountPosition("table-header"));
            dispatch(cleanActiveFilters());
            setItems(data?.labels);
            setRows(data?.labels);
            dispatch(setLoading(false));
        }
    }, [isReady, data]);

    if (labels?.loading) {
        return <Loading adjust="absolute" />;
    }

    if (labels?.error) {
        return <ErrorInfo retry={labels.get}>{labels.error}</ErrorInfo>;
    }

    if (labels?.called) {
        return (
            <>
                <UseSectionHeader title={["labels"]} hideBreadcrumbs={true} />

                <div className=" relative">
                    <div style={{ top: "2rem", right: "2rem" }} className=" absolute top-5 z-50">
                        <Button
                            design="blue"
                            onClick={() => {
                                openModalVendureTranslations({
                                    VARIABLES: { facetId: data?.facetID },
                                    QUERY: TYPE_QUERY.CREATE_LABEL,
                                    title: `${t("add-label")}`,
                                    NO_FETCH_DATA: true,
                                });
                            }}
                            id="add-label-button"
                        >
                            {t("add-label")}
                        </Button>
                    </div>
                    <UseTable />
                </div>
                <UpdateLabels />
            </>
        );
    }

    return null;
};

const paginateItems = (items, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return items ? items.slice(startIndex, startIndex + itemsPerPage) : [];
};

const searchItems = (items, search) => {
    if (items?.length > 0 && search?.length > 0) {
        const searchValue = decodeURIComponent(search.toUpperCase());
        return items.filter((i) => {
            if (i?.name?.toUpperCase().includes(searchValue)) {
                return true;
            }
            if (i?.taxRates?.length > 0) {
                return i.taxRates.some((taxRate) => taxRate.name?.toUpperCase().includes(searchValue));
            }
            return false;
        });
    }
    return items;
};

export default ListLabels;
