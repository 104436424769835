import React, { useEffect, useState } from "react";
import { BOOKING_INFORMATION } from "./utils";
import { useLazyQuery } from "react-apollo";
import { POLLINTERVAL_15S } from "hooks/Utils/Utils";
import { gql } from "apollo-boost";
import { useParams } from "react-router-dom";
import { QUERY_STAY_BOOKING_FILTER } from "./queries";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshContentData } from "actions/sectionActions";
import { showGlobalLoading } from "actions/uiActions";

const initialState = {
    tab: null,
};

export const PreCheckInContext = React.createContext(initialState);

export const ReservationDetailsProvider = ({ children }) => {
    const [tabSelected, setTabSelected] = useState(BOOKING_INFORMATION);
    const { refreshData } = useSelector((state) => state.sectionContent);
    const dispatch = useDispatch();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [valuesContext, setValuesContext] = useState(null);
    const [inEdition, setInEdition] = useState(false);
    const { id } = useParams();

    // console.log(id);

    const GET_RESERVATIONS_DATAILS_DATA = gql(`${QUERY_STAY_BOOKING_FILTER(id)}`);

    const [executeQuery, { data: data_, refetch }] = useLazyQuery(GET_RESERVATIONS_DATAILS_DATA, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
            setTimeout(() => {
                dispatch(showGlobalLoading(false));
            }, 1000);
        },
    });

    useEffect(() => {
        executeQuery();
        setLoading(true);
    }, []);

    useEffect(() => {
        if (refreshData && refetch) {
            refetch();
            dispatch(setRefreshContentData(false));
        }
    }, [refreshData]);

    useEffect(() => {
        const data = {};

        if (data_?.stayBookings?.results?.[0]) {
            data.stayBookings = data_.stayBookings.results[0];
        }
        if (data_?.preCheckInSettingsCustomFields) {
            data.preCheckInSettingsCustomFields = data_.preCheckInSettingsCustomFields;
        }
        if (data_?.preCheckInSettingsCustomFields) {
            data.preCheckInSettingsCustomFieldsChildren = data_.preCheckInSettingsCustomFieldsChildren;
        }

        if (data_?.preCheckInSettings) {
            data.preCheckInSettings = data_.preCheckInSettings;
        }

        if (data_?.stayBookings?.results?.[0] && data_?.preCheckInSettings) {
            dispatch(showGlobalLoading(false));
            setLoading(false);
        }

        setData(data);
    }, [data_]);

    return (
        <PreCheckInContext.Provider
            value={{
                tabSelected,
                setTabSelected,
                data,
                loading,
                valuesContext,
                setValuesContext,
                inEdition,
                setInEdition,
            }}
        >
            {children}
        </PreCheckInContext.Provider>
    );
};
