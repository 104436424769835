import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { MonitorContext } from "contexts/Sales/Monitor";
import { ShopContext } from "contexts/Sales/Shop";

import { ORDER_STATUS } from "../../../../../../hooks/Utils/Services/OrdersUtils";
import UpdateOrders from "../../../../../../hooks/GraphqlCalls/Sales/UpdateOrders";
import OrdersHeadMenu from "../../OrdersHeadMenu";
import OrderMonitorTypes from "./OrderMonitorTypes";
import OrderMonitorFilters from "./OrderMonitorFilters";
import OrderMonitorRow from "./OrderMonitorRow";
import _ from "lodash";
import OrderMonitorColumns from "./OrderMonitorColumns";
import SessionDate from "date";

import ErrorInfo from "components/ErrorInfo";
import Loading from "components/Loading";

import { getDeliveryMethodIcon, getDeliveryMethodText, useDeliveryLocationInfo } from "constants/sales";

// this constant defines how many orders are displayed at once
const DISPLAY_STEP = 20;

export const FILTER_SEARCH = "order-monitor-filter-search";
export const FILTER_STATUS = "order-monitor-filter-status";
export const FILTER_DELIVERY_METHOD = "order-monitor-filter-delivery-method";
export const FILTER_LAST_UPDATE = "order-monitor-filter-last-update";
export const FILTER_DELIVERY_SHOPS = "order-monitor-filter-delivery-shops";
export const FILTERS = [
    FILTER_SEARCH,
    FILTER_STATUS,
    FILTER_DELIVERY_METHOD,
    FILTER_LAST_UPDATE,
    FILTER_DELIVERY_SHOPS,
];

const OrderMonitor = () => {
    const { t } = useTranslation();
    const { id, token } = useContext(ShopContext);
    const { lang, loading, updating, error, refresh, orders, currentType, setFiltersQuery } =
        useContext(MonitorContext);

    const arrangeOrders = useArrangeOrders();

    const refreshData = useSelector((state) => state?.table?.refreshData);

    const [rows, setRows] = useState([]);
    const [orderCollapsed, setOrderCollapsed] = useState(null);

    const [forceRefetchSummary, setForceRefetchSummary] = useState(false);
    const [ordersPreLoaded, setOrdersPreLoaded] = useState([]);
    const [displayLimit, setDisplayLimit] = useState(DISPLAY_STEP);

    const totalRows = rows?.length;
    const totalDelayedOrders = rows?.filter((item) => item.isDelayed)?.length;

    // disable animations if display limit is too high to avoid performance issues
    const disableAnimations = displayLimit > 60;

    useEffect(() => {
        // when type changes, reset display limit
        setDisplayLimit(DISPLAY_STEP);
        if (currentType && (token || !id)) {
            handleChangeFilters();
        }
    }, [currentType, id, token]);

    useEffect(() => {
        if (refreshData) {
            setForceRefetchSummary(true);
            setOrderCollapsed(null);
        }
    }, [refreshData]);

    useEffect(() => {
        if (orders) {
            let newOrders = arrangeOrders({ items: orders, t, ordersPreLoaded });
            setRows(newOrders);
            setOrdersPreLoaded(arrangeOrdersPreLoaded({ newOrders: newOrders, ordersPreLoaded }));
        }
    }, [orders]);

    useLayoutEffect(() => {
        const content = document.getElementById("sectionContent");

        const checkScroll = () => {
            if (!loading && totalRows > 0 && displayLimit < totalRows) {
                const content = document.getElementById("sectionContent");
                if (content && content.clientHeight > 0 && content.scrollHeight <= content.clientHeight) {
                    // if there is no scroll, increase display limit (to fill the screen)
                    setDisplayLimit(displayLimit + DISPLAY_STEP);
                }
            }
        };

        const handleScroll = () => {
            // when scroll reaches bottom, increase display limit if there are more orders to show
            if (!loading && totalRows > 0 && displayLimit < totalRows) {
                const content = document.getElementById("sectionContent");
                if (
                    content &&
                    content.clientHeight > 0 &&
                    Math.abs(content.scrollHeight - content.clientHeight - content.scrollTop) <= 10
                ) {
                    setDisplayLimit(displayLimit + DISPLAY_STEP);
                }
            }
        };

        if (content) {
            checkScroll();
            content.addEventListener("scroll", handleScroll);
        }
        return () => {
            const content = document.getElementById("sectionContent");
            if (content) {
                content.removeEventListener("scroll", handleScroll);
            }
        };
    }, [displayLimit, loading, totalRows]);

    const handleChangeFilters = (props = {}) => {
        const filters = arrangeFilters({
            shopToken: token,
            currentType,
            lang,
            ...props,
        });
        setFiltersQuery(filters);
        setDisplayLimit(DISPLAY_STEP);
    };

    const firstLoading = loading && !updating;

    //Response
    return (
        <>
            <UpdateOrders />
            <OrdersHeadMenu />
            <div
                className="px-10 py-10"
                style={{ marginTop: calculateMarginTopStyle({ parentId: `order-monitor-head-menu-mainContainer` }) }}
            >
                <div className="w-full">
                    <OrderMonitorTypes
                        forceRefetchSummary={forceRefetchSummary}
                        setForceRefetchSummary={setForceRefetchSummary}
                    />
                </div>
                {error ? <ErrorInfo retry={refresh}>{error}</ErrorInfo> : null}
                {error ? null : (
                    <div className="w-full mt-4 bg-white py-10 px-6">
                        <div className="w-full flex justify-between items-center">
                            <div className="w-full flex justify-start items-center">
                                <OrderMonitorFilters handleChange={handleChangeFilters} />
                            </div>
                            <div>
                                {totalDelayedOrders > 0 && !loading ? (
                                    <div className="flex justify-end items-center">
                                        <span className="icon icon-warning text-red-100 text-2xl"></span>
                                        <span className="whitespace-no-wrap ml-3 mr-1">{t("delayed-deliveries")}:</span>
                                        <span className="font-bold">{totalDelayedOrders}</span>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {firstLoading ? (
                            <Loading className="p-16" />
                        ) : (
                            <div className={`w-full mt-10${loading ? " opacity-50" : ""}`}>
                                <div className="t-body">
                                    <div className="t-head-container">
                                        <OrderMonitorColumns />
                                    </div>
                                    <div id="rows">
                                        {totalRows > 0 ? (
                                            rows
                                                .slice(0, displayLimit)
                                                .map((order, index) => (
                                                    <OrderMonitorRow
                                                        rowId={index}
                                                        key={order.id}
                                                        order={order}
                                                        animations={!disableAnimations}
                                                        setOrderCollapsed={setOrderCollapsed}
                                                        orderCollapsed={orderCollapsed}
                                                    />
                                                ))
                                        ) : (
                                            <div className="w-full mt-10 text-center">
                                                <span className="text-sm text-gray-700 whitespace-normal">
                                                    {loading ? null : t("no-orders-yet")}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default OrderMonitor;

const arrangeFilters = (props) => {
    const { currentType, shopToken, lang } = props;
    let response = "";
    let filters = "";

    if (shopToken) {
        filters += ` shopToken:["${shopToken}"] `;
    } else if (props[FILTER_DELIVERY_SHOPS] && props[FILTER_DELIVERY_SHOPS].length > 0) {
        filters += ` shopToken:[${props[FILTER_DELIVERY_SHOPS].map((filter) => `"${filter}"`)}] `;
    }
    if (props[FILTER_SEARCH]) {
        filters += ` search:"${props[FILTER_SEARCH]}" `;
    }
    if (props[FILTER_STATUS] && props[FILTER_STATUS].length > 0) {
        filters += ` state:[${props[FILTER_STATUS].map((filter) => filter)}] `;
    }
    if (props[FILTER_DELIVERY_METHOD] && props[FILTER_DELIVERY_METHOD].length > 0) {
        filters += ` deliveryMethods:${props[FILTER_DELIVERY_METHOD]} `;
    }
    if (props[FILTER_LAST_UPDATE]) {
        filters += ` lastUpdate:${props[FILTER_LAST_UPDATE]} `;
    }
    if (filters && filters !== "") {
        filters = ` ,filter:{${filters}} `;
    }
    response = ` (type:${currentType} languageRef:"${lang}" ${filters})  `;
    return response;
};

const useArrangeOrders = () => {
    const getDeliveryLocationInfo = useDeliveryLocationInfo();
    return (props = {}) => {
        const formatDate = (date, type) => {
            let response = "";
            if (date) {
                if (type === "date") {
                    response = date.toLocaleString({
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    });
                } else if (type === "time") {
                    response = date.toLocaleString({
                        hour: "2-digit",
                        minute: "2-digit",
                    });
                } else {
                    response = date;
                }
            }
            return response;
        };

        const { items, t, ordersPreLoaded = [] } = props;
        let response = [];
        if (items && items.length > 0) {
            items.forEach((item) => {
                const deliveryInfo = getDeliveryLocationInfo(item.deliveryMethod, item.deliveryLocation);
                let orderDate = null;
                let orderHour = null;
                let deliveryMethodData = {};
                let statusData = {};
                let prevStatusData = {};
                let updatedAtDate = null;
                let updatedAtHour = null;
                let isPreloaded = ordersPreLoaded.includes(item.id);
                if (item.date) {
                    const date = SessionDate(item.date);
                    orderDate = formatDate(date, "date");
                    orderHour = formatDate(date, "time");
                }
                if (item.updatedAt) {
                    let date = SessionDate(item.updatedAt);
                    updatedAtDate = formatDate(date, "date");
                    updatedAtHour = formatDate(date, "time");
                }

                if (item.schedule) {
                    let parsedHours = [];
                    let parsedDates = [];
                    if (item.schedule.start && item.schedule.end) {
                        let date = SessionDate(item.schedule.start);
                        if (date) {
                            parsedHours.push(formatDate(date, "time"));
                            parsedDates.push(formatDate(date, "date"));
                        }
                        date = SessionDate(item.schedule.end);
                        if (date) {
                            let parsedInfo = formatDate(date, "time");
                            if (!parsedHours.includes(parsedInfo)) {
                                parsedHours.push(parsedInfo);
                            }
                            parsedInfo = formatDate(date, "date");
                            if (!parsedDates.includes(parsedInfo)) {
                                parsedDates.push(parsedInfo);
                            }
                        }
                        deliveryMethodData.firstTitle = parsedHours.join(" - ");
                        deliveryMethodData.secondTitle = parsedDates.join(" - ");
                    }
                }
                if (!deliveryMethodData.firstTitle) {
                    deliveryMethodData.firstTitle = t("as-soon-as-possible");
                }

                deliveryMethodData.value = item.deliveryMethod;
                deliveryMethodData.title = t(getDeliveryMethodText(item.deliveryMethod));
                deliveryMethodData.iconName = getDeliveryMethodIcon(item.deliveryMethod);
                deliveryMethodData.coords = deliveryInfo?.coords;

                if (item.status) {
                    Object.keys(ORDER_STATUS).forEach((subjectKey) => {
                        const status = ORDER_STATUS[subjectKey];
                        if (status.value === item.status) {
                            statusData = { ...statusData, ...status };
                        }
                    });
                }
                if (item.prevStatus) {
                    Object.keys(ORDER_STATUS).forEach((subjectKey) => {
                        const prevStatus = ORDER_STATUS[subjectKey];
                        if (prevStatus.value === item.prevStatus) {
                            prevStatusData = { ...prevStatusData, ...prevStatus };
                        }
                    });
                }
                response.push({
                    ...item,
                    shopName: item.shop?.name,
                    parsedOrderDate: orderDate,
                    parsedOrderHour: orderHour,
                    deliveryMethodData: deliveryMethodData,
                    statusData: statusData,
                    prevStatusData: prevStatusData,
                    parsedCustomerName: _.isEmpty(item.customerName) ? t("checked-out") : item.customerName,
                    updatedAtDate: updatedAtDate,
                    updatedAtHour: updatedAtHour,
                    isPreloaded: isPreloaded,
                });
            });
        }
        return response;
    };
};

const arrangeOrdersPreLoaded = (props = {}) => {
    const { newOrders = [], ordersPreLoaded = [] } = props;
    let response = [...ordersPreLoaded];
    if (newOrders && newOrders.length > 0) {
        let newOrdersIds = _.map(newOrders, "id");
        response = [...newOrdersIds, ...response];
        response = [...new Set(response)];
    }
    return response;
};

const calculateMarginTopStyle = (props = {}) => {
    const { parentId } = props;
    let marginTop = "0.0";
    const container = document.querySelector(`#${parentId}`);
    if (container) {
        marginTop = container.clientHeight;
    }
    return marginTop;
};
