import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Table, { stringValue } from "components/ZafiroTable";
import { useAlert, useConfirm } from "components/Modal";
import Icon from "components/Icon";
import Button from "components/Button";

const Tables = () => {
    const { t } = useTranslation();

    const tableEmailsRef = useRef(null);

    const { open: alert } = useAlert();
    const { open: confirm } = useConfirm();

    return (
        <div className="p-10">
            <div className="space-y-3 pb-5 max-w-sm">
                <Table
                    ref={tableEmailsRef}
                    id="table-emails"
                    cols={["email", "delete"]}
                    header={{
                        email: { title: "Email", sortable: true },
                        delete: { align: "right", width: "5%" },
                    }}
                    scrollable={true}
                    loadingMore={true}
                    itemsPerLoad={8}
                    rows={new Array(20).fill(0).map((_, index) => ({
                        rowConfig: {
                            batchActions: Math.random() > 0.5,
                        },
                        email: `${Math.random().toString(36).substring(2, 7)}@zafiro.tv`,
                        delete: (
                            <Button
                                id={`delete-email-${index}`}
                                onClick={() =>
                                    confirm({
                                        title: "Delete email",
                                        message: "Are you sure you want to delete this email?",
                                        onConfirm: () => toast.success(`Email deleted`),
                                        sensitive: true,
                                    })
                                }
                            >
                                <Icon type="delete" />
                            </Button>
                        ),
                    }))}
                    batchActions={true}
                    maxHeight="19rem"
                    className="bg-white rounded shadow p-5"
                />
                <Button
                    id="get-selected-rows"
                    design="blue"
                    onClick={() => {
                        const selectedRows = tableEmailsRef.current?.getSelectedRows();
                        alert(
                            <div className="space-y-2">
                                {selectedRows?.map((row) => (
                                    <div key={row?.id}>{row?.email}</div>
                                ))}
                            </div>,
                            `Selected emails: ${selectedRows?.length}`
                        );
                    }}
                >
                    Get selected emails
                </Button>
            </div>
            <div className="w-full">
                <Table
                    id="table-test"
                    cols={["id", "color", "pet", "city", "code", "available"]}
                    search={true}
                    page={3}
                    paginate={true}
                    perPage={8}
                    showCount={true}
                    className="bg-white rounded shadow p-5"
                    topRightCorner={
                        <div className="border border-dashed border-zafiro-200 p-5 text-gray-600">
                            Contenido custom en la esquina superior derecha de la tabla
                        </div>
                    }
                    batchActions={{
                        options: [
                            {
                                id: "delete",
                                value: "delete",
                                label: t("erase"),
                            },
                            {
                                id: "set-available",
                                value: "set-available",
                                label: t("set-as-available"),
                            },
                        ],
                        onChange: (action, rows) => {
                            if (action === "delete") {
                                confirm({
                                    title: "Delete selected rows",
                                    message: "Are you sure you want to delete the selected rows?",
                                    onConfirm: () => toast.success(`${rows.length} rows deleted`),
                                    sensitive: true,
                                });
                            } else {
                                toast.success(`Action ${action} applied to ${rows.length} rows`);
                            }
                        },
                    }}
                    header={{
                        id: { title: "ID", sortable: true },
                        color: {
                            title: "Color",
                            width: "20%",
                            sortable: true,
                        },
                        pet: { title: "Pet", width: "10%" },
                        city: { title: "City", width: "15%", sortable: true, align: "center" },
                        code: { title: "Code", width: "15%", sortable: true, align: "right" },
                        available: { title: "Available", width: "10%", sortable: true, align: "center" },
                    }}
                    rows={Array.from({ length: 57 }, (_, index) => {
                        const color = getRandom(MOCKUP_COLORS);
                        const city = getRandom(MOCKUP_CITIES_SPAIN);
                        const code = Math.floor(Math.random() * 1000);
                        const id = `ref-${String(index).padStart(4, "0")}`;
                        const info = [
                            [{ title: "Personal info", data: { Name: "John", Surname: "Doe", Age: 25 } }],
                            [{ title: "Location", data: { City: "New York", Country: "USA" } }],
                            [
                                { title: "Phone number", data: "+1 555 123 456" },
                                { title: "Email", data: "john.doe@gmail.com" },
                            ],
                        ];

                        let expandedData;
                        if (id === "ref-0020") {
                            expandedData = true;
                        } else if (id === "ref-0018") {
                            expandedData = (
                                <div className="flex flex-row space-x-5 justify-between">
                                    <div className="w-full">
                                        <Table
                                            id={`table-${id}`}
                                            cols={["name", "surname", "age"]}
                                            header={{
                                                name: { title: "Name" },
                                                surname: { title: "Surname" },
                                                age: { title: "Age" },
                                            }}
                                            rows={[
                                                { name: "John", surname: "Doe", age: 25 },
                                                { name: "Jane", surname: "Doe", age: 22 },
                                                { name: "Alice", surname: "Smith", age: 30 },
                                                { name: "Bob", surname: "Smith", age: 28 },
                                            ]}
                                            style={{ zoom: 0.8 }}
                                        />
                                    </div>
                                    <img
                                        src="https://cdn.pixabay.com/photo/2024/01/04/13/41/room-8487500_1280.jpg"
                                        alt="image"
                                        className="rounded max-h-20"
                                    />
                                </div>
                            );
                        } else if (id !== "ref-0019") {
                            expandedData = (
                                <div
                                    className={`text-sm text-gray-800 leading-6 grid gap-4 grid-cols-${info.length}`}
                                    style={{ marginLeft: "5.5rem" }}
                                >
                                    {info.map((items) => (
                                        <div className="flex flex-col space-y-2">
                                            {items?.map((item) => (
                                                <div>
                                                    <p>
                                                        <strong>{item?.title}</strong>
                                                    </p>
                                                    {typeof item?.data === "object"
                                                        ? Object.keys(item.data).map((subKey) => (
                                                              <>
                                                                  {`${subKey}: ${item?.data[subKey]}`}
                                                                  <br />
                                                              </>
                                                          ))
                                                        : item?.data}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            );
                        }
                        return {
                            id,
                            color: ({ expanded }) => (
                                <span
                                    alt={color}
                                    className={`text-white bg-${color}-600 px-3 ${
                                        expandedData === true && expanded ? "text-xl" : ""
                                    } rounded-lg`}
                                >
                                    {color}
                                </span>
                            ),
                            pet: getRandom(MOCKUP_ANIMALS),
                            city: ({ expanded }) => (
                                <span className={expandedData === true && expanded ? "text-xl" : null} alt={city}>
                                    {city}
                                </span>
                            ),
                            code: <span alt={code}>{`#${code}`}</span>,
                            available: Math.random() > 0.5 ? true : false,
                            rowConfig: {
                                expanded: expandedData,
                                actions: [
                                    {
                                        id: "edit",
                                        label: "Edit",
                                        disabled: id === "ref-0002",
                                        tooltip: "Edit tooltip here",
                                        onClick: () => alert(`Editing row ${id}`, "Edit row"),
                                    },
                                    {
                                        id: "delete",
                                        label: "Delete",
                                        disabled: id === "ref-0002" || Math.random() > 0.5,
                                        onClick: () =>
                                            confirm({
                                                title: "Delete row",
                                                message: `Are you sure you want to delete row ${id}?`,
                                                onConfirm: () => toast.success(`Row ${id} deleted`),
                                                sensitive: true,
                                            }),
                                    },
                                ],
                            },
                        };
                    })}
                    filters={[
                        {
                            id: "filter-by-color",
                            title: t("color"),
                            multiple: true,
                            options: MOCKUP_COLORS.map((color) => ({
                                id: color,
                                value: color,
                                label: color,
                            })),
                            onFilter: (values, row) => (values?.includes(stringValue(row?.color)) ? row : null),
                        },
                        {
                            id: "filter-by-pet",
                            title: t("Animal"),
                            options: MOCKUP_ANIMALS.map((animal) => ({
                                id: animal,
                                value: animal,
                                label: animal,
                            })),
                            onFilter: (value, row) => (stringValue(row?.pet) === String(value) ? row : null),
                        },
                        {
                            id: "filter-by-city",
                            title: t("City"),
                            multiple: true,
                            options: [
                                {
                                    id: "spain",
                                    label: "Spain",
                                    options: MOCKUP_CITIES_SPAIN.map((city) => ({
                                        id: city,
                                        value: city,
                                        label: city,
                                    })),
                                },
                                {
                                    id: "world",
                                    label: "World",
                                    options: MOCKUP_CITIES_WORLD.map((city) => ({
                                        id: city,
                                        value: city,
                                        label: city,
                                    })),
                                },
                            ],
                            onFilter: (values, row) => (values?.includes(stringValue(row?.city)) ? row : null),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

const MOCKUP_COLORS = ["red", "green", "blue", "yellow", "purple"];
const MOCKUP_ANIMALS = ["Dog", "Cat", "Bird", "Fish", "Rabbit", "Turtle", "Horse", "Cow", "Pig", "Sheep"];
const MOCKUP_CITIES_SPAIN = ["Madrid", "Barcelona", "Saragossa"];
const MOCKUP_CITIES_WORLD = ["Paris", "London", "Buenos Aires"];

const getRandom = (set) => {
    return set[Math.floor(Math.random() * set.length)];
};

export default Tables;
