import React, { useEffect, useState } from "react";
import { HashRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import ApolloClient, { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-boost";
import { Provider, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import ReactTooltip, { useRefresh as useTooltipRefresh } from "components/ReactTooltip";
import pkg from "../package.json";

import store from "./store";

import GlobalProvider from "contexts/Global";

import { Session } from "./hooks/Utils/Session";
import { UploadFileContextProvider } from "contexts/UploadFile/uploadFileContext";

import AppContent from "./AppContent";
import OldManager from "./components/Section/OldManager";
import Support from "./components/Section/Support";
import Playground from "sections/playground";
import NotFound from "components/Section/NotFound";

// CSS styles
import "react-tippy/dist/tippy.css";
import "./main.css";
import "react-toastify/dist/ReactToastify.css";

// Both ZAFIRO_TV and ZAFIRO_Support are manually rewritten to index.html in lighttpd.conf
// Any new location must have a corresponding rewrite in the configuration file to avoid a 404 error
const ZAFIRO_TV = "ZAFIRO_TV";
const ZAFIRO_SUPPORT = "ZAFIRO_Support";

function App() {
    const isCustomPath = [ZAFIRO_TV, ZAFIRO_SUPPORT].find((path) => document.location.pathname.startsWith(`/${path}`));

    if (isCustomPath) {
        switch (isCustomPath) {
            case ZAFIRO_TV:
                return <OldManager />;
            case ZAFIRO_SUPPORT:
                return <Support />;
            default:
                break;
        }
    }

    if (document.location.pathname != "/") {
        return (
            <div className="bg-white p-10 overflow-auto" style={{ height: "100vh" }}>
                <NotFound />
            </div>
        );
    }

    if (document.location?.hash === "#/version") {
        return <div className="font-mono text-gray-900 p-5">Zafiro Manager v{pkg?.version}</div>;
    }

    // This is a developer playground to test new features
    if (document.location?.hash?.startsWith("#/playground")) {
        return (
            <HashRouter>
                <Provider store={store}>
                    <AppContainer>
                        <div className="bg-gray-200 overflow-auto" style={{ height: "100vh" }}>
                            <Playground />
                        </div>
                    </AppContainer>
                </Provider>
            </HashRouter>
        );
    }

    return (
        <HashRouter>
            <Provider store={store}>
                <AppContainer>
                    <UploadFileContextProvider>
                        <AppContent />
                    </UploadFileContextProvider>
                </AppContainer>
            </Provider>
        </HashRouter>
    );
}

const AppContainer = ({ children }) => {
    useTooltipRefresh();

    Session.init();

    const token = useSelector((state) => state.auth?.token);

    const TOOLTIP_CONFIG = {
        place: "bottom",
        type: "light",
        offset: { top: -8, left: -8 },
        border: true,
        borderColor: "#D3DAE1",
        className: "max-w-2xl whitespace-prewrap",
    };

    return (
        <ApolloProvider client={getManagerService(token)}>
            <GlobalProvider>
                {children}
                <ToastContainer
                    position="top-right"
                    className="z-505 mt-24 first-capital"
                    draggable
                    pauseOnHover
                    closeOnClick
                    autoClose={3000}
                />
                <ReactTooltip id="default-tooltip" multiline {...TOOLTIP_CONFIG} />
                <ReactTooltip
                    id="warning-tooltip"
                    multiline
                    {...{
                        ...TOOLTIP_CONFIG,
                        borderColor: "#A85500",
                        backgroundColor: "#EF8500",
                        textColor: "#FFFFFF",
                    }}
                />
                <ReactTooltip id="dangerous-html-tooltip" dangerousHtml {...TOOLTIP_CONFIG} />
            </GlobalProvider>
        </ApolloProvider>
    );
};

const getManagerService = (token) => {
    const userToken = token;
    return new ApolloClient({
        uri: Session.getApiUrl(),
        headers: userToken ? { Authorization: userToken } : null,
        cache: new InMemoryCache({
            fragmentMatcher: new IntrospectionFragmentMatcher({
                introspectionQueryResultData: {
                    __schema: {
                        types: [], // no types provided
                    },
                },
            }),
        }),
        onError: ({ graphQLErrors }) => {
            // If we receive an unauthorized error, we close the session and reload the page
            if (graphQLErrors) {
                graphQLErrors.map((e) => {
                    if (e.message.match(/unauthorized/i)) {
                        if (e.path?.includes("getMonitorAvailability")) {
                            //OPTIMIZE this is a temporal fix, we need to investigate why the query is executed automatically sometimes, being a lazyQuery
                            return;
                        }
                        Session.close();
                        if (window.location.hash && window.location.hash !== "#/login") {
                            // We only reload the page if we are not in the login page
                            window.location.reload();
                        }
                    }
                });
            }
        },
    });
};

export default App;
