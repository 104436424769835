import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CurrencyInput from "components/Inputs/CurrencyInput.jsx";
import Checkbox from "components/Inputs/Checkbox.jsx";
import Select from "components/Select";
import classNames from "classnames";

/**
 * This component is used to input a price with tax. It allows the user to input the price without tax and the tax separately.
 * @param {string} id - unique id for the input
 * @param {string} currencyCode - currency code for the price (e.g. "EUR")
 * @param {number} price - price in cents
 * @param {number} taxID - id of the tax
 * @param {Array} taxes - array of taxes with id, name and value
 * @param {function} onChange - function to call when the price or tax changes
 * @param {string} design - design of the component (optional, values are "default" or "product")
 * @returns {JSX.Element} - PriceWithTax component
 */
const PriceWithTax = ({
    id: inputID,
    currencyCode,
    price: priceCents,
    taxID,
    taxes,
    onChange,
    design: customDesign,
}) => {
    const { t } = useTranslation();

    const design = customDesign || "default";

    const showCheckbox = design === "default";

    const defaultTax = taxes ? taxes.find((t) => t.default) : null;

    const storedTax = taxID && taxes ? taxes.find((t) => Number(t.id) === Number(taxID)) : null;

    const price = priceCents / 100 || 0;
    const priceWithTax = applyTax(price, storedTax?.value ?? defaultTax?.value);

    const [userChange, setUserChange] = useState(false);
    const [priceChecked, setPriceChecked] = useState(priceCents > 0 || !showCheckbox);
    const [newPriceWithoutTax, setNewPriceWithoutTax] = useState(price ?? null);
    const [newPriceWithTax, setNewPriceWithTax] = useState(priceWithTax ?? null);
    const [newTaxID, setNewTaxID] = useState(storedTax?.id ?? defaultTax?.id);

    const newTax = newTaxID && taxes ? taxes.find((t) => Number(t.id) === Number(newTaxID)) : null;

    useEffect(() => {
        if (!priceChecked) {
            setNewPriceWithoutTax(0);
            setNewPriceWithTax(0);
        }
    }, [priceChecked]);

    useEffect(() => {
        if (userChange) {
            setUserChange(false);
            if (onChange) {
                onChange({
                    price: newPriceWithoutTax === null ? null : Math.round(newPriceWithoutTax * 100),
                    tax: newTax,
                    total: newPriceWithTax === null ? null : Math.round(newPriceWithTax * 100),
                });
            }
        }
    }, [newPriceWithoutTax, newTaxID, newPriceWithTax]);

    useEffect(() => {
        setNewPriceWithTax(applyTax(newPriceWithoutTax, newTax?.value ?? 0));
    }, [newTaxID]);

    useEffect(() => {
        if (taxID && !storedTax?.id) {
            console.warn("Tax not found for id", {
                taxID,
                taxes,
                storedTax,
                defaultTax,
            });
        }
    }, []);

    useEffect(() => {
        const price = priceCents / 100 || 0;
        const priceWithTax = applyTax(price, storedTax?.value ?? defaultTax?.value);
        setNewPriceWithoutTax(price ?? null);
        setNewPriceWithTax(priceWithTax ?? null);
        setPriceChecked(price > 0 || !showCheckbox);
    }, [priceCents]);

    useEffect(() => {
        const priceWithTax = applyTax(newPriceWithoutTax, storedTax?.value ?? defaultTax?.value);
        setNewPriceWithTax(priceWithTax ?? null);
        setNewTaxID(storedTax?.id ?? defaultTax?.id);
    }, [storedTax]);

    const togglePrice = ({ checked }) => {
        setUserChange(true);
        setPriceChecked(checked);
    };

    const inputPriceID = inputID;
    const inputTaxID = `${inputID}-tax`;
    const inputTotalID = `${inputID}-total`;
    const inputCheckPriceID = `${inputID}-set`;

    const rowClass = classNames({
        "w-full flex items-center text-left mb-2 pl-1 ml-6": design === "default",
        "w-full grid grid-cols-2 text-left mb-2": design === "product",
    });

    const labelClass = classNames({ "w-32": true });
    const labelTotalClass = classNames({
        [labelClass]: true,
        "font-bold": design === "product",
    });

    const currencyInputClass = classNames({
        "w-32": design === "default",
        "w-full": design === "product",
    });

    const selectClass = classNames({
        "min-w-52": design === "default",
        "w-full": design === "product",
    });

    return (
        <>
            {showCheckbox ? (
                <div className="w-full flex items-center mb-2">
                    <Checkbox
                        id={inputCheckPriceID}
                        checked={priceChecked}
                        onChange={togglePrice}
                        label={t("set-price")}
                    />
                </div>
            ) : null}
            <div className={rowClass}>
                <div className={labelClass}>{t("without-tax")}</div>
                <CurrencyInput
                    id={inputPriceID}
                    value={newPriceWithoutTax}
                    disabled={!priceChecked}
                    onChange={(value) => {
                        setUserChange(true);
                        if (priceChecked) {
                            setNewPriceWithoutTax(value);
                            setNewPriceWithTax(applyTax(value, newTax?.value ?? 0));
                        }
                    }}
                    currencyCode={currencyCode}
                    className={currencyInputClass}
                />
            </div>
            <div className={rowClass}>
                <div className={labelClass}>{t("tax")}</div>
                <div className={selectClass}>
                    <Select
                        id={inputTaxID}
                        width="100%"
                        value={newTaxID}
                        disabled={!priceChecked}
                        warning={!newTaxID ? "Warning: No tax stored, please select one and save" : null}
                        onChange={(value) => {
                            setUserChange(true);
                            setNewTaxID(value);
                        }}
                        options={
                            taxes
                                ? taxes.map((t) => ({
                                      value: t.id,
                                      label: `${t.name} (${t.value}%)`,
                                  }))
                                : null
                        }
                    />
                </div>
            </div>
            <div className={rowClass}>
                <div className={labelTotalClass}>{t("total")}</div>
                <CurrencyInput
                    id={inputTotalID}
                    value={newPriceWithTax}
                    disabled={!priceChecked}
                    onChange={(value) => {
                        setUserChange(true);
                        if (priceChecked) {
                            const base = removeTax(value, newTax?.value ?? 0);
                            setNewPriceWithoutTax(base);
                            setNewPriceWithTax(applyTax(base, newTax?.value ?? 0));
                        }
                    }}
                    currencyCode={currencyCode}
                    className={currencyInputClass}
                />
            </div>
        </>
    );
};
PriceWithTax.displayName = "PriceWithTax";

const applyTax = (value, tax) => {
    if (isNaN(value)) {
        return value;
    }
    return tax ? Math.round((value * ((100 + tax) * 100)) / 100) / 100 : value;
};

const removeTax = (value, tax) => {
    return tax ? Math.round((value * 10000) / (100 + tax)) / 100 : value;
};

export default PriceWithTax;
