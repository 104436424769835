import { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { arrangeData, arrangeFloorFilter, arrangeLoactionFilter } from "../utils/topologyUtils";
import { GET_ROOMS_INFO } from "../utils/topologyQueries";
import { POLLINTERVAL_30S } from "hooks/Utils/Utils";

export const useListTopologyRooms = () => {
    const [roomsInformation, setRoomsInformation] = useState([]);
    const [locationFilter, setLocationFilter] = useState([]);
    const [floorFilter, setFloorFilter] = useState([]);

    const [executeRoomsInfoQuery, { data: roomsInfo, loading, error, refetch, networkStatus }] = useLazyQuery(
        GET_ROOMS_INFO,
        {
            pollInterval: POLLINTERVAL_30S,
        }
    );

    useEffect(() => {
        executeRoomsInfoQuery();
    }, []);

    useEffect(() => {
        if (roomsInfo && !loading && !error && networkStatus === 7) {
            const dataItems = arrangeData(roomsInfo?.rooms?.results);
            const filterFloor = arrangeFloorFilter(roomsInfo?.floors?.results);
            const filterLocation = arrangeLoactionFilter(roomsInfo?.locations?.results);
            setRoomsInformation(dataItems);
            setLocationFilter(filterLocation);
            setFloorFilter(filterFloor);
        }
    }, [roomsInfo, loading, error, networkStatus]);

    return { roomsInformation, locationFilter, floorFilter, refetch };
};
