// Defined in srv-datacenter
export const PERMISSION = {
    // Others
    corporateIdentity: "corporateIdentity",
    digitalKey: "digitalKey",
    guestSettings: "guestSettings",
    bookings: "bookings",
    capacity: "capacity",
    cleaning: "cleaning",
    staff: "staff",
    zonesConfig: "a&a",
    // Products
    productCORPORATE: "productCORPORATE",
    productCHAIN: "productCHAIN",
    productADVANCEDDESIGN: "productADVANCEDDESIGN",
    productCAST: "productCAST",
    productMOBILE: "productMOBILE",
    productGUEST: "productGUEST",
    productHOUSE: "productHOUSE",
    productSALES: "productSALES",
    productCHANNELS: "productCHANNELS",
    productTV: "productTV",
    productWIFI: "productWIFI",
    productAPPS: "productAPPS",
    productONDEMAND: "productONDEMAND",
    // Widgets
    wdgIframe: "wdgIframe",
    wdgHtml: "wdgHtml",
    wdgCarrousel: "wdgCarrousel",
    wdgSlider: "wdgSlider",
    wdgText: "wdgText",
    wdgLogo: "wdgLogo",
    wdgImage: "wdgImage",
    wdgVideo: "wdgVideo",
    wdgIcon: "wdgIcon",
    wdgWeather: "wdgWeather",
    wdgCalendar: "wdgCalendar",
    wdgClock: "wdgClock",
    wdgQrcode: "wdgQrcode",
    wdgContainer: "wdgContainer",
    wdgMessages: "wdgMessages",
    wdgChat: "wdgChat",
    wdgNavigElements: "wdgNavigElements",
    wdgLogin: "wdgLogin",
    wdgButton: "wdgButton",
    wdgAlarm: "wdgAlarm",
    wdgParental: "wdgParental",
    // Widget actions
    actBill: "actBill",
    actCheckout: "actCheckout",
    actParental: "actParental",
    actAreas: "actAreas",
    actMybookings: "actMybookings",
    actSales: "actSales",
    actListChannels: "actListChannels",
    actChannel: "actChannel",
    actTvinputs: "actTvinputs",
    actPos: "actPos",
    actSurveys: "actSurveys",
    actFeedback: "actFeedback",
    // Tags
    tagRoomnumber: "tagRoomnumber",
    tagRoomname: "tagRoomname",
    tagPairingurl: "tagPairingurl",
    tagRoomcode: "tagRoomcode",
    tagWifissid: "tagWifissid",
    tagWifipassword: "tagWifipassword",
    tagChromecastname: "tagChromecastname",
    tagGuesttitle: "tagGuesttitle",
    tagGuestname: "tagGuestname",
    tagGuestsurname: "tagGuestsurname",
    tagGuestfullname: "tagGuestfullname",
    tagGuestgroup: "tagGuestgroup",
    tagChekcoutdate: "tagChekcoutdate",
    // Legacy
    legacyCAST: "legacyCAST",
    legacyTV: "legacyTV",
    legacySALES: "legacySALES",
    legacyWIFI: "legacyWIFI",
    legacyGUEST: "legacyGUEST",
    legacyMOBILE: "legacyMOBILE",
    // Other permissions
    simpleInterface: "simpleInterface",
    multipleInterface: "multipleInterface",
    interfaceEditor: "interfaceEditor",
    appearancesEditor: "appearancesEditor",
    interfacePreview: "interfacePreview",
    statsCast: "statsCast",
    library: "library",
    libraryVideos: "libraryVideos",
    map: "map",
    monitoring: "monitoring",
    statusMail: "statusMail",
    castConfig: "castConfig",
    roomGroups: "roomGroups",
    roomList: "roomList",
    topology: "topology",
    networkAlerts: "networkAlerts",
    networkAlertsMail: "networkAlertsMail",
    networkMonitor: "networkMonitor",
    mobileConfig: "mobileConfig",
    loyaltyCustomers: "loyaltyCustomers",
    statsMobile: "statsMobile",
    checkInOut: "checkInOut",
    guestGroups: "guestGroups",
    guestList: "guestList",
    welcomeScreens: "welcomeScreens",
    messaging: "messaging",
    guestSatisfaction: "guestSatisfaction",
    taxManagement: "taxManagement",
    shopsAdministrator: "shopsAdministrator",
    shopsGeneralconfig: "shopsGeneralconfig",
    shopsManager: "shopsManager",
    shopsOrders: "shopsOrders",
    geolocation: "geolocation",
    statsSales: "statsSales",
    myOrders: "myOrders",
    orderSupervisedAcceptance: "orderSupervisedAcceptance",
    corporateChannels: "corporateChannels",
    channelList: "channelList",
    channelGroups: "channelGroups",
    channelGrids: "channelGrids",
    channelSettings: "channelSettings",
    multipleGrids: "multipleGrids",
    multipleCorporateChannels: "multipleCorporateChannels",
    alarms: "alarms",
    tvConfig: "tvConfig",
    interfaceMenu: "interfaceMenu",
    customQr: "customQr",
    tvInputs: "tvInputs",
    highAvailability: "highAvailability",
    checkoutInterface: "checkoutInterface",
    optTVPremium: "optTVPremium",
    wifiConfig: "wifiConfig",
    wifiPasscodes: "wifiPasscodes",
    statsWifi: "statsWifi",
    apps: "apps",
    movieList: "movieList",
    movieSettings: "movieSettings",
    revenueShare: "revenueShare",
    hollywoodContent: "hollywoodContent",
    adultContent: "adultContent",
    freeToguest: "freeToguest",
    freeTohotel: "freeTohotel",
    serviceNativeApps: "serviceNativeApps",
    nativeApps: "nativeApps",
    posIntegration: "posIntegration",
    preCheckin: "preCheckin",
};

// Defined in database ctrl-project.pc_system.accesses
export const ROLE = {
    // General Settings
    manageLanguages: "manageLanguages",
    manageRoles: "manageRoles",
    manageUsers: "manageUsers",
    staff: PERMISSION.staff,
    taxManagement: PERMISSION.taxManagement,

    // Customers - Alarms
    alarms: PERMISSION.alarms,

    // Legacy
    legacyAdmin: "legacyAdmin",

    // Property - Property Management
    manageBrands: "manageBrands",
    manageProperties: "manageProperties",

    // Property - Configuration
    castConfig: PERMISSION.castConfig,
    mobileConfig: PERMISSION.mobileConfig,
    topology: PERMISSION.topology,
    tvConfig: PERMISSION.tvConfig,
    tvInputs: PERMISSION.tvInputs,
    wifiConfig: PERMISSION.wifiConfig,

    // Property - Installation
    map: PERMISSION.map,
    monitoring: PERMISSION.monitoring,
    networkAlerts: PERMISSION.networkAlerts,
    networkAlertsMail: PERMISSION.networkAlertsMail,
    statusMail: PERMISSION.statusMail,

    // Property - Rooms
    roomGroups: PERMISSION.roomGroups,
    roomList: PERMISSION.roomList,

    // Customers - Guests
    guestGroups: PERMISSION.guestGroups,
    guestList: PERMISSION.guestList,
    guestSettings: PERMISSION.guestSettings,

    // Customers - Registered Customers
    loyaltyCustomers: PERMISSION.loyaltyCustomers,

    // Customers - Messages
    messaging: PERMISSION.messaging,

    // Customers - Wifi Passcodes
    wifiPasscodes: PERMISSION.wifiPasscodes,

    // Design
    appearancesEditor: PERMISSION.appearancesEditor,
    interfaceEditor: PERMISSION.interfaceEditor,
    library: PERMISSION.library,

    // Services - Sales
    shopsAdministrator: PERMISSION.shopsAdministrator,
    shopsGeneralconfig: PERMISSION.shopsGeneralconfig,
    shopsManager: PERMISSION.shopsManager,
    shopsOrders: PERMISSION.shopsOrders,

    // Services - TV Channels
    channelGrids: PERMISSION.channelGrids,
    channelGroups: PERMISSION.channelGroups,
    channelList: PERMISSION.channelList,
    channelSettings: PERMISSION.channelSettings,
    corporateChannels: PERMISSION.corporateChannels,

    // GOS
    zonesConfig: PERMISSION.zonesConfig,
    bookings: PERMISSION.bookings,
    capacity: PERMISSION.capacity,
    cleaning: PERMISSION.cleaning,

    // Wifi
    networkAdmin: "networkAdmin",
    networkManagement: "networkManagement",

    // Statistics
    statsCast: PERMISSION.statsCast,
    statsMobile: PERMISSION.statsMobile,
    statsSales: PERMISSION.statsSales,
    statsWifi: PERMISSION.statsWifi,
};

export const validatePermission = (id) => {
    if (!id || !Object.values(PERMISSION).includes(id)) {
        console.error(`Permission "${id}" is not defined`);
        return false;
    }
    return true;
};

export const validateRole = (id) => {
    if (!id || !Object.values(ROLE).includes(id)) {
        console.error(`Role "${id}" is not defined`);
        return false;
    }
    return true;
};
