import React, { useEffect, useState } from "react";
import classNames from "classnames";

/**
 * @description
 * @component
 * @param {string} id - ID for the input
 * @param {string} tooltip - Text for tooltip
 * @param {string} label - Label for the input
 * @param {boolean} checked - Checked state of the input
 * @param {boolean} uncheck - Allow to uncheck the input
 * @param {boolean} disabled - Disable the input
 * @param {function} onChange - Function to call when the input changes
 * @param {string} className - Additional classes
 * @param {(radio|checkbox|switch)} design - Design of the input
 * @returns {JSX.Element} - Toggle component
 * @example
 * <Toggle id="input-id" checked={false} onChange={(value) => console.log(value)} className="w-1/2" />
 */
const Toggle = ({
    id,
    label,
    checked: extChecked,
    uncheck = true,
    disabled,
    onChange,
    value,
    className,
    on: customON,
    off: customOFF,
    design = "checkbox",
    tooltip = null,
}) => {
    const [checked, setChecked] = useState(extChecked);

    const isRadio = design === "radio";
    const isSwitch = design === "switch";
    const isCheckbox = design === "checkbox" || (!isRadio && !isSwitch);

    const hasCustomState = (checked && customON) || (!checked && customOFF);

    const baseClasses = classNames({
        [`toggle-${design}`]: !hasCustomState,
        [`toggle-${design}-disabled`]: !hasCustomState && disabled,
        [`toggle-${design}-checked`]: !hasCustomState && checked,
    });

    const handleCheck = () => {
        if (checked && !uncheck) {
            // Can't be unchecked
            return;
        }
        setChecked(!checked);
        if (onChange) {
            onChange({ checked: !checked, value });
        }
    };

    useEffect(() => {
        setChecked(extChecked);
    }, [extChecked]);

    return (
        <div
            id={`checkbox-${id}`}
            className={`flex items-center ${disabled ? "cursor-default" : "cursor-pointer"} ${className || ""}`}
            onClick={disabled ? null : handleCheck}
            data-tip={tooltip ? tooltip : null}
            data-for={"dangerous-html-tooltip"}
        >
            {hasCustomState ? (
                <>{checked ? customON : customOFF}</>
            ) : (
                <div className={baseClasses}>
                    <div className={isCheckbox ? "icon icon-tick-right" : ""} />
                </div>
            )}
            {label ? (
                <div id={id + "-label"} className={`pl-2 ${disabled ? "text-gray-500" : ""}`}>
                    {label}
                </div>
            ) : null}
        </div>
    );
};
Toggle.displayName = "Toggle";

export default Toggle;
