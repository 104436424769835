import React, { useState } from "react";
import { useSelector } from "react-redux";
import { parseTranslation, getProjectLangs } from "../../hooks/Utils/SalesUtils";
import { useTranslation } from "react-i18next";
import { Session } from "../../hooks/Utils/Session";

const UseModalTaxInUse = ({ index }) => {
    const { data: modalData } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { projectLangs } = useSelector((state) => state.ui);
    const { t } = useTranslation();

    const projects = JSON.parse(Session.getSessionProp("projects")) || [];
    const idTax = modalData?.taxZone?.id ? Number(modalData.taxZone.id) : null;

    const moviesProjectNames =
        idTax && modalData?.moviesData?.length > 0
            ? modalData.moviesData.filter((s) => s.project && s.project.name).map((s) => s.project.name)
            : [];

    const shopsRelatedToTax =
        idTax && modalData?.shopsData?.length > 0
            ? modalData.shopsData.filter((s) => Number(s.defaultTaxZone.id) === idTax)
            : [];

    const projectsGrouped = shopsRelatedToTax.reduce((acc, shop) => {
        const projectMatch = projects.find((p) => p.ref === shop.projectRef);
        const projectName = projectMatch ? projectMatch.name : "Unknown Project";

        if (!acc[projectName]) {
            acc[projectName] = { shops: [] };
        }

        acc[projectName].shops.push(parseTranslation(shop.name, getProjectLangs(projectLangs, t).langDefault));
        return acc;
    }, {});

    const projectCount = Object.keys(projectsGrouped).length;

    console.log(`Número de proyectos únicos: ${projectCount}`);

    const [expandedProjects, setExpandedProjects] = useState({});

    const toggleProject = (projectName) => {
        setExpandedProjects((prevState) => ({
            ...prevState,
            [projectName]: !prevState[projectName],
        }));
    };

    return (
        <div>
            <div
                style={{ minHeight: "21.45rem", maxHeight: "21.45rem", overflowY: "scroll" }}
                className="border-t border-b rounded border-gray-200"
            >
                {Object.entries(projectsGrouped).map(([projectName, { shops }], index, array) => (
                    <div
                        key={projectName}
                        className={`py-2 ${index !== 0 ? "border-t border-gray-200" : ""} ${
                            index === array.length - 1 ? "border-b border-gray-200" : ""
                        }`}
                    >
                        <div className="flex items-center cursor-pointer" onClick={() => toggleProject(projectName)}>
                            <i
                                className={`fas py-4 fa-chevron-${expandedProjects[projectName] ? "up" : "down"} mr-3`}
                            ></i>
                            <span className="font-bold block w-full cursor-pointer">{projectName}</span>
                        </div>
                        {expandedProjects[projectName] && (
                            <>
                                {moviesProjectNames.includes(projectName) && (
                                    <div className="pl-6 py-4 border-t border-gray-200">
                                        <span className="block w-full">{`${t("movies")}`}</span>
                                    </div>
                                )}

                                {shops.map((shopName) => (
                                    <div key={shopName} className="pl-6 py-4 border-t border-gray-200">
                                        <span className="block w-full">{`${t("shop")} - ${shopName}`}</span>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UseModalTaxInUse;
