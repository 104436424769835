import Button from "components/Button";
import Icon from "components/Icon";
import { satisfactionLevelValues } from "hooks/GraphqlCalls/Customers/Satisfaction/utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { parseISO, format, isValid } from "date-fns";
import useModalFeedbackHistory from "hooks/GraphqlCalls/Customers/Satisfaction/modals/useModalFeedbackHistory";

const GuestInfoFeedback = ({ classNameContainer = "", roomId }) => {
    const { t } = useTranslation();
    const { stayFeedbacks, data } = useSelector((state) => state.sectionContent);

    const { open: openModalFeedbackHistory } = useModalFeedbackHistory();

    const guestID = data?.guestID || null;
    const roomID = roomId;
    const latestFeedback = stayFeedbacks?.[stayFeedbacks.length - 1] || {};
    const { satisfactionLevel = null, createdAt = null } = latestFeedback;

    let feedbackTime = "N/A";
    let feedbackDate = "N/A";

    console.log(roomId);

    if (createdAt) {
        const parsedDate = parseISO(createdAt);
        if (isValid(parsedDate)) {
            const formattedFeedbackDate = format(parsedDate, "HH:mm dd/MM/yyyy");
            [feedbackTime, feedbackDate] = formattedFeedbackDate.split(" ");
        }
    }

    return (
        <div className={` ${classNameContainer} bg-white px-8  py-5 mb-8`}>
            <div className="border-b border-gray-200 text-lg py-2 flex justify-between">
                <div className="capitalize">{`${t("stay-feedback")} (${stayFeedbacks?.length || 0})`}</div>
            </div>
            <div>
                <div className="flex justify-between mt-4">
                    <div>{t("latest-feedback")}</div>
                    <Button
                        id="see-more-latest-feedback"
                        onClick={() => {
                            openModalFeedbackHistory({ guestID: guestID, roomID: roomID });
                        }}
                        className="text-zafiro-600 font-bold"
                    >
                        {t("see-more")}
                    </Button>
                </div>
                {satisfactionLevel && createdAt && (
                    <div className="flex items-center mt-5">
                        <Icon
                            type={satisfactionLevelValues[satisfactionLevel]?.icon}
                            style={satisfactionLevelValues[satisfactionLevel]?.style}
                            size={1.8}
                        />
                        <div className="ml-4 text-gray-700 font-bold">{feedbackTime}</div>
                        <div className="ml-4 text-gray-700 font-bold">{feedbackDate}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GuestInfoFeedback;
