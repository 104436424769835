import React from "react";

import UseBorder from "../../../components/Section/Design/Sales/stylesEditor/DropDownOptions/UseBorder";
import UseColor from "../../../components/Section/Design/Sales/stylesEditor/DropDownOptions/UseColor";
import UseColors from "../../../components/Section/Design/Sales/stylesEditor/DropDownOptions/UseColors";
import UseOpacity from "../../../components/Section/Design/Sales/stylesEditor/DropDownOptions/UseOpacity";
import UseStatuses from "../../../components/Section/Design/Sales/stylesEditor/DropDownOptions/UseStatuses";
import UseGroup from "../../../components/Section/Design/Sales/stylesEditor/DropDownOptions/UseGroup";
import { SCREEN } from "../../Utils/Design/EditorUtils";
import { LATO_FONT, ROBOTO_FONT, textAlignment as textAlignmentConst } from "./FontUtils";
import i18next from "i18next";
import { highlightColor } from "../../../components/Section/Design/Sales/layouts/utils";
import { px2vw } from "../DesignUtils";

export const EXTERNAL = "EXTERNAL";

export const MOCK_SALES_DATA = {
    useHomeBg: true,
    title: LATO_FONT,
    text: { ...ROBOTO_FONT },
};

export const SALES_TOOLBAR = {
    SETTINGS: "settings",
    STYLES: "color",
};

export const BACKGROUND = {
    IMAGE_TYPE: { LIBRARY: "libraryImage", EXTERNAL: "externalImage" },
};

export const SALES_SEQUENCE = {
    [SCREEN.TYPE.SALES]: {
        SHOPS: {
            pos: 1,
            name: "shops-list",
            divToFocus: "shop-tok_token",
            targets: ["items", "section1", "section3"],
        },
        PRODUCTS_LIST: {
            pos: 2,
            name: "products-list",
            divToFocus: "536-item-465-339",
            targets: ["buttons", "items", "section1", "section2", "section3"],
        },
        PRODUCT_DETAIL: {
            pos: 3,
            name: "product-detail",
            divToFocus: "extra-239",
            targets: ["buttons", "items", "section1", "section2", "section3"],
        },
        CART: {
            pos: 4,
            name: "cart",
            divToFocus: "cart-entry-0",
            targets: ["buttons", "items", "section1", "section3"],
        },
        ORDERS: {
            pos: 5,
            name: "my-orders-history",
            divToFocus: "my-order-1234",
            targets: ["general", "details", "section1", "section2", "section3", "items"],
        },
    },
    [SCREEN.TYPE.SALESMOBILE]: {
        SHOPS: {
            pos: 1,
            name: "shops-list",
            targets: ["general", "section1"],
        },
        PRODUCTS_LIST: {
            pos: 2,
            name: "products-list",
            targets: ["general", "items", "details", "section1", "section2"],
        },
        PRODUCT_DETAIL: {
            pos: 3,
            name: "product-detail",
            targets: ["general", "details", "section1", "section2"],
        },
        CART: {
            pos: 4,
            name: "cart",
            targets: ["general", "details", "section1", "section2"],
        },
        ORDERS: {
            pos: 5,
            name: "my-orders-history",
            targets: ["general", "details", "section1"],
        },
    },
    nextScreen: function (type, current) {
        let _next = Object.keys(SALES_SEQUENCE[type]).find((key) => SALES_SEQUENCE[type][key].pos > current.pos);
        return SALES_SEQUENCE[type][_next];
    },
    prevScreen: function (type, current) {
        let _prev = Object.keys(SALES_SEQUENCE[type]).find((key) => current.pos - 1 === SALES_SEQUENCE[type][key].pos);
        return SALES_SEQUENCE[type][_prev];
    },
    length: function (type) {
        let _highest = 1;
        Object.keys(SALES_SEQUENCE[type]).forEach((key) => {
            if (SALES_SEQUENCE[type][key].pos > _highest) {
                _highest = SALES_SEQUENCE[type][key].pos;
            }
        });
        return _highest;
    },
};

export const SALES_OPTIONS_KEYS = {
    COLOR: "color",
    BORDER: "border",
    COLORS: "colors",
    STATUS: "status",
    SECTIONS: "sections",
    OPACITY: "opacity",
};

export const STATUS = {
    DEFAULT: "default",
    ACTIVE: "active",
    FOCUS: "focus",
    DISABLED: "disabled",
};

const WHITE_PERCENT = (percent) => {
    return `rgba(255,255,255,${Number(percent) / 100})`;
};

export const SALES_STYLES_OPTIONS = {
    [SCREEN.TYPE.SALES]: (theme) => {
        return {
            background: { opacity: 100, color: WHITE_PERCENT(100), targets: false },
            buttons: {
                status: {
                    default: {
                        colors: {
                            bgColor: theme?.default?.rgbaBG,
                            fgColor: theme?.default?.rgbaFG,
                        },
                        border: {
                            color: "#D3DAE1",
                            width: 1,
                        },
                    },
                    active: {
                        colors: {
                            bgColor: theme?.active?.rgbaBG,
                            fgColor: theme?.active?.rgbaFG,
                        },
                        border: {
                            color: WHITE_PERCENT(100),
                            width: 0,
                        },
                    },
                    focus: {
                        colors: {
                            bgColor: theme?.focus?.rgbaBG,
                            fgColor: theme?.focus?.rgbaFG,
                        },
                        border: {
                            color: "rgba(34,134,239,0.8)",
                            width: 5,
                        },
                    },
                },
                targets: true,
            },
            items: {
                status: {
                    focus: {
                        colors: {
                            bgColor: theme?.focus?.rgbaBG,
                            fgColor: theme?.focus?.rgbaFG,
                        },
                    },
                },
                targets: true,
            },
            section1: {
                colors: {
                    bgColor: "#76889B",
                    fgColor: WHITE_PERCENT(100),
                },
                targets: true,
            },
            section2: {
                status: {
                    default: {
                        colors: {
                            fgColor: WHITE_PERCENT(100),
                            bgColor: "#76889B",
                        },
                    },
                    focus: {
                        colors: {
                            fgColor: theme?.focus?.rgbaFG,
                            bgColor: theme?.focus?.rgbaBG,
                        },
                    },
                },
                targets: true,
            },
            section3: {
                colors: {
                    bgColor: "rgba(245,246,248,0.8)",
                    fgColor: "#2E3843",
                },
                targets: true,
            },
        };
    },
    [SCREEN.TYPE.SALESMOBILE]: (theme) => {
        return {
            general: {
                colors: {
                    bgColor: "#F5F6F8",
                    fgColor: "#2E3843",
                },
                targets: true,
            },
            items: {
                status: {
                    default: {
                        sections: {
                            tabs1: {
                                colors: {
                                    bgColor: theme?.default?.rgbaBG,
                                    fgColor: theme?.default?.rgbaFG,
                                },
                            },
                            tabs2: {
                                colors: {
                                    fgColor: "#2E3843",
                                    bgColor: "#FBFBFB",
                                },
                                border: {
                                    color: "#D3DAE1",
                                    width: 1,
                                    radius: 0.5,
                                },
                            },
                        },
                    },
                    active: {
                        sections: {
                            tabs1: {
                                colors: {
                                    fgColor: theme?.active?.rgbaFG,
                                },
                            },
                            tabs2: {
                                colors: {
                                    fgColor: "#FFFFFF",
                                    bgColor: "#76889B",
                                },
                                border: {
                                    color: null,
                                    width: 1,
                                },
                            },
                        },
                    },
                },
                targets: true,
            },
            details: {
                colors: {
                    bgColor: "#2E3843",
                    fgColor: "#FBFBFB",
                },
                targets: true,
            },
            section1: {
                colors: {
                    bgColor: theme?.default?.rgbaBG,
                    fgColor: theme?.default?.rgbaFG,
                    iconColor: theme?.active?.rgbaFG,
                },
                targets: true,
            },
            section2: {
                status: {
                    default: {
                        sections: {
                            general: {
                                colors: {
                                    bgColor: theme?.default?.rgbaBG,
                                    fgColor: theme?.default?.rgbaFG,
                                },
                            },
                            button: {
                                colors: {
                                    bgColor: "#FBFBFB",
                                    fgColor: "#2E3843",
                                },
                                border: {
                                    color: "#D3DAE1",
                                    width: 1,
                                    radius: 0,
                                },
                            },
                        },
                    },
                    disabled: {
                        sections: {
                            button: {
                                colors: {
                                    bgColor: "#D3DAE1",
                                    fgColor: "#FBFBFB",
                                },
                                border: {
                                    color: null,
                                    width: 0,
                                },
                            },
                        },
                    },
                },
                targets: true,
            },
        };
    },
};

export const ID = (field, group) => {
    if (group) {
        return `${field}--${group}`;
    }
    return field;
};

export const decodeID = (id) => {
    const [field, group] = id.split("--");
    return { field, group };
};

export const printOptions = (keyOption, data, id, group, noTitles = false) => {
    const layout = [];
    switch (keyOption) {
        case SALES_OPTIONS_KEYS.OPACITY:
            layout.push(
                <UseOpacity key={"useOpacity"} showTitle={!noTitles} data={data} id={id || keyOption} group={group} />
            );
            break;
        case SALES_OPTIONS_KEYS.COLOR:
            layout.push(
                <UseColor key={"useColor"} showTitle={!noTitles} data={data} id={id || keyOption} group={group} />
            );
            break;
        case SALES_OPTIONS_KEYS.COLORS:
            layout.push(
                <UseColors key={"useColors"} showTitle={!noTitles} data={data} id={id || keyOption} group={group} />
            );
            break;
        case SALES_OPTIONS_KEYS.BORDER:
            layout.push(
                <UseBorder key={"useBorder"} showTitle={!noTitles} data={data} id={id || keyOption} group={group} />
            );
            break;
        case SALES_OPTIONS_KEYS.STATUS:
            layout.push(<UseStatuses sectionData={data} key={"useStatus"} id={id || keyOption} group={group} />);
            break;
        case SALES_OPTIONS_KEYS.SECTIONS:
            if (data && typeof data === "object") {
                const items = Object.keys(data);
                items.forEach((key, index) => {
                    layout.push(<UseGroup data={data[key]} key={key} id={key} />);
                    if (index != items.length - 1) {
                        layout.push(<div className="border-b px-2 border-gray-300 pt-2"></div>);
                    }
                });
            }
            break;
        default:
            return null;
    }
    return layout && layout.length > 0 ? layout : null;
};
export const ORDERED_PRINT_LIST = {
    [SCREEN.TYPE.SALES]: (theme) => Object.keys(SALES_STYLES_OPTIONS[SCREEN.TYPE.SALES](theme)),
    [SCREEN.TYPE.SALESMOBILE]: (theme) => Object.keys(SALES_STYLES_OPTIONS[SCREEN.TYPE.SALESMOBILE](theme)),
};

export const parseSalesPredefinedScreenData = (type, data, theme) => {
    let parsedData = {
        background: {
            useHomeBg: true,
            libraryRef: null,
            externalUrl: null,
            type: BACKGROUND.IMAGE_TYPE.LIBRARY,
        },
        title: MOCK_SALES_DATA.title,
        text: MOCK_SALES_DATA.text,
        currentScreen: SALES_SEQUENCE[type].SHOPS,
        salesStyleData: SALES_STYLES_OPTIONS[type](theme),
        ordersButton: null,
        salesTitleData: {
            text: "\xa0",
            alignment: textAlignmentConst.CENTER,
            showOptions: false,
        },
        styleInputData: { key: {}, value: null },
    };
    // parse JSON
    const dataPS = JSON.parse(data);

    const { typography, title, background, style, textAlignment, ordersButton } = dataPS || {
        typography: null,
        title: null,
        textAlignment: textAlignmentConst.CENTER,
        background: null,
        style: null,
    };
    // setters
    if (typography) {
        setTypography(typography, parsedData);
    }
    if (title && textAlignment) {
        setTitle({ title, textAlignment }, parsedData);
    }
    if (background) {
        setBackground(background, parsedData);
    }
    if (style) {
        setStyle(style, parsedData);
    }

    if (ordersButton !== null) {
        setOrdersButton(ordersButton, parsedData);
    }

    return parsedData;
};

const setTypography = (values, result) => {
    if (values?.text?.name) {
        result.text.name = values.text.name;
    }
    if (values?.title?.name) {
        result.title.name = values.title.name;
    }
    if (values?.text?.externalUrl) {
        result.text.ref = EXTERNAL;
        result.text.externalUrl = values.text.externalUrl;
    }
    if (values?.text?.libraryRef) {
        result.text.ref = values.text.libraryRef;
        result.text.externalUrl = null;
    }
    if (values?.title?.externalUrl) {
        result.title.ref = EXTERNAL;
        result.title.externalUrl = values.title.externalUrl;
    }
    if (values?.title?.libraryRef) {
        result.title.ref = values.title.libraryRef;
        result.title.externalUrl = null;
    }
};
const setTitle = (values, result) => {
    if (values?.title?.text) {
        result.salesTitleData.text = values.title.text;
    }
    if (values?.title?.id) {
        result.salesTitleData.id = values.title.id;
    }
    if (values?.textAlignment) {
        result.salesTitleData.alignment = values.textAlignment;
    }
};
const setBackground = (values, result) => {
    if (Object.keys(values).includes("useHomeBg")) {
        result.background.useHomeBg = values.useHomeBg;
    }
    if (values?.libraryRef) {
        result.background.libraryRef = values.libraryRef;
        result.background.type = BACKGROUND.IMAGE_TYPE.LIBRARY;
    }
    if (values?.externalUrl) {
        result.background.externalUrl = values.externalUrl;
        result.background.type = BACKGROUND.IMAGE_TYPE.EXTERNAL;
    }
};
const setStyle = (values, result) => {
    if (values?.general || values?.background) {
        result.salesStyleData = values;
    }
};

const setOrdersButton = (values, result) => {
    if (values === false || values === true) {
        result.ordersButton = values;
    }
};

export const STYLE_SECTIONS = {
    ROOMSHOPS: "roomshops",
    CART: "cart",
    PRODUCTS: "products",
    PRODUCTDETAILS: "productDetails",
    ALL: "default",
};

// get prop from an object
export const getProp = (obj, key) =>
    key.split(".").reduce((o, x) => (typeof o == "undefined" || o === null ? o : o[x]), obj);

export const parseColorsToStyles = (screenType, theme, styles, highlightedSection) => {
    const colors = styles || (screenType ? SALES_STYLES_OPTIONS[screenType](theme) : null);

    const getButtons = () => {
        return {
            default: {
                color: getProp(colors, "buttons.status.default.colors.fgColor"),
                backgroundColor: getProp(colors, "buttons.status.default.colors.bgColor"),
                borderColor: getProp(colors, "buttons.status.default.border.color"),
                borderWidth: getProp(colors, "buttons.status.default.border.width"),
            },
            active: {
                color: getProp(colors, "buttons.status.active.colors.fgColor"),
                backgroundColor: getProp(colors, "buttons.status.active.colors.bgColor"),
                borderColor: getProp(colors, "buttons.status.active.border.color"),
                borderWidth: getProp(colors, "buttons.status.active.border.width"),
            },
            focus: {
                color: getProp(colors, "buttons.status.focus.colors.fgColor"),
                backgroundColor: getProp(colors, "buttons.focus.active.colors.bgColor"),
                borderColor: getProp(colors, "buttons.status.focus.border.color"),
                borderWidth: getProp(colors, "buttons.status.focus.border.width"),
            },
        };
    };
    const getSection2 = () => {
        return {
            default: {
                color: getProp(colors, "section2.status.default.colors.fgColor"),
                backgroundColor: getProp(colors, "section2.status.default.colors.bgColor"),
            },
        };
    };
    const getSection1 = () => {
        return {
            default: {
                color: getProp(colors, "section1.colors.fgColor"),
                backgroundColor: getProp(colors, "section1.colors.bgColor"),
            },
        };
    };
    const getSection3 = () => {
        return {
            default: {
                color: getProp(colors, "section3.colors.fgColor"),
                backgroundColor: getProp(colors, "section3.colors.bgColor"),
            },
        };
    };
    const EVAL_SECTION = {
        [SCREEN.TYPE.SALES]: {
            roomshops: () => {
                return {
                    items: {
                        focus: {
                            backgroundColor: getProp(colors, "items.status.focus.colors.bgColor"),
                        },
                    },
                    section1: getSection1(),
                    section3: getSection3(),
                };
            },
            cart: () => {
                return {
                    items: {
                        focus: {
                            color: getProp(colors, "items.status.focus.colors.fgColor"),
                            backgroundColor: getProp(colors, "items.status.focus.colors.bgColor"),
                        },
                    },
                    section1: getSection1(),
                    section3: getSection3(),
                    buttons: getButtons(),
                };
            },
            products: () => {
                return {
                    items: {
                        focus: {
                            borderColor: getProp(colors, "items.status.focus.colors.fgColor"),
                        },
                    },
                    section1: getSection1(),
                    section3: getSection3(),
                    buttons: getButtons(),
                    section2: getSection2(),
                };
            },
            productDetails: () => {
                return {
                    items: {
                        focus: {
                            backgroundColor: getProp(colors, "items.status.focus.colors.bgColor"),
                        },
                    },
                    section1: getSection1(),
                    section3: getSection3(),
                    buttons: getButtons(),
                    section2: getSection2(),
                };
            },
            default: () => {
                const roomshops = EVAL_SECTION[screenType].roomshops();
                const cart = EVAL_SECTION[screenType].cart();
                const products = EVAL_SECTION[screenType].products();
                const productDetails = EVAL_SECTION[screenType].productDetails();
                return { roomshops, cart, products, productDetails };
            },
        },
        [SCREEN.TYPE.SALESMOBILE]: {
            fgColor: colors?.general?.colors?.fgColor,
            bgColor: colors?.general?.colors?.bgColor,
            items: {
                tabs1: {
                    fgColor: colors?.items?.status?.default?.sections?.tabs1?.colors?.fgColor,
                    bgColor: colors?.items?.status?.default?.sections?.tabs1?.colors?.bgColor,
                },
                tabs2: {
                    fgColor: colors?.items?.status?.default?.sections?.tabs2?.colors?.fgColor,
                    bgColor: colors?.items?.status?.default?.sections?.tabs2?.colors?.bgColor,
                    borderColor: colors?.items?.status?.default?.sections?.tabs2?.border?.color,
                    borderWidth: colors?.items?.status?.default?.sections?.tabs2?.border?.width,
                    radius: colors?.items?.status?.default?.sections?.tabs2?.border?.radius,
                },
                details: {
                    fgColor: colors?.details?.colors?.fgColor,
                    bgColor: colors?.details?.colors?.bgColor,
                },
                section1: {
                    fgColor: colors?.section1?.colors?.fgColor,
                    bgColor: colors?.section1?.colors?.bgColor,
                    iconColor: colors?.section1?.colors?.iconColor,
                },
                general: {
                    fgColor: colors?.section2?.status?.default?.sections?.general?.colors?.fgColor,
                    bgColor: colors?.section2?.status?.default?.sections?.general?.colors?.bgColor,
                },
                button: {
                    fgColor: colors?.section2?.status?.default?.sections?.button?.colors?.fgColor,
                    bgColor: colors?.section2?.status?.default?.sections?.button?.colors?.bgColor,
                    borderColor: colors?.section2?.status?.default?.sections?.button?.border?.color,
                    borderWidth: colors?.section2?.status?.default?.sections?.button?.border?.width,
                    radius: colors?.section2?.status?.default?.sections?.button?.border?.radius,
                },
            },
            status: {
                active: {
                    items: {
                        tabs1: {
                            fgColor: colors?.items?.status?.active?.sections?.tabs1?.colors?.fgColor,
                            bgColor: colors?.items?.status?.active?.sections?.tabs1?.colors?.bgColor,
                        },
                        tabs2: {
                            fgColor: colors?.items?.status?.active?.sections?.tabs2?.colors?.fgColor,
                            bgColor: colors?.items?.status?.active?.sections?.tabs2?.colors?.bgColor,
                            borderColor: colors?.items?.status?.active?.sections?.tabs2?.border?.color,
                            borderWidth: colors?.items?.status?.active?.sections?.tabs2?.border?.width,
                            radius: colors?.items?.status?.active?.sections?.tabs2?.border?.radius,
                        },
                    },
                },
                disabled: {
                    items: {
                        button: {
                            fgColor: colors?.section2?.status?.disabled?.sections?.button?.colors?.fgColor,
                            bgColor: colors?.section2?.status?.disabled?.sections?.button?.colors?.bgColor,
                            borderColor: colors?.section2?.status?.active?.sections?.button?.border?.color,
                            borderWidth: colors?.section2?.status?.active?.sections?.button?.border?.width,
                        },
                    },
                },
            },
        },
    };

    let returnData = EVAL_SECTION[screenType];
    if (returnData && returnData.default && typeof returnData.default === "function") {
        returnData = returnData.default();
    }

    if (highlightedSection) {
        // add highlightedSection to styles
        if (screenType === SCREEN.TYPE.SALES) {
            Object.keys(returnData).forEach((screen) => {
                if (!returnData?.[screen]?.[highlightedSection]) {
                    returnData[screen][highlightedSection] = {};
                    returnData[screen][highlightedSection].default = {};
                } else if (!returnData?.[screen]?.[highlightedSection]?.default) {
                    returnData[screen][highlightedSection].default = {};
                }

                returnData[screen][highlightedSection].default.borderColor = highlightColor;
                returnData[screen][highlightedSection].default.borderWidth = "4px";
                returnData[screen][highlightedSection].default.borderStyle = "solid";
                returnData[screen][highlightedSection].default["zIndex"] = "20";
            });
        } else {
            returnData.highlight = highlightedSection;
        }
    }
    return returnData;
};

export const getSalesTexts = (cLang) => {
    const translate = i18next.getFixedT(cLang);
    return {
        "Delivery time": translate("delivery-time"),
        "Delivery hours": translate("delivery-hour"),
        "Delivery 24 hours": translate("24-hours-delivery"),
        cart: translate("cart"),
        "Empty cart": translate("empty-cart"),
        "Finalise your order": translate("finalise-order"),
        "add-for-price": translate("add-for-price"),
        "price-per-unit": translate("price-per-unit"),
        "Tax included": translate("tax included"),
        "Tax exculded": translate("tax-excluded"),
        total: translate("total"),
        my_orders: translate("my_orders"),
        shop_name: translate("shop_name"),
        order: translate("order"),
        delivery: translate("delivery"),
        total_price: translate("total_price"),
        state: translate("state"),
        order_date: translate("order_date"),
    };
};

export const generateFocusInSalesLibrary = (idToFocus, salesStyle, theme) => {
    const element = document.getElementById("salesPreviewiFrame")?.contentWindow?.document?.getElementById(idToFocus);

    const style = {};

    if (element) {
        console.log("CAMBIO FOCO STYLE");
        switch (idToFocus) {
            case SALES_SEQUENCE[SCREEN.TYPE.SALES].SHOPS.divToFocus:
                // box-shadow: 0 0 0 ${Theme.focus?.size} ${salesTheme?.style?.items?.status?.focus?.colors?.bgColor}
                style.boxShadow = `0 0 0 ${theme.focusBorderSize}px ${salesStyle?.items?.status?.focus?.colors?.bgColor}`;
                break;
            case SALES_SEQUENCE[SCREEN.TYPE.SALES].PRODUCTS_LIST.divToFocus:
                style.height = element.offsetHeight - theme.focusBorderSize * 2 + "px";
                style.marginTop = theme.focusBorderSize + "px";
                style.boxShadow = `0 0 0 ${theme.focusBorderSize}px ${salesStyle?.items?.status?.focus?.colors?.bgColor}`;
                break;
            case SALES_SEQUENCE[SCREEN.TYPE.SALES].PRODUCT_DETAIL.divToFocus:
            case SALES_SEQUENCE[SCREEN.TYPE.SALES].CART.divToFocus:
            case SALES_SEQUENCE[SCREEN.TYPE.SALES].ORDERS.divToFocus:
                style.backgroundColor = salesStyle?.items?.status?.focus?.colors?.bgColor;
                style.color = salesStyle?.items?.status?.focus?.colors?.fgColor;
                style.borderWidth =
                    px2vw(theme.focusBorderSize, document.getElementById("salesPreview").offsetWidth) + "vw";
                style.borderStyle = "solid";
                style.borderColor = salesStyle?.items?.status?.focus?.colors?.bgColor;
            default:
                break;
        }
        Object.keys(style).forEach((cssParam) => {
            element.style[cssParam] = style[cssParam];
        });
        if (idToFocus === SALES_SEQUENCE[SCREEN.TYPE.SALES].SHOPS.divToFocus) {
            element.parentElement.parentElement.style.display = "contents";
        }
    } else {
        console.log("RELANZO");
        //didn`t render iframe, throw again generateFocus...
        setTimeout(() => {
            generateFocusInSalesLibrary(idToFocus, salesStyle, theme);
        }, 400);
    }
};
