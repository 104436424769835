import React from "react";

import { Header } from "sections/playground";

import Button from "components/Button";
import Icon from "components/Icon";

import Table from "components/ZafiroTable";
import DropdownButton from "components/DropdownButton";

const Buttons = () => {
    const buttonStyles = ["REGULAR", "NARROW", "LINK", "ACTIONS"];

    const buttonVariants = ["DEFAULT", "ICON", "ICONS", "DISABLED"];

    const actionClickTest = (e) => console.log("Click on action", e);
    const buttonClickTest = (e) => console.log("Click on button", e);
    const Small = ({ children }) => (
        <span className="text-gray-700 text-sm font-normal whitespace-no-wrap">{children}</span>
    );

    return (
        <>
            <Header title="Buttons">
                Buttons are used to trigger actions and links. Buttons can contain a combination of text and icon.
            </Header>
            {buttonStyles.map((style) => {
                const text = (() => {
                    switch (style) {
                        case "LINK":
                            return "Text link";
                        case "ACTIONS":
                            return "Actions";
                        default:
                            return "Button";
                    }
                })();

                const buttonTypes =
                    style === "LINK"
                        ? ["PRIMARY", "SECONDARY"]
                        : ["PRIMARY", "SECONDARY", "DANGER", "BASIC", "DARK", "LIGHT"];
                const buttonDesign =
                    style === "LINK"
                        ? {
                              PRIMARY: "link",
                              SECONDARY: "classic-link",
                          }
                        : {
                              PRIMARY: "blue",
                              SECONDARY: "blue-outline",
                              DANGER: "red",
                              BASIC: "basic",
                              DARK: "dark-blue",
                              LIGHT: "white",
                          };

                return (
                    <div className="bg-white shadow-inner text-gray-900 p-10">
                        <h3 className="text-xl font-bold mb-5">{style}</h3>

                        <Table
                            id="table-buttons-regular"
                            cols={["variant", ...buttonTypes]}
                            header={{
                                variant: { width: "6rem" },
                                ...buttonTypes.reduce((acc, type) => {
                                    acc[type] = { title: <Small>{type}</Small> };
                                    return acc;
                                }, {}),
                            }}
                            rows={buttonVariants.map((variant) => ({
                                variant: <Small>{variant}</Small>,
                                ...buttonTypes.reduce((acc, type) => {
                                    const hasIcon = variant === "ICON" || variant === "ICONS";
                                    const hasIcons = variant === "ICONS";
                                    const design = buttonDesign[type.split(" ")?.[0]];
                                    const id = `btn-${type}-${style}-${variant}-${design}`;
                                    const randomIcon = () =>
                                        [
                                            "user",
                                            "delete",
                                            "category",
                                            "media",
                                            "alarm",
                                            "product",
                                            "zafiro-solid",
                                            "rooms",
                                        ].find(() => Math.random() > 0.5) || "zafiro-outline";
                                    acc[type] =
                                        style === "ACTIONS" ? (
                                            <DropdownButton
                                                id={id}
                                                design={design}
                                                disabled={variant === "DISABLED"}
                                                label={
                                                    hasIcon ? (
                                                        <div
                                                            id={`${id}-label`}
                                                            className="flex items-center space-x-2"
                                                            onClick={actionClickTest}
                                                        >
                                                            <Icon type={randomIcon()} />
                                                            <span>{text}</span>
                                                            {hasIcons && <Icon type={randomIcon()} />}
                                                        </div>
                                                    ) : (
                                                        {
                                                            id: `${id}-label`,
                                                            label: text,
                                                            onClick: actionClickTest,
                                                        }
                                                    )
                                                }
                                                options={[
                                                    {
                                                        id: `${id}-1`,
                                                        label: "Actions 2",
                                                        onClick: actionClickTest,
                                                    },
                                                    {
                                                        id: `${id}-2`,
                                                        label: "Actions 3",
                                                        onClick: actionClickTest,
                                                    },
                                                    {
                                                        id: `${id}-3`,
                                                        label: "Actions 4",
                                                        onClick: actionClickTest,
                                                    },
                                                ]}
                                            />
                                        ) : (
                                            <Button
                                                id={id}
                                                design={design}
                                                narrow={style === "NARROW"}
                                                disabled={variant === "DISABLED"}
                                                onClick={buttonClickTest}
                                            >
                                                {hasIcon ? (
                                                    <>
                                                        <Icon type={randomIcon()} />
                                                        <span>{text}</span>
                                                        {hasIcons && <Icon type={randomIcon()} />}
                                                    </>
                                                ) : (
                                                    text
                                                )}
                                            </Button>
                                        );
                                    return acc;
                                }, {}),
                            }))}
                        />
                    </div>
                );
            })}
        </>
    );
};

export default Buttons;
