import React from "react";

import { Component, ListComponents } from "sections/playground";

import Button from "components/Button";
import { useLocationMapModal } from "components/LocationMap";
import { useModal, useAlert } from "components/Modal";

const Modals = () => {
    const { open, close } = useModal();
    const { open: showMap } = useLocationMapModal();
    const { open: alert } = useAlert();

    return (
        <ListComponents>
            <Component title="Modals">
                <Button
                    id="basic-modal"
                    design="blue"
                    onClick={() =>
                        open({
                            title: "Modal title",
                            children: "Modal content",
                            footer: (
                                <>
                                    <Button
                                        id="btn-custom"
                                        design="white"
                                        className="px-10 bg-pink-200 text-pink-500 hover:text-pink-600"
                                        onClick={() => {
                                            alert("Custom button clicked");
                                        }}
                                    >
                                        Custom button
                                    </Button>
                                    <Button id="btn-exit" design="blue" onClick={close}>
                                        Exit
                                    </Button>
                                </>
                            ),
                        })
                    }
                >
                    useModal
                </Button>
                <Button
                    id="location-map"
                    design="blue"
                    onClick={() => showMap({ latitude: "41.6488", longitude: "-0.8891" })}
                >
                    useLocationMapModal
                </Button>
            </Component>
            <Component title="Alerts">
                <Button
                    design="blue"
                    id="alert-1"
                    onClick={() => alert("Lorem ipsum dolor sit amet, consectetur adipiscing elit.")}
                >
                    useAlert
                </Button>
            </Component>
        </ListComponents>
    );
};

export default Modals;
