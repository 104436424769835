import React, { useContext, useEffect } from "react";
import NumberInput from "components/NumberInput";
import { TopologyContext } from "contexts/Topology";
import { useTranslation } from "react-i18next";

export const TopologyNumberInputs = ({ type, value }) => {
    const { setTvs, setRoomNumber, roomNumberValidation } = useContext(TopologyContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (value) {
            if (type === "tvs") {
                setTvs(value);
            } else if (type === "room-number") {
                setRoomNumber(value);
            }
        }
    }, [value]);

    return (
        <NumberInput
            placeholder={type === "tvs" ? t("tvs") : t("room-number")}
            onChange={(value) => {
                if (type === "tvs") {
                    setTvs(value);
                } else if (type === "room-number") {
                    setRoomNumber(value);
                }
            }}
            value={value}
            className={type === "room-number" && roomNumberValidation}
        />
    );
};
