import React, { forwardRef, useImperativeHandle, useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useProjectConfig from "hooks/Data/useProjectConfig";
import useSalesConfig from "hooks/Data/useSalesConfig";
import { executeVendureQuery, getVendureApiData } from "hooks/Utils/Integrations/useVendure";

export const SalesContextDeprecated = React.createContext({
    data: null,
});

/**
 * @type {
 *      React.Context<{
 *      ref: null,
 *      credentials: {
 *          called: boolean,
 *          data: null,
 *          load: *,
 *          ready: boolean,
 *          loading: boolean,
 *          error: null
 *      },
 *      guestMessages: {
 *          called: boolean,
 *          data: {
 *              ACCEPTED: false,
 *              READY: false,
 *              DELETED: false,
 *              DELIVERY_FAILED: false,
 *              SCHEDULED_SUCCESS: false,
 *              INSTANT_SUCCESS: false
 *          },
 *          load: *,
 *          ready: boolean,
 *          loading: boolean,
 *          error: null
 *      },
 *      permissions: {
 *          ordersManagement: null,
 *          pmsManagement: null
 *      },
 *      pricesIncludeTax: (function(): Promise<never>)
 *      }>
 *  }
 */
export const SalesContext = React.createContext({
    ref: null, // Provider ref

    // Sales permissions
    permissions: {
        ordersManagement: null,
        pmsManagement: null,
    },

    // Show prices with tax (General settings)
    pricesIncludeTax: () => Promise.reject(new Error("Not implemented")),

    // Project credentials
    credentials: {
        called: false,
        loading: false,
        error: null,
        ready: false,
        data: null,
        load: () => {},
    },
});

const SalesProvider = forwardRef(({ children }, ref) => {
    const ordersManagement = useSelector((state) => state?.ui?.permissions?.services?.shopsOrders);
    const hasProductGuests = useSelector((state) => state?.ui?.permissions?.product?.guest);
    const pmsManagement = hasProductGuests;

    const [pricesIncludeTax, setPricesIncludeTax] = useState(null);
    const [credentials, setCredentials] = useState(null);
    const [credentialsError, setCredentialsError] = useState(null);

    const projectConfig = useProjectConfig({ concept: "project_credentials", pkg: "GENERAL" });
    const salesConfig = useSalesConfig();

    const getPricesIncludeTax = () => {
        return new Promise((resolve, reject) => {
            executeVendureQuery(getVendureApiData(), {
                queryBody: `{channels{customFields{price_field}}}`,
            })
                .then((res) => {
                    const result = res?.data?.channels?.[0]?.customFields?.price_field === "WithTax";
                    setPricesIncludeTax(result);
                    resolve(result);
                })
                .catch((e) => {
                    reject(e instanceof Error ? e : new Error(e));
                });
        });
    };

    const contextData = useMemo(
        () => ({
            ref,

            refresh: () => {
                if (ref?.current) {
                    ref.current.refresh();
                }
            },

            permissions: {
                ordersManagement,
                pmsManagement,
            },

            pricesIncludeTax: () =>
                new Promise((resolve, reject) => {
                    if (pricesIncludeTax === null) {
                        getPricesIncludeTax().then(resolve).catch(reject);
                    } else {
                        resolve(pricesIncludeTax);
                    }
                }),

            credentials: {
                called: projectConfig.called,
                loading: projectConfig.loading,
                error: projectConfig.error || credentialsError,
                ready: projectConfig.ready,
                data: credentials,
                load: () => {
                    if (projectConfig && !projectConfig.called) {
                        projectConfig.load();
                    }
                },
            },

            guestMessages: {
                called: salesConfig.called,
                loading: salesConfig.loading,
                error: salesConfig.error,
                ready: salesConfig.ready,
                data: salesConfig.data,
                load: () => {
                    if (salesConfig && !salesConfig.called) {
                        salesConfig.load();
                    }
                },
            },
        }),
        [ref, projectConfig, salesConfig, ordersManagement, credentials, credentialsError, pricesIncludeTax]
    );

    useImperativeHandle(ref, () => ({
        refresh: () => {
            if (projectConfig) {
                if (projectConfig?.called) {
                    projectConfig.load();
                }
            }
        },
    }));

    useEffect(() => {
        if (projectConfig.data) {
            try {
                setCredentials(parseProjectCredentials(projectConfig.data));
            } catch (e) {
                setCredentialsError(e);
            }
        }
    }, [projectConfig.data]);

    return <SalesContext.Provider value={contextData}>{children}</SalesContext.Provider>;
});

const parseProjectCredentials = (data) => {
    if (data) {
        let config;
        try {
            config = data ? JSON.parse(data) : null;
        } catch (e) {
            return e;
        }

        return {
            user: config?.user,
            password: config?.password,
        };
    }
};

export default SalesProvider;
