import React, { createContext, useMemo, useState, useCallback } from "react";
import { useCreateRoom } from "hooks/GraphqlCalls/Hotel/Settings/useCreateRoom";
// import { useEditRoom } from "hooks/GraphqlCalls/Hotel/Settings/useEditRoom";

export const TopologyContext = createContext({
    selectedWing: null,
    setSelectedWing: () => {},
    selectedFloor: null,
    setSelectedFloor: () => {},
    roomNumber: null,
    setRoomNumber: () => {},
    pmsNumber: null,
    setPmsNumber: () => {},
    name: null,
    setName: () => {},
    tvs: null,
    setTvs: () => {},

    //Validations
    wingValidation: {},
    floorValidation: {},
    roomNumberValidation: {},
    nameValidation: {},
    setWingValidation: () => {},
    setFloorValidation: () => {},
    setRoomNumberValidation: () => {},
    setNameValidation: () => {},

    editing: false,
    setEditing: () => {},
});

export const TopologyProvider = ({ children }) => {
    const [selectedWing, setSelectedWing] = useState(null);
    const [selectedFloor, setSelectedFloor] = useState(null);
    const [roomNumber, setRoomNumber] = useState(null);
    const [pmsNumber, setPmsNumber] = useState(null);
    const [name, setName] = useState(null);
    const [tvs, setTvs] = useState(null);
    const [location, setLocation] = useState([]);
    const [roomsTVs, setRoomsTVs] = useState([]);
    const [tvsLengthWhileEditing, setTvsLengthWhileEditing] = useState(null);
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const [wingValidation, setWingValidation] = useState({});
    const [floorValidation, setFloorValidation] = useState({});
    const [roomNumberValidation, setRoomNumberValidation] = useState({});
    const [nameValidation, setNameValidation] = useState({});
    const [locationValidation, setLocationValidation] = useState([]);

    const [editing, setEditing] = useState(false);
    const [creating, setCreating] = useState(false);

    const contextValue = useMemo(
        () => ({
            selectedWing,
            setSelectedWing,
            selectedFloor,
            setSelectedFloor,
            roomNumber,
            setRoomNumber,
            pmsNumber,
            setPmsNumber,
            name,
            setName,
            tvs,
            setTvs,
            wingValidation,
            setWingValidation,
            floorValidation,
            setFloorValidation,
            roomNumberValidation,
            setRoomNumberValidation,
            nameValidation,
            setNameValidation,
            location,
            setLocation,
            locationValidation,
            setLocationValidation,
            editing,
            setEditing,
            creating,
            setCreating,
            roomsTVs,
            setRoomsTVs,
            tvsLengthWhileEditing,
            setTvsLengthWhileEditing,
            loadingRefresh,
            setLoadingRefresh,
        }),
        [
            selectedWing,
            selectedFloor,
            roomNumber,
            pmsNumber,
            name,
            tvs,
            wingValidation,
            floorValidation,
            roomNumberValidation,
            nameValidation,
            location,
            locationValidation,
            editing,
            creating,
            roomsTVs,
            tvsLengthWhileEditing,
            loadingRefresh,
        ]
    );

    return <TopologyContext.Provider value={contextValue}>{children}</TopologyContext.Provider>;
};
