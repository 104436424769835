import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import UseInputText from "../Table/useInputText";
import { setActionInput } from "../../actions/globalActions";
import { Session } from "../../hooks/Utils/Session";
import { executeVendureQuery, getVendureApiData } from "../../hooks/Utils/Integrations/useVendure";
import { toast } from "react-toastify";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import { getProjectLangs } from "../../hooks/Utils/SalesUtils";
import { setLoadingModalContent } from "actions/uiActions";

const UseModalEditNameCategory = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [langDefault, setLangDefault] = useState("");
    const [langsArray, setLangsArray] = useState([]);
    const [objectNames, setObjectNames] = useState({});
    const [translationsCategory, setTranslationsCategory] = useState({});
    const [placeHolder, setPlaceHolder] = useState("");
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { projectLangs } = useSelector((state) => state.ui);
    const NAME_CATEGORY = "edit-name-category-";

    const tokenShop = Session.getSessionProp("tokenShop");

    const GET_SHOP_CATEGORY_BY_ID = `query {
        collection(id: "${data && data.idCategory}") {
        id
        name
        id
        slug
        translations {
            languageCode
            name
            slug
            id
        }
        customFields {
            image_url
            enabled
            can_have_children
        }
        filters {
            args {
              name
              value
            }
          }
        } 
      }`;

    const fetchCategoryById = async () => {
        const response = await executeVendureQuery(
            getVendureApiData(),
            { queryBody: GET_SHOP_CATEGORY_BY_ID },
            { "vendure-token": tokenShop }
        );
        if (response && response.data && !response.errors) {
            if (response.data.collection) {
                if (response.data.collection.translations) {
                    const obj = {};
                    for (let i = 0; i < response.data.collection.translations.length; i++) {
                        const item = response.data.collection.translations[i];
                        obj[item.languageCode] = {
                            name: item.name,
                            languageCode: item.languageCode,
                            slug: item.slug,
                            id: item.id ? item.id : "",
                        };
                    }
                    setObjectNames(obj);
                }
            }
            setTimeout(() => {
                dispatch(setLoadingModalContent(false));
            }, 500);
        } else {
            dispatch(setLoadingModalContent(false));
            toast.error(t("mutation-error"));
        }
    };

    useEffect(() => {
        setTimeout(() => {
            fetchCategoryById();
        }, 1500);
        dispatch(setLoadingModalContent(true));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (objectNames) {
            setTranslationsCategory(objectNames);
            const dataToAction = {
                "data-translations-obj-categories": objectNames,
                "id-category-shop": data.idCategory,
            };
            dispatch(setActionInput(dataToAction));
        }
        // eslint-disable-next-line
    }, [objectNames]);

    useEffect(() => {
        if (translationsCategory[langDefault]) {
            setPlaceHolder(translationsCategory[langDefault].name);
        }
        // eslint-disable-next-line
    }, [translationsCategory]);

    useEffect(() => {
        if (projectLangs) {
            setLangDefault(getProjectLangs(projectLangs, t).langDefault);
            setLangsArray(getProjectLangs(projectLangs, t).langs);
        }
        //eslint-disable-next-line
    }, [projectLangs]);

    return (
        <div className="relative">
            <div>
                <div> {`${t("default-language")}:`} </div>
                <div className=" mt-2 flex items-center mb-4 w-full">
                    <div className=" font-bold w-3/12">{`${t("language:" + langDefault)}`}</div>
                    <div className=" w-9/12 ">
                        <UseInputText
                            inputData={{
                                name: NAME_CATEGORY + "languages-for-category-" + langDefault,
                            }}
                            validation={true}
                            currentValue={
                                translationsCategory[langDefault] && translationsCategory[langDefault].name
                                    ? translationsCategory[langDefault].name
                                    : ""
                            }
                            onChangeAction={(val) => {
                                setPlaceHolder(val);
                            }}
                            id={NAME_CATEGORY + "languages-for-category-" + langDefault}
                        />
                    </div>
                </div>
                <div className=" mb-5"> {`${capitalizeFirst(t("translations"))}:`} </div>
                <div>
                    {langsArray && langsArray.length > 0
                        ? langsArray.map((lang) => {
                              return (
                                  <div className=" mt-2 mb-4 flex items-center w-full">
                                      <div className=" font-bold w-3/12">{`${t("language:" + lang.languageRef)}`}</div>
                                      <div className=" w-9/12  ">
                                          <UseInputText
                                              inputData={{
                                                  name: NAME_CATEGORY + "languages-for-category-" + lang.languageRef,
                                              }}
                                              validation={true}
                                              currentValue={
                                                  translationsCategory[lang.languageRef] &&
                                                  translationsCategory[lang.languageRef].name
                                                      ? translationsCategory[lang.languageRef].name
                                                      : ""
                                              }
                                              ph={placeHolder}
                                              id={NAME_CATEGORY + "languages-for-category-" + lang.languageRef}
                                          />
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </div>
        </div>
    );
};

export default UseModalEditNameCategory;
