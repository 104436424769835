import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import TableContext from "./Context";

import Top from "./Top";
import Body from "./Body";
import Pages from "./Pages";

import { forceString } from "hooks/Utils/Utils";

import Checkbox from "components/Inputs/Checkbox";
import Dropdown from "components/Dropdown";
import Button from "components/Button";
import Icon from "components/Icon";
import Loading from "components/Loading";

/**
 * @description A table component
 * @param {Array} cols - The columns to display
 * @param {Array} rows - The rows to display
 * @param {Object} header - The header of the table
 * @param {Boolean} paginate - If the table should be paginated
 * @param {Boolean} search - If the table should have a search input
 * @param {Array} colsWidth - The width of each column
 * @param {Boolean} showCount - If the table should show the count of elements (default: true)
 * @param {Number} perPage - The number of elements per page (default: 6)
 * @returns {JSX.Element} The table component
 */
const Table = forwardRef(
    (
        {
            id,
            children,
            cols,
            rows,
            header,
            loading,
            batchActions,
            paginate,
            search: displaySearch,
            page = 1,
            perPage = 6,
            height,
            minHeight,
            maxHeight,
            filters,
            showCount = false,
            topRightCorner,
            sort: sortInfo,
            customTexts,
            className,
            bodyClassName,
            folders,
            style,
            disabled,
            scrollable = false,
            loadingMore = false,
            itemsPerLoad = 20,
        },
        ref
    ) => {
        if (!id) {
            throw new Error("The table must have an id");
        }

        const { t } = useTranslation();

        const [paginationIndex, setPaginationIndex] = useState(1);
        const [sort, setSort] = useState(sortInfo);
        const [search, setSearch] = useState("");
        const [searchResults, setSearchResults] = useState(null);
        const [activeFilters, setActiveFilters] = useState(null);
        const [selectedRows, setSelectedRows] = useState(null);
        const [expandedRows, setExpandedRows] = useState([]);

        const searchEnabled = displaySearch || search?.length;
        const expandRows = rows && !!rows.some((row) => row?.rowConfig?.expanded);
        const hasRowActions = rows && !!rows.some((row) => !!row?.rowConfig?.actions?.length);
        const finalCols = [
            expandRows ? "row-expand" : null,
            batchActions ? "row-check" : null,
            ...cols,
            hasRowActions ? "row-actions" : null,
        ].filter((c) => c);

        const isExpanded = (rowID) => expandedRows?.includes(rowID);

        const toggleExpand = (rowID) => {
            setExpandedRows((prev) => (isExpanded(rowID) ? prev.filter((r) => r !== rowID) : [...prev, rowID]));
        };

        const finalRows =
            batchActions || expandRows || hasRowActions
                ? (rows || []).map((row, index) => {
                      if (!row) {
                          return null;
                      }
                      row["id"] = row.id || `row-${index}`;
                      if (expandRows) {
                          row["row-expand"] = row?.rowConfig?.expanded ? (
                              <span
                                  className={`icon-chevron animated mr-4 ml-6 ${
                                      isExpanded(row.id) ? "-rotate-180" : ""
                                  }`}
                                  id={`expand-${row.id}`}
                                  onClick={(e) => {
                                      if (e) {
                                          e.stopPropagation();
                                      }
                                      toggleExpand(row.id);
                                  }}
                              />
                          ) : null;
                      }
                      if (batchActions) {
                          row["row-check"] = (
                              <Checkbox
                                  id={row.id}
                                  checked={selectedRows?.some((r) => r?.id === row.id)}
                                  disabled={row?.rowConfig?.batchActions === false}
                                  onChange={({ checked }) => {
                                      setSelectedRows((prev) => {
                                          if (checked) {
                                              return prev ? [...prev, row] : [row];
                                          }
                                          return prev ? prev.filter((r) => r?.id !== row.id) : null;
                                      });
                                  }}
                              />
                          );
                      }
                      if (hasRowActions) {
                          const ddActionsRef = React.createRef();

                          const disabledActions = row?.rowConfig?.actions?.every((a) => a?.disabled);

                          row["row-actions"] = row?.rowConfig?.actions?.length ? (
                              <Dropdown
                                  id={`row-actions-${row.id}`}
                                  ref={ddActionsRef}
                                  float={true}
                                  showArrow={false}
                                  disabled={disabledActions}
                                  handler={
                                      <Icon
                                          type="row-actions"
                                          size="xl"
                                          className={classNames({
                                              "p-2": true,
                                              "hover:text-zafiro-300": !disabledActions,
                                              "opacity-50": disabledActions,
                                          })}
                                      />
                                  }
                              >
                                  <div className="select-none mt-1 rounded shadow-lg text-left border bg-white border-gray-100 text-gray-900 flex-row">
                                      {row?.rowConfig?.actions?.map((action, index) => {
                                          const id = `opt-row-actions-${action?.label.toLowerCase() || index + 1}`;
                                          return (
                                              <Button
                                                  id={id}
                                                  key={id}
                                                  disabled={action?.disabled || disabled}
                                                  onClick={(e) => {
                                                      if (e) {
                                                          e.stopPropagation();
                                                      }
                                                      if (action?.onClick) {
                                                          action.onClick(e);
                                                      }
                                                      if (ddActionsRef.current) {
                                                          ddActionsRef.current.close();
                                                      }
                                                  }}
                                                  tooltip={action?.tooltip}
                                                  design="link"
                                                  className={`px-4 py-2 w-full ${
                                                      action.disabled ? "text-gray-500 hover:text-gray-500" : ""
                                                  }`}
                                              >
                                                  {action?.label}
                                              </Button>
                                          );
                                      })}
                                  </div>
                              </Dropdown>
                          ) : null;
                      }
                      return row;
                  })
                : rows;

        const filteredRows = ((rows, filters) => {
            if (!filters) {
                return null;
            }
            let filtered = rows;
            for (let key in filters) {
                const { value, fn } = filters[key];
                const result = ((fn, value, rows) => {
                    if (rows?.length && (value || value === false || value === 0) && fn) {
                        const filtered = rows.filter((row) => fn(value, row));
                        return filtered;
                    }
                    return rows;
                })(fn, value, filtered);

                if (result !== undefined) {
                    filtered = result;
                }
            }
            return filtered;
        })(finalRows, activeFilters);
        const baseRows = filteredRows !== null ? filteredRows : finalRows;
        const baseRowsHash = JSON.stringify(baseRows?.map((row) => JSON.stringify([row?.lastUpdate, row?.id])));

        const searchRows = search?.length ? searchResults : baseRows;
        const sortedRows =
            sort && searchRows
                ? [...searchRows].sort((a, b) => {
                      const strValA = getSortValue(a?.[sort.field], { expanded: isExpanded(a?.id) });
                      const strValB = getSortValue(b?.[sort.field], { expanded: isExpanded(a?.id) });
                      if (isNaN(strValA) || isNaN(strValB)) {
                          return sort.criteria === "asc"
                              ? strValA.localeCompare(strValB)
                              : strValB.localeCompare(strValA);
                      }
                      return sort.criteria === "asc" ? strValA - strValB : strValB - strValA;
                  })
                : searchRows;

        const visibleRows = paginate
            ? sortedRows?.slice(paginationIndex * perPage, paginationIndex * perPage + perPage)
            : sortedRows;

        const totalPages = Math.ceil(sortedRows?.length / perPage) - 1;

        const finalHeader = {
            ...(expandRows ? { "row-expand": { title: "", align: "center", width: "1em" } } : null),
            ...(batchActions
                ? {
                      "row-check": {
                          title: (
                              <Checkbox
                                  id="select-all"
                                  checked={
                                      selectedRows?.length &&
                                      selectedRows?.length ===
                                          visibleRows?.filter((r) => r?.rowConfig?.batchActions !== false)?.length
                                  }
                                  onChange={({ checked }) => {
                                      setSelectedRows(
                                          checked
                                              ? visibleRows?.filter((r) => r?.rowConfig?.batchActions !== false)
                                              : null
                                      );
                                  }}
                              />
                          ),
                          align: "center",
                          width: "1em",
                      },
                  }
                : null),
            ...header,
            ...(hasRowActions ? { "row-actions": { title: "", align: "center", width: "1em" } } : null),
        };

        useImperativeHandle(ref, () => ({
            getSelectedRows: () => selectedRows,
        }));

        // On key left or right, go to next or previous page
        useEffect(() => {
            const handleKeyPress = (e) => {
                if (e.key === "ArrowLeft") {
                    setPaginationIndex((prev) => (prev <= 1 ? 0 : prev - 1));
                } else if (e.key === "ArrowRight") {
                    setPaginationIndex((prev) => (prev >= totalPages ? totalPages : prev + 1));
                }
            };
            document.addEventListener("keydown", handleKeyPress);
            return () => document.removeEventListener("keydown", handleKeyPress);
        }, [paginationIndex]);

        useEffect(() => {
            if (!displaySearch) {
                // Search is disabled
                setSearchResults(null);
                return;
            }

            const results = search?.length
                ? baseRows?.filter((row) => {
                      let isMatch = false;
                      for (let col of finalCols) {
                          const td = row[col];
                          const strVal = getSearchValue(td, { expanded: isExpanded(row?.id) });
                          if (strVal.toLowerCase().includes(search.toLowerCase())) {
                              isMatch = true;
                              break;
                          }
                      }
                      return isMatch;
                  })
                : null;
            setSearchResults(results);
        }, [search, baseRowsHash, displaySearch, rows]);

        useEffect(() => {
            setPaginationIndex(page <= 1 ? 0 : page - 1);
        }, [page]);

        const count = sortedRows?.length || 0;

        return (
            <TableContext.Provider
                value={useMemo(
                    () => ({
                        // Table configuration
                        cols: finalCols,
                        rows: finalRows,
                        header: finalHeader,
                        perPage,

                        // Table state
                        visibleRows,
                        disabled: !!disabled || !rows?.length,
                        total: count,

                        // Pagination
                        paginate,
                        page: paginationIndex + 1,
                        setPage: (page) => setPaginationIndex(page <= 1 ? 0 : page - 1),

                        // Searching
                        search: search,
                        setSearch: (value) => {
                            setSearch(value);
                            setPaginationIndex(0);
                            setSelectedRows(null);
                        },

                        // Filtering
                        setActiveFilters: (value) => {
                            setActiveFilters(value);
                            setPaginationIndex(0);
                            setSelectedRows(null);
                        },

                        // Sorting
                        sort,
                        setSort: (config) => {
                            setSort(config);
                            setPaginationIndex(0);
                        },

                        // Expandable rows
                        isExpanded,
                        toggleExpand,

                        // Texts
                        texts: {
                            noTableData: t("no-results"),
                            noSearchResults: t("no-search-results"),
                            countResults: (count) => t("x rows", { count }),
                            ...customTexts,
                        },
                    }),
                    [
                        count,
                        finalCols,
                        finalHeader,
                        finalRows,
                        paginate,
                        paginationIndex,
                        perPage,
                        search,
                        sort,
                        visibleRows,
                    ]
                )}
            >
                <div className={`overflow-auto ${className || ""}`} style={style}>
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <Top
                                id={id}
                                search={searchEnabled}
                                filters={filters}
                                count={showCount ? count : null}
                                batchActions={batchActions}
                                selectedRows={selectedRows}
                                topRightCorner={topRightCorner}
                                folders={folders}
                            />
                            <Body
                                height={height}
                                className={bodyClassName}
                                minHeight={minHeight}
                                maxHeight={maxHeight}
                                scrollable={scrollable}
                                loadingMore={loadingMore}
                                itemsPerLoad={itemsPerLoad}
                            >
                                {children}
                            </Body>
                            <Pages />
                        </>
                    )}
                </div>
            </TableContext.Provider>
        );
    }
);
Table.displayName = "ZafiroTable";

export const getElement = (td, props) => {
    let element = td;
    if (typeof td === "function") {
        element = td({ ...props });
    }
    return element;
};

export const getSortValue = (td, props) => {
    const element = getElement(td, props);
    if (element?.props?.sortvalue) {
        return String(element?.props?.sortvalue);
    }
    if (typeof element?.type?.getSortValue === "function") {
        return String(element?.type?.getSortValue(element?.props, props));
    }
    return stringValue(td, props);
};

export const getSearchValue = (td, props) => {
    const element = getElement(td, props);
    if (element?.props?.searchvalue) {
        return String(element?.props?.searchvalue);
    }
    if (typeof element?.type?.getSearchValue === "function") {
        return String(element?.type?.getSearchValue(element?.props, props));
    }
    return stringValue(td, props);
};

export const stringValue = (td, props) => {
    return forceString(getElement(td, props));
};

export default Table;
