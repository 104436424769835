import React, { useContext, useEffect, forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { GlobalContext } from "contexts/Global";

import Button from "components/Button";
import Loading from "components/Loading";
import { setLoading } from "actions/tableActions";

/**
 * This component is a modal
 * @param {JSX.Element} children The content of the modal
 * @param {string} title The title of the modal
 * @param {JSX.Element} footer The footer of the modal
 * @param {function} onClose The function to call when the modal is closed
 * @param {number} minWidth The minimum width of the modal (style attribute value)
 * @param {string} className The class name of the modal
 * @param {boolean} overlay The flag to show the overlay
 * @returns {JSX.Element} The UI of the component
 * @example <Modal title="Title" buttons={<Button>Close</Button>} onClose={close}>Content</Modal>
 */
const Modal = forwardRef(({ children, id, title, footer, minWidth, onClose, className, overlay = true }, ref) => {
    const [loading, setLoading] = useState(false);

    if (!id) {
        console.warn("The modal must have an id");
    }

    const close = () => {
        if (onClose) {
            onClose();
        }
    };

    useImperativeHandle(ref, () => ({
        close,
        setLoading,
    }));

    const titleElement =
        title && typeof title === "string" ? (
            <h2 id="modal-title" className={`section-title ${children || footer ? "mb-5" : ""}`}>
                {title}
            </h2>
        ) : (
            title
        );

    const overlayClass = classNames({
        "fixed inset-0 z-205": true,
        "bg-black bg-opacity-50": overlay,
    });

    const modalClass = classNames({
        "bg-white rounded-lg shadow-lg border border-gray-200 max-h-80 overflow-auto": true,
        "p-10": !className,
        [className]: true,
    });

    return (
        <div id={id ? `modal-overlay-${id}` : null} onClick={close} className={overlayClass}>
            <div className="absolute inset-0 flex justify-center items-center">
                <div
                    id={id ? `modal-body-${id}` : null}
                    onClick={(e) => e.stopPropagation()}
                    className={modalClass}
                    style={{ minWidth }}
                >
                    {loading ? <Loading adjust="full" /> : null}
                    <div className={loading ? "opacity-0" : ""}>
                        {titleElement}
                        {children}
                        {footer ? (
                            <div id="modal-buttons" className={`pt-8 text-center space-x-3 flex justify-end`}>
                                {footer}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
});

/**
 * This hook is used to open and close modals
 */
export const useModal = () => {
    const { openModal, closeModal } = useContext(GlobalContext);
    return {
        open: openModal,
        close: closeModal,
    };
};

/**
 * This hook is used to show alerts to the user
 */
export const useAlert = () => {
    const { t } = useTranslation();
    const { open, close } = useModal();
    return {
        open: (msg, title) => {
            open(
                <Modal
                    id="alert"
                    title={title}
                    footer={
                        <Button id="alert-accept" design="blue" onClick={close}>
                            {t("accept")}
                        </Button>
                    }
                    minWidth="30rem"
                    className="p-5"
                    onKeyPress={{ Enter: close }}
                >
                    <div className="text-lg text-left">{msg}</div>
                </Modal>
            );
        },
        close,
    };
};

/**
 * This hook is used to show confirmations to the user
 */
export const useConfirm = () => {
    const { t } = useTranslation();
    const { open, close } = useModal();
    return {
        open: ({ id, message, title, onConfirm, confirmText, sensitive }) => {
            const confirm = () => {
                if (onConfirm) {
                    onConfirm();
                }
                close();
            };

            open(
                <Modal
                    id={id}
                    title={title}
                    footer={
                        <>
                            <Button id="confirm-cancel" design="blue-outline" onClick={close}>
                                {t("cancel")}
                            </Button>
                            <Button id="confirm-accept" design={sensitive ? "red" : "blue"} onClick={confirm}>
                                {confirmText || t("confirm")}
                            </Button>
                        </>
                    }
                    minWidth="30rem"
                    className="p-10"
                    onKeyPress={{ Enter: !sensitive ? confirm : null }}
                >
                    <div className="text-lg text-left">{message}</div>
                </Modal>
            );
        },
        close,
    };
};

/**
 * This component is a container for modals that can be opened and closed dynamically by the user
 * @param {Array} modals The list of modals to show
 * @param {function} onClose The function to call when a modal is closed
 */
export const ModalsContainer = ({ modals, onClose }) => {
    const currentModal = modals ? modals[modals.length - 1] : null;
    const showCurrentAlone = currentModal?.alone || currentModal?.props?.alone;
    const showCurrentOverlay = currentModal?.overlay ?? currentModal?.props?.overlay ?? true;
    const customKeyPress = currentModal?.onKeyPress || currentModal?.props?.onKeyPress;

    const close = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleKeyPress = (e) => {
        const key = e?.key;
        if (customKeyPress && key in customKeyPress) {
            if (customKeyPress[key]) {
                if (typeof customKeyPress[key] === "function") {
                    customKeyPress[key]();
                } else {
                    throw new Error("The onKeyPress value must be a function");
                }
            }
        } else if (key === "Escape") {
            close();
        }
    };

    // Close the modal when the escape key is pressed
    useEffect(() => {
        if (currentModal) {
            // Add the event listener to the document only when a modal is open
            document.addEventListener("keydown", handleKeyPress);

            // Remove the focus from the currently focused element to avoid unwanted events
            const focusedElement = document.activeElement;
            if (typeof focusedElement?.blur === "function") {
                focusedElement.blur();
            }
        }

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [currentModal, handleKeyPress]);

    if (!modals?.length) {
        return null;
    }

    return modals.map((modal, index) => {
        // Skip all modals except the current modal if the config is set to show the current alone
        const isCurrent = index === modals.length - 1;
        if (showCurrentAlone && !isCurrent) {
            return null;
        }

        const key = modal?.id || modal?.props?.id || `modal-${index}`;

        return parseModalInput(modal, {
            key,
            overlay: isCurrent && showCurrentOverlay !== false,
            onClose: close,
        });
    });
};

const parseModalInput = (input, props) => {
    const onClose = input?.onClose || input?.props?.onClose;
    const overlay = input?.overlay ?? input?.props?.overlay ?? true;
    const inputProps = {
        ...props,
        overlay: overlay && props?.overlay,
        onClose: (...args) => {
            if (props?.onClose) {
                props.onClose(...args);
            }
            if (onClose) {
                onClose(...args);
            }
        },
    };

    if (React.isValidElement(input)) {
        return React.cloneElement(input, inputProps);
    }

    if (typeof input === "object") {
        return <Modal {...input} {...inputProps} />;
    }

    if (typeof input === "string") {
        return <Modal title={input} {...inputProps} />;
    }

    throw new Error("Invalid modal type", input);
};

export default Modal;
