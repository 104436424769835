import React from "react";
import classNames from "classnames";
import { Route, Routes, useLocation } from "react-router-dom";
import uuid from "react-uuid";

import Button from "components/Button";
import Icon from "components/Icon";
import Dropdown from "components/Dropdown";
import Warning from "components/Warning";

import Info from "./info";
import Inputs from "./inputs";
import Modals from "./modals";
import Others from "./others";
import Tables from "./tables";
import Pending from "./pending";
import Buttons from "./buttons";
import Dropdowns from "./dropdowns";

/**
 * This is the main playground component
 */
const Playground = () => {
    const location = useLocation();

    const sections = [
        {
            id: "buttons",
            title: "Buttons",
            element: <Buttons />,
        },
        {
            id: "cards",
            title: "Cards",
            element: <UXWarning />,
        },
        {
            id: "datepickers",
            title: "Date pickers",
            element: <UXWarning />,
        },
        {
            id: "dropdowns",
            title: "Dropdowns",
            element: <Dropdowns />,
        },
        {
            id: "inputs",
            title: "Inputs",
            element: (
                <>
                    <UXWarning />
                    <Inputs />
                </>
            ),
        },
        {
            id: "modals",
            title: "Modals",
            element: (
                <>
                    <UXWarning />
                    <Modals />
                </>
            ),
        },
        {
            id: "tables",
            title: "Tables",
            element: (
                <>
                    <UXWarning />
                    <Tables />
                </>
            ),
        },
        {
            id: "tabs",
            title: "Tabs",
            element: <UXWarning />,
        },
        {
            id: "tags",
            title: "Tags",
            element: <UXWarning />,
        },
        {
            id: "textfield",
            title: "Text field",
            element: <UXWarning />,
        },
        {
            id: "toasts",
            title: "Toasts",
            element: <UXWarning />,
        },
        {
            id: "tooltips",
            title: "Tooltips",
            element: <UXWarning />,
        },
        {
            id: "translations",
            title: "Translations",
            element: <UXWarning />,
        },
        {
            id: "warnings",
            title: "Warnings",
            element: (
                <>
                    <UXWarning />
                    <Info />
                </>
            ),
        },
        {
            id: "others",
            title: "Others",
            element: (
                <>
                    <UXWarning />
                    <Others />
                </>
            ),
        },
        {
            id: "pending",
            title: "Pending validation",
            element: (
                <>
                    <UXWarning />
                    <Pending />
                </>
            ),
        },
    ];

    return (
        <div className="flex">
            <div className="flex flex-col items-center justify-start bg-white shadow-xs h-screen p-5 min-w-60">
                <h1 className="text-2xl text-center font-bold pb-5">Playground</h1>
                <div className="w-full flex flex-col items-center justify-center space-y-1">
                    {sections.map((section) => {
                        const id = section.id;
                        const to = `playground/${section.id}`;
                        const isSelected = location?.pathname?.startsWith(`/${to}`);
                        const hasMenu = section?.menu?.length > 0;
                        const hasElement = !!section?.element;

                        const itemClass = (selected) =>
                            classNames({
                                "w-full p-3 font-bold border-l-2 hover:text-zafiro-400": true,
                                "border-white text-gray-900 bg-white": !selected,
                                "border-zafiro-600 text-zafiro-600 bg-gray-100": selected,
                            });

                        return (
                            <>
                                {hasElement && !hasMenu ? (
                                    <Button id={id} to={to} className={itemClass(isSelected)}>
                                        {section.title}
                                    </Button>
                                ) : hasMenu ? (
                                    <Dropdown
                                        id={`dropdown-${id}`}
                                        handler={section.title}
                                        designClass={{
                                            dropdown: "p-3 w-full",
                                            handler: `font-bold pr-3 hover:text-zafiro-400 ${
                                                isSelected ? "text-zafiro-600" : ""
                                            }`,
                                        }}
                                        initialOpen={isSelected}
                                    >
                                        <div className="py-3 px-5 space-y-4">
                                            {section.menu.map((menu) => {
                                                const to = `playground/${section.id}/${menu.id}`;
                                                const isSelected = location?.pathname?.startsWith(`/${to}`);
                                                return (
                                                    <Button id={menu.id} to={to} className={itemClass(isSelected)}>
                                                        {menu.title}
                                                    </Button>
                                                );
                                            })}
                                        </div>
                                    </Dropdown>
                                ) : null}
                            </>
                        );
                    })}
                </div>
            </div>
            <div className="w-full h-screen overflow-auto">
                <Routes>
                    {sections.map((section) => (
                        <>
                            {section?.element ? (
                                <Route key={section.id} path={`playground/${section.id}`} element={section.element} />
                            ) : null}
                            {section?.menu?.length > 0
                                ? section.menu.map((menu) => (
                                      <Route
                                          key={menu.id}
                                          path={`playground/${section.id}/${menu.id}`}
                                          element={menu.element}
                                      />
                                  ))
                                : null}
                        </>
                    ))}
                </Routes>
            </div>
        </div>
    );
};

/**
 * This component is used to group components in the playground
 */
export const ListComponents = ({ children, cols = 3 }) => {
    const child = Array.isArray(children) ? children : [children];
    const groupedComponents = child.reduce((acc, component) => {
        let title =
            component?.type?.displayName || component?.type?.name + " ⚠" || component?.type + " ⚠" || "Unknown ⚠";
        if (title === "Component" && component?.props?.title) {
            title = component.props.title;
        }
        if (!acc[title]) {
            acc[title] = [];
        }
        acc[title].push(component);
        return acc;
    }, {});

    return (
        <>
            <div className={`grid grid-cols-1 gap-4 p-10`}>
                {Object.keys(groupedComponents).map((title) => {
                    if (groupedComponents[title]?.[0]?.type?.displayName === "Component") {
                        return groupedComponents[title];
                    }
                    return (
                        <Component key={title} title={title}>
                            {groupedComponents[title]}
                        </Component>
                    );
                })}
            </div>
        </>
    );
};

/**
 * This component displays a group of components with a title and a warning if the component is not validated
 * @param {JSX.Element} children - The components to display
 * @param {string} title - The title of the group
 * @param {boolean} validated - If the components are validated or not (default: true)
 * @returns {JSX.Element} Final component
 */
export const Component = ({ children, title, validated = true }) => {
    const child = (Array.isArray(children) ? children : [children])
        .reduce((acc, child) => {
            const isFragment = !child?.type && Array.isArray(child);
            if (isFragment) {
                if (child) {
                    acc.push(...(Array.isArray(child) ? child : [child]));
                }
            } else {
                if (child) {
                    acc.push(child);
                }
            }
            return acc;
        }, [])
        .filter((c) => c);

    return (
        <div className="p-5 rounded border" style={{ background: "rgba(255,255,255,.5)" }}>
            <h1 className="text-2xl font-bold pb-5">
                {title}
                {!validated && (
                    <span className="text-orange-500 float-right text-sm ml-2 flex items-center space-x-1">
                        <Icon type="warning" size="xl" style={{ zoom: "70%" }} />
                        <span data-tip="This component is not ready for production" data-for="default-tooltip">
                            Not validated yet
                        </span>
                    </span>
                )}
            </h1>
            <div className="items-start justify-start flex flex-wrap">
                {child.length > 1
                    ? child
                          .filter((c) => c)
                          .map((component) => {
                              const key = component?.key || component?.props?.id || uuid();
                              return (
                                  <div key={key} className="inline-block p-2 m-1 border border-dashed">
                                      {component}
                                  </div>
                              );
                          })
                    : child}
            </div>
        </div>
    );
};
Component.displayName = "Component";

/**
 * This component displays a header with a title and a description
 * @param {string} title - The title of the header
 * @param {string} children - The description of the header
 * @returns {JSX.Element} Final component
 */
export const Header = ({ title, children, design = "default" }) => {
    if (!title && !children) {
        return null;
    }

    const designClass = classNames({
        "shadow-inner p-10": true,
        "text-white bg-blue-300": design === "default",
        "text-black bg-gray-100": design === "light",
    });

    return (
        <div className={designClass}>
            {title ? <h2 className="text-2xl font-bold">{title}</h2> : null}
            {children ? <p>{children}</p> : null}
        </div>
    );
};

export const UXWarning = () => {
    return (
        <Warning
            id="warning-test-B"
            title="This section is pending review for UX / Front alignment"
            icon="info"
            design="black"
            readMore={() =>
                window.open(
                    "https://www.figma.com/design/9PAAAlbTtVNL7UI90FhOhe/Developers---Design-System-Manager?node-id=7895-48755&t=Okf7rCxZXEyOQ6ks-1",
                    "_blank",
                    "noopener"
                )
            }
        >
            It is recommended to review the presentation of the elements in this section.
        </Warning>
    );
};

export default Playground;
