import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UseInputText from "../../../../../../Inputs/useInputText";
import UseInputTextArea from "../../../../../../Inputs/useInputTextArea";
import { setExecuteQuery } from "../../../../../../../actions/sectionActions";
import {
    UPDATE_PRODUCT_NAME_AND_DESCRIPTION,
    UPDATE_PRODUCT_NAME_TRANSLATIONS,
} from "../../../../../../../hooks/Utils/Integrations/ShopSettingsUtils";
import { capitalizeFirst } from "../../../../../../../hooks/Utils/Utils";
import { SalesContextDeprecated } from "contexts/Sales";
import { useParams } from "react-router-dom";
import { useSalesModalVendureTranslations } from "../../../modals/useSalesModalVendureTranslations";
import { TYPE_QUERY } from "../../../utils/QueriesTranslations";

function ProductSettingsNameAndDescriptionCard({ data }) {
    const dispatch = useDispatch();

    //Consts&states
    const { setProductName, langDefault } = data;
    const { t } = useTranslation();
    const { productId: id } = useParams();
    const { refreshData } = useSelector((state) => state.sectionContent);
    const { data: dataProduct } = useContext(SalesContextDeprecated);
    const { open: openModalVendureTranslations } = useSalesModalVendureTranslations();

    const [defaultTranslationData, setDefaultTranslationData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [currentName, setCurrentName] = useState("");
    const [currentDescription, setCurrentDescription] = useState("");
    const sectionName = "product-settings";
    const inputsIds = [`${sectionName}-name-input`, `${sectionName}-description-input`];
    const translationsData = dataProduct?.product?.translations;
    //queries&mutations

    useEffect(() => {
        if (langDefault && translationsData && translationsData.length > 0) {
            const { defaultTranslation } = arrangeData(translationsData, langDefault);
            setDefaultTranslationData(defaultTranslation);
        }
    }, [translationsData]);

    useEffect(() => {
        if (defaultTranslationData) {
            setName(defaultTranslationData.name);
            setDescription(defaultTranslationData.description);
            setCurrentName(defaultTranslationData.name);
            setCurrentDescription(defaultTranslationData.description);
            document.querySelector(`#${inputsIds[0]}`).value = defaultTranslationData.name;
            document.querySelector(`#${inputsIds[1]}`).value = defaultTranslationData.description;
        } // eslint-disable-next-line
    }, [defaultTranslationData]);

    useEffect(() => {
        if (loading && !refreshData) {
            setLoading(false);
        } // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        setProductName(name);
        // eslint-disable-next-line
    }, [name]);

    //arrangeData
    const arrangeData = (translationsData, defaultLang) => {
        let defaultTranslation = null;
        let translations = [];
        if (translationsData && translationsData.length > 0) {
            translationsData.forEach((translation) => {
                if (translation.languageCode && translation.languageCode === defaultLang) {
                    defaultTranslation = translation;
                }
                translations.push({
                    id: translation.id,
                    languageRef: translation.languageCode,
                    value: translation.name,
                });
            });
        }
        return { defaultTranslation, translations };
    };

    const handleTranslate = () => {
        if ((name && name !== currentName) || description !== currentDescription) {
            setLoading(true);
            dispatch(
                setExecuteQuery({
                    action: UPDATE_PRODUCT_NAME_AND_DESCRIPTION,
                    params: {
                        id: id,
                        translationId: defaultTranslationData ? defaultTranslationData.id : null,
                        languageCode: defaultTranslationData ? defaultTranslationData.languageCode : null,
                        name: name,
                        description: description,
                    },
                })
            );
        }
    };

    //response
    return (
        <div className="w-full my-2">
            <div className="flex items-center w-full justify-between mb-5">
                <span className="font-bold text-gray-900 text-1xl">{t("name-and-description")}</span>
                <span>{`${langDefault ? t("language:" + langDefault) : ``} (${capitalizeFirst(
                    t("default-lang")
                )})`}</span>
            </div>
            <div className="flex items-center w-full justify-between mb-2">
                <span className="font-bold text-gray-900">{t("name")} *</span>
                <span
                    id={"product-settings-translate-name"}
                    className={`text-zafiro-600 cursor-pointer`}
                    onClick={() => {
                        openModalVendureTranslations({
                            VARIABLES: { id },
                            QUERY: TYPE_QUERY.PRODUCT,
                            title: `${currentName} - ${t("translate-name")}`,
                        });
                        // handleShowTranslateTitleModal();
                    }}
                >
                    {t("translate")}
                </span>
            </div>
            <div className="w-full mb-5">
                <UseInputText
                    id={inputsIds[0]}
                    ph="write-name-ph"
                    value={name}
                    action={() => {
                        handleTranslate();
                    }}
                    changeAction={(value) => setName(value)}
                />
            </div>
            <div className="flex items-center w-full justify-between mb-2">
                <span className="font-bold text-gray-900 first-capital">{t("description")}</span>
                <span
                    id={"product-settings-translate-description"}
                    className={`text-zafiro-600 cursor-pointer`}
                    onClick={() => {
                        openModalVendureTranslations({
                            VARIABLES: { id },
                            QUERY: TYPE_QUERY.PRODUCT_DESCRIPTION,
                            title: `${t("translate-description")}`,
                        });

                        // handleDescriptionModal();
                    }}
                >
                    {t("translate")}
                </span>
            </div>
            <div className="w-full">
                <UseInputTextArea
                    id={inputsIds[1]}
                    ph="write-description"
                    value={description}
                    rows={5}
                    noResize={true}
                    action={() => {
                        handleTranslate();
                    }}
                    changeAction={(value) => setDescription(value)}
                />
            </div>
        </div>
    );
}

export default ProductSettingsNameAndDescriptionCard;
