import TextInput from "components/TextInput";
import { TopologyContext } from "contexts/Topology";
import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

export const TopologyTextInputs = ({ type, value }) => {
    const { setName, nameValidation, setPmsNumber } = useContext(TopologyContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (value) {
            if (type === "name") {
                setName(value);
            } else if (type === "pms-number") {
                setPmsNumber(value);
            }
        }
    }, [value]);

    return (
        <TextInput
            placeholder={type === "name" ? t("name") : t("pms-number")}
            className={type === "name" && nameValidation}
            value={value}
            onChange={(value) => {
                if (type === "name") {
                    setName(value);
                } else if (type === "pms-number") {
                    setPmsNumber(value);
                }
            }}
        />
    );
};
