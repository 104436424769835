import React, { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { EditGroup } from "components/Section/Hotel/Rooms/EditGroup";
import { DeleteGroup } from "components/Section/Hotel/Rooms/DeleteRoom";

export const useRoomGroups = () => {
    const [roomData, setRoomData] = useState([]);
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const { t } = useTranslation();

    const GET_HOTEL_ROOMS = gql`
        {
            roomGroups: roomGroups(orderBy: { field: "name", criteria: "ASC" }) {
                info {
                    count
                }
                results {
                    id
                    name
                    numberRooms
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, called, refetch }] = useLazyQuery(GET_HOTEL_ROOMS, {
        fetchPolicy: "network-only",
    });

    const arrangeData = () => {
        let tempArray = [];
        for (let i = 0; i < data?.roomGroups?.results?.length; i++) {
            tempArray.push(RoomGroup(data?.roomGroups?.results[i], t, refetch, setLoadingRefresh));
        }
        return tempArray;
    };

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (!loading && !error && data?.roomGroups) {
            const roomsData = arrangeData();
            setRoomData(roomsData);
        }
        // eslint-disable-next-line
    }, [data]);

    return { roomData, ready: !loading && called, loadingRefresh, refetch, setLoadingRefresh };
};

const RoomGroup = (roomGroup, t, refetch, setLoadingRefresh) => {
    roomGroup = {
        "group-name": roomGroup?.name,
        "number-of-rooms": [roomGroup?.numberRooms, " ", roomGroup?.numberRooms === 1 ? t("room") : t("rooms")],
        edit: <EditGroup group={roomGroup} refetch={refetch} setLoadingRefresh={setLoadingRefresh} type="room" />,
        delete: <DeleteGroup group={roomGroup} refetch={refetch} setLoadingRefresh={setLoadingRefresh} type="room" />,
    };
    return roomGroup;
};
