import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshData } from "actions/tableActions";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import UseInputText from "../../../../../Inputs/useInputText";
import UseRadio from "../../../../../useRadio";
import { capitalizeFirst } from "../../../../../../hooks/Utils/Utils";
import { ACCEPTEDFORMATS, checkFileType } from "../../../../../../hooks/Utils/FileUtils";
import { setExecuteQuery, setRefreshContentShopData } from "../../../../../../actions/sectionActions";
import { toast } from "react-toastify";
import CustomColorPicker from "../../../../Design/Widgets/WidgetToolbars/OptionsDetails/CustomColorPicker";
import { setColorPickerReference } from "../../../../../../actions/designActions";
import { hexToRgbA } from "../../../../../../hooks/Utils/DesignUtils";
import { rgba2hex } from "../../../../../../hooks/Utils/ColorUtils";
import UseInputPercent from "../../../../../Inputs/useInputPercent";
import { useCategoryUpdate } from "hooks/Data/useShopConfig";
import { getAssetUrl } from "../../../../../../hooks/Utils/SalesUtils";

import { ShopContext } from "contexts/Sales/Shop";
import { SalesContextDeprecated as CategoryContext } from "contexts/Sales";

import SettingsCard from "components/SettingsCard";
import Loading from "components/Loading";

const IMAGE = "IMAGE";
const COLOR = "COLOR";
const PREFIX_ID = "sales-select-image-component";
const PICKER_ID = "shop-color-picker";
const AUTO_UPDATE_TIMEOUT = 1000;

const ShopSettingsImage = ({ isCategory = false }) => {
    const { t } = useTranslation();

    const { idCat } = useParams();

    const { id, token, coverURL, updateConfig, refresh } = useContext(ShopContext);

    const { data } = useContext(CategoryContext);
    const category = data?.collection;

    const PROP_NAME = isCategory ? "category-bgColor" : "shop-bgColor";

    const dispatch = useDispatch();

    const sectionContent = useSelector((state) => state?.sectionContent);
    const refreshData = useSelector((state) => state?.sectionContent?.refreshData);

    const storedData = isCategory ? category?.customFields?.image_url : coverURL;
    const storedDataIsColor = storedData?.includes("rgba(");
    const defaultColor = storedDataIsColor ? storedData : null;
    const defaultImage = !storedDataIsColor ? storedData : null;

    const updateCategoryImage = useCategoryUpdate();

    const [uploadingFile, setUploadingFile] = useState(false);
    const [valueChanged, setValueChanged] = useState(false);
    const [autoUpdateTimeout, setAutoUpdateTimeout] = useState(null);
    const [color, setColor] = useState(defaultColor);
    const [image, setImage] = useState(defaultImage);

    const colorHex = color ? rgba2hex(color) : null;
    const colorAlpha = color ? getColorAlpha(color) : 100;

    const [option, setOption] = useState(defaultImage ? IMAGE : COLOR);

    const pickerColor = useSelector((state) =>
        state.sectionContent?.executeQuery?.action === "update-appearance-colors" &&
        state.sectionContent?.executeQuery?.params?.property === PROP_NAME
            ? state.sectionContent.executeQuery.params.value
            : null
    );

    const resetValues = () => {
        setValueChanged(false);
        let newOption;
        if (defaultImage || defaultColor) {
            newOption = defaultImage ? IMAGE : COLOR;
            setOption(newOption);
        }
        if (defaultColor || (newOption || option) === COLOR) {
            setColor(defaultColor);
        }
        setImage(defaultImage);
    };

    useEffect(() => {
        if (pickerColor) {
            setValueChanged(true);
            setColor(pickerColor);
        }
    }, [pickerColor]);

    useEffect(() => {
        if (!isCategory) {
            resetValues();
        }
    }, [coverURL]);

    useEffect(() => {
        if (isCategory) {
            resetValues();
        }
    }, [category]);

    useEffect(() => {
        if (!image) {
            setOption(COLOR);
        }
    }, [image]);

    useEffect(() => {
        if (uploadingFile) {
            dispatch(
                setExecuteQuery({
                    action: isCategory ? "create-asset-image-category" : "create-asset-image",
                    params: {
                        file: uploadingFile,
                        idShop: id,
                        idCategory: idCat,
                        token,
                    },
                })
            );
            dispatch(setRefreshContentShopData({ action: "uploading" }));
        }
    }, [uploadingFile]);

    useEffect(() => {
        if (refreshData) {
            if (!isCategory) {
                refresh();
            }
            dispatch(setRefreshData(false));
            setUploadingFile(false);
        }
    }, [refreshData]);

    const saveChanges = () => {
        if (isCategory) {
            updateCategoryImage({
                id: idCat,
                imageURL: option === IMAGE ? image : color,
            }).catch((error) => {
                console.error("Failed to update category image", error);
                toast.error(t("mutation-error"));
            });
        } else {
            updateConfig({
                coverURL: option === IMAGE ? image : color,
            });
        }
    };

    useEffect(() => {
        if (valueChanged) {
            if (autoUpdateTimeout) {
                clearTimeout(autoUpdateTimeout);
            }
            setAutoUpdateTimeout(setTimeout(saveChanges, AUTO_UPDATE_TIMEOUT));
            setValueChanged(false);
        }
        return () => {
            if (autoUpdateTimeout) {
                clearTimeout(autoUpdateTimeout);
            }
        };
    }, [valueChanged]);

    const handleChangeImageInput = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            Array.from(files).forEach((file) => {
                const validImage = checkFileType(file, ACCEPTEDFORMATS["image"]);
                if (!validImage) {
                    toast.error(`${file.name} ${t("error-file-not-valid-dataType")}`);
                    return;
                } else if (!file.size || (validImage && file.size / 1000 / 1000 > 11)) {
                    toast.error(`${file.name} ${t("error-exceeds-allowed-size")}`);
                    return;
                } else {
                    setUploadingFile(file);
                }
            });
        }
    };

    const handleChangeHex = (hex) => {
        let colorData = null;
        try {
            colorData = hexToRgbA(hex, colorAlpha);
        } catch {
            colorData = "rgba(255,255,255,1)";
        }
        setColor(colorData);
        setValueChanged(true);
    };

    const handleChangeAlpha = (alpha) => {
        const colorData = hexToRgbA(colorHex, alpha);
        setColor(colorData);
        setValueChanged(true);
    };

    const deleteImage = () => {
        setImage(null);
        setValueChanged(true);
    };

    const showPicker = () => {
        if (option === COLOR) {
            dispatch(
                setColorPickerReference({
                    id: PICKER_ID,
                    property: PROP_NAME,
                    color,
                })
            );
        }
    };

    return (
        <SettingsCard title={t("image")} info={t("recommend-resolution-minimum-77")} className="relative">
            {uploadingFile ? <Loading adjust="absolute" /> : null}
            <div className={uploadingFile ? "invisible" : ""}>
                <CustomColorPicker />
                <div className=" flex w-full">
                    <div className=" mt-4 relative mr-10 w-32 h-32 rounded border-gray-600 border">
                        {image && option === IMAGE ? (
                            <img
                                className=" w-full h-full rounded"
                                alt=""
                                style={{ objectFit: "cover" }}
                                src={getAssetUrl(image)}
                                id={`${PREFIX_ID}-image`}
                            />
                        ) : color && option === COLOR ? (
                            <div
                                className=" w-full h-full rounded"
                                style={{ backgroundColor: color ? color : null }}
                                id={`${PREFIX_ID}-color`}
                            ></div>
                        ) : (
                            <div
                                id={`${PREFIX_ID}-not-image`}
                                className=" flex rounded items-center justify-center text-xs text-gray-700 h-full w-full bg-gray-100"
                            >
                                {t("not-image-yet")}
                            </div>
                        )}
                        {image && option === IMAGE ? (
                            <div className=" absolute flex items-center justify-center w-full h-8 bottom-0 left-0 bg-gray-700 bg-opacity-70 ">
                                <span
                                    id={`${PREFIX_ID}-delete-button`}
                                    className=" text-white cursor-pointer icon icon-delete text-lg"
                                    onClick={deleteImage}
                                ></span>
                            </div>
                        ) : null}
                    </div>
                    <div className=" flex flex-col mt-8">
                        <div className=" flex flex-col">
                            <UseRadio
                                id={`${PREFIX_ID}-option-color`}
                                selected={option}
                                value={COLOR}
                                handleClick={() => {
                                    if (option === COLOR) {
                                        return;
                                    }
                                    setOption(COLOR);
                                    setValueChanged(true);
                                }}
                            />
                            <div className=" ml-6 mb-10 w-full flex " style={{ marginTop: "-0.65rem" }}>
                                <div
                                    id={PICKER_ID}
                                    onClick={showPicker}
                                    className={`rounded h-10 w-10 border bg-gray-200 border-gray-600 ${
                                        option !== COLOR || !color
                                            ? "icon-no-color font-bold text-45xl text-red-100 p-px "
                                            : ""
                                    } `}
                                    style={{
                                        backgroundColor:
                                            option === COLOR && color ? (colorHex ? colorHex : "#fffff") : "#ffffff",
                                        fontSize: "2.3rem",
                                    }}
                                ></div>

                                <div className=" w-20 ml-2 ">
                                    <UseInputText
                                        id={`${PREFIX_ID}-color-code`}
                                        name={"background-code"}
                                        key={colorHex}
                                        ph={"#"}
                                        adjust={"w-full h-10"}
                                        value={colorHex ? colorHex : null}
                                        disabled={option !== COLOR}
                                        action={handleChangeHex}
                                    />
                                </div>
                                <div className=" w-24 ml-2 flex ">
                                    <UseInputPercent
                                        id={`${PREFIX_ID}-color-percent`}
                                        adjust={"w-full h-10 text-center"}
                                        adjustArrowTop={"text-sm pb-2"}
                                        adjustArrowBottom={"text-sm"}
                                        key={colorAlpha}
                                        value={colorAlpha ? colorAlpha : 100}
                                        disabled={option !== COLOR || color === null}
                                        action={handleChangeAlpha}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=" flex flex-col">
                            <UseRadio
                                id={`${PREFIX_ID}-option-image`}
                                selected={option}
                                value={IMAGE}
                                handleClick={() => {
                                    if (option === IMAGE) {
                                        return;
                                    }
                                    setOption(IMAGE);
                                    setValueChanged(true);
                                }}
                            />
                            <div className=" ml-6 mb-6 w-full " style={{ marginTop: "-0.65rem" }}>
                                <span
                                    className={` inline-flex items-center justify-center h-10 rounded border w-auto px-3 text-base text-white ${
                                        option === COLOR
                                            ? " bg-gray-400 cursor-default "
                                            : " bg-zafiro-600 cursor-pointer "
                                    }  `}
                                    id={`${PREFIX_ID}-button-select-image`}
                                    onClick={() => {
                                        const inputFyle = document.getElementById("getImageShop");
                                        if (option === IMAGE && inputFyle) {
                                            inputFyle.click();
                                        }
                                    }}
                                >
                                    {capitalizeFirst(t("choose-an-image"))}
                                </span>
                                <input
                                    type="file"
                                    id="getImageShop"
                                    style={{ display: "none" }}
                                    accept="image/png, image/jpeg, image/webp, image/avif"
                                    multiple={false}
                                    onChange={(e) => {
                                        handleChangeImageInput(e);
                                    }}
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SettingsCard>
    );
};

const getColorAlpha = (color) => {
    if (typeof color !== "string") return 100;
    return Math.round(Number(color.replace(")", "").split(",")[3]) * 100);
};

export default ShopSettingsImage;
