import { useEffect, useState } from "react";
import getLatinise from "../../helpers/latinise";
import { Session } from "./Session";
import _ from "lodash";
import { UsePermissions } from "../map";

export const POLLINTERVAL_15S = 15000;
export const POLLINTERVAL_30S = 30000;

export const HOTEL = {
    GLOBAL_MANAGEMENT: "CORPORATE",
};

export const capitalizeFirst = (text) => {
    if (!text) {
        return null;
    }
    return String(text).charAt(0).toUpperCase() + String(text).slice(1);
};

export const getFontMymeType = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    const fontExtensionsToMIME = {
        ttf: "font/ttf",
        otf: "font/otf",
        woff: "application/font-woff",
        woff2: "font/woff2",
    };
    return fontExtensionsToMIME[extension] || null;
};

export const checkShowTooltip = (elem, setter) => {
    if (elem.offsetWidth < elem.scrollWidth) {
        setter(true);
    } else {
        setter(false);
    }
};

export const uncapitalizeFirst = (text) => {
    if (!text) {
        return null;
    }
    return String(text).charAt(0).toLowerCase() + String(text).slice(1);
};

export const rgbaPattern = /^rgba\((\d{1,3}),(\d{1,3}),(\d{1,3}),(\d(?:\.\d+)?)\)$/;

export const capitalizeT = (text, t) => {
    return t("{{capitalize, capitalize}}", { capitalize: t(text) });
};

export const ensureValue = (value) => {
    return value ? value : "-";
};

export const translate = (text, langStrings) => {
    return langStrings[text] ? langStrings[text] : text;
};

export const multipleTranslate = (texts, langStrings) => {
    if (langStrings !== null && typeof langStrings === "function") {
        return Array.isArray(texts)
            ? texts.map((label) => (langStrings(label).length > 1 ? langStrings(label) : label)).join(" ")
            : langStrings(texts) || texts;
    } else {
        let returnedText = "";
        if (Array.isArray(texts)) {
            texts.forEach((word) => (returnedText += `${langStrings?.[word] ? langStrings[word] : word} `));
        } else {
            returnedText = langStrings?.[texts] ? langStrings[texts] : texts;
        }
        return returnedText;
    }
};
/**
 * @param s {string}
 * @returns {string}
 */
export const escapeSpecialChars = (s) => {
    return s ? s.replace(/\\/g, "\\\\").replace(/"/g, '\\"') : s;
};
/**
 * @param str {string}
 * @returns {string}
 */
export const normalizeToSearch = (str) => {
    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
};

export const joinFormDateTime = (dateValue, hourValue = "00:00") => {
    return dateValue ? new window.ZDate(dateValue + " " + hourValue) : new window.ZDate();
};

export const isValidDate = (d) => {
    return d instanceof window.ZDate && d.isValid();
};

export const minutesToHours = (n) => {
    const hours = n / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return `${String(rhours).padStart(2, "0")}:${String(rminutes).padStart(2, "0")}`;
};

export const getBookingDurationOptions = (bookingFreq) => {
    let options = [];

    for (let index = 1; index <= 10; index++) {
        const optVal = minutesToHours(bookingFreq * index);
        options.push({ id: optVal, value: optVal });
    }

    return options;
};

export const removeDiacritics = (strAccents) => {
    if (!strAccents) {
        return strAccents;
    }
    strAccents = strAccents.split("");
    let strAccentsOut = [];
    let strAccentsLen = strAccents.length;
    let accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
    let accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    for (let y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) !== -1) {
            strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
        } else {
            strAccentsOut[y] = strAccents[y];
        }
    }
    strAccentsOut = strAccentsOut.join("");

    return strAccentsOut;
};
//transform date format dd/mmm/YYYY to YYYY/mm/dd
export const bdDateTransform = (dateString) => {
    if (!dateString) return null;

    const date = new window.ZDate(dateString);

    return `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
};

export const bdDateStringTransform = (dateString) => {
    const dateParts = dateString.split("/");
    if (dateParts) {
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    } else {
        return dateString;
    }
};
export const bdDateToDateInput = (dateString) => {
    if (!dateString) return null;

    const date = new window.ZDate(dateString);

    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
};
export const dateString = (inputDate) => {
    const newDate = inputDate instanceof window.ZDate ? inputDate : new window.ZDate(inputDate);
    return newDate.toISOString();
};

//client.cache.reset(); // this is for clean all cache keys
export const removeApolloCacheKeys = (cache, keypart, keypart2 = null) => {
    if (!keypart || !cache) {
        return false;
    }

    //Keypart is a keys array
    if (typeof keypart === "object") {
        Object.keys(cache.data.data).forEach((key) =>
            keypart.map((keyToDelete) => (key.indexOf(keyToDelete) > -1 ? cache.data.delete(key) : null))
        );

        return true;
    }

    Object.keys(cache.data.data).forEach((key) =>
        keypart === "all" || (key.indexOf(keypart) > -1 && (!keypart2 || (keypart2 && key.indexOf(keypart2) > -1)))
            ? cache.data.delete(key)
            : null
    );
    return true;
};

export const UseAnySubsectionEnabled = (subsections) => {
    const { check } = UsePermissions();
    let anyEnabled = false;
    if (subsections) {
        subsections.forEach((subsection) => {
            if (
                !subsection.attributeNeeded ||
                (subsection.attributeNeeded && check(subsection.section, subsection.attributeNeeded))
            ) {
                anyEnabled = true;
            }
        });
    }
    return anyEnabled;
};

export const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
};

export const remToPixel = (rem) => {
    return rem * parseFloat(getComputedStyle(document.body).fontSize);
};

export const timeSinceLastDisplay = (dateOrigin, dateToDiff) => {
    let _d1 = new window.ZDate();
    let _d2 = new window.ZDate();
    if (dateOrigin && dateOrigin !== "") {
        _d1 = new window.ZDate(dateOrigin);
    }
    if (dateToDiff && dateToDiff !== "") {
        _d2 = new window.ZDate(dateToDiff);
    }

    const _ONE_HOUR = 60 * 60 * 1000; // minutes*seconds*milliseconds

    const diff = Math.round(Math.abs((_d1.getTime() - _d2.getTime()) / _ONE_HOUR));

    if (diff === 0) {
        return { diff: 0, messure: "now" };
    }
    if (diff === 1) {
        return { diff: diff, messure: "hour-ago" };
    }
    if (diff === 24) {
        return { diff: Math.round(diff / 24), messure: "day-ago" };
    }
    if (diff > 24) {
        return { diff: Math.round(diff / 24), messure: "days-ago" };
    }
    return { diff: diff, messure: "hours-ago" };
};

export const calculateAge = (birthday) => {
    // birthday is a date
    let ageDifMs = window.ZDate.now() - birthday.getTime();
    let ageDate = new window.ZDate(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

//ordena array de objetos por hasta 3 propiedades
//para los string es case no sensitive
//admite ordenación por campos booleanos
export const sortObjectArray = (array, property, secondProperty, thirdProperty) => {
    return array.sort(function (a, b) {
        let aProperty = a[property];
        let bProperty = b[property];

        let a2Property = a[secondProperty];
        let b2Property = b[secondProperty];

        let a3Property = a[thirdProperty];
        let b3Property = b[thirdProperty];

        if (typeof aProperty === "string") {
            aProperty = aProperty.toLowerCase();
            bProperty = bProperty.toLowerCase();
        }

        if (a2Property && typeof a2Property === "string") {
            a2Property = a2Property.toLowerCase();
            b2Property = b2Property.toLowerCase();
        }

        if (a3Property && typeof a3Property === "string") {
            a3Property = a3Property.toLowerCase();
            b3Property = b3Property.toLowerCase();
        }

        if (secondProperty && typeof a2Property === "boolean") {
            return !a2Property ? 1 : -1;
        }
        if (aProperty === bProperty && secondProperty) {
            // Price is only important when cities are the same

            if (thirdProperty && typeof a3Property === "boolean") {
                return !a3Property ? 1 : -1;
            }
            if (aProperty === bProperty && thirdProperty) {
                return a3Property > b3Property ? 1 : -1;
            }
            return a2Property > b2Property ? 1 : -1;
        }
        if (property && typeof aProperty === "boolean") {
            return !aProperty ? 1 : -1;
        }
        return aProperty > bProperty ? 1 : -1;
    });
};

export const cloneObject = (object) => {
    return JSON.parse(JSON.stringify(object));
};

export const formatBytes = (bytes, decimals = 2, decimalStartUnit = "MB") => {
    if (bytes === 0) {
        return "0 Bytes";
    }
    const k = 1024;
    let dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const dmStartUnit = sizes.indexOf(decimalStartUnit);
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    if (i < dmStartUnit) dm = 0;
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const composeDestination = (actionData) => {
    // no action data
    if (!actionData?.values) {
        return "{}";
    }
    // no filters set in action data
    const actionDataValuesKey = Object.keys(actionData.values);
    if (!actionDataValuesKey.find((element) => element.includes("filter_") && actionData.values[element].length > 0)) {
        return "{}";
    }
    let filters = [];

    for (let index = 0; index < 10; index++) {
        if (actionData?.values?.[`filter_${index}`]?.[0]) {
            let nType = "";
            let stringType = false;
            switch (actionData.values[`filter_${index}`][0]) {
                case "all":
                    nType = "all";
                    break;
                case "room":
                    nType = "roomIDs";
                    break;
                case "roomGroup":
                    nType = "roomGroupIDs";
                    break;
                case "floor":
                    nType = "floorIDs";
                    break;
                case "wing":
                    nType = "wingIDs";
                    break;
                case "guest":
                    nType = "stayGuestIDs";
                    break;
                case "guestGroup":
                    nType = "stayGroupIDs";
                    break;
                case "language":
                    nType = "languageRefs";
                    stringType = true;
                    break;
                case "country":
                    nType = "countryRefs";
                    stringType = true;
                    break;
                case "without_room":
                    nType = "WITHOUT_ROOM";
                    break;
                default:
                    break;
            }
            if (nType) {
                if (nType === "all") {
                    filters.push(`all:true`);
                } else {
                    filters.push(
                        `${nType}:${
                            actionData.values[`filter_${index}_ids`] &&
                            actionData.values[`filter_${index}_ids`].length > 0
                                ? ` [${
                                      stringType
                                          ? actionData.values[`filter_${index}_ids`].map((item) => `"${item}"`)
                                          : actionData.values[`filter_${index}_ids`]
                                  }]`
                                : "[]"
                        }`
                    );
                }
            }
        }
    }
    return filters && filters.length > 0 ? `{${filters.join(",")}}` : "{}";
};

export const destinationData = () => {
    return `destination {
        all
        rooms{
            id
            name
        }
        wings{
            id
            name
        }
        floors{
            id
            name
        }
        countries{
            name
            ref
        }
        languages{
            name
            ref
        }
        roomGroups{
            id
            name
        }
        stayGroups{
            id
            name
        }
        stayGuests{
            id
            name
        }
    }`;
};
export const contentData = () => {
    return `
        id
        designID
        name
        isEnabled
        linkedFrom
        devices{
        grid{
            cols
            rows
        }
        name
        orientation
        res{
            h
            w
        }
        type
        }
        pos
        contentStyle
        contentWidgets{
            ID
            tContentID
            data
            pos
            orientation
            type
            widgetStyle
            actions
            contentWidgets{
                ID
                contentID
                data
                pos
                orientation
                type
                widgetStyle
                actions
            }
        }`;
};

export const hasDestinationSelected = (destination) => {
    if (!destination) {
        return false;
    }
    let selected = false;
    if (destination.all) {
        selected = true;
    }
    const destinationKeys = Object.keys(destination);
    destinationKeys.forEach((key) => {
        if (key !== TYPENAME_GRAPHQL && destination[key].length > 0) {
            selected = true;
        }
    });
    return selected;
};

export const predefinedTags = (t, permissionsTags, deviceType, screenType) => {
    const filteredTags = [];
    const tags = [
        {
            key: "roomNumber",
            value: "{{.Roomnumber}}",
            text: capitalizeFirst(t("room number")),
            category: "predefined-tags",
        },
        { key: "roomName", value: "{{.Roomname}}", text: capitalizeFirst(t("room name")), category: "predefined-tags" },
        {
            key: "guestTitle",
            value: "{{.Guesttitle}}",
            text: capitalizeFirst(t("guest title")),
            category: "predefined-tags",
        },
        {
            key: "guestName",
            value: "{{.Guestname}}",
            text: capitalizeFirst(t("guest name")),
            category: "predefined-tags",
        },
        {
            key: "guestSurname",
            value: "{{.Guestsurname}}",
            text: capitalizeFirst(t("guest surname")),
            category: "predefined-tags",
        },
        {
            key: "guestFullname",
            value: "{{.Guestfullname}}",
            text: capitalizeFirst(t("guest fullname")),
            category: "predefined-tags",
        },
        {
            key: "guestGroup",
            value: "{{.Guestgroup}}",
            text: capitalizeFirst(t("guest group")),
            category: "predefined-tags",
        },
        {
            key: "checkoutDate",
            value: "{{.Checkoutdate}}",
            text: capitalizeFirst(t("checkout date")),
            category: "predefined-tags",
        },
        {
            key: "pairingURL",
            value: "{{.Pairingurl}}",
            text: capitalizeFirst(t("pairing URL")),
            category: "predefined-tags",
        },
        {
            key: "pairingCode",
            value: "{{.Pairingcode}}",
            text: capitalizeFirst(t("pairing-code")),
            category: "predefined-tags",
        },
        { key: "wifiSSID", value: "{{.Wifissid}}", text: capitalizeFirst(t("wifi SSID")), category: "predefined-tags" },
        {
            key: "wifiPassword",
            value: "{{.Wifipassword}}",
            text: capitalizeFirst(t("wifi password")),
            category: "predefined-tags",
        },
        {
            key: "CCName",
            value: "{{.Ccname}}",
            text: capitalizeFirst(t("Chromecast name")),
            category: "predefined-tags",
        },
    ];

    if (deviceType && screenType && permissionsTags) {
        tags.forEach((tag) => {
            if (deviceType === "TV" && (screenType === "TV" || screenType === "GENERAL")) {
                if (permissionsTags.includes(tag.key)) {
                    if (
                        tag.key === "roomNumber" ||
                        tag.key === "roomName" ||
                        tag.key === "guestTitle" ||
                        tag.key === "guestName" ||
                        tag.key === "guestSurname" ||
                        tag.key === "guestFullname" ||
                        tag.key === "guestGroup" ||
                        tag.key === "checkoutDate" ||
                        tag.key === "pairingURL" ||
                        tag.key === "pairingCode" ||
                        tag.key === "wifiSSID" ||
                        tag.key === "wifiPassword" ||
                        tag.key === "CCName"
                    ) {
                        filteredTags.push(tag);
                    }
                }
            } else if (deviceType === "Mobile" && (screenType === "MOBILE" || screenType === "GENERAL")) {
                if (permissionsTags.includes(tag.key)) {
                    if (
                        tag.key === "roomNumber" ||
                        tag.key === "roomName" ||
                        tag.key === "guestTitle" ||
                        tag.key === "guestName" ||
                        tag.key === "guestSurname" ||
                        tag.key === "guestFullname" ||
                        tag.key === "guestGroup" ||
                        tag.key === "checkoutDate" ||
                        tag.key === "wifiSSID" ||
                        tag.key === "wifiPassword"
                    ) {
                        filteredTags.push(tag);
                    }
                }
            } else if (screenType === "CCINSTRUCTION" || screenType === "CCPAIRINGSUCCESSFUL") {
                if (permissionsTags.includes(tag.key)) {
                    if (
                        tag.key === "roomNumber" ||
                        tag.key === "roomName" ||
                        tag.key === "guestTitle" ||
                        tag.key === "guestName" ||
                        tag.key === "guestSurname" ||
                        tag.key === "guestFullname" ||
                        tag.key === "guestGroup" ||
                        tag.key === "checkoutDate" ||
                        tag.key === "pairingURL" ||
                        tag.key === "CCName" ||
                        tag.key === "wifiSSID" ||
                        tag.key === "wifiPassword" ||
                        tag.key === "CCName"
                    ) {
                        filteredTags.push(tag);
                    }
                }
            } else if (screenType === "PORTAL") {
                if (permissionsTags.includes(tag.key)) {
                    if (tag.key === "wifiSSID" || tag.key === "wifiPassword") {
                        filteredTags.push(tag);
                    }
                }
            }
        });
    }

    if (deviceType && screenType && permissionsTags) {
        return filteredTags;
    } else {
        return tags;
    }
};

export const predefinedSalesTags = (t) => {
    const tags = [
        {
            key: "shopName",
            value: "{{.ShopName}}",
            text: capitalizeFirst(t("shop-name")),
            category: "predefined-tags",
            blockTag: false,
        },
        {
            key: "pickUpPlace",
            value: "{{.PickUpPlace}}",
            text: capitalizeFirst(t("pick-up-place")),
            category: "predefined-tags",
            blockTag: false,
        },
        {
            key: "guestName",
            value: "{{.Guestname}}",
            text: capitalizeFirst(t("guest name")),
            category: "predefined-tags",
            blockTag: false,
        },
        {
            key: "orderSummary",
            value: "{{.OrderSummary}}",
            text: capitalizeFirst(t("order-summary")),
            category: "predefined-tags",
            blockTag: true,
        },
        {
            key: "deliveryHourAndDate",
            value: "{{.DeliveryHourAndDate}}",
            text: capitalizeFirst(t("delivery-hour-and-date")),
            category: "predefined-tags",
            blockTag: false,
        },
    ];
    return tags;
};

export const ALL_BG_LABEL = "bg-blue-400"; // all property
export const ROOM_BG_LABEL = "bg-blue-300"; // room, room_group, floor, wing
export const GUEST_BG_LABEL = "bg-blue-200"; // guest, guest_group, country, language

export const printDestinationLabels = (destination, t) => {
    if (!destination) {
        return null;
    }

    let filtersApplied = [];

    destination.rooms.map((roomFilter) => filtersApplied.push({ name: roomFilter.name, color: ROOM_BG_LABEL }));
    destination.floors.map((floorFilter) => filtersApplied.push({ name: floorFilter.name, color: ROOM_BG_LABEL }));
    destination.wings.map((wingFilter) => filtersApplied.push({ name: wingFilter.name, color: ROOM_BG_LABEL }));
    destination.stayGuests.map((stayGuest) => filtersApplied.push({ name: stayGuest.name, color: GUEST_BG_LABEL }));
    destination.stayGroups.map((stayGroup) => filtersApplied.push({ name: stayGroup.name, color: GUEST_BG_LABEL }));
    destination.roomGroups.map((roomGroup) => filtersApplied.push({ name: roomGroup.name, color: ROOM_BG_LABEL }));
    if (destination.countries) {
        destination.countries.map((country) =>
            filtersApplied.push({
                name: t("country:" + country.ref.toLowerCase()),
                color: GUEST_BG_LABEL,
            })
        );
    }
    destination.languages.map((language) => filtersApplied.push({ name: language.name, color: GUEST_BG_LABEL }));
    if (destination.all) {
        filtersApplied.push({ name: t("all property"), color: ALL_BG_LABEL });
    }
    let htmlLabels = "";
    filtersApplied.map(
        (filter) =>
            (htmlLabels += `<div class="rounded py-1 px-4 m-1 text-white inline-block ${filter.color}">
                            ${filter.name}
                            </div>`)
    );

    return htmlLabels ? `<div id="destiantionLabels"> ${htmlLabels}</div>` : null;
};

export const TYPENAME_GRAPHQL = "__typename";

export const getDestinationLabels = (destination, t) => {
    if (!destination) {
        return [];
    }
    const ROOM_LABEL_COLOR = { keys: ["rooms", "floors", "wings", "roomGroups"], label: ROOM_BG_LABEL };
    const GUEST_LABEL_COLOR = { keys: ["stayGuests", "stayGroups", "countries", "languages"], label: GUEST_BG_LABEL };
    const ALL_LABEL_COLOR = { text: t("all property"), label: ALL_BG_LABEL };

    let filtersApplied = [];
    let _index = 0;
    const destinationKeys = Object.keys(destination);
    if (destination.all) {
        filtersApplied.push([_index, ALL_LABEL_COLOR.text, ALL_LABEL_COLOR.label]);
        _index++;
    }

    destinationKeys.forEach((key) => {
        if (destination[key] && key !== TYPENAME_GRAPHQL && key !== "all") {
            destination[key].forEach((element) => {
                filtersApplied.push([
                    _index,
                    key === "countries" ? t("country:" + element.ref.toLowerCase()) : element.name,
                    ROOM_LABEL_COLOR.keys.includes(key) ? ROOM_LABEL_COLOR.label : GUEST_LABEL_COLOR.label,
                ]);
                _index++;
            });
        }
    });

    return filtersApplied;
};

export const getTextWidth = (pText, pFontSize, pStyle, pClass) => {
    let lDiv = document.createElement("div");

    document.body.appendChild(lDiv);

    if (pStyle != null) {
        lDiv.style = pStyle;
    }
    lDiv.style.fontSize = "" + pFontSize;
    lDiv.style.position = "absolute";
    lDiv.style.left = -1000;
    lDiv.style.top = -1000;
    if (pClass) {
        const allClasses = pClass.split(" ");
        allClasses.map((myClass) => lDiv.classList.add(myClass));
    }

    lDiv.innerHTML = pText;

    let lResult = {
        width: lDiv.clientWidth,
        height: lDiv.clientHeight,
    };

    document.body.removeChild(lDiv);
    lDiv = null;

    return lResult.width;
};

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export const includeWidgetRenderLibrary = () => {
    const wrPath = Session.getDriveUrl(`render/widgetsRender-1.0.0.min.js?v=${process.env.REACT_APP.VERSION}`);
    if (document?.getElementsByTagName("HEAD")?.[0]?.children) {
        const headElements = Array.from(document.getElementsByTagName("HEAD")[0].children);
        const element_found = headElements.find((element) => element.href && element.href === wrPath);
        if (!element_found) {
            let link = document.createElement("script");
            link.src = wrPath;
            // link.src = `/zdrive/render/widgetsRender-1.0.0.min.js`;

            document.getElementsByTagName("HEAD")[0].appendChild(link);
        }
    }
};

export const parseBoolean = (value) => {
    if (typeof value === "boolean") {
        return value;
    }
    if (parseInt(value) || typeof value === "number") {
        return parseInt(value) > 0;
    }
    if (typeof value === "string") {
        return value === "true" ? true : false;
    }
    return false;
};

export const sanitizeJson = (value) => {
    return value.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": ');
};

export function convertValueToSlug(value) {
    return latinise(value)
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s--]+/g, "_")
        .replace(/^-+|-+$/g, "");
}

export function latinise(str) {
    // eslint-disable-next-line
    return str.replace(/[^A-Za-z0-9\[\] ]/g, function (a) {
        return getLatinise().latin_map[a] || a;
    });
}

export function calculateDecimals(value, decimals = 2) {
    let decimals_number = value.countDecimals();
    if (decimals_number && decimals_number > decimals) {
        value = value.toFixed(decimals);
    }
    return value;
}

// eslint-disable-next-line
Number.prototype.countDecimals = function () {
    if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
    return this.toString().split(".")[1].length || 0;
};

/**
 * Finds data from an array with the itemsChecked
 *
 * @param {String} label - label encontrado del result a agregar
 * @param {Array} checkedItems - List of checkedItems to review
 * @param {Array} results - List of contrast to review with checkedItems
 *
 * @returns {Array} - final list with the data found between checkedItems and results
 */
export const getDataFromResult = (label, checkedItems, results) => {
    let _data = [];
    if (checkedItems.length > 0) {
        checkedItems.forEach((itemId) => {
            let row = results.filter((item) => item.id === itemId);
            if (row.length > 0) {
                _data.push(row[0][label]);
            }
        });
    }

    return _data;
};

export const isDescendant = (parent, child) => {
    let node = child.parentNode;
    while (node !== null) {
        if (node === parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
};

export const getFromDAS = (libraryRef, languageRef) => {
    if (libraryRef) {
        return Session.getDasUrl(`${libraryRef}?lang=${languageRef || localStorage.getItem("lang")}`);
    }
    return null;
};

export const replaceTags = (str, regex, obj) => {
    const foundMatches = [...str.matchAll(regex)];
    if (foundMatches && foundMatches.length > 0) {
        foundMatches.map((foundMatch, index) => {
            str = str.replaceAll(foundMatch[0], getValue(foundMatch[1], obj));
        });
    }
    return str;
};

export const getValue = (path, data) => {
    let pathSplit = path.split(".");
    if (pathSplit.length > 1) {
        let dataNested = data[pathSplit[0]];
        let pathNested = pathSplit.slice(1).join(".");
        return getValue(pathNested, dataNested);
    } else {
        return Array.isArray(data) ? _.map(data, path) : _.get(data, path);
    }
};

export const updateDataOptions = ({
    dataOptions,
    values,
    name,
    setDataOptions,
    oneSelected,
    setIsComponentVisible,
}) => {
    if (!dataOptions) {
        return;
    }

    let temCopy = JSON.parse(JSON.stringify(dataOptions));

    if (values && values[name] && Object.keys(values[name]).length > 0) {
        temCopy.forEach((item) => {
            item.selected = Object.values(values[name]).includes(item.id);
        });
    } else {
        temCopy.forEach((item) => (item.selected = false));
    }

    setDataOptions(temCopy);

    if (oneSelected) {
        setIsComponentVisible(false);
    }
};

export const sortLangs = (langs, t) => {
    const sortedProjectLangsAlphabetically = langs.sort((a, b) =>
        t("language:" + a.languageRef).localeCompare(t("language:" + b.languageRef))
    );

    const sortedByDefaultValue = sortedProjectLangsAlphabetically.sort(
        (a, b) => Number(b.isDefault) - Number(a.isDefault)
    );
    return sortedByDefaultValue;
};

export const pathsWithoutChainOrProjectRef = [
    "/login",
    "/login-impersonate",
    "/select-project",
    "/select-profile",
    "/login-superuser",
    "/impersonate-list",
    "/policy",
    "/privacy-policy",
];

export const forceString = (input) => {
    if (input !== null && input !== undefined && input !== "") {
        if (typeof input === "string") {
            return input;
        }

        // Check if it is a React component with alt prop defined
        if (input?.props?.alt) {
            return String(input?.props?.alt);
        }

        // Check if it is a React component with getString() method defined
        if (typeof input?.type?.getString === "function") {
            return String(input?.type?.getString(input?.props));
        }

        // Check if it is a React component with searchValue prop defined
        if (input?.props?.searchValue) {
            return String(input?.props?.searchValue);
        }

        // Check if it is a React component with getSearchValue() method defined
        if (typeof input?.type?.getSearchValue === "function") {
            return String(input?.type?.getSearchValue(input?.props));
        }

        // Check if it is a object with toString() method available
        let strValue = input?.toString();
        if (checkValidString(strValue)) {
            return strValue;
        }

        // Check if it is a React component with children
        let childrenStr = forceString(input?.props?.children);
        if (checkValidString(childrenStr)) {
            return childrenStr;
        }
    }

    return "";
};

const checkValidString = (input) => {
    return !(
        typeof input !== "string" ||
        typeof input !== "number" ||
        input === null ||
        input === undefined ||
        input === "undefined" ||
        input === "null" ||
        input === "[object Object]"
    );
};
