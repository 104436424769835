import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import TableContext from "./Context";

import Button from "components/Button";
import Icon from "components/Icon";

const Pages = () => {
    const { t } = useTranslation();
    const { page: currentPage, setPage, paginate, perPage, total } = useContext(TableContext);

    const totalPages = total > 0 ? Math.ceil(total / perPage) : 0;

    if (!paginate || totalPages === 0) {
        return null;
    }

    const goToPage = (page) => {
        if (page < 1) {
            setPage(1);
        } else if (page > totalPages) {
            setPage(totalPages);
        } else {
            setPage(page);
        }
    };

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    const getPageNumbers = () => {
        const pages = [];
        const maxPagesToShow = 15;
        const ellipsis = "...";

        if (totalPages <= maxPagesToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            pages.push(1);

            if (currentPage > 3) {
                pages.push(ellipsis);
            }

            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 3);

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            if (currentPage < totalPages - 2) {
                pages.push(ellipsis);
            }

            pages.push(totalPages);
        }

        return pages;
    };

    const pages = getPageNumbers();

    return (
        <div className="flex justify-center items-center py-2 text-gray-700 mt-5">
            {!isFirstPage && (
                <Button id={"prev_page"} design="link" className="px-3" onClick={() => goToPage(currentPage - 1)}>
                    <Icon type="chevron-left" className="mr-2" />
                    {t("previous")}
                </Button>
            )}

            {pages.map((page, index) => {
                if (page === "...") {
                    return (
                        <span key={index} className="px-3">
                            {page}
                        </span>
                    );
                } else {
                    const id = `page_${page}`;
                    const btnClass = classNames({
                        "px-3": true,
                        "text-zafiro-400 font-bold": currentPage === page,
                    });
                    return (
                        <Button key={id} design={"link"} id={id} className={btnClass} onClick={() => goToPage(page)}>
                            {page}
                        </Button>
                    );
                }
            })}

            {!isLastPage && (
                <Button id={"next_page"} design="link" className="px-3" onClick={() => goToPage(currentPage + 1)}>
                    {t("next")}
                    <Icon type="chevron-right" className="ml-2" />
                </Button>
            )}
        </div>
    );
};

export default Pages;
