import React from "react";

import { Header } from "sections/playground";

import Icon from "components/Icon";
import Select from "components/Select";

import Table from "components/ZafiroTable";
import Button from "components/Button";

const Dropdowns = () => {
    const sections = ["SELECT"];

    const types = ["SINGLE", "MULTIPLE"];
    const variants = ["DEFAULT", "GROUPS", "SEARCH", "ICON", "DISABLED"];

    const onChangeTest = (value) => console.log("Selected value: ", value);

    const Small = ({ children }) => (
        <span className="text-gray-700 text-sm font-normal whitespace-no-wrap">{children}</span>
    );

    const randomIcon = () =>
        ["user", "delete", "category", "media", "alarm", "product", "zafiro-solid", "rooms"].find(
            () => Math.random() > 0.5
        ) || "zafiro-outline";

    const singleOptions = new Array(5).fill(0).map((_, i) => ({
        label: `Option ${i + 1}`,
        value: i + 1,
    }));

    const iconOptions = new Array(2).fill(0).map((_, i) => ({
        label: `Header ${i + 1}`,
        options: new Array(3).fill(0).map((_, j) => ({
            id: `group-${i + 1}-option-${j + 1}`,
            label: (
                <div className="inline-block" alt={`Option ${i + 1}${j + 1}`}>
                    <div className="flex items-center space-x-2">
                        <Icon type={randomIcon()} />
                        <div>{`Option ${i + 1}${j + 1}`}</div>
                    </div>
                </div>
            ),
            value: `${i + 1}${j + 1}`,
        })),
    }));

    const groupOptions = new Array(5).fill(0).map((_, i) => ({
        label: `Header ${i + 1}`,
        options: new Array(3).fill(0).map((_, j) => ({
            id: `group-${i + 1}-option-${j + 1}`,
            label: `Option ${i + 1}${j + 1}`,
            value: `${i + 1}${j + 1}`,
        })),
    }));

    const [testOptions, setTestOptions] = React.useState();

    return (
        <>
            <Header title="Dropdowns">
                Select gives the ability to make a single or multiple selections from different options.
            </Header>
            {sections.map((style) => (
                <div className="bg-white shadow-inner text-gray-900 p-10">
                    <h3 className="text-xl font-bold mb-5">{style}</h3>

                    <Table
                        id="table-buttons-regular"
                        cols={["variant", ...types]}
                        header={{
                            variant: { width: "6rem" },
                            ...types.reduce((acc, type) => {
                                acc[type] = { title: <Small>{type}</Small> };
                                return acc;
                            }, {}),
                        }}
                        rows={variants.map((variant) => ({
                            variant: <Small>{variant}</Small>,
                            ...types.reduce((acc, type) => {
                                const id = `select-${type}-${style}-${variant}`;
                                acc[type] = (
                                    <Select
                                        id={id}
                                        search={variant === "SEARCH" ? true : undefined}
                                        disabled={variant === "DISABLED"}
                                        multiple={type === "MULTIPLE"}
                                        options={(() => {
                                            if (variant === "GROUPS" || variant === "SEARCH") {
                                                return groupOptions;
                                            }
                                            if (variant === "ICON") {
                                                return iconOptions;
                                            }
                                            return singleOptions;
                                        })()}
                                        onChange={onChangeTest}
                                    />
                                );
                                return acc;
                            }, {}),
                        }))}
                    />
                </div>
            ))}

            <div className="p-5 bg-white m-5 flex flex-col space-y-3">
                <div className="font-bold text-xl">Setting options dynamically</div>
                <div className="flex space-x-2">
                    <Button
                        id="dynamic-test-change"
                        design={testOptions ? "red" : "blue-outline"}
                        onClick={() => {
                            setTestOptions(
                                testOptions
                                    ? null
                                    : [
                                          {
                                              label: "Estadísticas de Cast",
                                              value: "statsCast",
                                          },
                                          {
                                              label: "estadísticas de WiFi",
                                              value: "statsWifi",
                                          },
                                          {
                                              label: "estadísticas de Mobile",
                                              value: "statsMobile",
                                          },
                                          {
                                              label: "estadísticas de ventas",
                                              value: "statsSales",
                                          },
                                      ]
                            );
                        }}
                    >
                        {testOptions ? "Clear options" : "Fill options"}
                    </Button>
                    <Select
                        id={"dynamic-test"}
                        multiple={true}
                        value={testOptions ? ["statsCast"] : ["fakeValue"]}
                        options={testOptions}
                        onChange={(value) => console.log("TEST ON CHANGE value: ", value)}
                    />
                </div>
            </div>
        </>
    );
};

export default Dropdowns;
