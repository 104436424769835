import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import TextInput from "components/TextInput";
import Icon from "components/Icon";

const Search = forwardRef(({ id, value, disabled, onChange, className, placeholder }, ref) => {
    const inputRef = useRef(null);
    const { t } = useTranslation();

    const selectClass = classNames({
        "inline-block min-w-60": true,
        [className]: className,
    });

    useImperativeHandle(ref, () => ({
        focus: () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        },
    }));

    return (
        <div className={selectClass}>
            <div className="w-full relative">
                <TextInput
                    ref={inputRef}
                    id={id}
                    placeholder={placeholder || t("search-for") + "..."}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                />
                <Icon type="search" size="xl" className="text-gray-700 absolute right-0 mr-3 mt-2" />
            </div>
        </div>
    );
});

export default Search;
