import React, { isValidElement, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import TableContext from "./Context";

import Button from "components/Button";
import Select from "components/Select";
import Search from "components/TextInput/Search";

const Top = ({ id, search, filters, batchActions, selectedRows, count, topRightCorner, folders }) => {
    const { t } = useTranslation();

    const { search: searchValue, setSearch: setSearchValue, disabled } = useContext(TableContext);

    const batchActionsComponent = batchActions?.options?.length ? (
        <BatchActions config={batchActions} disabled={disabled} selectedRows={selectedRows} />
    ) : null;

    const foldersComponent = isValidElement(folders) ? folders : null;

    const searchComponent = search ? (
        <Search id={`table-search-${id}`} value={searchValue} disabled={disabled} onChange={setSearchValue} />
    ) : null;

    const filtersComponent = filters ? (
        <div className={"inline-flex items-center space-x-6"}>
            <span className="first-capital w-full">{t("filter-by")}:</span>
            {filters.map((filter, index) => (
                <Filter key={filter?.id} id={index} config={filter} />
            ))}
        </div>
    ) : null;

    const hasCount = count || count === 0;

    const counterComponent = hasCount ? (
        <div className="flex justify-end items-end">
            <Counter total={count} />
        </div>
    ) : null;

    const counterPosition = (() => {
        if (!hasCount) {
            // No counter
            return 0;
        }
        if (topRightCorner) {
            // Below top right corner
            return 1;
        }
        if (batchActionsComponent) {
            // In batch actions line
            return 3;
        }
        // In search/filters line
        return 2;
    })();

    return searchComponent || filtersComponent || batchActionsComponent || topRightCorner || hasCount ? (
        <div className="w-full mb-10">
            <div className="relative flex flex-wrap gap-5 flex-row-reverse w-full justify-between items-stretch">
                {topRightCorner ? (
                    <div className="flex flex-col items-end justify-between">
                        {topRightCorner || null}
                        {counterPosition === 1 ? counterComponent : null}
                    </div>
                ) : null}
                <div className="flex flex-col gap-5" style={{ flex: 1 }}>
                    {searchComponent || filtersComponent || counterPosition === 2 ? (
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-wrap gap-5">
                                {searchComponent ? (
                                    <div className="flex items-center space-x-8">{searchComponent}</div>
                                ) : null}
                                {filtersComponent || null}
                            </div>
                            {counterPosition === 2 ? counterComponent : null}
                        </div>
                    ) : null}
                    {batchActionsComponent ? (
                        <div className="flex flex-row justify-between">
                            {batchActionsComponent || null}
                            {counterPosition === 3 ? counterComponent : null}
                        </div>
                    ) : null}
                    {foldersComponent ? <div className="mt-4">{foldersComponent || null}</div> : null}
                </div>
            </div>
        </div>
    ) : null;
};

const Counter = ({ total }) => {
    const { texts } = useContext(TableContext);
    if (texts?.countResults) {
        return <div className="text-gray-800 text-sm whitespace-nowrap">{texts.countResults(total)}</div>;
    }
    return null;
};

const BatchActions = ({ config, selectedRows, disabled }) => {
    const { t } = useTranslation();
    const { options, onChange } = config;

    const [selectedAction, setSelectedAction] = useState(null);

    const noneSelected = !selectedRows?.length;

    return (
        <div className="flex items-center space-x-2">
            <Select
                id="batch-actions"
                value={selectedAction}
                placeholder={t("batch-title")}
                disabled={disabled}
                options={options}
                onChange={setSelectedAction}
            />
            <Button
                id="batch-execute"
                design="blue"
                disabled={!selectedAction || noneSelected || disabled}
                onClick={() => {
                    if (onChange) {
                        onChange(selectedAction, selectedRows);
                    }
                }}
            >
                {t("batch-execute")}
            </Button>
        </div>
    );
};

const Filter = ({ id, config }) => {
    const { setPage, setActiveFilters, disabled } = useContext(TableContext);
    const { title, options, onFilter, onChange, multiple, selectGroup } = config;
    return (
        <Select
            id={`options-${id}`}
            placeholder={title}
            multiple={multiple}
            disabled={disabled}
            selectGroup={selectGroup}
            onChange={(value) => {
                if (onChange) {
                    onChange(value);
                }
                if (onFilter) {
                    setActiveFilters((prev) => ({ ...prev, [id]: { value, fn: onFilter } }));
                    setPage(1);
                }
            }}
            options={options}
        />
    );
};

export default Top;
