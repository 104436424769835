import { MonitorDevices } from "../components/Section/Hotel/Monitor/MonitorDevices";
import InstallationMap from "../components/Section/Hotel/InstallationMap";
import Rooms from "../components/Section/Hotel/Rooms/Rooms";
import RoomDetail from "../components/Section/Hotel/Rooms/RoomDetail";
import RoomPreview from "../components/Section/Hotel/Rooms/RoomPreview";
import TVPreview from "../components/Section/Hotel/TVPreview";
import RoomGroups from "../components/Section/Hotel/Rooms/RoomsGroups";
import ServicesConfig from "../components/Section/Hotel/ServicesConfig";
import Topology from "../components/Section/Hotel/Settings/Topology";
import EditRoom from "../components/Section/Hotel/Settings/EditRoom";
import AddRoom from "../components/Section/Hotel/Settings/AddRoom";
import AddFloor from "../components/Section/Hotel/Settings/AddFloor";
import AddWing from "../components/Section/Hotel/Settings/AddWing";
import TVSettings from "../components/Section/Hotel/TVSettings";
import CastSettings from "../components/Section/Hotel/CastSettings";
import WifiSettings from "../components/Section/Hotel/WifiSettings";
import PortalManagerWifi from "../components/Section/Hotel/PortalManagerWifi";
import MobileSettings from "components/Section/Hotel/MobileSettings";
import AccessProfiles from "../components/Section/Hotel/AccessProfles";
import AccessTypes from "../components/Section/Hotel/AccessTypes/AccessTypes";
import InputGroups from "../components/Section/Hotel/Settings/InputGroups";

import { newRoute, newRedirect } from "./utils";
import { NetworkWarning } from "components/Section/Hotel/NetworkWarning";

import { MenuHome } from "sections";

const sectionID = "hotel";
const packageID = "hotel";

const attrTopology = "topology";
const attrRoomGroups = "roomGroups";
const attrRooms = "roomList";
const attrDD = "dd";
const attrTV = "TVSettings";
const attrMonitoring = "monitoring";
const attrMap = "map";
const attrNetworkWarning = "networkAlerts";
const attrCastConfig = "castConfig";
const attrTVInputs = "tvInputs";
const packageProduct = "product";
const attrWifi = "wifi";

const route = newRoute(sectionID, packageID);
const redirect = newRedirect(sectionID);

export const hotelRoutes = [
    route("/", {
        section: MenuHome,
    }),

    route("/installation/", {
        redirect: redirect("/installation/monitor"),
        attribute: attrMonitoring,
    }),
    route("/installation/devices", {
        section: MonitorDevices,
        attribute: attrMonitoring,
    }),
    route("/installation/network-warning", {
        section: NetworkWarning,
        attribute: attrNetworkWarning,
        check: ({ permissions, isCorporate, userManagerRole, isDhcp, isSuperUser }) => {
            const hasCast = permissions?.product?.cast;
            return hasCast && isDhcp?.isSection && !isCorporate && (isSuperUser || !userManagerRole);
        },
    }),
    route("/installation/map", {
        section: InstallationMap,
        attribute: attrMap,
    }),
    route("/installation/monitor", {
        redirect: redirect("/installation/devices"),
        attribute: attrMonitoring,
    }),

    route("/rooms/", {
        redirect: redirect("/rooms/list"),
        attribute: attrRooms,
    }),
    route("/rooms/list", {
        section: Rooms,
        attribute: attrRooms,
    }),
    route("/rooms/roomgroups", {
        section: RoomGroups,
        attribute: attrRoomGroups,
    }),
    route("/rooms/room/preview/tv/:roomtvid/:ref", {
        hideSidebar: true,
        hideHeader: true,
        section: TVPreview,
        attribute: attrRooms,
    }),
    route("/rooms/room/preview/:roomtvid", {
        hideSidebar: true,
        hideHeader: true,
        section: RoomPreview,
        attribute: attrRooms,
    }),
    route("/rooms/room/preview/:roomtvid/:lang", {
        hideSidebar: true,
        hideHeader: true,
        section: RoomPreview,
        attribute: attrRooms,
    }),
    route("/rooms/room/detail/:id", {
        section: RoomDetail,
        attribute: attrRooms,
    }),

    route("/services", {
        section: ServicesConfig,
        attribute: attrDD,
    }),

    route("/property-settings", {
        redirect: redirect("/property-settings/wifi"),
    }),
    route("/property-settings/wifi", {
        section: WifiSettings,
        package: packageProduct,
        attribute: attrWifi,
    }),
    route("/property-settings/wifi/access-type", {
        section: AccessTypes,
        package: packageProduct,
        attribute: attrWifi,
    }),
    route("/property-settings/wifi/manager", {
        section: PortalManagerWifi,
        package: packageProduct,
        attribute: attrWifi,
    }),
    /*
    route("/property-settings/wifi/hotspot",{
        section: PortalHotspot,
        package: packageProduct,
        attribute: attrWifi,
    },
    */
    route("/property-settings/wifi/access-profile", {
        section: AccessProfiles,
        package: packageProduct,
        attribute: attrWifi,
    }),
    route("/property-settings/cast", {
        section: CastSettings,
        attribute: attrCastConfig,
    }),

    route("/property-settings/topology", {
        section: Topology,
        attribute: attrTopology,
    }),
    route("/property-settings/topology/edit-room/:id", {
        section: EditRoom,
        attribute: attrTopology,
    }),
    route("/property-settings/topology/add-room/:id", {
        section: AddRoom,
        attribute: attrTopology,
    }),
    route("/property-settings/topology/add-floor/:id", {
        section: AddFloor,
        attribute: attrTopology,
    }),
    route("/property-settings/topology/add-wing", {
        section: AddWing,
        attribute: attrTopology,
    }),

    route("/property-settings/tv", {
        redirect: redirect("/property-settings/tv-settings"),
        attribute: attrTV,
    }),

    route("/property-settings/mobile-settings", {
        section: MobileSettings,
        attribute: "mobileConfig",
    }),
    route("/property-settings/tv-settings", {
        section: TVSettings,
        attribute: attrTV,
    }),
    route("/property-settings/tv/inputs", {
        section: InputGroups,
        attribute: attrTVInputs,
    }),
];
