import React from "react";
import { LocationsItem } from "components/Section/Hotel/Settings/TopologyComponents/LocationsItem";
import { DeleteRoom } from "components/Section/Hotel/Settings/TopologyComponents/DeleteRoom";
import { EditRoom } from "components/Section/Hotel/Settings/TopologyComponents/EditRoom";

export const TopologyRoomModel = (room) => {
    const { floorName, floorID, id, name, number, pmsNumber, roomTVs, wingName } = room;
    const truncatedFloorName = floorName.length > 10 ? `${floorName.slice(0, 15)}...` : floorName;

    return {
        id,
        wing: wingName,
        floor: truncatedFloorName,
        floorFilter: floorID,
        roomNumber: (
            <p sortvalue={number} alt={number}>
                {number}
            </p>
        ),
        pmsNumber: <p sortvalue={pmsNumber}>{pmsNumber}</p>,
        name: (
            <p sortvalue={name} alt={name}>
                {name}
            </p>
        ),
        tvs: roomTVs.length,
        location: <LocationsItem locations={roomTVs} />,
        edit: <EditRoom room={room} />,
        delete: <DeleteRoom name={name} id={id} />,
        floorNumberForEdit: number,
        pmsNumberForEdit: pmsNumber,
        nameForEdit: name,
        locationsForEdit: roomTVs,
    };
};
