import Button from "components/Button";
import Icon from "components/Icon";
import { TopologyContext } from "contexts/Topology";
import { useCreateRoom } from "hooks/GraphqlCalls/Hotel/Settings/useCreateRoom";
import { useEditRoom } from "hooks/GraphqlCalls/Hotel/Settings/useEditRoom";
import React, { useContext, useEffect } from "react";

export const Save = ({ floorFilter, type, id, roomTv }) => {
    const { createRoomMutation } = useCreateRoom();
    const { editRoom } = useEditRoom();
    const {
        selectedWing,
        setWingValidation,
        selectedFloor,
        setFloorValidation,
        roomNumber,
        setRoomNumberValidation,
        name,
        setNameValidation,
        tvs,
        location,
        setLocationValidation,
        pmsNumber,
        setRoomsTVs,
        setCreating,
        setSelectedFloor,
        setSelectedWing,
        setRoomNumber,
        setName,
        setTvs,
        setLocation,
        setEditing,
        setPmsNumber,
    } = useContext(TopologyContext);

    useEffect(() => {
        setRoomsTVs(roomTv);
    }, [roomTv]);

    const floorID = floorFilter
        .find((floor) => floor?.label?.toLowerCase() === selectedWing)
        ?.options?.find((floor) => floor.id === selectedFloor)?.id;

    const handleAddRoom = () => {
        if (!selectedWing) {
            setWingValidation({ validation: "border border-red-100" });
        } else {
            setWingValidation({});
        }
        if (!selectedFloor) {
            setFloorValidation({ validation: "border border-red-100" });
        } else {
            setFloorValidation({});
        }
        if (!roomNumber) {
            setRoomNumberValidation("border border-red-100");
        } else {
            setRoomNumberValidation({});
        }
        if (!name) {
            setNameValidation("border border-red-100");
        } else {
            setNameValidation({});
        }

        if (tvs && location.length > 0) {
            setLocationValidation((prev) => {
                return location.map((loc, index) => {
                    const updatedLocation = [...prev];
                    updatedLocation[index] = {
                        ...updatedLocation[index],
                        validation: !loc?.name && "border border-red-100",
                    };
                    return updatedLocation[index];
                });
            });
        }

        if (
            selectedFloor &&
            selectedWing &&
            roomNumber &&
            name &&
            (tvs || location?.map((loc) => loc?.name).every((loc) => loc))
        ) {
            createRoomMutation(floorID, name, roomNumber, pmsNumber, location, tvs);
            setCreating(false);
            setSelectedFloor(null);
            setSelectedWing(null);
            setRoomNumber(null);
            setName(null);
            setTvs(null);
            setLocation([]);
        }
    };

    const handleSaveEdit = () => {
        if (!roomNumber) {
            setRoomNumberValidation("border border-red-100");
        } else {
            setRoomNumberValidation({});
        }
        if (!name) {
            setNameValidation("border border-red-100");
        } else {
            setNameValidation({});
        }

        if (tvs && location.length > 0) {
            setLocationValidation((prev) => {
                return location.map((loc, index) => {
                    const updatedLocation = [...prev];
                    updatedLocation[index] = {
                        ...updatedLocation[index],
                        validation: !loc?.name && "border border-red-100",
                    };
                    return updatedLocation[index];
                });
            });
        }

        if (roomNumber && name && tvs && location?.map((loc) => loc?.name).every((loc) => loc)) {
            editRoom(id, name, roomNumber, pmsNumber);
            setSelectedWing(null);
            setSelectedFloor(null);
            setRoomNumber(null);
            setPmsNumber(null);
            setName(null);
            setTvs(null);
            setLocation([]);
            setEditing((prev) => ({ ...prev, editing: false }));
            setCreating(false);
        }
    };

    return (
        <Button
            id="save"
            className="bg-green-100 p-1 rounded"
            onClick={() => {
                type === "addRoom" ? handleAddRoom() : handleSaveEdit();
            }}
        >
            <Icon type="tick-right" size="xl" className="text-white" />
        </Button>
    );
};
