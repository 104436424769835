import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData, cleanCheckedItems } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData, setRefreshContentShopData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";
import { capitalizeFirst } from "../../Utils/Utils";

//UTILS
import { toast } from "react-toastify";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { executeVendureQuery, getVendureApiData, uploadVendureMutation } from "../../Utils/Integrations/useVendure";
import { arrangeToastMessagesUploadingFiles, arrangeToastMessagesWhenFailsBatchActions } from "../../Utils/DesignUtils";
import UseToast from "../../../components/Notifications/useToast";
import { useNavigate as useHistory, useParams } from "react-router-dom";
import { Session } from "../../Utils/Session";
import uuid from "react-uuid";
import { initialParamsMutation } from "../../Utils/SalesUtils";

const UpdateListCategories = ({ client }) => {
    const { t } = useTranslation();
    const { idCat, id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { useGlobalLoading } = useSelector((state) => state.ui.modalContent);
    const { checkedItems, results } = useSelector((state) => state.table);
    const { executeQuery, refreshContentShopData } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const { projectLangs } = useSelector((state) => state.ui);
    const jsonTitlesFailsActionsBatch = {
        "delete-shops-category": "error-deleting-items-failed",
        "set-as-available-category-batch": "error-failure-items-available",
        "set-as-unavailable-category-batch": "error-failure-items-unavailable",
    };

    const vendureCategories = Session.getSessionProp("vendure-categories");
    const vendureCategoriesProducts = Session.getSessionProp("vendure-categories-products");

    const [categories, setCategories] = useState("");
    const [productsCategories, setProductsCategories] = useState([]);

    useEffect(() => {
        if (vendureCategories) {
            setCategories(JSON.parse(vendureCategories));
        }
    }, [vendureCategories]);

    useEffect(() => {
        if (vendureCategoriesProducts) {
            setProductsCategories(JSON.parse(vendureCategoriesProducts));
        }
    }, [vendureCategoriesProducts]);

    useEffect(() => {
        let {
            execute,
            mutation,
            mutationFile,
            executeAnotherQuery,
            mutationName,
            avoidToast,
            aditionalHeader,
            refreshShopData,
            useLoading,
            avoidRefresh,
            avoidCloseModal,
            avoidCleanAction,
            multipleMutations,
            multipleMutationsPC,
            avoidLoading,
            extraParams,
        } = initialParamsMutation;
        const titlesFailsActionsBatch = jsonTitlesFailsActionsBatch[executeQuery?.action];
        const name = (executeQuery?.params?.name || "").replace(/"/g, '\\"');
        const slug = (executeQuery?.params?.slug || "").replace(/"/g, '\\"');
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            execute = true;
            switch (executeQuery.action) {
                case "create-asset-image-categorie":
                    mutation = `mutation Mutation($file: Upload!) {
                        createAssets(input: [{ file: $file }]) {
                          ... on Asset {
                            id
                            name
                            fileSize
                          }
                          ... on ErrorResult {
                            message
                          }
                        }
                      }`;
                    aditionalHeader = { "vendure-token": executeQuery.params.token };
                    mutationFile = { file: executeQuery.params.file, mutationName: "createAssets" };
                    executeAnotherQuery = { action: "get-image-preview-categorie", params: { ...executeQuery.params } };
                    refreshShopData = true;
                    useLoading = false;
                    avoidRefresh = true;
                    avoidCloseModal = true;
                    avoidCleanAction = true;
                    break;
                case "get-image-preview-categorie":
                    mutation = `query{
                        asset(id: ${executeQuery.params.id}){
                          preview
                        }
                      }`;
                    executeAnotherQuery = { action: "not", params: { ...executeQuery.params } };
                    avoidToast = true;
                    mutationName = "asset";
                    refreshShopData = true;
                    useLoading = false;
                    avoidRefresh = true;
                    avoidCloseModal = true;
                    avoidCleanAction = true;
                    break;
                case "create-facet-categorie-value":
                    mutation = `mutation {
                        createFacetValues(input: {
                          facetId: ${actionData.values["id-category-facet-shop"]},
                          code: "${(actionData.actionData["name-category-shop"].toLowerCase() || "").replace(
                              /"/g,
                              '\\"'
                          )}",
                          translations: [
                            { languageCode: ${actionData.values["def-lang-category-shop"]}, name:  "${(
                        actionData?.actionData?.["name-category-shop"] || ""
                    ).replace(/"/g, '\\"')}" }
                          ]
                        }) {
                          id
                          code
                          translations {
                            languageCode
                            name
                          }
                        }
                      }`;
                    executeAnotherQuery = {
                        action: "create-categorie-as-collection",
                        params: { ...executeQuery.params },
                    };
                    aditionalHeader = { "vendure-token": actionData.values["tokenShop"] };
                    avoidCloseModal = false;
                    mutationName = "createFacetValues";
                    avoidRefresh = true;
                    avoidToast = true;
                    break;
                case "create-categorie-as-collection":
                    mutation = `mutation {
                        createCollection(
                          input: {
                            customFields:{
                                image_url: "${
                                    actionData.values["img-category-shop"] ? actionData.values["img-category-shop"] : ""
                                }"
                                can_have_children: ${
                                    actionData.values["type-category-shop"] === "PRODUCTS" ? false : true
                                }
                            }
                            translations: [
                              {
                                slug: "${uuid()}"
                                description: "\u200b"
                                languageCode: ${executeQuery.params[0].translations[0].languageCode}
                                name: "${(executeQuery.params[0].translations[0].name || "").replace(/"/g, '\\"')}"
                              },
                              
                            ]
                            filters: [
                              {
                                code: "facet-value-filter"
                                arguments: [
                                  { name: "facetValueIds", value: "[${executeQuery.params[0].id}]" }
                                  { name: "containsAny", value: "false" }
                                  { name: "combineWithAnd", value: "true" }
                                ]
                              }
                            ]
                            ${idCat ? `parentId: "${idCat}"` : ""}
                            ${actionData.values["type-category-shop"] === "PRODUCTS" ? "" : "isPrivate:false"}
                          }
                        ) {
                          id
                          name
                        }
                      }`;
                    aditionalHeader = { "vendure-token": actionData.values["tokenShop"] };
                    avoidCloseModal = false;
                    avoidRefresh = idCat ? false : true;
                    extraParams = {
                        "is-main-category":
                            actionData && actionData.values && actionData.values["id-category-parent"] ? false : true,
                    };
                    mutationName = "createCollection";
                    break;
                case "set-as-available-category":
                    mutation = `mutation {
                        updateCollection(
                          input:{
                          id:${actionData.itemsAffected[0]}
                          customFields:{
                            enabled: true
                          }
                        }
                          
                        ) {
                          id
                        }
                      }
                      `;
                    mutationName = "updateCollection";
                    break;
                case "set-as-unavailable-category":
                    mutation = `mutation {
                        updateCollection(
                          input:{
                          id:${actionData.itemsAffected[0]}
                          customFields:{
                            enabled: false
                          }
                        }
                          
                        ) {
                          id
                        }
                      }
                      `;
                    mutationName = "updateCollection";
                    break;
                case "delete-category":
                    const facetValuesIds =
                        findResultsItemById(results, actionData.itemsAffected[0])?.facetValueIds || [];
                    mutation = `mutation {
                        deleteCollection(id: ${actionData.itemsAffected[0]}) {
                          result
                          message
                        }
                        ${
                            facetValuesIds.length > 0
                                ? `deleteFacetValues(
                            ids: [${facetValuesIds.join(",")}]
                            force: true
                          ){
                            result
                            message
                        }`
                                : ""
                        }
                      }
                      `;
                    mutationName = "updateCollection";
                    break;
                case "delete-category-batch":
                    multipleMutations = {
                        arr: [],
                    };
                    checkedItems.forEach((checkedItem) => {
                        const facetValuesIds = results.filter((item) => item.id === checkedItem)?.[0].facetValueIds;
                        multipleMutations.arr.push({
                            id: checkedItem,
                            mutation: `mutation {
                                deleteCollection(id: ${Number(checkedItem)}) {
                                  result
                                  message
                                }
                                ${
                                    facetValuesIds.length > 0
                                        ? `deleteFacetValues(
                                    ids: [${facetValuesIds.join(",")}]
                                    force: true
                                  ){
                                    result
                                    message
                                }`
                                        : ""
                                }
                              }`,
                        });
                    });
                    mutationName = "deleteCollection";
                    executeQuery.closeModal = true;
                    break;
                case "set-as-available-category-batch":
                    multipleMutations = {
                        arr: [],
                    };
                    checkedItems.forEach((item) => {
                        multipleMutations.arr.push({
                            id: item,
                            mutation: `mutation {
                                updateCollection(
                                  input:{
                                  id:${Number(item)}
                                  customFields:{
                                    enabled: true
                                  }
                                }
                                  
                                ) {
                                  id
                                }
                              }
                            `,
                        });
                    });
                    mutationName = "updateCollection";
                    executeQuery.closeModal = true;
                    break;
                case "set-as-unavailable-category-batch":
                    multipleMutations = {
                        arr: [],
                    };
                    checkedItems.forEach((item) => {
                        multipleMutations.arr.push({
                            id: item,
                            mutation: `mutation {
                                updateCollection(
                                  input:{
                                  id:${Number(item)}
                                  customFields:{
                                    enabled: false
                                  }
                                }
                                  
                                ) {
                                  id
                                }
                              }
                            `,
                        });
                    });
                    mutationName = "updateCollection";
                    executeQuery.closeModal = true;
                    break;

                case "edit-names-category":
                    const namesCategoriesLaguages = [];
                    const translationsObj =
                        actionData && actionData.actionData && actionData.actionData["data-translations-obj-categories"]
                            ? actionData.actionData["data-translations-obj-categories"]
                            : {};
                    const idCategory =
                        actionData && actionData.actionData && actionData.actionData["id-category-shop"]
                            ? actionData.actionData["id-category-shop"]
                            : "";
                    const firstLang = Object.keys(translationsObj)[0];
                    const firstSlug = translationsObj[firstLang].slug;

                    projectLangs.forEach((lang) => {
                        const selector = document.querySelector(
                            "#edit-name-category-languages-for-category-" + lang.languageRef
                        );
                        if (selector) {
                            if (selector.value && selector.value.trim() !== "") {
                                namesCategoriesLaguages.push({
                                    lang: lang.languageRef,
                                    name: selector.value ? selector.value : "",
                                    slug: firstSlug,
                                    id:
                                        translationsObj[lang.languageRef] && translationsObj[lang.languageRef].id
                                            ? translationsObj[lang.languageRef].id
                                            : null,
                                });
                            }
                        }
                    });

                    let translationsString = "";
                    namesCategoriesLaguages.forEach((item) => {
                        translationsString += `{
                            languageCode: ${item.lang}
                            name: "${item.name}"
                            ${item.id ? `id: "${item.id}"` : ""}
                            slug: "${item.slug}"
                            description: "\u200b"
                          },`;
                    });

                    mutation = `mutation {
                        updateCollection(
                          input: {
                            id: "${idCategory}"
                            translations: [
                                ${translationsString}
                            ]
                          }
                        ) {
                          id
                          translations {
                            languageCode
                            name
                          }
                        }
                      }`;

                    mutationName = "updateCollection";
                    executeQuery.closeModal = true;
                    avoidLoading = true;
                    break;
                case "update-name-category":
                    mutation = `mutation {
                        updateCollection(
                          input: {
                            id: "${executeQuery.params.idCategory}"
                            translations: [
                                {
                                    languageCode: ${executeQuery.params.languageCode}
                                    name: "${name}"
                                    ${
                                        executeQuery.params.translationId
                                            ? `id: "${executeQuery.params.translationId}"`
                                            : ""
                                    }
                                    slug: "${slug}"
                                    description: "\u200b"
                                  }
                            ]
                          }
                        ) {
                          id
                          translations {
                            languageCode
                            name
                          }
                        }
                      }`;

                    mutationName = "updateCollection";
                    executeQuery.closeModal = true;
                    avoidLoading = true;
                    avoidToast = true;
                    avoidRefresh = true;
                    break;
                case "set-state-category":
                    mutation = `mutation {
                        updateCollection(
                          input: {
                            id: "${executeQuery.params.id}"
                                  customFields:{
                                    enabled:${executeQuery.params.active}
                            }
                          }
                        ) {
                          id
                        }
                      }
                      `;
                    mutationName = "updateCollection";
                    executeQuery.closeModal = true;
                    avoidLoading = true;
                    avoidRefresh = true;
                    break;
                case "add-category-to-product":
                    let mutationString = `mutation {`;
                    executeQuery.params.dataProductsArr.forEach((p, idx) => {
                        const facetsIdsSet = new Set([
                            ...p.categories,
                            ...p.idCategoriesParents,
                            ...p.otherFacetValuesSelected,
                        ]);

                        const facetsIds = Array.from(facetsIdsSet).join(",");

                        mutationString += `updateProduct${idx}: updateProduct(
                                 input: {
                                     id: "${p.idProduct}",
                                     facetValueIds:[ ${`${facetsIds}`} ]
                                   }
                                 ) {
                                   id
                                 }`;
                    });

                    mutationString += `}`;

                    mutation = mutationString;

                    executeAnotherQuery = {
                        action: "run-pending-search-update",
                        params: {},
                    };
                    avoidRefresh = true;
                    mutationName = "updateProduct";
                    break;
                case "delete-category-from-product":
                    let facetsWithoutIdsToDelete = "";
                    executeQuery.params.categories.forEach((cat) => {
                        if (!executeQuery.params.facetsToDelete.includes(Number(cat))) {
                            facetsWithoutIdsToDelete += cat + ",";
                        }
                    });

                    if (
                        executeQuery.params &&
                        executeQuery.params.otherFacetValuesSelected &&
                        executeQuery.params.otherFacetValuesSelected.length > 0
                    ) {
                        facetsWithoutIdsToDelete += executeQuery.params.otherFacetValuesSelected.join(",");
                    }

                    // console.log(`${`${facetsWithoutIdsToDelete.replace(/\s*,\s*/g, ",").replace(/,{2,}/g, ",")}`}`);

                    mutation = `mutation {
                      updateProduct(
                        input: {
                          id: "${executeQuery.params.idProduct}",
                          facetValueIds:[ ${`${facetsWithoutIdsToDelete
                              .replace(/\s*,\s*/g, ",")
                              .replace(/,{2,}/g, ",")}`} ]
                        }
                      ) {
                        id
                      }
                    }`;
                    executeAnotherQuery = {
                        action: "run-pending-search-update",
                        params: {},
                    };
                    avoidRefresh = true;
                    mutationName = "updateProduct";
                    // return;
                    break;
                case "run-pending-search-update":
                    mutation = `mutation {
                      runPendingSearchIndexUpdates {
                        success
                      }
                    }`;
                    mutationName = "runPendingSearchIndexUpdates";
                    // avoidRefresh = true;
                    avoidToast = true;
                    break;
                case "delete-category-from-product-batch":
                    multipleMutations = {
                        arr: [],
                    };
                    executeQuery.params.itemsData.forEach((item) => {
                        let facetsWithoutIdsToDelete = "";

                        item.categories.forEach((cat) => {
                            if (!item.facetsToDelete.includes(Number(cat))) {
                                facetsWithoutIdsToDelete += cat + ",";
                            }
                        });

                        const facets = `${` ${facetsWithoutIdsToDelete} `} ${
                            item.otherFacetValuesSelected && item.otherFacetValuesSelected.length > 0
                                ? "," + item.otherFacetValuesSelected.join(",")
                                : ""
                        }`;

                        multipleMutations.arr.push({
                            id: item.id,
                            mutation: `mutation {
                            updateProduct(
                              input: {
                                id: "${item.id}",
                                facetValueIds:[ ${`${facets.replace(/\s*,\s*/g, ",").replace(/,{2,}/g, ",")}`} ]
                              }
                            ) {
                              id
                            }
                          }`,
                        });
                    });
                    executeAnotherQuery = {
                        action: "run-pending-search-update",
                        params: {},
                    };
                    multipleMutationsPC = true;
                    avoidRefresh = true;
                    mutationName = "updateProduct";
                    executeQuery.closeModal = true;
                    break;
                default:
                    mutation = null;
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (useGlobalLoading && !avoidLoading) dispatch(showGlobalLoading(true));
            if (useLoading && !avoidLoading) dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation({
                    mutation,
                    executeAnotherQuery,
                    mutationFile,
                    mutationName,
                    avoidToast,
                    aditionalHeader,
                    refreshShopData,
                    avoidRefresh,
                    avoidCloseModal,
                    avoidCleanAction,
                    multipleMutations,
                    titlesFailsActionsBatch,
                    multipleMutationsPC,
                    extraParams,
                });
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    const executeMutation = async ({
        mutation,
        executeAnotherQuery,
        mutationFile,
        mutationName,
        avoidToast,
        aditionalHeader,
        refreshShopData,
        avoidRefresh,
        avoidCloseModal,
        avoidCleanAction,
        multipleMutations,
        titlesFailsActionsBatch,
        multipleMutationsPC,
        extraParams,
    }) => {
        if (!avoidCloseModal) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(false));
        }

        let errorMutation = false;
        let toastId = "";
        let files = null;

        if (mutationFile) {
            files = [
                {
                    name: mutationFile.file.name,
                    error: errorMutation,
                    textTooltip: mutationFile.file.name,
                    status: 1,
                    ref: "",
                    size: mutationFile.file.size,
                },
            ];
        }

        if (multipleMutations && multipleMutations.arr && multipleMutations.arr.length > 0) {
            const successIds = [];
            const sucessResponse = [];
            const errorsIds = [];
            const errorsResponse = [];
            const errorsJsonStores = [];
            const deleteAction = mutationName === "deleteCollection" || mutationName === "updateProduct";
            const categoriesDeleted = multipleMutationsPC
                ? "next-products-have-been-deleted"
                : "next-categories-have-been-deleted";
            const categoriesNotDeleted = "next-categories-have-not-been-deleted";
            const categoriesModified = "next-categories-have-been-modified-successfully";
            const categoriesNotModified = "next-categories-have-not-been-modified-successfully";

            await Promise.all(
                multipleMutations.arr.map(async (item) => {
                    const res = await executeVendureQuery(getVendureApiData(), { queryBody: item.mutation }); // Send request for each id

                    if (res && res.data && !res.data.errors) {
                        successIds.push(item.id);
                    }

                    if (res && res.error) {
                        errorsIds.push(item.id);
                    }
                })
            );

            if (successIds.length > 0) {
                let itemsToIterate = multipleMutationsPC ? productsCategories : categories;
                successIds.forEach((id) => {
                    itemsToIterate.forEach((element) => {
                        if (element.id === id) {
                            sucessResponse.push(element.name);
                        }
                    });
                });
                toast.success(
                    <div className=" flex flex-col">
                        <span>{capitalizeFirst(t(deleteAction ? categoriesDeleted : categoriesModified))}:</span>
                        {sucessResponse.map((item) => (
                            <span>&#x2022; {item}</span>
                        ))}
                    </div>
                );
            }

            if (errorsIds.length > 0) {
                let itemsToIterate = multipleMutationsPC ? productsCategories : categories;
                errorsIds.forEach((errorId) => {
                    itemsToIterate.forEach((element) => {
                        if (element.id === errorId) {
                            errorsResponse.push(element.name);
                            errorsJsonStores.push({
                                name: element.name,
                                storeId: errorId,
                            });
                        }
                    });
                });

                if (titlesFailsActionsBatch) {
                    let msgs = arrangeToastMessagesWhenFailsBatchActions(errorsJsonStores);
                    toast.warning(<UseToast title={t(titlesFailsActionsBatch)} msgs={msgs} minimize={false} />, {
                        autoClose: 3500,
                        className: "use-toast fail-products-delete",
                        closeButton: true,
                        closeOnClick: false,
                        draggable: false,
                        hideProgressBar: true,
                        icon: false,
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error(
                        <div className=" flex flex-col">
                            <span>
                                {capitalizeFirst(t(deleteAction ? categoriesNotDeleted : categoriesNotModified))}:
                            </span>
                            {errorsResponse.map((item) => (
                                <span>&#x2022; {item}</span>
                            ))}
                        </div>
                    );
                }
            }
            dispatch(setRefreshData(true));
            dispatch(cleanCheckedItems());
            dispatch(setLoading(false));
            if (executeAnotherQuery) {
                dispatch(
                    setExecuteQuery({
                        action: executeAnotherQuery.action,
                        params: executeAnotherQuery.params,
                    })
                );
            } else {
                dispatch(setExecuteQuery(null));
            }
        } else {
            let response = "";
            if (!mutationFile) {
                response = await executeVendureQuery(
                    getVendureApiData(),
                    { queryBody: mutation },
                    typeof aditionalHeader === "object" ? aditionalHeader : {}
                );
                if (response && response.data && !response.data.errors) {
                    if (mutationName) {
                        let mutationObject = response.data[mutationName];
                        if (
                            mutationObject &&
                            mutationObject.result &&
                            mutationObject.result.toUpperCase().includes("NOT")
                        ) {
                            errorMutation = true;
                        } else if (executeAnotherQuery) {
                            executeAnotherQuery.params = { ...executeAnotherQuery.params, ...mutationObject };
                            dispatch(
                                setExecuteQuery({
                                    action: executeAnotherQuery.action,
                                    params: executeAnotherQuery.params,
                                })
                            );
                        }
                        if (mutationName === "createCollection") {
                            if (extraParams["is-main-category"]) {
                                history(
                                    `/services/sales/shop/categories/${id}/edit-category/${response.data["createCollection"]?.id}`
                                );
                            }
                        }
                    }
                } else {
                    errorMutation = true;
                }
            } else {
                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toastId = toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: false,
                    className: "use-toast",
                    closeButton: false,
                    closeOnClick: false,
                    draggable: false,
                    hideProgressBar: true,
                    icon: false,
                });

                response = await uploadVendureMutation(
                    getVendureApiData(),
                    {
                        queryBody: mutation,
                        file: mutationFile.file,
                    },
                    typeof aditionalHeader === "object" ? aditionalHeader : {}
                );

                if (response && response.data && !response.data.errors) {
                    if (mutationFile.mutationName) {
                        let mutationObject = response.data[mutationFile.mutationName];
                        if (
                            mutationObject &&
                            mutationObject.result &&
                            mutationObject.result.toUpperCase().includes("NOT")
                        ) {
                            errorMutation = true;
                        } else if (
                            mutationObject instanceof Array &&
                            mutationObject.length > 0 &&
                            executeAnotherQuery
                        ) {
                            executeAnotherQuery.params = { ...executeAnotherQuery.params, ...mutationObject[0] };
                        }
                    }
                } else {
                    errorMutation = true;
                }
            }
        }

        if (!errorMutation && !multipleMutations) {
            if (mutationFile && !errorMutation) {
                // eslint-disable-next-line
                files.forEach((file) => {
                    file["status"] = 2;
                });

                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                    className: "use-toast",
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    closeButton: true,
                });

                if (executeAnotherQuery) {
                    dispatch(
                        setExecuteQuery({
                            action: executeAnotherQuery.action,
                            params: executeAnotherQuery.params,
                        })
                    );
                }
            } else if (!avoidToast) {
                toast.success(t("operation-successful"));
            }
            if (refreshShopData && !avoidRefresh) {
                dispatch(setRefreshContentShopData({ ...refreshContentShopData, state: true }));
                setTimeout(() => {
                    dispatch(setRefreshContentShopData(false));
                }, 1000);
            } else if (!avoidRefresh) {
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
            }

            if (mutationName === "asset" && refreshShopData) {
                dispatch(setRefreshContentShopData({ ...refreshContentShopData, state: true }));
                setTimeout(() => {
                    dispatch(setRefreshContentShopData(false));
                }, 1000);
            }

            dispatch(setExecuteQuery(null));
            if (!avoidCleanAction) {
                dispatch(cleanAction());
            }
            if (executeQuery.cacheKeyToDelete) {
                removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
            }
        } else if (!multipleMutations) {
            dispatch(setExecuteQuery(null));
            if (useGlobalLoading) dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            if (mutationFile) {
                // eslint-disable-next-line
                files.forEach((file) => {
                    file["status"] = 4;
                    file["errorMessage"] = "";
                });
                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                    className: "use-toast",
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    closeButton: true,
                });
            } else {
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
                toast.error(t("mutation-error"));
            }
        }
    };

    return null;
};

export default withApollo(UpdateListCategories);

const findResultsItemById = (results, id) => {
    for (let item of results) {
        if (item.id === id) {
            return item;
        }
        if (item.subrowData && item.subrowData.length) {
            const found = findResultsItemById(item.subrowData, id);
            if (found) {
                return found;
            }
        }
    }
    return null;
};
