import React, { useState } from "react";
import Icon from "./Icon";
import classNames from "classnames";
import Dropdown from "components/Dropdown";

export const DropdownSection = ({ dropdownSectionHeader, children, canDisplay = true, open, id }) => {
    const [opened, setOpen] = useState(open);

    if (!id) {
        throw new Error("DropdownSection: id is required");
    }

    const dropdownClass = classNames({
        [`p-8 flex gap-2 items-center justify-between bg-white w-full`]: true,
    });
    const chevronClass = classNames({
        [`flex item-center animated cursor-pointer ${opened ? "rotate-180" : ""}`]: canDisplay,
    });

    return (
        <Dropdown
            autoClose={false}
            designClass={{
                dropdown: "bg-white",
                handlerIcon: "text-xl",
            }}
            initialOpen={open ?? !canDisplay}
            onOpenChange={setOpen}
            showArrow={false}
            disabled={!canDisplay}
            handler={
                <div className={dropdownClass}>
                    <div className="flex items-center gap-2">
                        {canDisplay && (
                            <div className={chevronClass}>
                                <Icon type="chevron" size="xl" />
                            </div>
                        )}
                        <div className="flex items-center gap-2">{renderHeader(dropdownSectionHeader)}</div>
                    </div>
                    {dropdownSectionHeader?.finalIcon ? (
                        <div onClick={(e) => e.stopPropagation()}>
                            <Icon
                                type={dropdownSectionHeader?.finalIcon?.type}
                                size={dropdownSectionHeader?.finalIcon?.size}
                                className={dropdownSectionHeader?.finalIcon?.className}
                                tooltip={dropdownSectionHeader?.finalIcon?.tooltip}
                            />
                        </div>
                    ) : null}
                </div>
            }
        >
            <div className="px-10">
                <hr />
            </div>
            {children}
        </Dropdown>
    );
};
DropdownSection.displayName = "DropdownSection";

const renderHeader = (dropdownSectionHeader) => {
    const dataType = typeof dropdownSectionHeader;
    switch (dataType) {
        case "string":
            return <h1>{dropdownSectionHeader}</h1>;
        case "object":
            const { title, titleIcon } = dropdownSectionHeader;
            return (
                <>
                    <h1 className={title?.className}>{title?.content}</h1>
                    <Icon
                        type={titleIcon?.type}
                        size={titleIcon?.size}
                        className={titleIcon?.className}
                        tooltip={titleIcon?.tooltip}
                    />
                </>
            );
        default:
            return null;
    }
};
