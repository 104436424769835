import NotFound from "../components/Section/NotFound";
import { customersRoutes } from "./customers";
import { commonZonesRoutes } from "./commonZones";
import { devicesRoutes } from "./devices";
import { authRoutes } from "./auth";
import { hotelRoutes } from "./hotel";
import { designRoutes } from "./design";
import { infoRoutes } from "./info";
import { settingsRoutes } from "./settings";
import { statsRoutes } from "./stats";
import { servicesRoutes } from "./services";
import { Session } from "../hooks/Utils/Session";
import BlankPage from "../components/Section/Blank";
import { allPropertiesRoutes } from "./allProperties";
import Home from "sections";
// import { tableTestRoutes } from "./tableTest";

const isLoggedIn = Session.getSessionProp("token") || Session.usePreviousSession();
const isSuperUser = Session.getSessionProp("superUser") || null;
const chainRef = Session.getSessionProp("chainRef") || null;

const ALL_ROUTES = [
    {
        path: "/",
        section: Home,
    },
    {
        path: "/no-content",
        section: BlankPage,
    },
    {
        path: "/playground/*",
        reload: true,
    },
    {
        path: "/version",
        reload: true,
    },
    ...allPropertiesRoutes,
    ...customersRoutes,
    ...commonZonesRoutes,
    ...devicesRoutes,
    ...authRoutes,
    ...designRoutes,
    ...hotelRoutes,
    ...infoRoutes,
    ...settingsRoutes,
    ...statsRoutes,
    ...servicesRoutes,
    // ...tableTestRoutes,
    {
        path: "*",
        section: NotFound,
    },
];

let _routes = ALL_ROUTES;

if (isLoggedIn && isSuperUser && !chainRef) {
    _routes = [
        ...authRoutes,
        {
            path: "*",
            redirect: "/select-profile",
        },
    ];
    if (!Session.getSessionProp("superUserData")) {
        Session.removeSessionProp("projectRef");
        Session.removeSessionProp("chainRef");
        console.log(Session.getSessionProp("token"));
        Session.setSessionProp(
            "superUserData",
            JSON.stringify({
                token: Session.getSessionProp("token"),
                userFullname: Session.getSessionProp("userFullname"),
                email: Session.getSessionProp("userEmail"),
                recovery: Session.getSessionProp("recovery"),
            })
        );
    }
}

export const routes = !isLoggedIn
    ? [
          ...authRoutes,
          {
              path: "*",
              redirect: "/login",
          },
      ]
    : _routes;
