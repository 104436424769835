import React, { useRef, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { ShopContext } from "contexts/Sales/Shop";

import SettingsCard from "components/SettingsCard";
import UseSectionHeader from "components/useSectionHeader";
import Loading from "components/Loading";
import Icon from "components/Icon";

import EditRoomService from "./Delivery.RoomService.jsx";
import EditGeolocation from "./Delivery.Geolocation.jsx";
import EditPickup from "./Delivery.Pickup.jsx";

import PriceWithTax from "components/Inputs/PriceWithTax";

import {
    DELIVERY_METHOD_ROOM,
    DELIVERY_METHOD_PICKUP,
    DELIVERY_METHOD_GEOLOCATION,
    DELIVERY_METHODS,
    sortDeliveryMethods,
    getDeliveryMethodText,
} from "constants/sales";

const Delivery = () => {
    const { t } = useTranslation();

    const editPickupRef = useRef();

    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const { id, loading, shippingConfig, pickupLocations, setDeliveryMethodAvailable } = useContext(ShopContext);

    const availableLocationsCount = pickupLocations?.filter((location) => location.available).length || 0;

    const projectLangs = useSelector((state) => state?.ui?.projectLangs);
    const defaultLang = projectLangs?.find((lang) => lang.isDefault)?.languageRef;

    const [editing, setEditing] = useState({});

    const breadcrumbs = [
        {
            name: t("shop-settings"),
            route: `/services/sales/shop/settings/${id}`,
        },
        {
            name: t("orders-and-delivery"),
            route: `/services/sales/shop/settings/${id}`,
        },
    ];

    useEffect(() => {
        if (!loading) {
            setIsFirstLoad(false);
        }
    }, [loading, shippingConfig]);

    const enabledDeliveryMethodsCount = DELIVERY_METHODS.filter((key) => shippingConfig?.[key]?.enabled).length;

    return (
        <>
            <UseSectionHeader title={t("Delivery method allowed")} customBreadCrumbs={breadcrumbs} />
            {loading && isFirstLoad ? (
                <Loading />
            ) : (
                <div className={loading ? "opacity-50" : ""}>
                    {DELIVERY_METHODS
                        ? sortDeliveryMethods(DELIVERY_METHODS).map((key) => {
                              const isPickup = key === DELIVERY_METHOD_PICKUP;
                              const isRoomService = key === DELIVERY_METHOD_ROOM;
                              const isGeolocation = key === DELIVERY_METHOD_GEOLOCATION;
                              const isEditing = editing[key];
                              const isEnabled = shippingConfig?.[key]?.enabled;
                              const canDisable = enabledDeliveryMethodsCount > 1;

                              const title = t(getDeliveryMethodText(key));
                              const info = isGeolocation && !isEditing ? t("geolocation-info") : null;

                              const toggleButton = !(isPickup && isEditing)
                                  ? {
                                        checked: isEnabled,
                                        disabled:
                                            (isEnabled && !canDisable) ||
                                            loading ||
                                            (isPickup && availableLocationsCount === 0),
                                        label: t("available"),
                                        action: (value) => {
                                            setDeliveryMethodAvailable(key, value).then(() => {
                                                toast.success(t("operation-successful"));
                                            });
                                        },
                                    }
                                  : null;

                              const editButton = !isEditing
                                  ? {
                                        onClick: () => {
                                            setEditing({ ...editing, [key]: true });
                                        },
                                    }
                                  : null;

                              const editClose = (changed) => {
                                  setEditing({ ...editing, [key]: false });
                              };

                              return (
                                  <SettingsCard
                                      className="mb-5 py-4"
                                      key={key}
                                      id={key}
                                      title={
                                          <div className="flex pl-10 text-base space-x-5 items-center ">
                                              {isPickup ? (
                                                  <PickupHeader
                                                      title={title}
                                                      isEditing={isEditing}
                                                      lang={defaultLang}
                                                      amount={availableLocationsCount}
                                                  />
                                              ) : (
                                                  <div className="py-2 flex items-center">
                                                      <div>{title}</div>
                                                      {info ? (
                                                          <Icon type="info" size="xl" tooltip={info} className="ml-2" />
                                                      ) : null}
                                                  </div>
                                              )}
                                          </div>
                                      }
                                      toggle={toggleButton}
                                      edit={editButton}
                                      right={
                                          isPickup && isEditing ? (
                                              <button
                                                  id="add-location-button"
                                                  onClick={() => {
                                                      if (editPickupRef.current) {
                                                          editPickupRef.current.addLocation();
                                                      }
                                                  }}
                                                  disabled={loading}
                                                  className="text-blue-300 font-bold"
                                              >{`+ ${t("Add location")}`}</button>
                                          ) : null
                                      }
                                  >
                                      {isEditing &&
                                          !loading && [
                                              isPickup && (
                                                  <EditPickup
                                                      ref={editPickupRef}
                                                      onClose={editClose}
                                                      defaultLang={defaultLang}
                                                  />
                                              ),
                                              isRoomService && <EditRoomService onClose={editClose} />,
                                              isGeolocation && (
                                                  <EditGeolocation defaultLang={defaultLang} onClose={editClose} />
                                              ),
                                          ]}
                                  </SettingsCard>
                              );
                          })
                        : null}
                </div>
            )}
        </>
    );
};

const PickupHeader = ({ title, isEditing, lang, amount }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={`min-w-52 ${isEditing ? null : "border-r-2"}`}>
                <div className="py-2">{title}</div>
            </div>
            <div className="font-normal">
                {isEditing
                    ? t(`language:${lang}`) + ` (${t("default-lang")})`
                    : t("Locations available x", {
                          count: amount || 0,
                      })}
            </div>
        </>
    );
};

export const FooterButtons = ({ id, disabled, onCancel, onSave }) => {
    const { t } = useTranslation();

    if (!onCancel && !onSave) {
        throw new Error("No actions provided");
    }

    const cancelID = id ? `${id}-cancel` : null;
    const saveID = id ? `${id}-save` : null;

    return (
        <div className={`pt-6 space-x-5 text-center flex justify-end ${disabled ? "opacity-50" : ""}`}>
            {onCancel ? (
                <button
                    id={cancelID}
                    disabled={disabled}
                    onClick={onCancel}
                    className="btn-white p-4 rounded btn-blue-outline"
                >
                    {t("cancel")}
                </button>
            ) : null}
            {onSave ? (
                <button id={saveID} disabled={disabled} onClick={onSave} className="btn-blue p-4 rounded">
                    {t("save")}
                </button>
            ) : null}
        </div>
    );
};

export const EditPrice = ({ id: inputID, price: priceCents, taxRate: taxRateID, onChangePrice }) => {
    const { taxRates, defaultTax, currency } = useContext(ShopContext);

    const taxes = taxRates
        ? taxRates.map((rate) => ({
              id: rate.id,
              value: rate.value,
              name: rate.name,
              default: defaultTax?.id === rate.id,
          }))
        : null;

    return (
        <PriceWithTax
            id={inputID}
            price={priceCents}
            taxID={taxRateID}
            currencyCode={currency?.code}
            taxes={taxes}
            onChange={(data) => {
                if (onChangePrice) {
                    onChangePrice({
                        price: data.price,
                        taxRate: taxRates ? taxRates.find((rate) => rate.id === data.tax?.id) : null,
                    });
                }
            }}
        />
    );
};

export const TextInput = ({
    id,
    value: initialName,
    placeholder,
    maxLength,
    multiline,
    required,
    displayCount,
    onChange,
    className,
}) => {
    const { t } = useTranslation();
    const [name, setName] = useState(initialName);

    useEffect(() => {
        setName(initialName);
    }, [initialName]);

    const inputProps = {
        id,
        value: name ?? "",
        placeholder,
        required,
        maxLength,
        onChange: (e) => setName(e.target.value),
        className: classNames({
            "border bg-gray-200 text-gray-900 py-1 px-3 w-full rounded": true,
            "border-gray-200": !(required && !name),
            "border-red-100": required && !name,
        }),
        onBlur: () => {
            if (onChange) {
                onChange(name);
            }
        },
    };

    return (
        <div className={className}>
            {multiline ? <textarea {...inputProps} rows={multiline} /> : <input {...inputProps} />}
            {displayCount && maxLength !== undefined ? (
                <span className="float-right text-gray-800 text-sm">
                    {t("x/y characters", { current: name?.length || 0, count: maxLength })}
                </span>
            ) : null}
        </div>
    );
};

export default Delivery;
