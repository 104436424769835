import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UseCheckBox from "components/Inputs/useCheckBox";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import { changeActionValues } from "actions/globalActions";
import { setLoadingModalContent } from "actions/uiActions";

import useGuestOrders from "hooks/Data/useGuestOrders";

import ErrorInfo from "components/ErrorInfo";
import Icon from "components/Icon";

import { ORDER_STATUS_INPROGRESS, ORDER_STATUS_PENDING, ORDER_STATUS_READY } from "constants/sales";
import { PMS_STATUS_DISABLED, PMS_STATUS_CHARGED } from "constants/charges";

const UseModalCheckout = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [hasWarnings, setHasWarnings] = useState(false);

    const { permissions } = useSelector((state) => state.ui);
    const hasOrdersManagement = permissions?.services?.shopsOrders;

    const [loading, setLoading] = useState(hasOrdersManagement);
    const [selectedRooms, setSelectedRooms] = useState({});

    const { guestID, rooms, checkAll, roomChecked } = useSelector(
        (state) => state?.ui?.modalContent?.inputs?.[index]?.data
    );
    const { results, checkedItems: guestIDs } = useSelector((state) => state.table);

    const guests = (guestID ? [guestID] : guestIDs) || [];

    useEffect(() => {
        dispatch(setLoadingModalContent(loading));
    }, [loading]);

    useEffect(() => {
        let data = [];
        if (selectedRooms) {
            Object.keys(selectedRooms).forEach((guestID) => {
                if (selectedRooms[guestID]) {
                    data = data.concat(selectedRooms[guestID]);
                }
            });
        }
        dispatch(changeActionValues({ "modal-checkout-rooms-checked": data.join(",") }));
    }, [selectedRooms]);

    if (!guests || guests.length === 0) {
        return <ErrorInfo>Bad params: not found guest ids</ErrorInfo>;
    }

    return (
        <>
            <div className="-mt-4 mb-5">{t("are-you-sure-you-want-to-check-out")}</div>
            {guests.map((guestID, index) => {
                const rr = rooms || getRoomsById(results, guestID);
                const guestName = getNameValueById(results, guestID);
                return (
                    <GuestCheckout
                        key={guestID}
                        mainIndex={index}
                        displayWarnings={guests.length > 1 || rr.length > 1}
                        guestID={guestID}
                        guestName={guestName}
                        rooms={rr}
                        checkAll={checkAll}
                        roomChecked={roomChecked}
                        setLoading={setLoading}
                        onSelectedRoomsChange={(data) => {
                            setSelectedRooms({ ...selectedRooms, [guestID]: data });
                        }}
                        onWarnings={() => {
                            setHasWarnings(true);
                        }}
                    />
                );
            })}
            {hasWarnings ? (
                <div className="w-full mt-5 flex items-center whitespace-no-wrap">
                    <span className="icon block ml-1 mr-2 text-2xl icon-warning text-orange-100"></span>
                    <span>
                        {hasOrdersManagement ? t("there-are-checkout-warnings") : t("there-are-failed-charges")}
                    </span>
                </div>
            ) : null}
        </>
    );
};

const GuestCheckout = ({
    guestID,
    guestName,
    rooms,
    checkAll,
    roomChecked,
    setLoading,
    onSelectedRoomsChange,
    onWarnings,
    mainIndex,
    displayWarnings,
}) => {
    const { t } = useTranslation();

    const { permissions } = useSelector((state) => state.ui);
    const hasOrdersManagement = permissions?.services?.shopsOrders;

    const [roomsData, setRoomsData] = useState(arrangeRooms(rooms, [], [], checkAll, roomChecked, hasOrdersManagement));

    const {
        load: loadGuestOrders,
        loading: loadingGuestOrders,
        called: guestOrdersCalled,
        error,
        data: dataGuestOrders,
    } = useGuestOrders({ commonVars: { lang: "es" } });

    const loading = loadingGuestOrders || !guestOrdersCalled;

    useEffect(() => {
        if (roomsData) {
            onSelectedRoomsChange(roomsData?.filter((room) => room.check).map((room) => room.id));

            let warnings = 0;
            roomsData.forEach((room) => {
                if (room.hasUndeliveredOrders || room.hasFailedCharges) {
                    warnings++;
                }
            });
            if (warnings > 0 && onWarnings) {
                onWarnings();
            }
        }
    }, [roomsData]);

    useEffect(() => {
        if (dataGuestOrders) {
            setRoomsData(
                arrangeRooms(
                    rooms,
                    dataGuestOrders?.orders?.results,
                    dataGuestOrders?.charges?.results,
                    checkAll,
                    roomChecked,
                    hasOrdersManagement
                )
            );
        }
    }, [dataGuestOrders]);

    useEffect(() => {
        loadGuestOrders({ guestID });
    }, []);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    return (
        <>
            {guestName ? (
                <div id={`modal-checkout-guest-name-${mainIndex}`} className=" mt-3 mb-2">
                    {guestName}
                </div>
            ) : null}
            {roomsData.map((room, index) => {
                let warnings = [];
                if (room.hasUndeliveredOrders) {
                    warnings.push({ id: "warn-undelivered-orders", text: t("there-are-undelivered-orders") });
                }
                if (room.hasFailedCharges) {
                    warnings.push({ id: "warn-failed-charges", text: t("there-are-failed-charges") });
                }
                return (
                    <div className="flex items-center ml-2" key={room.number}>
                        <div className="flex whitespace-no-wrap">
                            <UseCheckBox
                                id={`modal-checkout-guest-name-${mainIndex}-${index}`}
                                onClick={() => {
                                    setRoomsData(toggleCheck(roomsData, room.number));
                                }}
                                checked={room.check}
                                name={`${capitalizeFirst(t("room"))} ${room.number}`}
                            />
                        </div>
                        {displayWarnings && warnings.length > 0 ? (
                            <div className="w-full ml-5 flex items-center -mt-4 whitespace-no-wrap">
                                {warnings.map((warning) => (
                                    <span
                                        key={warning?.id}
                                        id={warning?.id}
                                        className="icon block mr-2 text-2xl icon-warning text-orange-100"
                                        data-tip={warning?.text}
                                        data-for="default-tooltip"
                                    ></span>
                                ))}
                            </div>
                        ) : null}
                    </div>
                );
            })}
            {error ? (
                <div className="flex items-start space-x-2 px-1 pt-5">
                    <Icon type="warning" size="3xl" />
                    <div>
                        {t("There are technical problems with Zafiro Sales")}
                        <br />
                        {t("There could be undelivered orders")}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default UseModalCheckout;

function arrangeRooms(rooms, orders, charges, checkAll, roomChecked, hasOrdersManagement) {
    return rooms.map((room) => {
        const roomInfo = {
            id: room.RoomID || room.roomID,
            number: room.number,
            hasUndeliveredOrders: false,
            hasFailedCharges: false,
            check: checkAll || roomChecked === room.number,
        };

        if (hasOrdersManagement) {
            roomInfo.hasUndeliveredOrders = orders
                ? orders
                      .filter((o) => o.roomNumber === room.number)
                      .some((o) =>
                          [ORDER_STATUS_INPROGRESS, ORDER_STATUS_PENDING, ORDER_STATUS_READY].includes(o.state)
                      )
                : null;
        }
        roomInfo.hasFailedCharges = charges
            .filter((c) => c.roomId === room.id)
            .some((c) => ![PMS_STATUS_DISABLED, PMS_STATUS_CHARGED].includes(c.pmsStatus));

        return roomInfo;
    });
}

function getRoomsById(results, guestId) {
    if (results) {
        const guestData = results.find((item) => item.id === guestId);
        return guestData ? guestData.rooms : [];
    }
    return [];
}

function getNameValueById(results, guestId) {
    if (results) {
        const guestData = results.find((item) => item.id === guestId);
        if (guestData) {
            const nameInfo = guestData.info.find((info) => info.name === "name");
            return nameInfo ? nameInfo.value : null;
        }
    }
    return null;
}

function toggleCheck(roomsData, roomNumberToFind) {
    if (roomsData) {
        return roomsData.map((room) => {
            if (room.number === roomNumberToFind) {
                room.check = !room.check;
            }
            return room;
        });
    }
    return roomsData;
}
