import Button from "components/Button";
import Icon from "components/Icon";
import { DateInput } from "components/Inputs/DateInput";
import { HourInput } from "components/Inputs/HourInput";
import Modal, { useModal } from "components/Modal";
import Select from "components/Select";
import { useEditAlarm } from "hooks/GraphqlCalls/Customers/useEditAlarm";
import { getTheCorrectDate } from "hooks/GraphqlCalls/Customers/utils/alarmsUtils";
import { getDate, getHour, subtractHours } from "hooks/GraphqlCalls/Customers/utils/alarmsUtils";
import Parser from "hooks/Utils/Parser";
import { capitalizeFirst } from "hooks/Utils/Utils";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const EditAlarm = ({
    stayguestName,
    stayguestSurname,
    rooms,
    type,
    tvChannelId,
    startTime,
    groupingID,
    refetch,
    setLoadingRefresh,
}) => {
    const roomsParsed = JSON.parse(rooms);
    const [alarmType, setAlarmType] = useState(type === "BEEP" ? "BEEP" : "TVCHANNEL");
    const [tvChannel, setTvChannel] = useState("");
    const [date, setDate] = useState(getTheCorrectDate(getDate(startTime)));
    const [hour, setHour] = useState(getHour(startTime));
    const [tvChannelValidation, setTvChannelValidation] = useState({ validation: "" });
    const [isoDate, setIsoDate] = useState(`${date}T${hour}:00.000Z`);
    const { editAlarm } = useEditAlarm();

    const { t } = useTranslation();
    const { channels } = useSelector((state) => state.sectionContent);
    const { close } = useModal();

    const getChannels = (channels) => {
        return channels.map((channel) => {
            return {
                id: channel.id,
                value: channel.name,
                label: channel.name,
            };
        });
    };

    const handleSaveTVChannelId = (value) => {
        const id = channels?.find((channel) => channel.name === value)?.id;
        setTvChannel(id);
    };

    const handleSave = () => {
        if (alarmType === "TVCHANNEL" && !tvChannel) {
            setTvChannelValidation({ validation: "border border-red-100" });
        } else {
            editAlarm({
                groupingID,
                startTime: isoDate,
                type: alarmType,
                tvChannelId: tvChannel,
            });
            close();
            refetch();
            setLoadingRefresh(true);
            setTimeout(() => {
                setLoadingRefresh(false);
            }, 1000);
            toast.success(t("toast-alarm", { action: t("edited") }));
        }
    };

    useEffect(() => {
        const dateArray = getDate(date).split("/");
        const hourToMutate = subtractHours(hour, 2);
        setIsoDate(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}T${hourToMutate}:00.000Z`);
    }, [date, hour]);

    const printAlarms = (rooms) => {
        const roomsParsed = JSON.parse(rooms);

        return (
            <div>
                {Object.keys(roomsParsed).map((room) =>
                    roomsParsed[room]?.map((location, index) => (
                        <div
                            key={location}
                            className={`flex gap-2 items-center mt-2 pb-2 font-bold ${
                                index !== roomsParsed[room]?.length - 1 ? "border-b border-gray-300" : ""
                            }`}
                        >
                            <Icon type="alarm" size="xl" className="text-blue-500" />
                            <span>
                                {t("tv")} - {location?.location}
                            </span>
                        </div>
                    ))
                )}
            </div>
        );
    };

    return (
        <Modal
            id="edit-alarm"
            title={`${stayguestName} ${stayguestSurname} - ${t("edit-alarm")}`}
            className="w-1/2 px-12 py-10 overflow-hidden"
            onKeyPress={{ Enter: close }}
        >
            <>
                <main className="flex gap-24">
                    <div>
                        <p>
                            {Parser(
                                t("edit-alarm-text", {
                                    guestName: `${stayguestName} ${stayguestSurname}`,
                                })
                            )}
                            :
                        </p>
                        <div className="flex items-center gap-4">
                            <DateInput
                                onChange={(value, day) => setDate(getTheCorrectDate(day))}
                                type="hyphen"
                                value={date}
                                customDisabledDays={[{ after: new Date(2024, 7, 0), before: new Date() }]}
                            />
                            <div className="mt-2">
                                <HourInput
                                    id={"modal-new-alarm"}
                                    onChange={(value) => setHour(value)}
                                    hour={hour}
                                    validation={true}
                                    clearable={false}
                                />
                            </div>
                        </div>
                        <div className="my-4">
                            <label className="mb-2">{t("type")} *</label>
                            <Select
                                id="alarm-type"
                                allowUnselect={false}
                                placeholder={t("select-alarm-type")}
                                value={type}
                                options={[
                                    { value: "BEEP", label: t("beep") },
                                    { value: "TVCHANNEL", label: t("tv-channel") },
                                ]}
                                onChange={(value) => setAlarmType(value)}
                            />
                        </div>
                        {alarmType === "TVCHANNEL" ? (
                            <div className="my-4">
                                <label className="mb-2">{t("select-channel")} *</label>
                                <Select
                                    id="alarm-type"
                                    allowUnselect={false}
                                    value={channels?.filter((channel) => channel.id === tvChannelId)[0]?.name}
                                    designClass={tvChannelValidation}
                                    optionsContainerClass="w-1/2 overflow-hidden"
                                    placeholder={t("select-channel")}
                                    options={getChannels(channels) || []}
                                    onChange={handleSaveTVChannelId}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div>
                        <p>{t("tvs")}:</p>
                        <h3 className="font-bold">{Object.keys(roomsParsed)}</h3>
                        <div className="mt-2 p-4 rounded border border-gray-200 mb-4 break-words text-base text-gray-800 w-80">
                            {printAlarms(rooms)}
                        </div>
                    </div>
                </main>
                <footer className="flex justify-end gap-4 mt-12">
                    <Button id="cancel" className="btn btn-white" onClick={close}>
                        {t("close")}
                    </Button>
                    <Button
                        id="save"
                        className="btn btn-blue"
                        onClick={() => {
                            handleSave();
                        }}
                    >
                        {capitalizeFirst(t("save"))}
                    </Button>
                </footer>
            </>
        </Modal>
    );
};
