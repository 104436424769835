import React, { useState, useEffect, useRef, useLayoutEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import { changeActionValues } from "../actions/globalActions";

//Utils
import useComponentVisible from "../components/useComponentVisible";
import UseCheckOptions from "./useCheckOptions";
import { removeDiacritics, multipleTranslate, capitalizeT } from "../hooks/Utils/Utils";
import Parser from "hooks/Utils/Parser";
import { Session } from "../hooks/Utils/Session";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const UseSelectWithSearch = ({ data, overlapOptions, widgetID, radioName }) => {
    const { t } = useTranslation();

    //data
    const {
        optionData,
        name,
        selectedIds,
        cStyle,
        noSelect,
        selectPlaceHolder,
        optionsAdjust,
        oneSelected,
        addInput,
        hideCheck,
        disabled,
        block,
        hideSearch,
        categoryCheck,
        onChange,
        selectedColor,
        sort,
        fixed,
        parentName,
        editOptions,
        noMaxHeight,
        adjust,
        noShowSelectedOptionsInDropDownSection,
        showSelectdOptionsInFooterSection,
        selectedTextAdjust,
        onChangeComponentVisible,
        onRemoveOption,
        id,
        adjustContainerOption,
        nonInitialOptionData,
        customNoDataMessage,
        useOptionID,
        tooltip,
    } = data;
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { values, validations } = useSelector((state) => state.action);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //States
    const [selectActive, setSelectActive] = useState({});
    const [showAllSelected, setShowAllSelected] = useState(false);
    if (optionData) {
        optionData.map((item) => (item.visible = true));
    }

    if (sort && optionData) {
        optionData.sort((a, b) => (a.name ? String(a.name).localeCompare(String(b.name)) : 0));
        optionData.sort((a, b) => (a.category ? String(a.category).localeCompare(String(b.category)) : 0));
    }

    const initialOptions = optionData ? JSON.parse(JSON.stringify(optionData)) : [];
    const [dataOptions, setDataOptions] = useState(initialOptions);
    const [selectedIdsDone, setSelectedIdsDone] = useState(false);
    const [searching, setSearching] = useState(false);

    const dataOptionsRef = useRef(dataOptions);
    dataOptionsRef.current = dataOptions;

    const overlapOptionsStyle = { position: "fixed", width: "25.5rem", zIndex: "2" };
    const [fixedStyle, setFixedStyle] = useState({ position: "fixed", top: "0px", zIndex: "501", width: "25rem" });

    //Actions
    const dispatch = useDispatch();

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            //ensure visibility of all elements
            const temCopy = JSON.parse(JSON.stringify(dataOptions));
            temCopy.map((item) => (item.visible = true));
            setDataOptions(temCopy);

            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
        if (onChangeComponentVisible && typeof onChangeComponentVisible === "function") {
            onChangeComponentVisible(isComponentVisible);
        }
        // eslint-disable-next-line
    }, [isComponentVisible]);

    useEffect(() => {
        if (optionData && nonInitialOptionData) {
            setDataOptions(JSON.parse(JSON.stringify(optionData)));
        }
    }, [optionData]);

    useLayoutEffect(() => {
        if (isComponentVisible) {
            //ensure visibility of all elements
            const radio = document.querySelector(`#${radioName}_radio_${widgetID}`);
            if (overlapOptions && radio) {
                const optionsContainer = document.querySelector(`#t-options-container_${widgetID}`);
                const editorScreenContainer = document.querySelector("#editor_screen_container");
                const radioPositionY = radio?.getBoundingClientRect().y;
                optionsContainer.style.top = `${radioPositionY + 20}px`;
                const totalHeightEditor = editorScreenContainer.getBoundingClientRect().height;
                const heighPlusYPosition =
                    optionsContainer.getBoundingClientRect().y + optionsContainer.getBoundingClientRect().height;
                const validationsOptionsSuperpassEditor = heighPlusYPosition > totalHeightEditor;

                if (validationsOptionsSuperpassEditor) {
                    optionsContainer.style.top = `${
                        optionsContainer.getBoundingClientRect().y -
                        optionsContainer.getBoundingClientRect().height -
                        80
                    }px`;
                }
            } else if (fixed) {
                const base = document.getElementById(name);
                const parent = document.getElementById(parentName);
                if (base && parent) {
                    const add = base.getBoundingClientRect().height > 32 ? 23 : 8;
                    setFixedStyle({
                        ...fixedStyle,
                        top: base.getBoundingClientRect().top + add,
                    });
                }
            }
        }
        // eslint-disable-next-line
    }, [isComponentVisible]);

    const elementIsVisible = (ele, container) => {
        const { bottom, height, top } = ele.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        return top <= containerRect.top
            ? containerRect.top - top + height <= height
            : bottom + height - 5 - containerRect.bottom <= height;
    };

    const markSelected = () => {
        if (dataOptionsRef.current && dataOptionsRef.current.length > 0 && selectedIds && selectedIds.length > 0) {
            const selectedItems = dataOptionsRef.current.map((item) =>
                selectedIds.filter((id) => String(id) === String(item.id)).length > 0 ? item.id : null
            );
            dispatch(
                changeActionValues({
                    [name]: selectedItems.filter((item) => item !== null),
                })
            );
        }
    };

    const clearSelected = () => {
        dispatch(changeActionValues({ [name]: null }));
        setDataOptions(
            dataOptions
                ? dataOptions.map((v) => {
                      if (v) v.selected = false;
                      return v;
                  })
                : null
        );
    };

    useEffect(() => {
        if (selectedIds && selectedIdsDone !== JSON.stringify(selectedIds)) {
            markSelected();
            setSelectedIdsDone(JSON.stringify(selectedIds));
        } else if (selectedIdsDone !== JSON.stringify(selectedIds)) {
            clearSelected();
        }
        // eslint-disable-next-line
    }, [selectedIds]);

    useEffect(() => {
        markSelected();
        // eslint-disable-next-line
    }, []);

    //Si las opciones del select llega tras crear el componente
    useEffect(() => {
        if (optionData) {
            if (
                Session.getSessionProp("updateSelectOptions") &&
                Session.getSessionProp("updateSelectOptions").includes(name)
            ) {
                if (sort) {
                    optionData.sort((a, b) => (a.name ? String(a.name).localeCompare(String(b.name)) : 0));
                    optionData.sort((a, b) => (a.category ? String(a.category).localeCompare(String(b.category)) : 0));
                }
                setDataOptions(JSON.parse(JSON.stringify(optionData)));
                let updateSelectOptionsValues = Session.getSessionProp("updateSelectOptions");
                updateSelectOptionsValues = updateSelectOptionsValues.replace(name, "");
                if (updateSelectOptionsValues && updateSelectOptionsValues !== "") {
                    updateSelectOptionsValues = updateSelectOptionsValues.split(",").filter((element) => element);
                    if (updateSelectOptionsValues && updateSelectOptionsValues.length > 0) {
                        Session.setSessionProp("updateSelectOptions", updateSelectOptionsValues.join(","));
                    } else {
                        Session.removeSessionProp("updateSelectOptions");
                    }
                } else {
                    Session.removeSessionProp("updateSelectOptions");
                }
            }
        }
        // eslint-disable-next-line
    }, [optionData]);

    useEffect(() => {
        if (!dataOptions || dataOptions.length < 1) {
            return;
        }
        if (values && values[name] && Object.keys(values[name]).length > 0) {
            const temCopy = JSON.parse(JSON.stringify(dataOptions));
            temCopy.map((item) => {
                item.selected = Object.values(values[name]).includes(item.id);
            });
            setDataOptions(temCopy);
        } else {
            const temCopy = JSON.parse(JSON.stringify(dataOptions));
            setDataOptions(temCopy);
        }

        if (oneSelected) {
            setIsComponentVisible(false);
        }
        // eslint-disable-next-line
    }, [values]);

    const removeOption = (e) => {
        e.stopPropagation();
        let removedId = String(e.currentTarget.dataset.id);
        const temCopy = JSON.parse(JSON.stringify(dataOptions));
        temCopy.map((item) => (String(item.id) === removedId ? (item.selected = false) : null));
        setDataOptions(temCopy);

        const selectedIds = temCopy.map((item) => (item.selected ? item.id : null));
        dispatch(
            changeActionValues({
                [name]: selectedIds.filter((item) => item !== null),
            })
        );
        if (onChange) {
            onChange(e.currentTarget.dataset.id);
        }
        if (onRemoveOption && typeof onRemoveOption === "function") {
            onRemoveOption(removedId);
        }
    };

    const filterResults = (e) => {
        e.stopPropagation();

        const search = e?.currentTarget?.value || "";

        const temCopy = JSON.parse(
            JSON.stringify(
                initialOptions?.map((i) => {
                    const found = dataOptions ? dataOptions.find((d) => d.id === i.id) : null;
                    return found || i;
                })
            )
        );
        if (search?.length) {
            setSearching(true);
            const cleanSearch = removeDiacritics(search.toLowerCase());
            temCopy.map((item) => {
                if (item) {
                    const cleanName = removeDiacritics((item.name || "").toLowerCase());
                    const cleanNumber = removeDiacritics((String(item.number) || "").toLowerCase());
                    const cleanDescription = removeDiacritics((item.description || "").toLowerCase());
                    const cleanCategory = removeDiacritics((item.category || "").toLowerCase());
                    item.visible =
                        cleanName.includes(cleanSearch) ||
                        cleanCategory.includes(cleanSearch) ||
                        cleanNumber.includes(cleanSearch) ||
                        cleanDescription.includes(cleanSearch);
                }
                return item;
            });
        } else {
            setSearching(false);
            temCopy.map((item) => (item.visible = true));
        }
        setDataOptions(temCopy);
    };

    const changeVisibility = (e) => {
        if (isComponentVisible) {
            const temCopy = JSON.parse(JSON.stringify(dataOptions));
            temCopy.map((item) => (item.visible = true));
            setDataOptions(temCopy);
        }
        if (!isComponentVisible) {
            setTimeout(function () {
                const inputSearch = document.querySelector(`#${name}_input`);
                if (inputSearch) {
                    inputSearch.focus();
                }
            }, 200);
        }
        setIsComponentVisible(isComponentVisible ? false : true);
    };

    const addItem = () => {
        const inputField = document.querySelector(`#${id}_add_item_input`);
        if (inputField && inputField.value) {
            let temCopy = JSON.parse(JSON.stringify(dataOptions));
            const allreadyDefined = temCopy.find(
                (element) => element.id.toLowerCase() === inputField.value.toLowerCase()
            );
            if (allreadyDefined) {
                toast.error(t("location-exist", { location: inputField.value }));
                return;
            }
            //remove preSelected option when oneSelected is active.
            if (oneSelected) temCopy.map((temCopyObject) => (temCopyObject.selected = false));

            temCopy.push({
                id: inputField.value,
                name: inputField.value,
                selected: true,
                visible: true,
            });
            setDataOptions(temCopy);

            const selectedIds = temCopy.map((item) => (item.selected ? item.id : null));
            dispatch(
                changeActionValues({
                    [name]: selectedIds.filter((item) => item !== null),
                })
            );
            inputField.value = "";
        }
    };

    const showSelectedOptionsInDropDownSection = () => {
        let response = [];

        if (dataOptions != null && dataOptions.length > 0) {
            let _countSelected = 0;
            dataOptions.forEach((option) => {
                if (option.selected) {
                    _countSelected++;
                }
            });
            // eslint-disable-next-line
            dataOptions.map((item, index) => {
                if (item.selected) {
                    if (response != null && response.length < 1)
                        response.push(
                            <p
                                key={`${item}_${index}`}
                                className={`font-bold text-gray-900 first-capital mt-1 mb-1 ${selectedTextAdjust}`}
                            >
                                {langStrings["selected"]}:
                            </p>
                        );
                    if ((response.length < 7 && !showAllSelected) || showAllSelected) {
                        response.push(
                            <div
                                key={item.id}
                                className={`float-left z-20 ${
                                    selectedColor ? selectedColor : item.color || "bg-blue-200"
                                } text-white pl-2 mr-4 p-1 mb-2 rounded whitespace-no-wrap`}
                            >
                                {Parser(item.name)}
                                {!item.blocked && (
                                    <div
                                        id={`${index}-remove`}
                                        className="icon-remove inline-block ml-8 cursor-pointer "
                                        data-index={index}
                                        data-id={item.id}
                                        onClick={removeOption}
                                    ></div>
                                )}
                            </div>
                        );
                    } else if (response.length === 7 && !showAllSelected) {
                        response.push(
                            <div
                                key={index}
                                className="float-left z-20 p-1 text-blue-200 cursor-pointer"
                                onClick={() => setShowAllSelected(true)}
                            >
                                +{_countSelected - 6}
                            </div>
                        );
                    }
                }
            });
        }
        return response;
    };

    const printSelectOptions = () => {
        if (!dataOptions || dataOptions?.length === 0) {
            return (
                <div className="text-gray-800 p-2">{capitalizeT(customNoDataMessage ?? "no options available", t)}</div>
            );
        }

        return (
            <UseCheckOptions
                dataOptions={dataOptions}
                setDataOptions={setDataOptions}
                name={name}
                oneSelected={oneSelected}
                hideCheck={hideCheck}
                onChange={onChange}
                categoryCheck={categoryCheck}
                searching={searching}
                fixed={fixed}
                editOptions={editOptions}
                noMaxHeight={false}
                parentSectionName={id}
                adjustContainerOption={adjustContainerOption}
                useOptionID={useOptionID}
            />
        );
    };

    return (
        <Fragment key={ref}>
            <div
                ref={ref}
                id={`${name}-select`}
                className={`${adjust ? adjust : "h-32px max-h-30"} inline-flex bg-gray-200 rounded relative ${
                    cStyle && dataOptions.filter((op) => op.selected === true).length > 0 && !noSelect
                        ? cStyle
                        : "w-full"
                } ${selectActive.container} } ${
                    typeof validations[name] !== "undefined" && !validations[name] ? "border border-red-100" : ""
                }`}
            >
                <div
                    id={id ? id : name}
                    tabIndex={0}
                    className={`t-filter-ph rounded 
                    ${disabled ? " bg-gray-300" : ""}
                    overflow-x-hidden overflow-y-auto overflow-ellipsis ${
                        disabled
                            ? "disabled"
                            : dataOptions.filter((item) => item.selected === true).length === 0
                            ? ""
                            : "text-gray-900"
                    } `}
                    data-tip={tooltip ? tooltip : null}
                    data-for={"default-tooltip"}
                    onClick={(e) => changeVisibility()}
                    onKeyUp={(e) => (e.keyCode === 13 ? changeVisibility() : null)}
                >
                    <div className={`w-11/12`}>
                        {dataOptions.map((item) =>
                            item.selected && !noSelect ? (
                                <div
                                    key={`optionSelected_${item.id}`}
                                    className={`float-left z-20   ${
                                        selectedColor ? selectedColor : item.color || "bg-blue-200"
                                    } text-white pl-2 mr-4 p-1 rounded h-6 text-sm mb-0.5 flex items-center`}
                                >
                                    {item.name}
                                    <div
                                        id={`${item.name}-remove`}
                                        className="icon-remove inline-block ml-8 cursor-pointer"
                                        data-id={item.id}
                                        onClick={removeOption}
                                    ></div>
                                </div>
                            ) : null
                        )}
                    </div>
                    {noSelect && dataOptions.filter((item) => item.selected === true).length > 0
                        ? dataOptions.filter((item) => item.selected === true).length === 1 && oneSelected
                            ? Parser(dataOptions.filter((item) => item.selected === true)[0].name)
                            : `${dataOptions.filter((item) => item.selected === true).length} ${
                                  langStrings["selected"]
                              }`
                        : null}
                    {dataOptions.filter((item) => item.selected === true).length === 0
                        ? multipleTranslate(selectPlaceHolder, langStrings)
                        : null}
                </div>
                {!disabled && !block ? (
                    <span
                        id={`${name}-chevron-down`}
                        className={`field-icon icon-chevron animated cursor-pointer text-gray-900 ${selectActive.icon}`}
                        onClick={changeVisibility}
                    ></span>
                ) : (
                    <span
                        id={`${name}-chevron-down`}
                        className={`field-icon icon-chevron animated cursor-pointer ${disabled ? "disabled" : ""}`}
                    ></span>
                )}

                {isComponentVisible && !disabled && !block ? (
                    <>
                        <div
                            id={`t-options-container_${widgetID}`}
                            className={`t-options-container ${optionsAdjust}`}
                            style={
                                fixed
                                    ? fixedStyle
                                    : overlapOptions
                                    ? overlapOptionsStyle
                                    : { position: "absolute", width: "100%", zIndex: "90" }
                            }
                        >
                            {!hideSearch ? (
                                <div>
                                    <div>
                                        <input
                                            id={`${name}_input`}
                                            type="text"
                                            className="t-filter-search"
                                            style={{ width: "calc(100% - 1rem)" }}
                                            placeholder={`${langStrings["search"]}...`}
                                            onChange={filterResults}
                                        ></input>
                                        <span
                                            className="field-icon icon-search table mt-5"
                                            style={{ height: "2rem" }}
                                        ></span>
                                    </div>
                                </div>
                            ) : null}
                            <span>
                                {noSelect && !oneSelected && !noShowSelectedOptionsInDropDownSection ? (
                                    <div className="bg-transparent w-full overflow-hidden pl-3">
                                        {showSelectedOptionsInDropDownSection()}
                                    </div>
                                ) : null}
                                {!disabled && !block && printSelectOptions()}
                            </span>
                            {addInput ? (
                                <div>
                                    <input
                                        id={`${id}_add_item_input`}
                                        type="text"
                                        className="t-filter-search  m-0 w-10/12"
                                        placeholder={`${langStrings["add-item"]}...`}
                                    ></input>

                                    <img
                                        id={`${id}_add_item`}
                                        onClick={(e) => addItem()}
                                        src={require(`../assets/images/icons/save-green.svg`)}
                                        className="w-2/12 inline cursor-pointer bg-gray-200"
                                        style={{ height: "2.5rem" }}
                                        alt=""
                                    ></img>
                                </div>
                            ) : null}
                        </div>
                    </>
                ) : null}
            </div>
            {noSelect && !oneSelected && showSelectdOptionsInFooterSection ? (
                <div className="bg-transparent w-full overflow-hidden mt-3 ">
                    {showSelectedOptionsInDropDownSection()}
                </div>
            ) : null}
        </Fragment>
    );
};

export default UseSelectWithSearch;
