import { useSelector } from "react-redux";
import {
    joinFormDateTime,
    dateString,
    isValidDate,
    bdDateToDateInput,
    escapeSpecialChars,
    composeDestination,
} from "../../../Utils/Utils";
import RoomModalMutationDispatcher from "../../Hotel/Rooms/modals/RoomModalMutationDispatcher";
import SettingsModalDispatcher from "../../Settings/modals/SettingsModalDispatcher";

//Utils
import { Session } from "../../../Utils/Session";
import moment from "moment";
import { SCREEN } from "../../../Utils/Design/EditorUtils";

export const ModalMutationDispatcher = (returnFakeMutation = false) => {
    //Store data
    const actionData = useSelector((state) => state.action);
    const { id, email, name, inputs, projectId, isDefault } = useSelector((state) => state.ui.modalContent);
    const { checkedItems } = useSelector((state) => state.table);
    const sectionContentData = useSelector((state) => state.sectionContent.data);
    const { sectionContent } = useSelector((state) => state);

    if (returnFakeMutation) {
        return `mutation{
            fake{ error id ok}}`;
    }

    return getMutation(
        actionData,
        id,
        email,
        name,
        sectionContentData,
        inputs,
        checkedItems,
        projectId,
        isDefault,
        sectionContent
    );
};

export const formattedDate = (actionData) => {
    let res;

    if (actionData.values && actionData.values["cleaning-date"]) {
        let date = actionData.values["cleaning-date"];
        let h = actionData.values["cleaning-hour"];
        if (h) {
            date += " " + h;
        }
        res = new window.ZDate(date);
    } else {
        res = new window.ZDate();
    }

    return isValidDate(res) ? res.toISOString() : "";
};

export const getMutationBookingFreq = (actionData, bookingFrec) => {
    let res = ` bookingFreq: `;
    switch (actionData.values[bookingFrec]) {
        case "each-15-min":
            res += `${15}`;
            break;
        case "each-30-min":
            res += `${30}`;
            break;
        case "each-hour":
            res += `${60}`;
            break;
        default:
            res += `${Number(actionData.values[bookingFrec])}`;
    }
    return res;
};

export const getBookingFreq = (actionData, bookingFrec) => {
    let res = 0;
    switch (actionData.values[bookingFrec]) {
        case "each-15-min":
            res = 15;
            break;
        case "each-30-min":
            res = 30;
            break;
        case "each-hour":
            res = 60;
            break;
        default:
            res = Number(actionData.values[bookingFrec]);
    }
    return res;
};

export const getMutation = (
    actionData,
    id,
    email,
    name,
    sectionContentData,
    inputs,
    checkedItems,
    projectId,
    isDefault,
    sectionContent
) => {
    //Data
    let mutation = ``;
    const bookingFrec = "booking-freq";

    let okResponse = "ok";
    let errorResponse = "error";
    let idResponse = "id";
    let inputData = inputs ? inputs.filter((input) => input.type === "data") : null;
    if (inputData) {
        inputData = inputData[0];
    } else {
        inputData = [];
    }
    const dataAction = {
        ...actionData,
        inputData,
        checkedItems,
        sectionContentData: { ...sectionContentData },
    };
    const actionName = actionData.actionName ? actionData.actionName : "default";
    let landingID;
    let projectRef;
    let designID;
    //Dispatcher
    switch (actionName) {
        case "delete-pre-check-ins":
            mutation += `mutation {
                deleteStayBookings(ids:[${checkedItems.join(",")}]`;
            break;
        case "change-password":
            mutation += `mutation{resetPassword(email: "${Session.getSessionProp(
                "userEmail"
            )}" password : { old:"${escapeSpecialChars(
                actionData.values["current-password"]
            )}" new:"${escapeSpecialChars(actionData.values["new-password"])}"}`;
            okResponse = "okUpdate ";
            idResponse = "";
            break;
        case "edit-text":
            let translations = "";
            Object.entries(actionData.values).map((item) =>
                item[0] !== "undefined" && item[0] !== "defaultLang"
                    ? (translations += `{lang:"${item[0]}" text:"${escapeSpecialChars(item[1])}"}`)
                    : null
            );

            mutation += `mutation{updateText(id:${actionData.itemsAffected[0]} parentType:${inputs[0].parentType}  i18n: [${translations}]`;
            break;
        case "booking-modify":
            mutation += `mutation{updateBooking(id: ${id} status: 2`;
            break;
        case "delete-schedule":
        case "delete-periodic-schedule":
        case "delete-schedule-editor":
            mutation += `mutation{deleteTimetable(id:"${id}"`;
            break;
        case "change-event":
            mutation += `mutation{deleteTimetableByCZ(id:${Number(actionData.itemsAffected[0])}`;
            break;
        case "delete-delete-category":
            mutation += `mutation{deleteCZCategory(id:"${id}"`;
            break;
        case "area-erase":
            mutation += `mutation {deleteCommonZone(id: "${id}"`;
            break;
        case "staff-generate-password":
            mutation += `mutation {regeneratePassword(email: "${email}", isStaff: true, ref:"${actionData.values.ref}"`;
            okResponse = "okEmail okUpdate";
            errorResponse = "";
            break;
        case "staff-delete":
            mutation += `mutation {deleteStaffUser(id: "${id}"`;
            break;
        case "edit-guest-group":
        case "new-guest-group":
            const isEdit = actionData.actionName === "edit-guest-group";
            const guestsIds = actionData.values["guests-of-group"];

            mutation += `mutation {${isEdit ? "updateGuestGroup" : "createGuestGroup"}(${
                isEdit ? `id:${actionData.itemsAffected[0]} batch:true ` : ""
            } name: "${
                actionData.values && actionData.values["name"] ? `${escapeSpecialChars(actionData.values["name"])}` : ""
            }", ${
                guestsIds && guestsIds.length > 0
                    ? `guests:[${guestsIds.filter((item) => item !== null).join(",")}]`
                    : "guests:[]"
            }`;
            break;

        case "generate-roomcode":
        case "chromecast-change-pairing":
            mutation += `mutation {regenerateRoomcodes(roomIDs: [${actionData.values["roomid"]}]`;
            idResponse = "";
            break;
        case "add-room":
            mutation += `mutation {addRoomToStay(stayGuestID: ${id},
                roomID: ${
                    actionData.values && actionData.values["room"] && actionData.values["room"][0]
                        ? actionData.values["room"][0]
                        : 0
                }`;
            break;
        case "change-room":
            if (inputData.rooms.length === 1) {
                mutation += `mutation {changeRoom(stayGuestID: ${inputData.guestID}, oldRoomID: ${
                    inputData.rooms[0].roomID
                }, newRoomID: ${
                    actionData.values[`room-${inputData.rooms[0].roomID}`]
                        ? actionData.values[`room-${inputData.rooms[0].roomID}`][0]
                        : null
                }`;
            } else {
                //cambio de hab múltiple
                mutation += `mutation {`;

                const totalToAdd = inputData.rooms.filter(
                    (room) => actionData.values[`room-${room.roomID}`] && actionData.values[`room-${room.roomID}`][0]
                );

                let _rooms = [];
                let _roomsTo = [];
                inputData.rooms.forEach((room) => {
                    if (actionData.values[`room-${room.roomID}`] && actionData.values[`room-${room.roomID}`][0]) {
                        _rooms.push(room.roomID);
                        _roomsTo.push(actionData.values[`room-${room.roomID}`][0]);
                    }
                });
                if (_rooms.length > 0) {
                    _rooms.forEach((room, index) => {
                        mutation += `query${index} : changeRoom(stayGuestID:${inputData.guestID}, oldRoomID: ${room}, newRoomID: ${_roomsTo[index]}`;
                        if (index + 1 < totalToAdd.length) {
                            mutation += `){
                                error
                                id
                                ok
                            }`;
                        }
                    });
                } else {
                    mutation += "checkIn(id:0";
                }
            }
            break;
        case "delete-companion":
            const companion = sectionContentData.companions.filter(
                (comp) => String(comp.id) === String(actionData.itemsAffected[0])
            )[0];
            mutation += `mutation {checkOut(rooms:[${companion ? companion.rooms[0].roomID : ""}], stayGuestID:${
                companion ? companion.id : 0
            }`;
            break;
        // case "add-companion":
        //     console.log("Holaaaa");
        //     return;
        //     break;
        case "new-guest":
            const birthDate = joinFormDateTime(actionData.values["birthdate"]);
            const arrivalDate = joinFormDateTime(actionData.values["arrival-date"], actionData.values["arrival-hour"]);
            const departureDate = joinFormDateTime(
                actionData.values["departure-date"],
                actionData.values["departure-hour"]
            );
            const customFields = Object.values(
                Object.fromEntries(Object.entries(actionData?.values).filter(([k]) => k.includes("custom-field-")))
            )?.map((val) => `{ref:"${val?.ref}", value:"${val.value}"}`);

            mutation += `mutation{
                checkIn(
                    guest:{
                        guestRole:"MAIN",
                        ${actionData.values["gender"] ? `gender:${actionData.values["gender"]},` : ""}
                        ${
                            actionData.values["type-of-document"]
                                ? `documentType:"${actionData.values["type-of-document"]}",`
                                : ""
                        }
                        ${
                            actionData.values["document-no"]
                                ? `documentNumber:"${actionData.values["document-no"]}",`
                                : ""
                        }
                        ${
                            actionData.values["pms-id"]
                                ? ` pmsID:"${escapeSpecialChars(actionData.values["pms-id"])}"`
                                : ``
                        }
                        ${
                            actionData.values["date-of-issue"]
                                ? `documentExtraInformation: { expeditionDate: "${actionData.values["date-of-issue"]}" }`
                                : ""
                        }
                        ${customFields?.length > 0 ? `customFieldsInformation:[${customFields}]` : ""}
                        name:"${escapeSpecialChars(actionData.values["name"])}"
                        surname:"${escapeSpecialChars(actionData.values["surname"])}"
                        ${
                            actionData.values["title"]
                                ? ` title:"${escapeSpecialChars(actionData.values["title"])}"`
                                : ``
                        }
                        ${
                            actionData.values["email"]
                                ? ` email:"${escapeSpecialChars(actionData.values["email"])}"`
                                : ``
                        }
                        ${
                            actionData.values["mobile-phone"]
                                ? ` phoneNumber:"${actionData.values["mobile-phone"]}"`
                                : ``
                        }
                        ${actionData.values["birthdate"] ? ` birthDate:"${bdDateToDateInput(birthDate)}"` : ``}
                        ${
                            actionData.values["guest-groups"] && actionData.values["guest-groups"].length > 0
                                ? ` stayGroupIDs:[${
                                      actionData.values["guest-groups"]
                                          ? actionData.values["guest-groups"].join(",")
                                          : ""
                                  }]`
                                : ``
                        }
                        ${
                            actionData.values.nationality && actionData.values.nationality[0]
                                ? `countryRef: "${actionData.values.nationality[0].toUpperCase()}"`
                                : ``
                        }
                        ${
                            actionData.values.lang && actionData.values.lang[0]
                                ? `languageRef: "${actionData.values.lang[0].toLowerCase()}"`
                                : ``
                        }
                    }
                    rooms : [${actionData.values["room-number"] ? actionData.values["room-number"].join(",") : ""}]
                     arrival:"${dateString(arrivalDate)}"
                     ${actionData.values["departure-date"] ? `departure:"${dateString(departureDate)}"` : ""}
                     
                     `;
            break;
        case "batch-assign-to-group":
            const groupId = actionData.values["guest-groups"] ? actionData.values["guest-groups"][0] : 0;
            mutation += `mutation {updateGuestGroup(id:${groupId},batch:false,
                ,guests:[${checkedItems.join(",")}]`;

            break;
        case "assign-to-group":
            const groups = actionData.values["guest-groups"];

            mutation += `mutation {updateGuest(id:${actionData.itemsAffected[0]}  ${
                groups && groups.length > 0
                    ? `groups:[${groups.filter((item) => item !== null).join(",")}]`
                    : "groups:[]"
            }`;
            break;
        case "delete-guest-group":
            mutation += `mutation {deleteGuestGroup(id: "${id}"`;
            break;
        case "batch-checkout":
            mutation += `mutation {batchCheckOut(stayGuestIDs:[
                ${checkedItems.join(",")}
            ]`;
            idResponse = "";
            break;
        case "checkout":
            mutation += `mutation {checkOut(rooms:[
                ${actionData.values["modal-checkout-rooms-checked"]}
            ]`;
            break;
        case "full-checkout":
            mutation += `mutation {batchCheckOut(stayGuestIDs:[
                        ${actionData.itemsAffected.join(",")}
                    ]`;
            idResponse = "";
            break;
        case "edit-staff-areas":
            const selectedIds = Object.values(
                actionData.values && actionData.values["staff-zones"] ? actionData.values["staff-zones"] : []
            );

            mutation += `mutation {updateStaffUser(id: ${Number(actionData.itemsAffected[0])} , areas: [${
                selectedIds && selectedIds.length > 0 ? selectedIds.join(",") : null
            }]`;
            break;
        case "cancel-booking":
            mutation += `
                mutation {updateBooking(id: ${id},
                status: ${Number(actionData.actionData["state"])},
                reasonCanceled: "${escapeSpecialChars(actionData.values["cancel-booking-comment"])}"`;
            okResponse = "okBooking";
            break;
        case "refresh-cleaning":
            let cleanDate = new window.ZDate();
            if (actionData.values && actionData.values["cleaning-date"]) {
                let date = actionData.values["cleaning-date"];
                let h = actionData.values["cleaning-hour"];
                if (h) {
                    date += " " + h;
                }
                cleanDate = new window.ZDate(date);
            }
            mutation += `
                mutation {
                    updateCleaning(
                    id: ${Number(actionData.itemsAffected[0])},
                    cleanDate:"${dateString(cleanDate)}",
                    responsible: ${Number(actionData.actionData["responsible"])}`;
            break;
        case "guest-delete":
            mutation += `mutation{deleteGuest(id:${id}`;
            break;
        case "email-booking-notification":
            let emails = [];
            if (actionData.values["inputs"]) {
                emails = JSON.parse(JSON.stringify(actionData.values["inputs"]));
            }
            if (
                actionData.values["email-booking-notification"] &&
                ((actionData.values["inputs"] &&
                    actionData.values["inputs"].indexOf(actionData.values["email-booking-notification"]) === -1) ||
                    !actionData.values["inputs"])
            ) {
                emails.push(actionData.values["email-booking-notification"]);
            }
            mutation += `mutation{
                updateCommonZone(
                    id:${actionData.itemsAffected} 
                    notificationEmail:${emails.length > 0 ? `["${emails.join('", "')}"]` : `[]`}`;
            break;
        case "area-temp-close":
        case "edit-temp-closed":
            mutation += `mutation{
                updateCommonZone(
                    id:${actionData.itemsAffected} currentStatus: ${false} `;
            if (actionData.values["area-temp-close-comment"]) {
                mutation += ` currentComment: "${escapeSpecialChars(actionData.values["area-temp-close-comment"])}" `;
            }
            if (actionData.values["temp-close-comment"]) {
                mutation += ` currentComment: "${escapeSpecialChars(actionData.values["temp-close-comment"])}" `;
            }
            break;
        case "area-move-category":
            mutation += `mutation{
                updateCommonZone(
                    id:${actionData.itemsAffected}
                    category: ${Number(actionData.values["area-move-category-id"])} `;
            break;
        case (actionName.match(/room-group/) || {}).input:
            mutation += RoomModalMutationDispatcher(dataAction);
            break;

        case (actionName.match(/-property/) || {}).input:
            mutation += SettingsModalDispatcher(dataAction);
            break;
        case "delete-delete-language":
            mutation += `mutation{deleteLangFromProject(languageRef:"${id}"`;
            break;
        case "update-language":
            mutation += `mutation{updateLangInProject(languageRef:"${id}",isDefault:${isDefault}`;
            idResponse = "";

            if (isDefault) {
                Session.setSessionProp("managerDefLang", id);
            }
            break;
        case "add-media-library-media":
            mutation += `mutation{createLibraryAsset(folderRef:"${actionData.values["parentRef"]}",file:"${actionData.values["file"]}",fileType:"${actionData.values["fileType"]}",fileName:"${actionData.values["fileName"]}"`;
            idResponse = "response{ ref } ";
            break;
        case "add-library-font":
            mutation += `mutation{createLibraryFont(file:"${actionData.values["file"]}",fileType:"${actionData.values["fileType"]}"`;
            idResponse = "response{ ref } ";
            break;
        case "font-library-delete-permanently":
            mutation += `mutation{deleteLibraryFont(`;
            if (id) {
                mutation += `ref:["${id}"]`;
            } else {
                mutation += `ref:[
                    ${checkedItems.map((item, index) => (index === 0 ? `"${item}"` : `,"${item}"`))}
                ]`;
            }
            idResponse = "";
            errorResponse = "error{code msg}";
            break;
        case "media-library-delete-permanently":
            mutation = ``;
            if (actionData.values["language"]) {
                mutation += `mutation{deleteLibraryAssetLocale(`;
            } else {
                mutation += `mutation{deleteLibraryContent(`;
            }
            if (id) {
                mutation += `ref:["${id}"]`;
            } else {
                mutation += `ref:[
                    ${checkedItems.map((item, index) => (index === 0 ? `"${item}"` : `,"${item}"`))}
                ]`;
            }
            if (actionData.values["language"]) {
                mutation += `,language:"${actionData.values["language"]}"`;
            }
            idResponse = "";
            errorResponse = "error{code msg}";
            break;
        case "video-library-delete-permanently":
            mutation = ``;
            if (actionData.values["language"]) {
                mutation += `mutation{deleteLibraryVideoLocale(`;
            } else {
                mutation += `mutation{deleteLibraryVideo(`;
            }
            if (id) {
                mutation += `ref:["${id}"]`;
            } else {
                mutation += `ref:[
                    ${checkedItems.map((item, index) => (index === 0 ? `"${item}"` : `,"${item}"`))}
                ]`;
            }
            if (actionData.values["language"]) {
                mutation += `,language:"${actionData.values["language"]}"`;
            }
            idResponse = "";
            errorResponse = "error{code msg}";
            break;
        case "media-library-move-content":
            if (id) {
                mutation += `mutation{moveLibraryContents(ref:["${id}"],folderRef:"${actionData.values["folderRef"]}"`;
            } else {
                mutation += `mutation {moveLibraryContents(ref:[
                    ${checkedItems.map((item, index) => (index === 0 ? `"${item}"` : `,"${item}"`))}
                ],folderRef:"${actionData.values["folderRef"]}"`;
            }
            idResponse = "";
            break;
        case "media-library-download":
            if (id) {
                mutation += `mutation{libraryAssetBase64(ref:["${id}"]`;
            } else {
                mutation += `mutation {libraryAssetBase64(ref:[
                    ${checkedItems.map((item, index) => (index === 0 ? `"${item}"` : `,"${item}"`))}
                ]`;
            }
            idResponse = "response{results{base64, contentType, name}}";
            idResponse = "";
            break;
        case "edit-media-library-folder":
            mutation += `mutation{updateLibraryFolder(ref: ""`;
            break;
        case "edit-media-library-media":
            mutation += `mutation{updateLibraryAsset(ref: ""`;
            break;
        case "media-library-update-media":
            mutation += `mutation{updateLibraryAsset(ref:"${id}"`;
            if (actionData.values["file"]) {
                mutation += `,file:"${actionData.values["file"]}",fileType:"${actionData.values["fileType"]}",fileName:"${actionData.values["fileName"]}"`;
            }
            if (actionData.values["language"]) {
                mutation += `,fileLanguage:"${actionData.values["language"]}"`;
            }
            if (actionData.values["name"]) {
                mutation += `,name:"${actionData.values["name"]}"`;
            }
            if (actionData.values["folderRef"]) {
                mutation += `,folderRef:"${actionData.values["folderRef"]}"`;
            }
            if (actionData.values["defaultLanguage"]) {
                mutation += `,defaultLanguage:"${actionData.values["defaultLanguage"]}"`;
            } else if (actionData.values["assetDefaultLanguage"]) {
                mutation += `,defaultLanguage:"${actionData.values["assetDefaultLanguage"]}"`;
            }
            idResponse = "response{ ref } ";
            break;
        case "media-library-update-video":
            mutation += `mutation{updateLibraryVideo(ref:"${id}"`;
            /*if (actionData.values["language"]) {
                mutation += `,fileLanguage:"${actionData.values["language"]}"`;
            }*/
            if (actionData.values["name"]) {
                mutation += `,name:"${actionData.values["name"]}"`;
            }
            if (actionData.values["folderRef"]) {
                mutation += `,folderRef:"${actionData.values["folderRef"]}"`;
            }
            if (actionData.values["defaultLanguage"]) {
                mutation += `,defaultLanguage:"${actionData.values["defaultLanguage"]}"`;
            } else if (actionData.values["assetDefaultLanguage"]) {
                mutation += `,defaultLanguage:"${actionData.values["assetDefaultLanguage"]}"`;
            }
            idResponse = "response{ ref } ";
            break;
        case "media-library-update-video-local":
            mutation += `mutation{updateLibraryVideoLocale(ref:"${id}"`;
            if (actionData.values["lang"]) {
                mutation += `,language:"${actionData.values["lang"]}"`;
            }
            if (actionData.values["transcode"]) {
                mutation += `,transcode:${actionData.values["transcode"]}`;
            }
            if (actionData.values["quality"]) {
                mutation += `,resolution:"${actionData.values["quality"]}"`;
            }
            idResponse = "response{ ref } ";
            break;
        case "apply-design":
            let currentDate = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z";
            mutation += `mutation{updateDesign(ID:${id},isAssigned:true,currentDate:"${currentDate}"`;
            break;
        case "edit-message-destinations":
            mutation += `mutation{updateMessage(id: ${
                sectionContent && sectionContent.autoSaveDraftMessage
                    ? sectionContent.autoSaveDraftMessage.idMessage
                    : "-1"
            }, destination: ${composeDestination(actionData)}`;
            idResponse = "";
            break;
        case "edit-screen-filters":
            landingID = actionData.values["landingID"];
            projectRef = actionData.values["screenprojectRef"];
            designID = actionData.values["screendesignID"];
            mutation += `mutation{${
                actionData.values["screentype"] === SCREEN.TYPE.WELCOME ? "updateWelcomeScreen" : "updateLanding"
            }(${
                actionData.values["screentype"] === SCREEN.TYPE.WELCOME ? "designId" : "designID"
            }: ${id},id: ${landingID}, ptarget:{  type: DIRECT , direct: {destination: ${composeDestination(
                actionData
            )} projectRef : "${projectRef}"}}`;
            idResponse = "";
            break;
        case "delete-screen-filter":
            landingID = actionData.values["landingID"];
            projectRef = actionData.values["screenprojectRef"];
            designID = actionData.values["screendesignID"];
            mutation += `mutation{${
                actionData.values["screentype"] === SCREEN.TYPE.WELCOME ? "updateWelcomeScreen" : "updateLanding"
            }(${
                actionData.values["screentype"] === SCREEN.TYPE.WELCOME ? "designId" : "designID"
            }: ${designID},id: ${landingID}, ptarget:{ type: ALL}`;
            idResponse = "";
            break;
        case "unpublish-content-filter":
            landingID = actionData.values["landingID"];
            mutation += `mutation{${
                actionData.values["screentype"] === SCREEN.TYPE.WELCOME ? "updateWelcomeScreen" : "updateLanding"
            }(${
                actionData.values["screentype"] === SCREEN.TYPE.WELCOME ? "designId" : "designID"
            }: ${id},id: ${landingID},isEnabled: false`;
            idResponse = "";
            break;
        case "add-new-mac-exception":
            mutation += `mutation {
                createMacException(
                  mac: "${actionData.values["mac-exception-direction"]}"
                  hotspot: "${actionData.values["mac-hotspot-name"]}"
                  description: "${actionData.values["mac-exception-description"]}"
                  access: ${actionData.values["mac-exception-access"]}`;
            break;
        case "edit-mac-exception":
            mutation += `mutation {
                updateMacException(
                  mac: "${actionData.values["mac-exception-direction"]}"
                  hotspot: "${actionData.values["mac-hotspot-name"]}"
                  description: "${actionData.values["mac-exception-description"]}"
                  access: ${actionData.values["mac-exception-access"]}`;
            break;
        case "delete-mac-exception":
            mutation += `mutation {
                deleteMacException(mac: "${actionData.values["mac-exception-direction"]}", 
                hotspot: "${actionData.values["mac-hotspot-name"]}"`;
            break;
        case "update-mobile-appearance":
            mutation += `mutation {
                    assignThemeToMobileApp(themeID:${
                        actionData.values.appearance && actionData.values.appearance[0]
                    },mobileAppID:${actionData.values.appId}`;

            break;
        default:
            //Mutation header
            mutation += `mutation{
                updateCommonZone(
                    id:${isNaN(actionData.itemsAffected) ? 1 : actionData.itemsAffected} `;
            //Mutation body
            if (actionName === "area-open") {
                mutation += `currentStatus: true`;
            }
            if (actionData.values["people-number"]) {
                mutation += ` capacityLimit: ${parseInt(actionData.values["people-number"])} `;
            }
            if (actionData.values["allow"]) {
                mutation += ` bookingAllowed: ${actionData.values["allow"] === "booking-allowed" ? true : false}`;
                if (actionData.values[bookingFrec]) {
                    mutation += getMutationBookingFreq(actionData, bookingFrec);
                }

                if (actionData.values["booking-duration"]) {
                    let hourParts = actionData.values["booking-duration"].split(":");
                    if (hourParts[0] && hourParts[1]) {
                        const minutes = parseInt(hourParts[0]) * 60 + parseInt(hourParts[1]);

                        mutation += ` slotsPerBooking: ${parseInt(minutes / getBookingFreq(actionData, bookingFrec))}`;
                    }
                }
                if (actionData.values["booking-duration-visible"]) {
                    mutation += ` visibleDuration: ${actionData.values["booking-duration-visible"] !== "hidden"}`;
                }
                if (actionData.values["confirmation-mode"]) {
                    mutation += ` autoBooking: ${actionData.values["confirmation-mode"] === "automatic"}`;
                }
            }
            if (actionData.values["area-edit-description"] || actionData.values["area-edit-description"] === "") {
                mutation += ` description: "${actionData.values["area-edit-description"]}"`;
            }
    }
    //Mutation end
    mutation += `){ ${errorResponse} ${idResponse} ${okResponse}}}`;
    return mutation;
};

export default ModalMutationDispatcher;
