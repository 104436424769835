import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";

/**
 * @description A number input component
 * @param {string} id - The id of the input
 * @param {number} value - The initial value of the input
 * @param {string} placeholder - The placeholder of the input
 * @param {function} onChange - The function to call when the input value changes
 * @param {boolean} autoFocus - If the input should be focused on render
 * @param {boolean} disabled - If the input should be disabled
 * @param {number} max - Maximum value
 * @param {string} className - The extended class name of the input
 * @example
 * <NumberInput id="age" value={30} placeholder="Enter your age" onChange={(value) => console.log(value)} />
 */
const NumberInput = forwardRef(({ id, value, placeholder, onChange, autoFocus, disabled, max, className }, ref) => {
    const [currentValue, setCurrentValue] = useState(value);

    const inputRef = useRef(null);

    useEffect(() => {
        const inputHandler = (e) => {
            const value = e?.target?.value || "";
            setCurrentValue(value);
            if (onChange) {
                onChange(value);
            }
        };
        if (inputRef.current) {
            inputRef.current.addEventListener("input", inputHandler);
        }
        if (autoFocus) {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 100);
        }
    }, [inputRef.current, onChange, autoFocus, disabled]);

    useImperativeHandle(ref, () => ({
        focus: () => {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 200);
        },
        getValue: () => {
            return currentValue;
        },
    }));

    // Update the value if initial value changes
    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const inputClass = classNames({
        "rounded py-1 px-4 leading-7 long bg-gray-200": true,
        "bg-gray-300 text-gray-700": disabled,
        [className]: className,
    });

    return (
        <input
            ref={inputRef}
            id={id}
            type="number"
            name={id}
            placeholder={placeholder}
            value={currentValue}
            disabled={disabled}
            className={inputClass}
            min={0}
            max={max}
        />
    );
});
NumberInput.displayName = "TextInput";

export default NumberInput;
