import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RoomOrdersModal from "../../../../hooks/GraphqlCalls/Customers/modals/RoomOrdersModal";
import { openModal, setModalContent } from "../../../../actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import GetGuestOrders from "../../Customers/Guest/GuesOrders.js/GetGuestOrders";

import Icon from "components/Icon";

const RoomDetailOrders = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [technicalProblems, setTechnicalProblems] = useState(false);
    const { room, guest } = useSelector((state) => state.sectionContent);
    const [loading, setLoading] = useState(true);
    const [ordersCount, setOrdersCount] = useState(0);

    const guestID = guest?.guestID;
    const guestRoomID = guest && room ? guest?.rooms?.find((r) => r.RoomID === room?.id)?.stayGuestRoomID : null;

    return (
        <div className=" bg-white rounded my-8 p-8">
            <GetGuestOrders
                guestID={guestID}
                guestRoomID={guestRoomID}
                setCount={setOrdersCount}
                setLoading={setLoading}
                onError={() => setTechnicalProblems(true)}
            />
            <div className=" w-full flex justify-between ">
                <div className=" text-lg font-bold">{t("orders")} </div>
                <i className=" icon icon-cart text-gray-800 text-3xl"></i>
            </div>
            <div className="flex items-center space-x-2 mt-2">
                <button
                    onClick={() => {
                        const modalContent = RoomOrdersModal({ room });
                        dispatch(setModalContent(modalContent));
                        dispatch(openModal());
                    }}
                    id="room-detail-show-orders"
                    disabled={loading}
                    className={` ${loading ? " text-gray-700" : "text-blue-600"} focus:outline-none text-lg text-left`}
                >
                    {`${t("show-orders")} (${!isNaN(ordersCount) ? ordersCount : 0})`}
                </button>
                {technicalProblems ? (
                    <Icon type="warning" size="3xl" tooltip={t("There are technical problems with Zafiro Sales")} />
                ) : null}
            </div>
        </div>
    );
};

export default RoomDetailOrders;
