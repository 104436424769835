import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ShopContext } from "contexts/Sales/Shop";

import Loading from "components/Loading";
import Translations from "components/Translations";
import { EditPrice, FooterButtons } from "./Delivery.jsx";

import { DELIVERY_METHOD_GEOLOCATION, GEOLOCATION_MESSAGE_TEMPLATE } from "constants/sales";

const EditGeolocation = ({ defaultLang, onClose }) => {
    const { t } = useTranslation();

    const projectLangs = useSelector((state) => state?.ui?.projectLangs);
    const otherlangs = projectLangs?.filter((lang) => lang.isDefault === false).map((lang) => lang.languageRef);

    const { shippingConfig, geolocationMessage, defaultTax, taxRates, updateShippingConfig, updateGeolocationMessage } =
        useContext(ShopContext);

    const [updating, setUpdating] = useState(false);

    const config = shippingConfig?.[DELIVERY_METHOD_GEOLOCATION];

    // normalize translations
    const [message, setMessage] = useState(geolocationMessage);

    const storedPrice = config?.price?.base ?? null;
    const storedRate = config?.price?.taxRate ?? null; // This is a tax rate value (%), not an ID
    const storedTaxRateID =
        storedRate && taxRates ? taxRates.find((rate) => Number(rate.value) === Number(storedRate))?.id : null;

    const [price, setPrice] = useState(storedPrice);
    const [taxRateID, setTaxRateID] = useState(storedTaxRateID ?? defaultTax?.id);
    const [taxRate, setTaxRate] = useState(storedRate ?? defaultTax);

    const onChangePrice = ({ price, taxRate }) => {
        setPrice(price);
        setTaxRateID(taxRate?.id);
        setTaxRate(taxRate?.value);
    };

    const cancelChanges = () => {
        setPrice(storedPrice);
        setTaxRateID(storedTaxRateID);
        setTaxRate(storedRate);
        if (onClose) {
            onClose(false);
        }
    };

    const saveChanges = () => {
        const newShippingConfig = {
            ...shippingConfig,
            [DELIVERY_METHOD_GEOLOCATION]: {
                ...config,
                price: {
                    ...config?.price,
                    base: price,
                    taxRate,
                },
            },
        };
        setUpdating(true);

        updateShippingConfig(newShippingConfig).finally(() => {
            updateGeolocationMessage(message)
                .then(() => {
                    toast.success(t("operation-successful"));
                })
                .finally(() => {
                    setUpdating(false);
                    if (onClose) {
                        onClose(true);
                    }
                });
        });
    };

    const containerID = `location-${DELIVERY_METHOD_GEOLOCATION}`;
    const translationsID = `${containerID}-description`;

    return (
        <>
            <div className="border-t-2 py-6 px-10">
                <div className="pb-10">
                    {t("geolocation-info")}
                    <br />
                    {t("geolocation-info-extended")}
                </div>
                {updating ? (
                    <Loading />
                ) : (
                    <div className="flex w-full flex-start">
                        <div className="flex-1 px-5 border-r-2">
                            <div className="mb-5">{t("delivery-method-message-info")}</div>
                            <Translations
                                id={translationsID}
                                languages={otherlangs}
                                translations={message}
                                defaultLang={defaultLang}
                                maxLength={200}
                                multiline={3}
                                onChange={setMessage}
                                template={GEOLOCATION_MESSAGE_TEMPLATE}
                            />
                        </div>
                        <div className="flex-1 px-5">
                            <EditPrice
                                id={`delivery-${DELIVERY_METHOD_GEOLOCATION}-price`}
                                price={price}
                                taxRate={taxRateID}
                                onChangePrice={onChangePrice}
                            />
                        </div>
                    </div>
                )}
            </div>
            <FooterButtons
                id={`button-${DELIVERY_METHOD_GEOLOCATION}`}
                disabled={updating}
                onCancel={() => {
                    cancelChanges();
                }}
                onSave={() => {
                    saveChanges();
                }}
            />
        </>
    );
};

export default EditGeolocation;
