import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import {
    getStatusText as getPMSStatusText,
    getStatusInfo as getPMSStatusInfo,
    PMS_STATUS_DISABLED,
} from "constants/charges";

import {
    getStatusText as getOrderStatusText,
    getStatusColor as getOrderStatusColor,
    ORDER_DELAYED,
    ORDER_STATUS_PENDING,
    ORDER_STATUS_EXPIRED,
} from "constants/sales";

/**
 * @description Display order status information
 * @component
 * @param {string} status - Order status
 * @param {string} pmsStatus - PMS status
 * @param {boolean} delayed - Order delayed
 * @param {boolean} expired - Order expired
 * @param {string} id - Element id
 * @param {string} className - Additional classes
 * @param {boolean} colorize - Apply color to status (default true)
 * @param {boolean} animate - Allow animations (default false)
 * @returns {JSX.Element} - Status component
 * @example
 * <OrderStatus status="Delivered" delayed={true} animate={true} pmsStatus="PMS_CHARGED" />
 */
const OrderStatus = ({
    status = null,
    pmsStatus = undefined,
    delayed = false,
    expired = false,
    id = null,
    className = "",
    colorize = true,
    animate = false,
}) => {
    const { t } = useTranslation();
    const orderStatusText = getOrderStatusText(status);
    const orderStatusColor = getOrderStatusColor(expired ? ORDER_STATUS_EXPIRED : status);

    const pmsStatusText = getPMSStatusText(pmsStatus);
    const pmsStatusInfo = getPMSStatusInfo(pmsStatus);
    const pmsStatusColor = "text-gray-700";

    const orderDelayedText = getOrderStatusText(ORDER_DELAYED);
    const orderDelayedColor = getOrderStatusColor(ORDER_DELAYED);

    const orderExpiredText = getOrderStatusText(ORDER_STATUS_EXPIRED);
    const orderExpiredColor = getOrderStatusColor(ORDER_STATUS_EXPIRED);

    return (
        <div className={className}>
            {status ? (
                <>
                    {expired ? (
                        <>
                            <span
                                id={id ? `${id}-expired` : null}
                                className={`capitalize whitespace-nowrap font-bold ${
                                    colorize ? orderExpiredColor : null
                                }`}
                            >
                                {orderExpiredText ? t(orderExpiredText) : null}
                            </span>
                            <br />
                        </>
                    ) : null}

                    <span id={id} className={`capitalize whitespace-nowrap ${colorize ? orderStatusColor : null}`}>
                        {orderStatusText ? t(orderStatusText) : null}
                    </span>

                    {delayed ? (
                        <>
                            <br />
                            <span
                                id={id ? `${id}-delayed` : null}
                                className={`capitalize whitespace-nowrap ${colorize ? orderDelayedColor : null}`}
                            >
                                {animate ? (
                                    <motion.div
                                        initial="visible"
                                        animate="hidden"
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "reverse",
                                            duration: 1,
                                        }}
                                        variants={{
                                            visible: {
                                                opacity: 1,
                                            },
                                            hidden: {
                                                opacity: 0,
                                            },
                                        }}
                                    >
                                        {t(orderDelayedText)}
                                    </motion.div>
                                ) : (
                                    t(orderDelayedText)
                                )}
                            </span>
                        </>
                    ) : null}
                </>
            ) : null}
            {(status != ORDER_STATUS_PENDING && pmsStatus !== undefined) || pmsStatus === PMS_STATUS_DISABLED ? (
                <div
                    id={id ? `${id}-pms` : null}
                    data-tip={pmsStatusInfo ? t(pmsStatusInfo) : null}
                    data-for="default-tooltip"
                    className={`capitalize whitespace-nowrap font-normal ${colorize ? pmsStatusColor : null}`}
                >
                    {pmsStatusText ? t(pmsStatusText) : null}
                </div>
            ) : null}
        </div>
    );
};
OrderStatus.displayName = "OrderStatus";

export default OrderStatus;
