import { arrangeAlarmGuestData, arrangeAlarmRoomsData, formatDateToISO } from "./utils/alarmsUtils";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "react-apollo";
import { GUEST_QUERY, NEW_ALARM_MUTATION, ROOMS_QUERY } from "./utils/alarmsQueries";

export const useSelectContent = () => {
    const [rooms, setRooms] = useState([]);
    const [guests, setGuests] = useState([]);

    const [executeRoomQuery, { data: roomsData, loading: roomsLoading }] = useLazyQuery(ROOMS_QUERY, {
        fetchPolicy: "network-only",
    });
    const [executeGuestQuery, { data: guestsData, loading: guestLoading }] = useLazyQuery(GUEST_QUERY, {
        fetchPolicy: "network-only",
    });
    const [executeMutation] = useMutation(NEW_ALARM_MUTATION);

    useEffect(() => {
        executeRoomQuery();
        executeGuestQuery();
    }, []);

    useEffect(() => {
        if (roomsData) {
            const rooms = arrangeAlarmRoomsData(roomsData);
            const guests = arrangeAlarmGuestData(guestsData);
            setGuests(guests);
            setRooms(rooms);
        }
    }, [roomsData]);

    const createAlarm = (values) => {
        const { recipient, roomOrGuest, alarmType, tvChannel, isoDate } = values;
        executeMutation({
            variables: {
                ...(recipient === "room" ? { stayGuestRoomId: roomOrGuest } : null),
                ...(recipient === "guest" ? { stayGuestId: roomOrGuest } : null),
                ...(alarmType === "TVCHANNEL" ? { tvChannelId: tvChannel } : null),
                type: alarmType,
                startTime: formatDateToISO(isoDate),
            },
        });
    };

    return { rooms, guests, loading: roomsLoading || guestLoading, createAlarm };
};
