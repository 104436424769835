import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import GetHotelTableHeaders from "../../../Utils/Table/Headers/GetHotelTableHeaders";
import { useMSQuery } from "../../useMSQuery";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { POLLINTERVAL_15S } from "../../../Utils/Utils";
import {
    cleanTableStore,
    setCount,
    setCountPosition,
    setError,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setRefreshData,
    setActions,
    setAvailableFilters,
    setCheckable,
    setCustomConfig,
    setExtendedInfo,
    addToastInfoItem,
    removeToastInfoItem,
} from "../../../../actions/tableActions";
import { DEVICE_FUNCTIONS, DEVICE_STATUS, getInputGroup } from "../../../Utils/Hotel/Monitor/ListDevicesUtils";
import { setDashboardCards } from "../../../../actions/uiActions";
import { Session } from "../../../Utils/Session";
import { toast } from "react-toastify";
import InfoToast from "../../../../components/Section/Hotel/Monitor/InfoToast";
import GetAvailableFilters from "../../../Utils/GetAvailableFilters";
import Device from "./models/Device";
import DeviceCards from "./models/DeviceCards";
import _ from "lodash";

const ListDevices = (props) => {
    //Consts&states
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { permissions } = useSelector((state) => state.ui);
    const { currentPage, perPage, sortCriteria, filterQuery, refreshData, toastInfoItems } = useSelector(
        (state) => state.table
    );
    const activeFilters = useSelector((state) => state.table.activeFilters);
    const [applyFilters, setApplyFilters] = useState(``);
    const superUser = Session.getSessionProp("superUser");
    const sectionName = "list-device";
    const wifiEnabled = permissions && permissions.product.wifi ? permissions.product.wifi : null;
    const castEnabled = permissions && permissions.product.cast ? true : false;

    //queries&mutations
    const GET_DATA = gql`
        {
            devices: devices (page: ${currentPage}, size: ${perPage} ${sortCriteria} ${applyFilters}){
                info {
                    count
                    page
                    size
                }
                results {
                    name
                    function
                    mac
                    ref
                    type
                    wifiSignal
                    firmwareVersion
                    commercialName
                    model
                    kind
                    warning{
                        info
                        level
                        roomID
                        actionInfo
                    }
                    location
                    roomID
                    roomTVID
                    roomTVName
                    roomNumber
                    lastActivity
                    lastActivityDate
                    tvInputID
                    ip
                }
            }
            tvInputs: tvInputs{
                results {
                    id
                    name
                    isDefault
                    tvsAssigned
                    inputs {
                        icon
                        name
                        pos
                        input
                        deviceType
                    }
                }
            }
            warnings: devices(size: 10000) {
                info {
                    count
                }
                results {
                    function
                    warning{
                        level
                        info
                    }
                }
            }
            ${
                wifiEnabled
                    ? `
                wifiLicenses: wifiLicenses {
                    used
                    available
                }`
                    : ""
            }
        }
    `;
    const GET_FILTERS_DATA = gql`
        {
            rooms: rooms {
                results {
                    wingID
                    floorID
                    floorName
                    wingName
                }
            }
            floors: installationMap {
                results {
                    id
                    name
                    floors {
                        id
                        name
                    }
                }
            }
            deviceFunctions: deviceFunctionsList {
                unspecified
                chromecast
                tv
                tvchromecast
            }
            deviceModels: monitoringModelTypes {
                results {
                    model
                    type
                }
            }
        }
    `;

    //useQueries&useMutations
    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_DATA, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });
    const [executeFiltersQuery, filtersData] = useLazyQuery(GET_FILTERS_DATA);

    //effects
    useEffect(() => {
        const tableHeaders = GetHotelTableHeaders(sectionName);
        dispatch(setLoading(true));
        dispatch(cleanTableStore());
        dispatch(setSearchable(true));
        dispatch(setHeaders(tableHeaders));
        dispatch(setCountPosition("table-header"));
        dispatch(setCheckable(false));
        dispatch(setActions(true));
        dispatch(setExtendedInfo(true));
        dispatch(setDashboardCards(null));
        dispatch(
            setCustomConfig({
                searchStyle: "col-span-3",
                filtersStyle: "col-span-12 mt-5",
            })
        );
        executeQuery();
        executeFiltersQuery();
        return () => {
            dispatch(setDashboardCards(null));
            dispatch(cleanTableStore());
        };
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
    }, [refreshData]);

    useEffect(() => {
        if (!loading && !error && data && data.devices && data.warnings && networkStatus === 7) {
            dispatch(setLoading(true));
            const dataWifiLicenses = data.wifiLicenses ? data.wifiLicenses : null;
            const dataItems = arrangeData(data, t);
            const dataCards = DeviceCards(data.warnings.results, permissions, dataWifiLicenses, t, superUser);
            dispatch(setDashboardCards(dataCards));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.devices.info.count));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
            checkWarnings(data.devices.results);
            // console.log(data);
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        if (filtersData) {
            if (filtersData.data) {
                const availableFilters = GetAvailableFilters(sectionName, arrangeFilterData(filtersData.data));
                dispatch(setAvailableFilters(availableFilters));
            }
            if (filtersData.error) {
                dispatch(setError(filtersData.error));
            }
        }
    }, [filtersData]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
            dispatch(setDashboardCards(DeviceCards([], permissions, null, t)));
        }
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        let currentFilters = arrangeFilters(activeFilters);
        setApplyFilters(
            currentFilters ? `, filter:{${currentFilters} unAuthorized: true}` : `, filter:{unAuthorized: true}`
        );
        // eslint-disable-next-line
    }, [filterQuery, activeFilters]);

    //arrangeData
    const arrangeData = (data, t) => {
        const items = [];
        let tvInputs = data.tvInputs && data.tvInputs.results ? data.tvInputs && data.tvInputs.results : [];
        data.devices.results.map((device) => {
            let inputGroupData = getInputGroup(device.tvInputID, tvInputs);
            items.push(
                Device(
                    {
                        device: device,
                        inputGroupData: inputGroupData,
                        tvInputs: tvInputs,
                        sectionName: sectionName,
                        castEnabled: castEnabled,
                    },
                    t,
                    permissions
                )
            );
        });
        return items;
    };

    const arrangeFilterData = (data) => {
        let response = {
            deviceModels: [],
            floors: [],
            deviceFunctions: [],
            status: [],
        };
        if (data.deviceFunctions) {
            Object.keys(data.deviceFunctions).map((deviceFunctionKey) => {
                if (data.deviceFunctions[deviceFunctionKey]) {
                    Object.keys(DEVICE_FUNCTIONS).map((actionKey) => {
                        if (DEVICE_FUNCTIONS[actionKey].typeValue === deviceFunctionKey) {
                            response.deviceFunctions.push({
                                value: DEVICE_FUNCTIONS[actionKey].label,
                                id: DEVICE_FUNCTIONS[actionKey].enumValue,
                                visible: true,
                            });
                        }
                    });
                }
            });
        }
        if (data.deviceModels && data.deviceModels.results.length > 0) {
            data.deviceModels.results.map((deviceModel) => {
                let models = deviceModel.model;
                if (models && models.length > 0) {
                    models.forEach((model) => {
                        response.deviceModels.push({
                            value: model,
                            id: model,
                            visible: true,
                            optGroup: deviceModel.type,
                        });
                    });
                }
            });
        }
        if (data.rooms && data.rooms.results.length > 0) {
            let floorsArray = [];
            data.rooms.results.forEach((room) => {
                if (floorsArray.filter((r) => r.id === room.floorID).length === 0) {
                    floorsArray.push({
                        id: room.floorID,
                        value: room.floorName,
                        visible: true,
                        optGroup: room.wingName,
                    });
                }
            });
            floorsArray = _.orderBy(floorsArray, ["optGroup", "value"], ["asc", "asc"]);
            response.floors = floorsArray;
        }
        Object.keys(DEVICE_STATUS).map((actionKey) => {
            if (!DEVICE_STATUS[actionKey].notFilterable) {
                response.status.push({
                    value: DEVICE_STATUS[actionKey].label,
                    id: DEVICE_STATUS[actionKey].value,
                    visible: true,
                });
            }
        });
        return response;
    };

    //handle&functions
    const checkWarnings = (items) => {
        items.forEach((device) => {
            if (
                device &&
                device.warning &&
                device.warning.info === "running-task" &&
                !toastInfoItems.includes(device.mac)
            ) {
                dispatch(addToastInfoItem(device.mac));
                const message = device.name + " " + t("task-" + device.warning.actionInfo);
                toast.info(<InfoToast msg={message} />, {
                    autoClose: 10000,
                });
                setTimeout(() => {
                    dispatch(removeToastInfoItem(device.mac));
                }, 60000);
            }
        });
    };

    const arrangeFilters = (filters) => {
        let currentFilters = "";
        if (!_.isEmpty(filters)) {
            for (const [key, value] of Object.entries(filters)) {
                if (value) {
                    if (value.charAt(0) === "[" || value === "true" || value === "false") {
                        currentFilters += `${key}:${value},`;
                    } else {
                        currentFilters += `${key}:"${value}",`;
                    }
                }
            }
        }
        return currentFilters;
    };

    //renders

    //response
};

export default ListDevices;

export const deviceIsTv = (device) => {
    return device.type.toLowerCase() === "tv";
};
