import Users from "../components/Section/Settings/Users";
import Roles from "../components/Section/Settings/Roles";
import Languages from "../components/Section/Settings/Languages";
import PredefinedRoles from "../components/Section/Settings/PredefinedRoles.jsx";
import PolicyAndConditions from "../components/Section/Settings/PolicyAndConditions";
import { parseBoolean } from "../hooks/Utils/Utils";
import { Session } from "../hooks/Utils/Session";
import TaxZones from "../components/Section/Settings/TaxZones";
import { newRoute, newRedirect } from "./utils";
import Staff from "components/Section/CommonZones/Staff";

const sectionID = "general-settings";
const packageID = "managerSetup";

const attrManageUsers = "manageUsers";
const attrManageRoles = "manageRoles";
const attrManageLangs = "manageLanguages";
const attrManageTaxes = "taxManagement";

const route = newRoute(sectionID, packageID);
const redirect = newRedirect(sectionID);

const isCorporate = Session.getSessionProp("projectRef") === "CORPORATE";
const hasCorporateUser = parseBoolean(Session.getSessionProp("hasCorporateUser"));

export const settingsRoutes = [
    route("/", {
        redirect: redirect("/user-management"),
    }),

    (isCorporate || !hasCorporateUser) &&
        route("/finance", {
            redirect: redirect("/finance/tax-zones"),
            attribute: attrManageTaxes,
        }),
    (isCorporate || !hasCorporateUser) &&
        route("/finance/tax-zones", {
            section: TaxZones,
            attribute: attrManageTaxes,
        }),

    route("/language-management", {
        redirect: redirect("/language-management/languages"),
        attribute: attrManageLangs,
    }),
    route("/language-management/languages", {
        section: Languages,
        attribute: attrManageLangs,
    }),

    (isCorporate || !hasCorporateUser) &&
        route("/policy-and-conditions-management/policy-and-conditions", {
            section: PolicyAndConditions,
            sectionID,
        }),

    route("/user-management", {
        redirect: redirect("/user-management/users"),
    }),
    route("/user-management/users", {
        section: Users,
        attribute: attrManageUsers,
    }),
    route("/user-management/roles", {
        section: Roles,
        attribute: attrManageRoles,
    }),
    route("/user-management/predefined-roles", {
        section: PredefinedRoles,
        hideSidebar: true,
        hideHeader: true,
        attribute: attrManageRoles,
        check: ({ isSuperUser }) => {
            return isSuperUser;
        },
    }),
    route("/user-management/app-staff-users", {
        section: Staff,
        attribute: "staff",
    }),
];
