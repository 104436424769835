import React, { createContext, useMemo, useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setLanguage } from "actions/uiActions";
import Loading from "components/Loading";
import { ModalsContainer } from "components/Modal";
import { Session } from "hooks/Utils/Session";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import pkg from "../../../package.json";
import { WARNING_TYPES } from "components/Section/Warning";

import { useAdvicedUpdate } from "hooks/Data/useUser";

import { UserNetworkProvider } from "contexts/NetworkWarnings/UserNetwork";
import { ValidateNetworkProvider } from "contexts/NetworkWarnings/ValidateNetwork";

/**
 * Parse environment variables to their correct types
 * @param {Object} data - Environment variables object
 * @returns {Object} - Environment variables object with correct types
 */
const parseEnvironmentVariables = (data) => {
    if (!data) {
        return data;
    }
    if (typeof data !== "object") {
        throw new Error("Environment variables must be an object");
    }
    return Object.keys(data).reduce((acc, key) => {
        const value = data[key];
        if (!isNaN(value)) {
            acc[key] = parseFloat(value);
        } else if (value === "true" || value === "false") {
            acc[key] = value === "true";
        } else {
            acc[key] = value;
        }
        return acc;
    }, {});
};

// React environment is the environment mode (development, production, test)
const ENV_MODE = process.env.NODE_ENV;
// Environment variables are defined in the .env file
const ENVIRONMENT_VARS = parseEnvironmentVariables(process.env.REACT_APP);
// Development mode is defined by the DEVELOPMENT variable in the .env file
const DEVEL_MODE = ENVIRONMENT_VARS.DEVELOPMENT;
// The final environment is the one that will be used in the app (development, production, testing)
const ENVIRONMENT = ENV_MODE === "development" || !DEVEL_MODE ? ENV_MODE : "testing";

const defaultContext = {
    environment: ENVIRONMENT,
    features: {
        userguiding: ENVIRONMENT_VARS.USERGUIDING,
        notifications: ENVIRONMENT_VARS.NOTIFICATIONS,
        selenium: ENVIRONMENT !== "production",
    },
};

export const GlobalContext = createContext({
    ...defaultContext,

    history: null,
    searchHistory: () => null,
    loading: false,
    setLoading: (loading) => {},

    lang: null, // Manager language (iso-639-1 code)
    setLang: (isoCode) => {},

    openModal: (modal) => {},
    closeModal: () => {},

    warnings: [],
    isVersionUpdated: false,
    setVersionUpdated: (value) => {},
    userAdvicedUpdate: false,
    saveUserAdvicedUpdate: (value) => {},
});

const GlobalProvider = ({ children }) => {
    const { t, i18n } = useTranslation();

    const location = useLocation();
    const [history, setHistory] = useState([]);

    const dispatch = useDispatch();
    const { update: advicedUpdate } = useAdvicedUpdate();

    const defaultLang = i18n?.language?.split("-")[0];

    const managerVersion = pkg?.version;
    const userRef = Session.getSessionProp("userRef");
    const isSuperUser = Session.getSessionProp("superUser");
    const isImpersonated = useSelector((state) => state.auth?.impersonated);
    const permissions = useSelector((state) => state.ui?.permissions);
    const [loading, setLoading] = useState(false);
    const [lang, setLang] = useState(defaultLang);
    const [modals, setModals] = useState([]);
    const warnings = pkg?.updateInfo?.display ? [{ type: WARNING_TYPES.UPDATE_INFO.type }] : [];
    const userWarnings = safeJsonParse(Session.getSessionProp("userWarnings") || "[]");
    const userAdvicedUpdate = safeJsonParse(Session.getSessionProp("userAdvicedUpdate") || "false");
    const [storedCloseds, setStoredCloseds] = useState(safeJsonParse(Session.getSessionProp("warnings-closed")));
    const [storedCollapseds, setStoredCollapseds] = useState(
        safeJsonParse(Session.getSessionProp("warnings-collapsed"))
    );
    const [isVersionUpdated, setIsVersionUpdated] = useState(false);

    const saveUserAdvicedUpdate = useCallback(
        (value) => {
            if (!isSuperUser && userAdvicedUpdate !== value) {
                Session.setSessionProp("userAdvicedUpdate", value);
                // mark user as notified only if not impersonating
                if (userRef && !isImpersonated) {
                    advicedUpdate({ ref: userRef, value });
                }
            }
        },
        [isSuperUser, userAdvicedUpdate, userRef, isImpersonated]
    );

    const setVersionUpdated = (value) => {
        setIsVersionUpdated(value);
        if (value) {
            toast.success(t("System update completed"));
            saveUserAdvicedUpdate(false);
        }
    };

    const allWarnings = [].concat(userWarnings || [], warnings || []);
    // Filter warnings that are not expired and the user has the necessary permissions
    const availableWarnings = allWarnings
        .map((w) => {
            if (w) {
                if (!w?.id) {
                    w.id = `warning-${w?.type}-${managerVersion}`;
                }
                w.collapsed = !!storedCollapseds?.[w.id];
            }
            return w;
        })
        .filter((w) => {
            if (!w) {
                return false;
            }

            if (WARNING_TYPES[w.type]) {
                if (WARNING_TYPES[w.type].deadlineDate) {
                    // Check if the warning has expired
                    if (new Date() >= WARNING_TYPES[w.type].deadlineDate) {
                        return false;
                    }
                }
                if (WARNING_TYPES[w.type].check) {
                    if (
                        w.type !== WARNING_TYPES.SYSTEM_UPDATE.type &&
                        allWarnings?.some((w) => w.type === WARNING_TYPES.SYSTEM_UPDATE.type)
                    ) {
                        // If the user has the system update warning, don't show other warnings
                        return false;
                    }
                    // Check if the user has the necessary permissions to see the warning
                    if (!WARNING_TYPES[w.type].check(permissions)) {
                        return false;
                    }
                }
            }

            // If the warning is closed, don't show it
            return w.id && !storedCloseds?.[w.id];
        });
    const availableWarningsHash = JSON.stringify(availableWarnings);

    const [changeUserLang, { data: changeUserLangResponse, loading: changingLanguage }] = useMutation(
        gql`
            mutation changeUserLang($lang: String!) {
                changeUserLang(lang: $lang) {
                    ok
                    token
                }
            }
        `,
        {
            onError(err) {
                console.error(err);
                toast.error(t("mutation-error"));
            },
        }
    );
    const updatedToken = changeUserLangResponse?.changeUserLang?.token;

    const contextData = useMemo(
        () => ({
            ...defaultContext,

            features: {
                userguiding: ENVIRONMENT_VARS.USERGUIDING,
                notifications: ENVIRONMENT_VARS.NOTIFICATIONS,
                selenium: ENVIRONMENT !== "production",
            },

            history,
            searchHistory: (search) => {
                if (history?.length) {
                    // Search for the last route in the history and return it if found
                    const lastPath = search?.lastPath;
                    if (lastPath) {
                        return history
                            ?.map((item) => item?.pathname + (item?.search || ""))
                            .reverse()
                            .find((item) => item === lastPath || item?.startsWith(lastPath + "?"));
                    }
                }
                return null;
            },
            loading: loading || changingLanguage,
            setLoading,

            lang,
            setLang: (isoCode) => {
                if (lang && isoCode) {
                    // Update user lang in back
                    changeUserLang({ variables: { lang: isoCode } });
                    setLang(isoCode);
                }
            },

            openModal: (modal) => {
                if (modal) {
                    setModals((modals) => [...modals, modal]);
                }
            },
            closeModal: () => {
                setModals((modals) => {
                    if (modals?.length) {
                        return modals.slice(0, modals.length - 1);
                    }
                    return [];
                });
            },

            warnings: availableWarnings?.length ? availableWarnings : null,
            isVersionUpdated,
            setVersionUpdated,
            userAdvicedUpdate,
            saveUserAdvicedUpdate,
        }),
        [
            history,
            loading,
            lang,
            isVersionUpdated,
            availableWarningsHash,
            userAdvicedUpdate,
            saveUserAdvicedUpdate,
        ]
    );

    useEffect(() => {
        if (
            history.length > 1 &&
            location.pathname === history[history.length - 2]?.pathname &&
            location.search === history[history.length - 2]?.search
        ) {
            // Back detected, remove the last location from the history
            setHistory((prevHistory) => prevHistory.slice(0, prevHistory.length - 1));
        } else {
            // Add the current location to the history
            setHistory((prevHistory) => [...prevHistory, location]);
        }
    }, [location]);

    useEffect(() => {
        // This is a temporary fix, while there are still implementations of localStorage.getItem("lang") to read the language
        //OPTIMIZE Remove this when all implementations are updated
        localStorage.setItem("lang", lang);

        // This is a temporary fix, while there are still implementation of redux state.ui.lang to read the language
        //OPTIMIZE Remove this when all implementations are updated
        dispatch(setLanguage(lang));

        // Update the language in the i18n instance
        i18n.changeLanguage(lang);
    }, [lang]);

    useEffect(() => {
        if (updatedToken) {
            // Update the token in the session
            Session.setToken(updatedToken);

            //OPTIMIZE This should not be necessary, at some point it should be removed
            window.location.reload();
        }
    }, [updatedToken]);

    useEffect(() => {
        const handleStorageChange = (event) => {
            switch (event?.detail?.key) {
                case "warnings-closed":
                    setStoredCloseds(safeJsonParse(event.detail.value));
                    break;
                case "warnings-collapsed":
                    setStoredCollapseds(safeJsonParse(event.detail.value));
                    break;
                default:
                //console.warn("Unhandled storage change:", event?.detail?.key);
            }
        };
        window.addEventListener("storageChange", handleStorageChange);
        return () => {
            window.removeEventListener("storageChange", handleStorageChange);
        };
    }, []);

    return (
        <GlobalContext.Provider value={contextData}>
            <ValidateNetworkProvider>
                <UserNetworkProvider>
                    {children}
                    {loading ? <Loading adjust="absolute" /> : null}
                    <ModalsContainer
                        modals={modals}
                        onClose={() => {
                            setModals((modals) => {
                                if (modals?.length > 0) {
                                    return modals.slice(0, modals.length - 1);
                                }
                                return [];
                            });
                        }}
                    />
                </UserNetworkProvider>
            </ValidateNetworkProvider>
        </GlobalContext.Provider>
    );
};

export function safeJsonParse(str) {
    if (str === "undefined" || str === "null" || str === "") {
        return null;
    }
    try {
        return JSON.parse(str);
    } catch (e) {
        console.error("Error parsing JSON:", e);
        return null;
    }
}

export default GlobalProvider;
