import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withApollo } from "@apollo/react-hoc";
//API
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ModalMutationDispatcher from "../../hooks/GraphqlCalls/CommonZones/modals/modalMutationDispatcher";
import UseModalAssignDevice from "./UseModalAssignDevice";
//Actions
import {
    closeModal,
    openModal,
    setModalContent,
    showGlobalLoading,
    setLoadingModalContent,
} from "../../actions/uiActions";
import {
    cleanAction,
    setActionValidations,
    cleanActionValidations,
    changeActionValues,
    changeGlobalAction,
    cleanActionValues,
    setActionName,
} from "../../actions/globalActions";
import {
    showRowForm,
    refreshPeriodicSchedule,
    deletePunctualView,
    setExecuteQuery,
    setRefreshContentData,
    setParametersSection,
} from "../../actions/sectionActions";
import {
    setDateFilter,
    setLoading,
    setRefreshData,
    setReload,
    setTableResults,
    setPage,
    setAllChecked,
    cleanCheckedItems,
    addToastInfoItem,
    removeToastInfoItem,
} from "../../actions/tableActions";
import { deleteEditorSSchedule } from "../../actions/scheduleActions";
//Components
import UseButton from "../useButton";
import UseModalRadios from "./useModalRadios";
import UseModalSelect from "./useModalSelect";
import UseModalText from "./useModalText";
import UseModalPasword from "./useModalPassword";
import UseMultipleInput from "./useMultipleInput";
import UseModalHtmlText from "./useModalHtmlText";
import UseModalTextArea from "./useModalTextArea";
import UseModalReleaseNotes from "./useModalReleaseNotes";
import UsePlainText from "./usePlainText";
import UseInputSelectSearch from "./useInputSelectSearch";
import UseSelectMultipleWithSearch from "./useSelectMultipleWithSearch";
import UseDuplicateContent from "./useDuplicateContent";
import UseSelectedResume from "./useSelectedResume";
import UseModalSelectedView from "./useModalSelectedView";
import UseModalDestinations from "./UseModalDestinations";
import UseModalFiltersResults from "./useModalFiltersResults";
import UseModalNumber from "./useModalNumber";
import UseModalCheckbox from "./useModalCheckbox";
import UseBatchSelection from "./useBatchSelection";
import DayPicker from "react-day-picker";
import UseCalendarNavbar from "../Schedule/useCalendarNavbar";
import UseModalTemplates from "../Modal/useModalTemplates";
import UseResendCode from "../Modal/useResendCode";
import UseNewProduct from "./useNewProduct";
import UseTranslationInputs from "../Modal/useTranslationInputs";
import UseModalFile from "./useModalFile";
import UseModalBrowserMediaLibrary from "./useModalBrowserMediaLibrary";
import PortalMac from "../Section/Hotel/PortalMac";
import MacExceptions from "../../hooks/GraphqlCalls/Settings/modals/MacExcepcionts";
import UseModalEditEmailOrders from "./UseModalEditEmailOrders";
import UseSelectImage from "./useSelectImage";
import UseSelectImageIcon from "./useSelectImageIcon";
import UseModalNewPasscode from "./UseModalNewPasscode";
import UseModalNewCategory from "./UseModalNewCategory";
import UseModalEditNameCategory from "./UseModalEditNameCategory";
import UseModalTaxInUse from "./UseModalTaxInUse";
import UseModalUsersAssigned from "./UseModalUsersAssigned";
import UseModalEditCustomField from "./UseModalEditCustomField";
import Actions from "../Section/Design/Widgets/WidgetToolbars/CommonOptions/Actions";
//Utils
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/es";
import { createBrowserHistory } from "history";
import { toast } from "react-toastify";
import { removeApolloCacheKeys, copyToClipboard, capitalizeFirst, getFontMymeType } from "../../hooks/Utils/Utils";
import { validateModal } from "../../hooks/Utils/Validations";
import UseLabel from "../Inputs/useLabel";
import BrowserMediaLibraryModal from "../../hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
import MenuNavNewItem from "../../hooks/GraphqlCalls/MediaLibrary/modals/MenuNavNewItem";
import WidgetItemNew from "../../hooks/GraphqlCalls/MediaLibrary/modals/WidgetItemNew";
import UseModalSwitch from "./useModalSwitch";
import { Session } from "../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";
import UseInputDateHour from "../useInputDateHour";
import UseToast from "../Notifications/useToast";
import UseModalLogo from "./useModalLogo";
import UseModalDesignsTree from "./useModalDesignsTree";
import Transcode from "../../hooks/GraphqlCalls/MediaLibrary/modals/Transcode";
import { arrangeToastMessagesUploadingFiles } from "../../hooks/Utils/DesignUtils";
import Parser from "hooks/Utils/Parser";
import UseModalNewAlarm from "./UseModalNewAlarm";
import UseModalEditAlarm from "./UseModalEditAlarm";
import { setToastData } from "../../actions/designActions";
import UseModalTaxZoneAssociatedTaxRates from "./UseModalTaxZoneAssociatedTaxRates";
import UseModalSalesAllergensTranslate from "./UseModalSalesAllergensTranslate";
import UseModalNewShop from "./UseModalNewShop";
import UseModalEditNameShop from "./UseModalEditNameShop";
import InfoToast from "../Section/Hotel/Monitor/InfoToast";
import UseModalTranslations from "./UseModalTranslations";
import UseModalProductAddExtra from "./UseModalProductAddExtra";
import UseModalAddChannel from "./UseModalAddChannel";
import UseModalEditName from "./UseModalEditName";
import { UseModalAssignedTvs } from "./useModalAssignedTvs";
import { UseInputGroupDetail } from "./useModalInputGroupDetail";
import { UseModalInputsManagement } from "./useModalInputsManagement";
import { UseModalAssignedTvsManagement } from "./useModalAssignedTvsManagement";
import UseModalChangeDeviceFunction from "./UseModalChangeDeviceFunction";
import UseModalAddChannelToGroup from "./UseModalAddChannelToGroup";
import NewChannelModalGroup from "../../hooks/GraphqlCalls/Services/modals/NewChannelModalGroup";
import UseModalGridChannelsFilters from "./UseModalGridChannelsFilters";
import UseModalAddGroupChannelToGrid from "./UseModalAddGroupChannelToGrid";
import UseModalVideoDetails from "./UseModalVideoDetails";
import { UseModalPreviewChannel } from "./UseModalPreviewChannel";
import UseModalAddCorporateChannel from "./UseModalAddCorporateChannel";
import UseModalChannelUsedInProperties from "./UseModalChannelUsedInProperties";
import UseBatchSelectionTable from "./UseBatchSelectionTable";
import UseModalRoomOrders from "./UseModalRoomOrders";
import UseModalCheckout from "./UseModalCheckout";
import { UseModalAddMobileApp } from "./UseModalAddMobileApp";
import { UseModalDeleteMobileApp } from "./UseModalDeleteMobileApp";
import UseModalTranslateTextsTabs from "./UseModalTranslateTextsTabs";
import { UseModalAddBooking } from "./UseModalAddBooking";
import UseModalImportChannels from "./useModalImportChannels";
import UseModalItemsResume from "./useModalItemsResume";
import UseModalEditCompanion from "./UseModalEditCompanion";
import UseModalEditDocumentation from "./UseModalEditDocumentation";
import UseModalDvbImportResume from "./useModalDvbImportResume";
import UseModalAutomationOfGuestReport from "./UseModalAutomationOfGuestReport";
import UseModalCheckin from "./UseModalCheckin";
import UseModalAddEditStaffUser from "./UseModalAddEditStaffUser";
import UseModalInformativeList from "./UseModalInformativeList";
import UseDeleteBatchChannelsDetail from "./useDeleteBatchChannelsDetail";
import { setToken } from "actions/authActions";
import { UseModalEditGuestInformation } from "./UseModalEditGuestInformation";
import { UseModalAdditionalInformation } from "./UseModalAdditionalInformation";
import { UseModalGuestReport } from "./UseModalGuestReport";

import { GlobalContext } from "contexts/Global";
import { UseModalDateRange } from "./UseModalDateRange";
import UseDeleteBatchGridDetail from "./UseDeleteBatchGridDetail";

const ModalContent = ({ client }) => {
    const { t } = useTranslation();

    const { lang } = useContext(GlobalContext);

    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const {
        id,
        text,
        textAdjust,
        twoColumns,
        inputs,
        buttons,
        buttonsStyle,
        buttonAlignment,
        schedule,
        currentEvent,
        useGlobalLoading,
        executeSectionQuery,
        preventCleanOnClose,
        preventSuccessToast,
        acceptCallback,
        noTranslateText,
    } = useSelector((state) => state.ui.modalContent);
    const { periodicEventsData, eventTypeActive } = useSelector((state) => state.sectionContent);
    const { dateFilter } = useSelector((state) => state.table);
    const actionData = useSelector((state) => state.action);
    const { checkedItems } = useSelector((state) => state.table);
    const { projectLangs, permissions } = useSelector((state) => state.ui);
    const projectRef = useSelector((state) => state.hotel.ref);
    const { itemSelected, cLang, cScreenData } = useSelector((state) => state.design);

    //Data
    const editAreaDesc = "area-edit-description";
    const areaMoveCat = "area-move-category";
    const buttonLayout = buttonAlignment ? buttonAlignment : "end";

    let inputData = inputs ? inputs.filter((input) => input.type === "data") : null;
    if (inputData) {
        inputData = inputData[0];
    }

    //States
    const [selectedDay, setSelectedDay] = useState(new window.ZDate());
    const [fullText, setFullText] = useState();
    const [calendarLang, setCalendarLang] = useState("");

    //API
    const mutationChain = ModalMutationDispatcher(executeSectionQuery);
    const DYNAMIC_MUTATION = gql`
        ${mutationChain}
    `;
    const [executeMutation, { data }] = useMutation(DYNAMIC_MUTATION, {
        onError(err) {
            console.log(err);
            dispatch(setLoading(false));
            dispatch(showGlobalLoading(false));
            dispatch(setModalContent(false));
            toast.error(t("mutation-error"));
        },
    });
    const history = createBrowserHistory();
    //Actions
    const dispatch = useDispatch();
    const hideModal = () => dispatch(closeModal());
    const handleClick = async (e) => {
        const actionType = e.currentTarget.dataset.action;
        const checkEnabled = e.currentTarget.dataset.checkenabled;
        e.stopPropagation();

        if (checkEnabled && !actionData.values[checkEnabled]) {
            return;
        }
        //validate
        const { validations, avoidDefError } = validateModal(actionType, actionData, inputData, t, permissions);
        const validationsArray = Object.values(validations);
        if (
            !(actionType === "close" || actionType === "accept") &&
            validationsArray.filter((item) => item === false).length > 0
        ) {
            dispatch(setActionValidations(validations));
            if (!avoidDefError) {
                toast.error(t("errors-in-red"));
            }
            return;
        } else {
            dispatch(setActionValidations(validations));
        }
        if (actionType === "goBack") {
            dispatch(setModalContent(false));
            dispatch(cleanAction());
            hideModal();
            history.back();
        } else if (
            actionType === "close" ||
            (actionType === "accept" && actionData.actionName !== "new-area-booking")
        ) {
            if (actionData.actionName === "new-staff-member") {
                history.go(0);
            }
            dispatch(setModalContent(false));
            if (!preventCleanOnClose) {
                dispatch(cleanAction());
            }
            acceptCallback && actionType === "accept" && window[acceptCallback](actionData.values);
            hideModal();
            if (
                actionData.actionName === "add-media-library-media" &&
                actionData.values["dataMediaLibraryBrowser"] &&
                actionData.values["dataMediaLibraryBrowser"] !== ""
            ) {
                dispatch(cleanAction());
                const modalBrowser = BrowserMediaLibraryModal(t, actionData.values["dataMediaLibraryBrowser"]);
                dispatch(
                    changeGlobalAction({
                        actionName: "browser-media-library",
                    })
                );
                dispatch(setModalContent(modalBrowser));
                dispatch(showGlobalLoading(false));
                dispatch(openModal());
            }
        } else if (actionType === "none") {
            return;
        } else if (actionType === "closeModal") {
            dispatch(closeModal());
            dispatch(setModalContent(false));
        } else if (actionType === "close&reload") {
            dispatch(setModalContent(false));
            dispatch(cleanAction());
            dispatch(setTableResults([]));
            dispatch(setReload(true));
            hideModal();
        } else if (actionType === "close&KeepActions") {
            dispatch(closeModal());
            dispatch(cleanActionValues());
            dispatch(setModalContent(false));
        } else if (actionType === "closeResetExecute") {
            dispatch(closeModal());
            dispatch(setModalContent(false));
            dispatch(cleanActionValues());
            dispatch(setExecuteQuery(null));
        } else if (actionType === "open-modal-menu-nav-new-item") {
            const actionDataValuesKey = Object.keys(actionData.values);
            const values = actionData.values;
            const arrActions = [];
            let newActionDataValues = {};
            actionDataValuesKey.forEach((key) => {
                if (key !== "browser-media-library-items-selected") {
                    newActionDataValues[key] = actionData.values[key];
                }
            });

            dispatch(changeActionValues(newActionDataValues));
            if (values && values["action-destination"]) {
                arrActions.push(values["action-destination"]);
            }
            dispatch(
                setModalContent(
                    MenuNavNewItem(
                        t,
                        itemSelected,
                        {
                            data: {
                                itemName: values && values["widget-item-name"] ? values["widget-item-name"] : null,
                                itemActions: values && values["action-destination"] ? arrActions : null,
                            },
                        },
                        cLang
                    )
                )
            );
            dispatch(openModal());
        } else if (actionType === "open-modal-widget-new-item") {
            const actionDataValuesKey = Object.keys(actionData.values);
            const values = actionData.values;
            const arrActions = [];
            let newActionDataValues = {};
            actionDataValuesKey.forEach((key) => {
                if (
                    key !== "browser-media-library-items-selected" &&
                    key !== "browser-media-library-items-info-selected"
                ) {
                    newActionDataValues[key] = actionData.values[key];
                }
            });

            dispatch(changeActionValues(newActionDataValues));
            if (values && values["action-destination"]) {
                arrActions.push(values["action-destination"]);
            }
            dispatch(
                setModalContent(
                    WidgetItemNew(
                        t,
                        itemSelected,
                        {
                            data: {
                                itemName: values && values["widget-item-name"] ? values["widget-item-name"] : null,
                                itemText: values && values["widget-item-text"] ? values["widget-item-text"] : null,
                                itemActions: values && values["action-destination"] ? arrActions : null,
                            },
                        },
                        cLang
                    )
                )
            );
            dispatch(openModal());
        } else if (actionData.actionName === "select-image-widget-new-item") {
            const values = actionData.values;
            const arrActions = [];
            dispatch(changeActionValues({ "option-resource-selected": "image-library" }));
            if (values && values["save-item-id"]) {
                dispatch(
                    changeGlobalAction({
                        actionName: "edit-item-widget-item",
                    })
                );
            } else {
                dispatch(
                    changeGlobalAction({
                        actionName: "create-item-widget-item",
                    })
                );
            }
            if (values && values["action-destination"]) {
                arrActions.push(values["action-destination"]);
            }

            const imageSelected = values["browser-media-library-items-selected"]?.[0];
            const itemName = values["browser-media-library-items-info-selected"]?.[0]?.name;

            dispatch(
                changeActionValues({
                    "image-selected": imageSelected,
                    "widget-item-name": itemName,
                })
            );

            dispatch(
                setModalContent(
                    WidgetItemNew(
                        t,
                        itemSelected,
                        {
                            data: {
                                itemName: itemName,
                                itemText: values && values["widget-item-text"] ? values["widget-item-text"] : null,
                                itemActions: values && values["action-destination"] ? arrActions : null,
                                itemId: values && values["save-item-id"] ? values["save-item-id"] : null,
                            },
                        },
                        cLang
                    )
                )
            );
            dispatch(openModal());
        } else if (actionData.actionName === "select-image-menu-nav-new-item") {
            const values = actionData.values;
            const arrActions = [];
            dispatch(changeActionValues({ "option-resource-selected": "image-library" }));
            if (values && values["save-item-id"]) {
                dispatch(
                    changeGlobalAction({
                        actionName: "edit-item-widget-nav",
                    })
                );
            } else {
                dispatch(
                    changeGlobalAction({
                        actionName: "create-item-widget-nav",
                    })
                );
            }
            if (values && values["action-destination"]) {
                arrActions.push(values["action-destination"]);
            }

            dispatch(
                changeActionValues({
                    "image-selected": values["browser-media-library-items-selected"][0],
                    "widget-item-name": values["browser-media-library-items-info-selected"][0]?.name,
                })
            );

            dispatch(
                setModalContent(
                    MenuNavNewItem(
                        t,
                        itemSelected,
                        {
                            data: {
                                itemName: values && values["widget-item-name"] ? values["widget-item-name"] : null,
                                itemActions: values && values["action-destination"] ? arrActions : null,
                                itemId: values && values["save-item-id"] ? values["save-item-id"] : null,
                            },
                        },
                        cLang
                    )
                )
            );
            dispatch(openModal());
        } else if (actionType === "close-and-open-add-channel-to-group") {
            dispatch(setModalContent(NewChannelModalGroup(t)));
            dispatch(setActionName("add-channel-to-group-channel"));
        } else if (actionData.actionName === "select-asset-for-modal-actions-item-widget-item") {
            const values = actionData.values;
            const arrActions = [];
            const action = { type: "library", value: values["browser-media-library-items-selected"][0] };

            let resourceValue = values["save-resource-value"] ? JSON.parse(values["save-resource-value"]) : null;

            dispatch(changeActionValues({ "action-destination": action }));

            if (resourceValue?.libraryRef) {
                dispatch(changeActionValues({ "image-selected": resourceValue.libraryRef }));
            }

            if (resourceValue?.externalUrl) {
                dispatch(changeActionValues({ "image-selected": resourceValue?.externalUrl }));
            }

            if (action) {
                arrActions.push(action);
            }

            dispatch(
                setModalContent(
                    WidgetItemNew(
                        t,
                        itemSelected,
                        {
                            data: {
                                itemName: values && values["widget-item-name"] ? values["widget-item-name"] : null,
                                itemText: values && values["widget-item-text"] ? values["widget-item-text"] : null,
                                itemActions: action ? arrActions : null,
                                resourceValue: resourceValue,
                            },
                        },
                        cLang
                    )
                )
            );
            if (values && values["save-item-id"]) {
                dispatch(
                    changeGlobalAction({
                        actionName: "edit-item-widget-item",
                    })
                );
            } else {
                dispatch(
                    changeGlobalAction({
                        actionName: "create-item-widget-item",
                    })
                );
            }
            dispatch(openModal());
        } else if (actionData.actionName === "select-asset-for-modal-actions-item-widget-nav") {
            const values = actionData.values;
            const arrActions = [];
            const action = { type: "library", value: values["browser-media-library-items-selected"][0] };

            dispatch(changeActionValues({ "action-destination": action }));

            if (action) {
                arrActions.push(action);
            }

            dispatch(
                setModalContent(
                    MenuNavNewItem(
                        t,
                        itemSelected,
                        {
                            data: {
                                itemName: values && values["widget-item-name"] ? values["widget-item-name"] : null,
                                itemActions: action ? arrActions : null,
                            },
                        },
                        cLang
                    )
                )
            );
            if (values && values["save-item-id"]) {
                dispatch(
                    changeGlobalAction({
                        actionName: "edit-item-widget-nav",
                    })
                );
            } else {
                dispatch(
                    changeGlobalAction({
                        actionName: "create-item-widget-nav",
                    })
                );
            }
            dispatch(openModal());
        } else if (actionType === "close-destiantions") {
            const actionDataValuesKey = Object.keys(actionData.values);
            let newActionDataValues = {};
            actionDataValuesKey.forEach((key) => {
                if (!key.includes(`filter_`)) {
                    newActionDataValues[key] = actionData.values[key];
                }
            });
            dispatch(cleanActionValues());
            dispatch(changeActionValues(newActionDataValues));
            dispatch(setLoadingModalContent(false));
            dispatch(setModalContent(false));
            hideModal();
        } else if (actionType === "booking-date-save" && actionData.actionName === "new-area-booking") {
            dispatch(setModalContent(false));
            dispatch(showRowForm(true));
            dispatch(setDateFilter(selectedDay));
            dispatch(setLoading(true));
            hideModal();
        } else if (actionType === "accept-policy") {
            dispatch(setModalContent(false));
            hideModal();
            Session.setTOSAnswer(true);
            if (Session.getSessionProp("tokentmp")) {
                Session.setToken(Session.getSessionProp("tokentmp"));
                dispatch(setToken(Session.getSessionProp("tokentmp")));
                Session.removeSessionProp("tokentmp");
                window.location.href = "#/select-project";
            } else {
                window.location.href = "#/select-project";
            }
        } else if (actionType === "load-next-modal") {
            dispatch(setModalContent(actionData.modalSequence[1]));
        } else if (actionType === "save-staff-zones") {
            const selectedIds = Object.values(
                actionData.values && actionData.values["staff-zones"] ? actionData.values["staff-zones"] : []
            );

            if (!selectedIds || selectedIds.length === 0) {
                toast.error(`${t("error-assign-zones")} ${t("before-saving")}`);
            } else {
                executeMutation();
            }
        } else if (executeSectionQuery) {
            dispatch(
                setExecuteQuery({
                    action: actionData.actionName || actionType,
                    params: inputData,
                    closeModal: true,
                })
            );
        } else if (isDirectExecute(actionType, actionData)) {
            if (actionData.actionName === "delete-schedule-editor") {
                dispatch(deleteEditorSSchedule(currentEvent));
                dispatch(cleanAction());
                hideModal();
            }
            if (actionData.actionName === "media-library-download") {
                let refs = [];
                if (actionData && actionData.itemsAffected && actionData.itemsAffected.length > 0) {
                    refs = refs.concat(actionData.itemsAffected);
                } else if (checkedItems && checkedItems.length > 0) {
                    refs = refs.concat(checkedItems);
                }
                if (refs.length > 0) {
                    const link = document.createElement("a");
                    link.target = "_blank";
                    document.body.appendChild(link);
                    refs.map(async (ref, index) => {
                        link.href = Session.getDasUrl(ref + "?download");
                        link.click();
                    });
                    document.body.removeChild(link);
                }
                dispatch(setModalContent(false));
                dispatch(setAllChecked(false));
                dispatch(cleanCheckedItems());
                hideModal();
                dispatch(cleanAction());
            } else if (
                actionData.actionName === "media-library-delete-permanently" ||
                actionData.actionName === "video-library-delete-permanently" ||
                actionData.actionName === "font-library-delete-permanently" ||
                actionData.actionName === "media-library-move-content"
            ) {
                executeMutation();
                dispatch(showGlobalLoading(true));
                hideModal();
            } else {
                executeMutation();
                dispatch(useGlobalLoading ? showGlobalLoading(true) : setLoading(true));
                hideModal();
            }
        } else if (actionType === "area-capacity-save") {
            if (!actionData.values["people-number"] || actionData.values["people-number"] < 0) {
                toast.error(t("error-capacity-limit"));
            } else if (actionData.values["people-number"]) {
                executeMutation();
            }
        } else if (actionType === "area-allow-booking-save") {
            if (
                !actionData.values["allow"] ||
                !actionData.values["booking-freq"] ||
                !actionData.values["booking-duration"] ||
                !actionData.values["booking-duration-visible"]
            ) {
                toast.error(t("error-booking-allow"));
            } else if (actionData.values["allow"] || actionData.values["booking-freq"]) {
                executeMutation();
            }
        } else if (actionData.actionName === editAreaDesc || actionData.actionName === "edit-description") {
            executeMutation();
        } else if (actionType === areaMoveCat) {
            if (actionData.values[areaMoveCat] && actionData.values[areaMoveCat] !== "") {
                executeMutation();
            } else {
                toast.error(t("error-select-category"));
            }
        } else if (actionData.actionName === "cancel-booking") {
            if (isCommentOk(actionData)) {
                executeMutation();
                hideModal();
            } else {
                toast.error(t("error-booking-cancel-comment"));
            }
        } else if (actionData.actionName === "area-see-link") {
            copyToClipboard(actionData.values.link);
            dispatch(setModalContent(false));
            dispatch(cleanAction());
            hideModal();
        } else if (
            actionData.actionName &&
            (actionData.actionName.includes("chromecast-") ||
                actionData.actionName.includes("tv-") ||
                actionData.actionName.includes("room-group"))
        ) {
            executeMutation();
            hideModal();
        } else if (
            actionData.actionName === "add-media-library-media" ||
            actionData.actionName === "add-library-font"
        ) {
            let actionDataValues = { ...actionData.values };
            let actionName = actionData.actionName;
            let videosTranscodeCompleted = true;
            let videoRequiredTranscodePosition = -1;
            if (actionDataValues.files && actionDataValues.files.length > 0) {
                for (let i = 0; i < actionDataValues.files.length; i++) {
                    let f = actionDataValues.files[i];
                    let typeFile = f.type;
                    if (typeFile === "video") {
                        if (
                            !actionDataValues["transcodeData"] ||
                            !actionDataValues["transcodeData"][i] ||
                            !actionDataValues["transcodeData"][i]["dataSelected"]
                        ) {
                            if (
                                actionDataValues.lang &&
                                actionDataValues.lang.length > 0 &&
                                actionDataValues.quality &&
                                actionDataValues.quality !== ""
                            ) {
                                if (!actionDataValues["transcodeData"]) {
                                    actionDataValues["transcodeData"] = [];
                                }
                                actionDataValues["transcodeData"][i] = {
                                    dataSelected: true,
                                    lang: actionDataValues.lang[0],
                                    quality: actionDataValues.quality,
                                };
                                delete actionDataValues.lang;
                                delete actionDataValues.quality;
                            } else {
                                videosTranscodeCompleted = false;
                                videoRequiredTranscodePosition = i;
                                break;
                            }
                        }
                    }
                }
            }
            if (!videosTranscodeCompleted && videoRequiredTranscodePosition >= 0) {
                let f = actionData.values.files[videoRequiredTranscodePosition];
                let fileToTranscode = f.file;
                dispatch(showGlobalLoading(true));
                dispatch(setModalContent(false));
                dispatch(cleanAction());
                hideModal();
                dispatch(
                    changeGlobalAction({
                        actionName: actionName,
                        values: actionDataValues,
                    })
                );
                const langs = [];
                if (projectLangs) {
                    projectLangs.map((projectLang) =>
                        langs.push({
                            id: projectLang.languageRef,
                            name: t("language:" + projectLang.languageRef) || projectLang.languageRef,
                            selected: projectRef !== "CORPORATE" ? projectLang.isDefault : false,
                        })
                    );
                }
                const transcodeModal = Transcode({
                    name: fileToTranscode.name,
                    projectLangs: langs,
                    actionName: actionName,
                });
                setTimeout(function () {
                    dispatch(showGlobalLoading(false));
                    dispatch(setModalContent(transcodeModal));
                    dispatch(openModal());
                }, 500);
            } else {
                let executeSigleMutation = true;
                dispatch(showGlobalLoading(true));
                if (actionDataValues.files && actionDataValues.files.length > 0) {
                    executeSigleMutation = false;
                    removeApolloCacheKeys(client.cache, getApollokeyToDelete(actionData.actionName));
                    dispatch(setModalContent(false));
                    hideModal();
                    dispatch(cleanAction());
                    dispatch(showGlobalLoading(false));
                    let filesUploading = [];
                    let transcodingVideos = false;
                    for (let i = 0; i < actionDataValues.files.length; i++) {
                        let f = actionDataValues.files[i];
                        let file = f.file;
                        let typeFile = f.type;
                        let transcodeData =
                            actionDataValues["transcodeData"] &&
                            actionDataValues["transcodeData"][i] &&
                            actionDataValues["transcodeData"][i]["dataSelected"]
                                ? actionDataValues["transcodeData"][i]
                                : null;
                        filesUploading.push({
                            name: file.name,
                            status: 1,
                            errorMessage: "",
                            ref: "",
                            typeFile: typeFile,
                            lang: transcodeData ? transcodeData.lang : null,
                            size: f && f.file && f.file.size ? f.file.size : 0,
                        });
                    }
                    let [title, msgs] = arrangeToastMessagesUploadingFiles(
                        t,
                        filesUploading,
                        actionDataValues.files.length
                    );
                    let toastId = toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: false,
                        className: "use-toast",
                        closeButton: false,
                        closeOnClick: false,
                        draggable: false,
                        hideProgressBar: true,
                        icon: false,
                    });
                    for (let i = 0; i < actionDataValues.files.length; i++) {
                        let f = actionDataValues.files[i];
                        let file = f.file;
                        let typeFile = f.type;
                        let transcodeData =
                            actionDataValues["transcodeData"] &&
                            actionDataValues["transcodeData"][i] &&
                            actionDataValues["transcodeData"][i]["dataSelected"]
                                ? actionDataValues["transcodeData"][i]
                                : null;
                        if (typeFile === "image") {
                            typeFile = "asset";
                        }
                        const uploadUrl = Session.getUploadUrl(
                            `?type=${typeFile}&token=${Session.getSessionProp("token").replace("Bearer ", "")}&folder=${
                                actionDataValues.parentRef
                            }`
                        );
                        const formData = new FormData();

                        if (typeFile === "font") {
                            const specificFontType = getFontMymeType(file.name);
                            if (specificFontType) {
                                const blob = new Blob([file], { type: specificFontType });
                                file = new File([blob], file.name, {
                                    type: specificFontType,
                                    lastModified: file.lastModified,
                                });
                            }
                        }

                        formData.append("file", file);
                        formData.append("fileName", file.name);
                        if (actionDataValues.parentRef) {
                            formData.append("folderRef", actionDataValues.parentRef);
                        }
                        if (transcodeData) {
                            formData.append("fileLanguage", transcodeData.lang);
                            formData.append("resolution", transcodeData.quality);
                        }
                        try {
                            const uploadResponse = await fetch(uploadUrl, {
                                method: "POST",
                                body: formData,
                            }).then((uploadResponse) => uploadResponse.json());
                            if (uploadResponse && uploadResponse.ref) {
                                dispatch(setRefreshData(true));
                                dispatch(setReload(true));
                                filesUploading[i].status =
                                    typeFile === "video" && uploadResponse.streamStatus !== "COMPLETE" ? 1 : 2;
                                filesUploading[i].ref = uploadResponse.ref;
                                [title, msgs] = arrangeToastMessagesUploadingFiles(
                                    t,
                                    filesUploading,
                                    actionDataValues.files.length
                                );
                            } else {
                                console.log(uploadResponse);
                                filesUploading[i].status = 4;
                                filesUploading[i].errorMessage =
                                    uploadResponse && uploadResponse.error && uploadResponse.error.message
                                        ? uploadResponse.error.message
                                        : "";
                                console.error(filesUploading[i].errorMessage);
                                [title, msgs] = arrangeToastMessagesUploadingFiles(
                                    t,
                                    filesUploading,
                                    actionDataValues.files.length
                                );
                            }
                        } catch (err) {
                            console.log(err);
                            filesUploading[i].status = 4;
                            [title, msgs] = arrangeToastMessagesUploadingFiles(
                                t,
                                filesUploading,
                                actionDataValues.files.length
                            );
                        }
                        if (filesUploading[i].status === 1) {
                            transcodingVideos = true;
                        } else {
                            filesUploading[i].parentRef = actionDataValues.parentRef;
                            filesUploading[i].sideBarRef = "media";
                        }
                        [title, msgs] = arrangeToastMessagesUploadingFiles(
                            t,
                            filesUploading,
                            actionDataValues.files.length
                        );
                        toast.update(toastId, {
                            render: <UseToast title={title} msgs={msgs} minimize={true} />,
                            className: "use-toast",
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    setTimeout(function () {
                        if (!transcodingVideos) {
                            [title, msgs] = arrangeToastMessagesUploadingFiles(
                                t,
                                filesUploading,
                                actionDataValues.files.length
                            );
                            if (filesUploading.filter((f) => f.status === 2).length === filesUploading.length) {
                                toast.dismiss(toastId);
                                toast.success(<UseToast title={title} msgs={msgs} type={"success"} />, {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    className: "use-toast",
                                    autoClose: false,
                                    closeButton: true,
                                    closeOnClick: false,
                                    draggable: false,
                                    hideProgressBar: true,
                                    icon: false,
                                });
                            } else {
                                toast.update(toastId, {
                                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    closeButton: true,
                                    className: "use-toast",
                                    icon: false,
                                });
                            }
                        }
                        dispatch(
                            setToastData({
                                id: toastId,
                                filesUploading: filesUploading,
                                size: actionDataValues.files.length,
                            })
                        );
                    }, 500);

                    if (
                        actionData.actionName === "add-media-library-media" &&
                        actionData.values["dataMediaLibraryBrowser"] &&
                        actionData.values["dataMediaLibraryBrowser"] !== ""
                    ) {
                        const modalBrowser = BrowserMediaLibraryModal(t, actionData.values["dataMediaLibraryBrowser"]);

                        if (
                            actionData.values["dataMediaLibraryBrowser"].action &&
                            actionData.values["dataMediaLibraryBrowser"].action === "widget-actions-media"
                        ) {
                            dispatch(
                                changeGlobalAction({
                                    actionName: "widget-actions-media",
                                })
                            );
                        } else {
                            dispatch(
                                changeGlobalAction({
                                    actionName: "browser-media-library",
                                })
                            );
                        }

                        dispatch(setModalContent(modalBrowser));
                        dispatch(showGlobalLoading(false));
                        dispatch(openModal());
                    }
                }
                if (executeSigleMutation) {
                    executeMutation();
                    dispatch(setModalContent(false));
                    dispatch(showGlobalLoading(true));
                    hideModal();
                }
            }
        } else if (
            actionData.actionName === "media-library-update-media" ||
            actionData.actionName === "media-library-update-video"
        ) {
            let executeSigleMutation = true;
            dispatch(showGlobalLoading(true));
            if (actionData.values.files && actionData.values.files.length > 0) {
                executeSigleMutation = false;
                removeApolloCacheKeys(client.cache, getApollokeyToDelete(actionData.actionName));
                dispatch(setModalContent(false));
                hideModal();
                dispatch(cleanAction());
                dispatch(showGlobalLoading(false));
                let filesUploading = [];
                let transcodingVideos = false;
                actionData.values.files.forEach((f) => {
                    let file = f.file;
                    let typeFile = f.type;
                    filesUploading.push({
                        name: file.name,
                        status: 1,
                        errorMessage: "",
                        ref: "",
                        typeFile: typeFile,
                        lang: actionData.values && actionData.values.language ? actionData.values.language : null,
                        size: f && f.file && f.file.size ? f.file.size : 0,
                    });
                });
                let [title, msgs] = arrangeToastMessagesUploadingFiles(
                    t,
                    filesUploading,
                    actionData.values.files.length
                );
                let toastId = toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: false,
                    className: "use-toast",
                    closeButton: false,
                    closeOnClick: false,
                    draggable: false,
                    hideProgressBar: true,
                    icon: false,
                });
                for (let i = 0; i < actionData.values.files.length; i++) {
                    let f = actionData.values.files[i];
                    let file = f.file;
                    let typeFile = f.type;
                    if (typeFile === "image") {
                        typeFile = "asset";
                    }
                    const uploadUrl = Session.getUploadUrl(
                        `?type=${typeFile}&token=${Session.getSessionProp("token").replace("Bearer ", "")}&ref=${
                            actionData.values.ref
                        }`
                    );
                    const formData = new FormData();
                    formData.append("file", file);
                    if (actionData.actionName === "media-library-update-video") {
                        if (actionData.values && actionData.values.language) {
                            formData.append("fileLanguage", actionData.values.language);
                        }
                    }
                    try {
                        const uploadResponse = await fetch(uploadUrl, {
                            method: "POST",
                            body: formData,
                        }).then((uploadResponse) => uploadResponse.json());
                        if (uploadResponse && uploadResponse.ref) {
                            dispatch(setRefreshData(true));
                            dispatch(setReload(true));
                            filesUploading[i].status =
                                typeFile === "video" && uploadResponse.streamStatus !== "COMPLETE" ? 1 : 2;
                            filesUploading[i].ref = uploadResponse.ref;
                            [title, msgs] = arrangeToastMessagesUploadingFiles(
                                t,
                                filesUploading,
                                actionData.values.files.length
                            );
                        } else {
                            filesUploading[i].status = 4;
                            filesUploading[i].errorMessage =
                                uploadResponse && uploadResponse.error && uploadResponse.error.message
                                    ? uploadResponse.error.message
                                    : "";
                            console.error(filesUploading[i].errorMessage);
                            [title, msgs] = arrangeToastMessagesUploadingFiles(
                                t,
                                filesUploading,
                                actionData.values.files.length
                            );
                        }
                    } catch (err) {
                        console.log(err);
                        filesUploading[i].status = 4;
                        [title, msgs] = arrangeToastMessagesUploadingFiles(
                            t,
                            filesUploading,
                            actionData.values.files.length
                        );
                    }
                    if (filesUploading[i].status === 1) {
                        transcodingVideos = true;
                    }
                    toast.update(toastId, {
                        render: <UseToast title={title} msgs={msgs} minimize={true} />,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        icon: false,
                        className: "use-toast",
                    });
                }
                setTimeout(function () {
                    if (!transcodingVideos) {
                        [title, msgs] = arrangeToastMessagesUploadingFiles(
                            t,
                            filesUploading,
                            actionData.values.files.length
                        );
                        toast.update(toastId, {
                            render: <UseToast title={title} msgs={msgs} minimize={false} />,
                            position: toast.POSITION.BOTTOM_RIGHT,
                            closeButton: true,
                            icon: false,
                            className: "use-toast",
                        });
                    }
                    dispatch(
                        setToastData({
                            id: toastId,
                            filesUploading: filesUploading,
                            size: actionData.values.files.length,
                        })
                    );
                }, 500);
            }
            if (executeSigleMutation) {
                executeMutation();
                dispatch(showGlobalLoading(true));
            }
        } else if (actionData.actionName === "media-library-update-video-local") {
            executeMutation();
            dispatch(showGlobalLoading(true));
        } else if (actionType === "room-preview") {
            const deviceSelected = inputData.devices.find((device) => {
                return device.name === actionData.values.device;
            });
            window.open("https://" + deviceSelected.previewURL);
            hideModal();
        } else if (actionData.actionName === "apply-design") {
            executeMutation();
            dispatch(setModalContent(false));
            dispatch(useGlobalLoading ? showGlobalLoading(true) : setLoading(true));
            hideModal();
        } else if (actionType === "edit-screen-filters") {
            const numPreviousFilters = actionData.values.numPreviousFilters;
            let numCurrentFilters = 0;
            let launchConfirmModal = false;
            for (let index = 0; index < 10; index++) {
                if (actionData.values[`filter_${index}`] && actionData.values[`filter_${index}`][0]) {
                    numCurrentFilters++;
                    if (
                        actionData.values[`filter_${index}_ids`] &&
                        actionData.values[`filter_${index}_ids`].length > 0
                    ) {
                        numCurrentFilters--;
                        numCurrentFilters += actionData.values[`filter_${index}_ids`].length;
                    }
                }
            }
            if (cScreenData?.landing?.isEnabled && numCurrentFilters <= 0 && numPreviousFilters > 0) {
                launchConfirmModal = true;
            }
            if (launchConfirmModal) {
                dispatch(setModalContent(false));
                dispatch(cleanAction());
                hideModal();
                const confirmScreenModalContent = {
                    id: id,
                    useGlobalLoading: true,
                    executeSectionQuery: false,
                    bodyAdjust: "w-4/12",
                    // twoColumns: true,
                    noCapitalizeTitle: true,
                    title: "delete-filters",
                    value: actionData.values.screenname,
                    text: capitalizeFirst(langStrings["delete-filters-confirm"]),
                    inputs: [],
                    buttons: [
                        { name: "cancel", style: "white", action: "close" },
                        {
                            name: "delete-filters",
                            style: "red",
                            action: "unpublish-content-filter",
                        },
                    ],
                };
                dispatch(
                    changeGlobalAction({
                        actionName: "unpublish-content-filter",
                        values: actionData.values,
                    })
                );
                dispatch(setModalContent(confirmScreenModalContent));
                dispatch(showGlobalLoading(false));
                dispatch(openModal());
            } else {
                executeMutation();
            }
        } else if (actionType === "unpublish-content-filter") {
            executeMutation();
        } else if (actionType === "return-selected-asset") {
            dispatch(setActionName("update-channel"));
            hideModal();
        }
    };

    //Calendar input
    const handleDayClick = (day, modifiers) => {
        if (!modifiers?.disabled) {
            setSelectedDay(day);
        }
    };

    //Listeners
    useEffect(() => {
        if (data && validateMutationSuccess(data)) {
            if (actionData.actionName !== "delete-schedule" && actionData.actionName !== "unpublish-content-filter") {
                dispatch(setRefreshData(true));
                if (useGlobalLoading) {
                    dispatch(setRefreshContentData(true));
                    dispatch(showGlobalLoading(false));
                }
                //dont show success toast when running-task actions in chromecast
                if (
                    ![
                        "chromecast-unassign",
                        "chromecast-assign",
                        "chromecast-factory-reset",
                        "chromecast-disable",
                        "chromecast-enable",
                        "chromecast-reboot",
                        "chromecast-pairing-remote-control",
                        "chromecast-launch-settings-screen",
                    ].includes(actionData.actionName)
                ) {
                    if (["add-media-library-media", "add-library-font"].includes(actionData.actionName)) {
                        dispatch(setRefreshData(true));
                        dispatch(showGlobalLoading(false));
                        toast.success(
                            <UseToast
                                title={t("success")}
                                msgs={[
                                    {
                                        text: t("operation-successful"),
                                        textAdjust: "inline-grid w-10/12 t-link font-bold",
                                        icon: {
                                            name: "save-circle",
                                            values: "ok",
                                        },
                                        iconAdjust: "inline-grid w-1/12",
                                    },
                                ]}
                            />,
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 50000,
                                hideProgressBar: true,
                                icon: false,
                                className: "use-toast",
                            }
                        );
                    } else if (!preventSuccessToast) {
                        toast.success(t("operation-successful"));
                    }
                } else {
                    if (actionData && actionData.values && actionData.values.mac) {
                        dispatch(addToastInfoItem(actionData.values.mac));
                        const message =
                            (actionData.values["cc-name"] ? actionData.values["cc-name"] : t("device")) +
                            " " +
                            t(actionData.actionName.replace("chromecast-", "task-"));
                        toast.info(<InfoToast msg={message} />, {
                            autoClose: 10000,
                        });
                        setTimeout(() => {
                            dispatch(removeToastInfoItem(actionData.values.mac));
                        }, 60000);
                    }
                }

                if (actionData.actionName === areaMoveCat) {
                    dispatch(setParametersSection(actionData.values["area-move-category-id"]));
                    history.push(`/common-zones/zones-configuration/areas`);
                }

                dispatch(setModalContent(false));
                dispatch(setAllChecked(false));
                dispatch(cleanCheckedItems());
                hideModal();
                if (!preventCleanOnClose) {
                    dispatch(cleanAction());
                }

                removeApolloCacheKeys(client.cache, getApollokeyToDelete(actionData.actionName));

                if (actionData?.actionName === "staff-delete") {
                    removeApolloCacheKeys(client.cache, "all");
                }
                if (
                    actionData.actionName === "new-guest" ||
                    actionData.actionName === "update-language" ||
                    actionData.actionName === "apply-design"
                ) {
                    dispatch(setPage(1));
                    dispatch(showGlobalLoading(false));
                }

                if (actionData.actionName === "change-password") {
                    Session.close();
                    setTimeout(function () {
                        history.go(0);
                        history.push("/login");
                    }, 1000);
                }
                if (
                    actionData.actionName === "media-library-delete-permanently" ||
                    actionData.actionName === "video-library-delete-permanently" ||
                    actionData.actionName === "font-library-delete-permanently" ||
                    actionData.actionName === "media-library-move-content"
                ) {
                    dispatch(setReload(true));
                }
                if (
                    actionData.actionName === "add-media-library-media" &&
                    actionData.values["dataMediaLibraryBrowser"] &&
                    actionData.values["dataMediaLibraryBrowser"] !== ""
                ) {
                    const modalBrowser = BrowserMediaLibraryModal(t, actionData.values["dataMediaLibraryBrowser"]);

                    if (
                        actionData.values["dataMediaLibraryBrowser"].action &&
                        actionData.values["dataMediaLibraryBrowser"].action === "widget-actions-media"
                    ) {
                        dispatch(
                            changeGlobalAction({
                                actionName: "widget-actions-media",
                            })
                        );
                    } else {
                        dispatch(
                            changeGlobalAction({
                                actionName: "browser-media-library",
                            })
                        );
                    }

                    dispatch(setModalContent(modalBrowser));
                    dispatch(showGlobalLoading(false));
                    dispatch(openModal());
                } else if (
                    (actionData && actionData.actionName === "edit-mac-exception") ||
                    (actionData && actionData.actionName === "add-new-mac-exception") ||
                    (actionData && actionData.actionName === "delete-mac-exception")
                ) {
                    const macModalContent = MacExceptions({ id: actionData.values["mac-hotspot-name"] });
                    dispatch(setModalContent(macModalContent[0]));
                    dispatch(openModal());
                }
            } else if (actionData.actionName === "unpublish-content-filter") {
                if (useGlobalLoading) {
                    dispatch(setRefreshContentData(true));
                    dispatch(showGlobalLoading(false));
                }
                dispatch(setModalContent(false));
                dispatch(
                    changeGlobalAction({
                        actionName: "delete-screen-filter",
                        values: actionData.values,
                    })
                );
                setTimeout(function () {
                    executeMutation();
                }, 1000);
            } else {
                if (eventTypeActive === "punctual-event") {
                    dispatch(deletePunctualView(Number(actionData.itemsAffected[0])));
                } else {
                    const newPeriodicEvents = periodicEventsData.currentData.filter((event) => event.ID !== id);
                    let newSectionContent = periodicEventsData;
                    newSectionContent.currentData = newPeriodicEvents;
                    dispatch(refreshPeriodicSchedule(newSectionContent));
                }
                dispatch(cleanAction());
                hideModal();
                toast.success(t("schedule-delete-successful"));
            }
        } else if (data && validateMutationErrors(data)) {
            dispatch(setLoading(false));
            dispatch(showGlobalLoading(false));
            let textLabel = t("error-mutation");
            let err = data[Object.keys(data)[0]].error;

            if (
                actionData &&
                (actionData.actionName === "add-media-library-media" ||
                    actionData.actionName === "media-library-update-media" ||
                    actionData.actionName === "media-library-update-video" ||
                    actionData.actionName === "media-library-update-video-local" ||
                    actionData.actionName === "add-library-font")
            ) {
                switch (actionData.actionName) {
                    case "add-library-font":
                    case "add-media-library-media":
                        textLabel = t("file x created error", { name: actionData.values["refName"] });
                        dispatch(cleanAction());
                        if (
                            actionData.values["dataMediaLibraryBrowser"] &&
                            actionData.values["dataMediaLibraryBrowser"] !== ""
                        ) {
                            const modalBrowser = BrowserMediaLibraryModal(
                                t,
                                actionData.values["dataMediaLibraryBrowser"]
                            );
                            dispatch(
                                changeGlobalAction({
                                    actionName: "browser-media-library",
                                })
                            );
                            dispatch(setModalContent(modalBrowser));
                            dispatch(showGlobalLoading(false));
                            dispatch(openModal());
                        }
                        break;
                    case "media-library-update-media":
                    case "media-library-update-video":
                    case "media-library-update-video-local":
                        textLabel = t("file x updated error", { name: actionData.values["refName"] });
                        dispatch(cleanAction());
                        dispatch(setModalContent(false));
                        hideModal();
                        break;
                    default:
                        break;
                }
                if (
                    err.includes("already exists") ||
                    err.includes("name not allowed") ||
                    err.includes("extension must be") ||
                    err.includes("name is empty") ||
                    err.includes("before uploading new files") ||
                    err.includes("before set the asset as") ||
                    err.includes("unknown extension") ||
                    err.includes("content type should be")
                ) {
                    textLabel = t("general:" + err);
                }
                toast.error(
                    <UseToast
                        title={t("error")}
                        msgs={[
                            {
                                text: textLabel,
                                textAdjust: "inline-grid w-10/12 t-link font-bold",
                                icon: {
                                    name: "delete-circle",
                                    values: "ok",
                                },
                                iconAdjust: "inline-grid w-1/12",
                            },
                        ]}
                    />,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 50000,
                        hideProgressBar: true,
                        icon: false,
                        className: "use-toast",
                    }
                );
            } else if (err && typeof err === "object" && err.code) {
                if (err.code === "Conflict") {
                    toast.error(t("inuse-cannot-delete"));
                } else {
                    toast.error(err.msg);
                }
            } else {
                toast.error(textLabel);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (text) {
            //TODO quitar esto
            setFullText(arrangeFullText({ text, t, noTranslateText }));
        }
        // eslint-disable-next-line
    }, [text]);

    useEffect(() => {
        if (lang) {
            setCalendarLang(lang);
        }
    }, [lang]);

    useEffect(() => {
        return () => {
            dispatch(cleanAction());
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {text && fullText ? (
                <div className={`first-capital break-words ${textAdjust} mt-4`}>
                    {Array.isArray(fullText) ? (
                        <>
                            {fullText.map((ftext, index) => (
                                <div className="block first-capital whitespace-pre-wrap" key={ftext}>
                                    {Parser(ftext)}
                                </div>
                            ))}
                        </>
                    ) : (
                        Parser(fullText)
                    )}
                </div>
            ) : null}
            <div className={twoColumns ? "grid grid-cols-2 gap-4 mt-2" : "mt-4"}>
                {inputs
                    ? inputs.map((input, index) =>
                          input.type === "hidden" ? null : (
                              <div
                                  key={JSON.stringify(input)}
                                  className={`${arrangeMainClassName({ input })} ${twoColumns ? "pt-0 pb-0" : ""}`}
                              >
                                  {renderInput({
                                      input,
                                      index,
                                      handleDayClick,
                                      selectedDay,
                                      dateFilter,
                                      calendarLang,
                                      inputData,
                                      id,
                                  })}
                              </div>
                          )
                      )
                    : null}
            </div>
            {schedule ? (
                <div style={{ paddingLeft: "8px" }}>
                    {Array.isArray(schedule) ? (
                        schedule.map((schedul, index) => (
                            <div key={schedule} className="w-full block">
                                {schedul}
                            </div>
                        ))
                    ) : (
                        <div className="w-full block">{schedule}</div>
                    )}
                </div>
            ) : null}

            {/* Modal footer content*/}
            {buttons ? (
                <div id="modal-buttons" className={`pt-8 text-center flex ${buttonsStyle} justify-${buttonLayout}`}>
                    {buttons.map((button, index) => {
                        const isDisabled = button.disabledFunc ? button.disabledFunc() : button.disabled;
                        const onClick = (e) => {
                            if (button?.onClick && typeof button?.onClick === "function") {
                                button.onClick(e);
                            }
                            handleClick(e);
                        };
                        return (
                            <div
                                onClick={(e) => (isDisabled ? e.stopPropagation() : onClick(e))}
                                className={index > 0 ? "ml-4" : ""}
                                data-action={button.action}
                                id={`${button.name}-modal-button`}
                                data-checkenabled={button.checkEnabled}
                                // data-callback={button.actionCallback}
                                key={`${button.action}-modal-button`}
                            >
                                <UseButton
                                    buttonName={button.name}
                                    buttonColor={
                                        !button.checkEnabled ||
                                        (button.checkEnabled && actionData.values[button.checkEnabled])
                                            ? `btn-${button.style}`
                                            : "btn-gray"
                                    }
                                    link={button.link}
                                    id={`modal-button-${button.name}`}
                                    disabled={isDisabled}
                                    action={button.actionCallback ? button.actionCallback : button.action}
                                    customClass={button.customClass}
                                />
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </>
    );
};

const renderInput = (props) => {
    const { input, index, handleDayClick, selectedDay, dateFilter, calendarLang, inputData, id } = props;
    let response = null;
    if (input && input.type) {
        switch (input.type) {
            case "component":
                response = input.component;
                break;
            case "radio":
                response = <UseModalRadios index={index} />;
                break;
            case "select":
                response = <UseModalSelect index={index} />;
                break;
            case "multipleInput":
                response = <UseMultipleInput index={index} />;
                break;
            case "text":
                response = <UseModalText index={index} />;
                break;
            case "password":
                response = <UseModalPasword index={index} />;
                break;
            case "htmltext":
                response = <UseModalHtmlText index={index} />;
                break;
            case "releaseNotes":
                response = <UseModalReleaseNotes index={index} />;
                break;
            case "batchSelection":
                response = <UseBatchSelection index={index} />;
                break;
            case "batchSelectionTable":
                response = <UseBatchSelectionTable index={index} />;
                break;
            case "newProduct":
                response = <UseNewProduct index={index} />;
                break;
            case "checkbox":
                response = <UseModalCheckbox index={index} />;
                break;
            case "automationOfGuestReport":
                response = <UseModalAutomationOfGuestReport index={index} />;
                break;
            case "switch":
                response = <UseModalSwitch index={index} />;
                break;
            case "number":
                response = <UseModalNumber index={index} />;
                break;
            case "add-edit-staff-user":
                response = <UseModalAddEditStaffUser index={index} />;
                break;
            case "modal-informative-list":
                response = <UseModalInformativeList index={index} />;
                break;
            case "text-area":
                response = <UseModalTextArea index={index} />;
                break;
            case "void":
                response = <UsePlainText index={index} />;
                break;
            case "checkout":
                response = <UseModalCheckout index={index} />;
                break;
            case "translations":
                response = <UseTranslationInputs index={index} />;
                break;
            case "selectWithSearch":
                response = <UseInputSelectSearch index={index} />;
                break;
            case "previewChannel":
                response = <UseModalPreviewChannel index={index} />;
                break;
            case "addCorporateChannel":
                response = <UseModalAddCorporateChannel index={index} />;
                break;
            case "channelUseInProperties":
                response = <UseModalChannelUsedInProperties index={index} />;
                break;
            case "editCompanion":
                response = <UseModalEditCompanion index={index} />;
                break;
            case "editDocumentation":
                response = <UseModalEditDocumentation index={index} />;
                break;
            case "duplicateContent":
                response = <UseDuplicateContent index={index} />;
                break;
            case "newPasscode":
                response = <UseModalNewPasscode index={index} />;
                break;
            case "selectImage":
                response = <UseSelectImage index={index} />;
                break;
            case "selectImageIcon":
                response = <UseSelectImageIcon index={index} />;
                break;
            case "selectMultipleWithSearch":
                response = <UseSelectMultipleWithSearch index={index} />;
                break;
            case "roomOrdersModal":
                response = <UseModalRoomOrders index={index} />;
                break;
            case "date":
                response = (
                    <div className="inline-block">
                        <UseInputDateHour index={index} data={input} />
                    </div>
                );
                break;
            case "selectedResume":
                response = <UseSelectedResume index={index} />;
                break;
            case "itemsResume":
                response = <UseModalItemsResume index={index} />;
                break;
            case "dvbImportResume":
                response = <UseModalDvbImportResume index={index} />;
                break;
            case "selectedView":
                response = <UseModalSelectedView index={index} />;
                break;
            case "destinations":
                response = (
                    <UseModalDestinations
                        index={index}
                        occupiedRooms={input.occupiedRooms}
                        isFilterDestination={input?.isFilterDestinations}
                    />
                );
                break;
            case "filters-results":
                response = <UseModalFiltersResults index={index} />;
                break;
            case "templates":
                response = <UseModalTemplates index={index} />;
                break;
            case "resendCode":
                response = <UseResendCode index={index} />;
                break;
            case "disabledText":
                response = (
                    <>
                        <UseLabel label={input.label} translatable={true} />
                        <UsePlainText index={index} />
                    </>
                );
                break;
            case "file":
                response = <UseModalFile index={index} />;
                break;
            case "channelsFile":
                response = <UseModalImportChannels index={index} />;
                break;
            case "browseMediaLibrary":
                response = <UseModalBrowserMediaLibrary index={index} />;
                break;
            case "gridChannelsFilters":
                response = <UseModalGridChannelsFilters index={index} />;
                break;
            case "addChannelToGroupChannel":
                response = <UseModalAddChannelToGroup index={index} />;
                break;
            case "edit-custom-field":
                response = <UseModalEditCustomField index={index} />;
                break;
            case "tableMacs":
                response = <PortalMac index={index} />;
                break;
            case "newCategory":
                response = <UseModalNewCategory index={index} />;
                break;
            case "date-specific":
                response = (
                    <div className="w-full text-center">
                        <DayPicker
                            showOutsideDays
                            firstDayOfWeek={1}
                            className="Selectable"
                            navbarElement={<UseCalendarNavbar />}
                            onDayClick={(day, modifiers) => handleDayClick(day, modifiers)}
                            disabledDays={input.closingDays}
                            selectedDays={selectedDay ? selectedDay : dateFilter}
                            localeUtils={MomentLocaleUtils}
                            locale={calendarLang}
                        />
                    </div>
                );
                break;
            case "logo":
                response = (
                    <div className="w-full text-center">
                        <UseModalLogo index={index} />
                    </div>
                );
                break;
            case "designsTree":
                response = <UseModalDesignsTree index={index} />;
                break;
            case "newAlarm":
                response = <UseModalNewAlarm index={index} />;
                break;
            case "editAlarm":
                const getInfoAlarms = (data) => {
                    let response = null;
                    if (data && data.value) {
                        if (data.value.alarms) {
                            response = data.value.alarms;
                        } else if (Array.isArray(data.value)) {
                            response = data.value;
                        } else {
                            response = [data.value];
                        }
                    }
                    return response;
                };
                response = (
                    <UseModalEditAlarm
                        index={index}
                        infoAlarms={getInfoAlarms(inputData)}
                        filterRoom={
                            inputData && inputData.value && inputData.value.filterRoom
                                ? inputData.value.filterRoom
                                : null
                        }
                    />
                );
                break;
            case "tax-zone-associated-vat":
                response = <UseModalTaxZoneAssociatedTaxRates index={index} />;
                break;
            case "actionsDestinations":
                response = (
                    <Actions
                        index={index}
                        actions={{
                            landing: true,
                            content: true,
                            section: true,
                            url: false, //TODO Disabled untill full development
                            library: true,
                            tvchannel: true,
                        }}
                        type={"actions-destinations"}
                        modalContentId={id}
                        modalActions={true}
                    />
                );
                break;
            case "actionsSliderDestinations":
                response = (
                    <Actions
                        index={index}
                        actions={{
                            content: true,
                            section: true,
                            url: true,
                            library: true,
                            tvchannel: true,
                        }}
                        type={"actions-slider-destinations"}
                        modalContentId={id}
                        modalActions={true}
                    />
                );
                break;
            case "allergens-translate":
                response = <UseModalSalesAllergensTranslate index={index} />;
                break;
            case "newShop":
                response = <UseModalNewShop index={index} />;
                break;
            case "tax-zone-in-use":
                response = <UseModalTaxInUse index={index} />;
                break;
            case "users-assigned":
                response = <UseModalUsersAssigned index={index} />;
                break;
            case "editEmailOrders":
                response = <UseModalEditEmailOrders index={index} />;
                break;
            case "editNameCategory":
                response = <UseModalEditNameCategory index={index} />;
                break;
            case "editNameShop":
                response = <UseModalEditNameShop index={index} />;
                break;
            case "videoDetails":
                response = <UseModalVideoDetails index={index} />;
                break;
            case "addGroupChannelToGroup":
                response = <UseModalAddGroupChannelToGrid index={index} />;
                break;
            case "general-translations":
                response = <UseModalTranslations index={index} />;
                break;
            case "assign-device":
                response = <UseModalAssignDevice index={index} />;
                break;
            case "editName":
                response = <UseModalEditName index={index} />;
                break;
            case "product-add-extra":
                response = <UseModalProductAddExtra index={index} />;
                break;
            case "changeDeviceFunction":
                response = <UseModalChangeDeviceFunction index={index} />;
                break;
            case "assignedTvs":
                response = <UseModalAssignedTvs index={index} />;
                break;
            case "assignedTvsManagement":
                response = <UseModalAssignedTvsManagement index={index} />;
                break;
            case "inputGroupDetail":
                response = <UseInputGroupDetail index={index} />;
                break;
            case "inputGroupDetailWithSelection":
                response = <UseInputGroupDetail index={index} withSelect={true} />;
                break;
            case "inputsManagement":
                response = <UseModalInputsManagement index={index} />;
                break;
            case "add-channel":
                response = <UseModalAddChannel index={index} />;
                break;
            case "addMobileApp":
                response = <UseModalAddMobileApp />;
                break;
            case "deleteMobileApp":
                response = <UseModalDeleteMobileApp />;
                break;
            case "translateTextsTabs":
                response = <UseModalTranslateTextsTabs />;
                break;
            case "newBooking":
                response = <UseModalAddBooking index={index} />;
                break;
            case "checkIn":
                response = <UseModalCheckin></UseModalCheckin>;
                break;
            case "deleteBatchChannelsDetail":
                response = <UseDeleteBatchChannelsDetail index={index} />;
                break;
            case "deleteBatchGridDetail":
                response = <UseDeleteBatchGridDetail index={index} />;
                break;
            case "editGuestInformation":
                response = (
                    <UseModalEditGuestInformation
                        guestInfo={input?.inputData}
                        customFields={input?.customFields}
                    ></UseModalEditGuestInformation>
                );
                break;
            case "guestAdditionalInformation":
                response = <UseModalAdditionalInformation guestInfo={input?.inputData}></UseModalAdditionalInformation>;
                break;
            case "guestDataReport":
                response = <UseModalGuestReport></UseModalGuestReport>;
                break;
            case "dateRange":
                response = <UseModalDateRange></UseModalDateRange>;
                break;
        }
    }
    return response;
};

const getApollokeyToDelete = (actionName) => {
    let key = null;
    switch (actionName) {
        case "edit-text":
            key = "all";
            break;
        case "area-erase":
            key = "cz_categories";
            break;
        case "delete-guest-group":
        case "edit-guest-group":
        case "new-guest-group":
        case "change-room":
        case "new-guest":
        case "add-room":
        case "checkout":
        case "batch-checkout":
        case "full-checkout":
        case "assign-to-group":
        case "batch-assign-to-group":
            key = ["guests", "installationMap", "rooms", "hotelRooms", "stays"];
            break;
        case "update-language":
        case "delete-language":
        case "delete-delete-language":
            key = "projectLangs";
            break;
        case "font-library-delete-permanently":
            key = "Font";
            break;
        case "add-media-library-media":
        case "add-library-font":
        case "media-library-delete-permanently":
        case "media-library-move-content":
            key = "libraryContents";
            break;
        case "video-library-delete-permanently":
            key = "LibraryVideo";
            break;
        case "delete-property-group":
        case "edit-property-group":
        case "new-property-group":
        case "duplicate-property-group":
            key = "brands";
            break;
        case "edit-room-group":
        case "batch-edit-room-group":
            key = ["rooms", "installationMap", "hotelRooms", "roomGroups"];
            break;
        case "area-move-category":
            key = "cz_categories";
            break;
        case "chromecast-unassign":
        case "chromecast-enable":
        case "chromecast-disable":
        case "chromecast-change-pairing":
        case "chromecast-reboot":
        case "chromecast-factory-reset":
        case "chromecast-authorize":
        case "chromecast-unauthorize":
        case "chromecast-hide":
            // case "chromecast-pairing-remote-control":
            // case "chromecast-launch-settings-screen":
            key = "chromecasts";
            break;
        case "apply-design":
            key = "designs";
            break;
        case "device-edit-tv-inputs":
            key = ["devices", "tvInputs"];
            break;
        default:
            break;
    }
    return key;
};

const validateMutationErrors = (data) => {
    //Returns true if mutation error
    return data[Object.keys(data)[0]] && data[Object.keys(data)[0]].error !== "";
};

const validateMutationSuccess = (data) => {
    //Returns true if mutation success
    return (
        data[Object.keys(data)[0]] &&
        (data[Object.keys(data)[0]].ok || data[Object.keys(data)[0]].okUpdate || data[Object.keys(data)[0]].okBooking)
    );
};

const isDirectExecute = (actionType, actionData) => {
    //Returns true if action must execute mutation directly
    let res = false;
    if (
        actionType === "delete-pre-check-ins" ||
        actionType === "delete" ||
        actionType === "new-guest" ||
        actionType === "full-checkout" ||
        actionType === "checkout" ||
        actionType === "batch-checkout" ||
        actionType === "add-guest-group" ||
        actionType === "assign-to-group" ||
        actionType === "batch-assign-to-group" ||
        actionType === "delete-companion" ||
        actionType === "add-room" ||
        actionType === "change-room" ||
        actionType === "change-password" ||
        actionType === "generate-roomcode" ||
        actionType === "staff-generate-password" ||
        actionType === "email-booking-notification" ||
        actionType === "add-property-group" ||
        actionType === "edit-property-group" ||
        actionType === "duplicate-property-group" ||
        actionType === "delete-property-group" ||
        actionType === "edit-property" ||
        actionType === "update-mobile-appearance" ||
        actionData.actionName === "area-temp-close" ||
        actionData.actionName === "resend-pre-check-in" ||
        actionData.actionName === "edit-temp-closed" ||
        actionData.actionName === "booking-modify" ||
        actionData.actionName === "refresh-cleaning" ||
        actionData.actionName === "delete-guest-group" ||
        actionData.actionName === "media-library-delete-permanently" ||
        actionData.actionName === "video-library-delete-permanently" ||
        actionData.actionName === "font-library-delete-permanently" ||
        actionData.actionName === "media-library-move-content" ||
        actionData.actionName === "media-library-download" ||
        actionData.actionName === "edit-text" ||
        actionData.actionName === "edit-message-destinations" ||
        actionData.actionName === "area-open"
    ) {
        res = true;
    }
    return res;
};

const arrangeFullText = (props) => {
    const { text, t, noTranslateText } = props;
    if (Array.isArray(text)) {
        return text.map((word) => (noTranslateText ? word : t(word))).join(" ");
    } else if (!noTranslateText && t(text) !== "") {
        return t(text);
    } else {
        return text;
    }
};

const arrangeMainClassName = (props) => {
    const { input } = props;
    let response = "";
    if (input.style) {
        response = input.style;
    } else if (input.type !== "data") {
        response = "pb-5";
    }
    return response;
};

export const isCommentOk = (actionData) => {
    //Returns true if comment is not valid
    let res = false;
    if (actionData.values["cancel-booking-comment"] && actionData.values["cancel-booking-comment"] !== "") {
        res = true;
    }
    return res;
};

export const isDescriptionError = (actionData, editAreaDesc) => {
    //Returns true if description is not valid
    let res = false;
    if (!actionData.values[editAreaDesc] || actionData.values[editAreaDesc] === "") {
        res = true;
    }
    return res;
};

export const needReload = (actionName) => {
    //Returns true if data needs window reload
    let res;
    if (actionName !== "booking-modify" && actionName !== "cancel-booking" && actionName !== "delete-schedule") {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export default withApollo(ModalContent);
