import React, { useEffect, useState } from "react";
import { dateBeforNow } from "hooks/Utils/Validations";
import { capitalizeFirst, joinFormDateTime } from "hooks/Utils/Utils";
import { setRefreshData, setFolder } from "actions/tableActions";
import Modal, { useModal } from "components/Modal";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import Loading from "components/Loading";
import Button from "components/Button";
import Toggle from "components/Inputs/Toggle";
import { DateInput } from "components/Inputs/DateInput";
import UseInputDateHour from "components/useInputDateHour";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import { PERIODIC_SEND, SCHEDULED_MSG, SPECIFIC_DAY } from "hooks/Utils/Customers/MessagesUtils";
import { Session } from "hooks/Utils/Session";

const ModalScheduleMessages = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { values } = useSelector((state) => state.action);
    let expirationDate = new Date(values["expiration-date"]);
    expirationDate.setDate(expirationDate.getDate() + 1);
    const hasExpirationDate = values["expiration-date"]
        ? [{ after: expirationDate }, { before: new Date() }]
        : [{ before: new Date() }];
    const [checkSpecificDay, setCheckSpecificDay] = useState(props.scheduleType === PERIODIC_SEND ? false : true);
    const [specificDayDate, setSpecificDayDate] = useState("");
    const [specificDayHour, setSpecificDayHour] = useState("");

    const [weekly, setWeekly] = useState({
        monday: { value: 1, status: false },
        tuesday: { value: 2, status: false },
        wednesday: { value: 3, status: false },
        thursday: { value: 4, status: false },
        friday: { value: 5, status: false },
        saturday: { value: 6, status: false },
        sunday: { value: 0, status: false },
    });
    const [weeklyHour, setWeeklyHour] = useState("");
    const [validation, setValidations] = useState({
        specificDayDate: true,
        specificDayHour: true,
        weekly: true,
        weeklyHour: true,
    });

    const formatScheduleDate = () => {
        if (specificDayDate && specificDayHour) {
            let finalDate = specificDayDate;
            let time = specificDayHour.split(":");
            finalDate.setHours(time[0]);
            finalDate.setMinutes(time[1]);
            return finalDate.toISOString();
        } else {
            return "";
        }
    };
    const MUTATION_SCHEDULE_MESSAGE = gql`
        mutation {
            updateMessage(
                sendMessage: false
                id: ${props.id}
                scheduleType: ${checkSpecificDay ? SPECIFIC_DAY : PERIODIC_SEND}
                ${
                    checkSpecificDay
                        ? ` scheduleDate: "${formatScheduleDate()}"`
                        : `
                            scheduleDaysOfWeek: [${Object.values(weekly)
                                .filter((val) => val.status)
                                .map((val) => {
                                    return val.value;
                                })}]
                            scheduleTime: "${weeklyHour}"`
                }
            ) {
                error
                id
                ok
            }
        }
    `;
    const [executeMutation, { data }] = useMutation(MUTATION_SCHEDULE_MESSAGE, {
        onCompleted() {
            dispatch(setRefreshData(true));
            if (!props.edit) {
                Session.setSessionProp("list-messages_folder", SCHEDULED_MSG);
                props.navigate(`/customers/messages/list`);
            }
            props.close();
        },
        onError() {
            toast.error(t("mutation-error"));
        },
    });

    const handleSaveClick = () => {
        let execute = true;
        if (!checkSpecificDay) {
            if (
                !Object.values(weekly)
                    .map((val) => {
                        return val.status;
                    })
                    .includes(true)
            ) {
                setValidations((val) => {
                    return { ...val, weekly: false };
                });
                execute = false;
            } else {
                setValidations((val) => {
                    return { ...val, weekly: true };
                });
            }
            if (!weeklyHour) {
                setValidations((val) => {
                    return { ...val, weeklyHour: false };
                });
                execute = false;
            } else {
                setValidations((val) => {
                    return { ...val, weeklyHour: true };
                });
            }
        } else {
            if (!specificDayDate || !validation.specificDayDate) {
                setValidations((val) => {
                    return { ...val, specificDayDate: false };
                });
                execute = false;
            } else {
                setValidations((val) => {
                    return { ...val, specificDayDate: true };
                });
            }

            if (!specificDayHour || !validation.specificDayHour) {
                setValidations((val) => {
                    return { ...val, specificDayHour: false };
                });
                execute = false;
            } else {
                setValidations((val) => {
                    return { ...val, specificDayHour: true };
                });
            }
        }
        if (execute) {
            setLoading(true);
            executeMutation();
        } else {
            toast.error(t("errors-in-red"));
        }
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    useEffect(() => {
        if (dateBeforNow(joinFormDateTime(specificDayDate, specificDayHour))) {
            setValidations((val) => {
                return { ...val, specificDayDate: false, specificDayHour: false };
            });
        } else {
            setValidations((val) => {
                return { ...val, specificDayDate: true, specificDayHour: true };
            });
        }
    }, [specificDayDate, specificDayHour]);

    useEffect(() => {
        if (props.scheduleDaysOfWeek) {
            setWeekly((val) => {
                let result = { ...val };
                Object.entries(val).forEach(([key, value]) => {
                    if (props.scheduleDaysOfWeek.includes(value.value)) {
                        result = { ...result, [key]: { ...value, status: true } };
                    }
                });
                return result;
            });
        }
        if (props.scheduleTime) {
            setWeeklyHour(props.scheduleTime);
        }
        if (props.scheduleDate) {
            const scheduleDate = new Date(props.scheduleDate);
            setSpecificDayDate(scheduleDate);
            setSpecificDayHour(`${scheduleDate.getHours()}:${scheduleDate.getMinutes()}`);
        }
    }, [props]);

    return (
        <Modal
            title={
                props.edit
                    ? `${capitalizeFirst(props.name)} - ${capitalizeFirst(t("edit-schedule"))}`
                    : `${capitalizeFirst(t("schedule-send"))}`
            }
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-save" onClick={handleSaveClick}>
                        {t("save")}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div className=" flex flex-col gap-2">
                    <Radiobutton
                        label={capitalizeFirst(t("specific-day"))}
                        checked={checkSpecificDay}
                        onChange={() => {
                            setCheckSpecificDay(true);
                        }}
                    />
                    {checkSpecificDay && (
                        <div className="flex gap-4">
                            <DateInput
                                placeholder={t("select-date")}
                                validation={!validation["specificDayDate"]}
                                value={specificDayDate}
                                customDisabledDays={hasExpirationDate}
                                tooltip={t("its-not-possible-to-select-after-expiration")}
                                onChange={(date, dateText) => {
                                    setSpecificDayDate(date);
                                    if (dateText.includes("_")) {
                                        setValidations((val) => {
                                            return { ...val, specificDayDate: false };
                                        });
                                    }
                                }}
                            ></DateInput>
                            <UseInputDateHour
                                data={{ name: "specificDayHour", hour: specificDayHour }}
                                justHour={true}
                                customHourValidation={!validation["specificDayHour"]}
                                setCustomHourValue={setSpecificDayHour}
                            ></UseInputDateHour>
                        </div>
                    )}
                    <Radiobutton
                        label={capitalizeFirst(t("periodic-send"))}
                        checked={!checkSpecificDay}
                        onChange={() => {
                            setCheckSpecificDay(false);
                        }}
                    />
                    {!checkSpecificDay && (
                        <>
                            <div
                                className={`flex ml-4 ${!validation["weekly"] ? " border border-red-100" : ""}`}
                                style={{ width: "fit-content" }}
                            >
                                {Object.keys(weekly).map((day, index) => {
                                    return (
                                        <Toggle
                                            on={
                                                <div className="cursor-pointer p-2 px-4 text-xl text-white bg-blue-300">
                                                    {capitalizeFirst(t(`day-${index + 1}-short`))}
                                                </div>
                                            }
                                            checked={weekly[day].status}
                                            off={
                                                <div className="cursor-pointer p-2 px-4 text-xl bg-gray-200 text-blue-300">
                                                    {capitalizeFirst(t(`day-${index + 1}-short`))}
                                                </div>
                                            }
                                            onChange={(check) => {
                                                setWeekly((val) => {
                                                    return { ...val, [day]: { ...val[day], status: check?.checked } };
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                            <UseInputDateHour
                                data={{ name: "weeklyHour", hour: weeklyHour }}
                                customHourValidation={!validation["weeklyHour"]}
                                setCustomHourValue={setWeeklyHour}
                                justHour={true}
                            ></UseInputDateHour>
                        </>
                    )}
                </div>
            )}
        </Modal>
    );
};

export const UseModalScheduleMessages = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalScheduleMessages {...newProps} />);
        },
    };
};
