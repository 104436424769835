import React from "react";
import Button from "components/Button";
import Modal, { useModal } from "components/Modal";
import { getDate, getHour } from "hooks/GraphqlCalls/Customers/utils/alarmsUtils";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const DeleteAlarm = ({
    deleteAlarm,
    setLoadingRefresh,
    refetch,
    stayguestName,
    stayguestSurname,
    startTime,
    groupingID,
}) => {
    const { t } = useTranslation();
    const { close } = useModal();
    return (
        <Modal
            id="delete-alarm"
            title={`${stayguestName} ${stayguestSurname} - ${t("delete-alarm")}`}
            className="w-1/3 px-12 py-10 overflow-hidden"
        >
            <main>
                <div
                    dangerouslySetInnerHTML={{
                        __html: t("alarm-delete-warnign", {
                            guest: `${stayguestName} ${stayguestSurname}`,
                            date: getDate(startTime),
                            time: getHour(startTime),
                        }),
                    }}
                ></div>
            </main>
            <footer className="flex justify-end gap-4 mt-12">
                <Button id="cancel" className="btn btn-white" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button
                    id="delete"
                    className="btn btn-red"
                    onClick={() => {
                        close();
                        deleteAlarm(groupingID);
                        refetch();
                        setLoadingRefresh(true);
                        setTimeout(() => {
                            setLoadingRefresh(false);
                        }, 1000);
                        toast.success(t("toast-alarm", { action: t("deleted") }));
                    }}
                >
                    {capitalizeFirst(t("delete"))}
                </Button>
            </footer>
        </Modal>
    );
};
