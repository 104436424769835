import { capitalizeFirst } from "../../../Utils/Utils";

/**
 * @param users {{id: string, name: string}}[]
 * @param t {(str: string)=>string}
 * @param data {{any}}
 * @returns {{buttons: [{name: string, action: string, style: string}], executeSectionQuery: boolean, inputs: [{data: (*|null), type: string}], text: string, title: *, value, bodyAdjust: string}}
 */
const UsersAssigned = (users, t, data) => {
    return {
        title: capitalizeFirst(t("assigned-users")),
        text: t("the-role-is-being-assigned-in-the-following-users-of-the-chain"),
        value: "",
        search: true,
        bodyAdjust: "w-4/12",
        executeSectionQuery: true,
        // id: taxZone.id,
        inputs: [{ type: "users-assigned", data: data ? data : null }],
        buttons: [{ name: "close", style: "blue", action: "close" }],
    };
};

export default UsersAssigned;
