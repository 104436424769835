import React, { useContext } from "react";
import UseEditableField from "components/useEditableField";
import { useTranslation } from "react-i18next";
import { GetNationalityName, GetNationalitiesOptions } from "hooks/Utils/NationalityUtils";
import { GetLanguageOptions } from "hooks/Utils/LanguagesUtils";
import { bdDateToDateInput } from "hooks/Utils/Utils";
import { useSelector } from "react-redux";
import UseCustomPhoneInput from "components/Inputs/UseCustomPhoneInput";
import { GENDERS } from "components/Section/Customers/Precheckin/ReservationDetails/utils";

import { GlobalContext } from "contexts/Global";

const prefixId = "modal-edit-guest-information";
export const UseModalEditGuestInformation = ({ guestInfo, customFields = [] }) => {
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const { groupsData } = useSelector((state) => state.sectionContent);
    const ensureValue = (value) => {
        return value ? value : "-";
    };
    let groups = "";
    let groupsIds = [];
    if (guestInfo && guestInfo.guestGroup) {
        guestInfo.guestGroup.map(
            (group) => (groups += groups ? ` , ${group.name}` : group.name) && groupsIds.push(String(group.id))
        );
    }
    const validateInfo = (data) => {
        return data ?? "-";
    };

    const additionalInformation = customFields
        .reduce((acc, curr) => {
            const title =
                curr.names.find((val) => val.lang == lang).name || curr.names.find((val) => val.name != "").name;
            const text = guestInfo.guestCustomFieldsInformation.find((val) => {
                return val.Ref === curr.ref;
            });
            acc.push({ title: validateInfo(title), text: validateInfo(text?.Value), order: curr.order, ref: curr.ref });
            return acc;
        }, [])
        .sort((a, b) => {
            return a.order - b.order;
        });
    return (
        <div className=" grid grid-cols-2 gap-8">
            <div className={`grid grid-cols-2 gap-x-4 gap-y-4 ${customFields?.length == 0 ? "col-span-2" : ""}`}>
                <p className=" col-span-2">{t("main-information")}</p>
                <UseEditableField
                    data={{
                        id: `${prefixId}-input-pms-id`,
                        label: "pms-id",
                        value: ensureValue(guestInfo.pmsID),
                        inEditionMode: true,
                        type: "text",
                        noEditionAdjust: "truncate",
                        tooltip: ensureValue(guestInfo.pmsID),
                    }}
                ></UseEditableField>
                <UseEditableField
                    data={{
                        id: `${prefixId}-input-title`,
                        label: "title",
                        value: ensureValue(guestInfo.guestTitle),
                        inEditionMode: true,
                        type: "text",
                        noEditionAdjust: "truncate",
                        tooltip: ensureValue(guestInfo.guestTitle),
                    }}
                ></UseEditableField>
                <UseEditableField
                    data={{
                        id: `${prefixId}-input-name`,
                        label: "name",
                        value: ensureValue(guestInfo.guestName),
                        inEditionMode: true,
                        required: true,
                        type: "text",
                        noEditionAdjust: "truncate",
                        tooltip: ensureValue(guestInfo.guestName),
                    }}
                ></UseEditableField>
                <UseEditableField
                    data={{
                        id: `${prefixId}-input-surname`,
                        label: "surname",
                        value: ensureValue(guestInfo.guestSurname),
                        inEditionMode: true,
                        required: true,
                        type: "text",
                        noEditionAdjust: "truncate",
                        tooltip: ensureValue(guestInfo.guestSurname),
                    }}
                ></UseEditableField>
                <UseEditableField
                    data={{
                        id: `${prefixId}-input-nationality`,
                        label: "nationality",
                        value: ensureValue(
                            guestInfo && guestInfo.guestCountry
                                ? GetNationalityName(guestInfo.guestCountry.toLowerCase())
                                : null
                        ),
                        inEditionMode: true,
                        type: "select-with-search",
                        oneSelected: true,
                        optionData: GetNationalitiesOptions(),
                        selectedIds: [guestInfo.guestCountry?.toUpperCase()],
                        tooltip: ensureValue(
                            guestInfo && guestInfo.guestCountry
                                ? GetNationalityName(guestInfo.guestCountry.toLowerCase())
                                : null
                        ),
                    }}
                ></UseEditableField>
                <UseEditableField
                    data={{
                        id: `${prefixId}-input-lang`,
                        label: "lang",
                        value: ensureValue(guestInfo.guestLang ? t("language:" + guestInfo.guestLang) : null),
                        inEditionMode: true,
                        type: "select-with-search",
                        oneSelected: true,
                        optionData: GetLanguageOptions(),
                        selectedIds: [guestInfo.guestLang],
                        tooltip: ensureValue(guestInfo.guestLang ? t("language:" + guestInfo.guestLang) : null),
                    }}
                ></UseEditableField>
                <UseEditableField
                    data={{
                        id: `${prefixId}-input-guest-gender`,
                        label: "gender",
                        value: ensureValue(guestInfo.guestGender),
                        inEditionMode: true,
                        type: "select-with-search",
                        oneSelected: true,
                        optionData: GENDERS,
                        selectedIds: [guestInfo.guestGender],
                        tooltip: ensureValue(groups),
                    }}
                ></UseEditableField>
                <UseEditableField
                    data={{
                        id: `${prefixId}-input-age`,
                        label: "age",
                        labelOnEdition: "birthdate",
                        value: bdDateToDateInput(guestInfo.guestBirthdate),
                        inEditionMode: true,
                        dateAdjust: "text-sm",
                        type: "date",
                    }}
                ></UseEditableField>

                <div className="relative">
                    <div className="fieldLabel">{t("phone")}</div>
                    <div>
                        <UseCustomPhoneInput
                            id={`${prefixId}-input-phone`}
                            actionValues={true}
                            value={guestInfo?.guestPhone ? guestInfo.guestPhone : null}
                        />
                    </div>
                </div>
                <UseEditableField
                    data={{
                        id: `${prefixId}-input-email`,
                        label: "email",
                        value: ensureValue(guestInfo.guestEmail),
                        inEditionMode: true,
                        type: "text",
                        noEditionAdjust: "truncate",
                        tooltip: ensureValue(guestInfo.guestEmail),
                    }}
                ></UseEditableField>
                <UseEditableField
                    data={{
                        id: `${prefixId}-input-guest-group`,
                        label: "guest-group",
                        value: ensureValue(groups),
                        inEditionMode: true,
                        type: "select-with-search",
                        optionData: groupsData,
                        selectedIds: groupsIds,
                        tooltip: ensureValue(groups),
                        useOptionID: false,
                    }}
                ></UseEditableField>
            </div>
            {customFields?.length > 0 && (
                <div>
                    <p className=" mb-4">{t("additional-information")}</p>
                    <div className="grid grid-cols-2 gap-4">
                        {additionalInformation.map((val, index) => {
                            return (
                                <UseEditableField
                                    data={{
                                        id: `${prefixId}-custom-input-${index}-id`,
                                        label: val.ref,
                                        labelOnEdition: val.title,
                                        value: ensureValue(val.text),
                                        inEditionMode: true,
                                        type: "text",
                                        noEditionAdjust: "truncate",
                                    }}
                                ></UseEditableField>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
