import React, { useRef, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { CSVLink } from "react-csv";

/**
 * This component is a button with actions
 * @param {JSX.Element} children The content of the button
 * @param {string} id The id of the button
 * @param {string} design The design of the button (blue, blue-outline, gray, dark-blue, red, white, link, classic-link)
 * @param {boolean} narrow The flag to make the button narrow (default: false)
 * @param {boolean} disabled The flag to disable the button
 * @param {boolean} readOnly The flag to make the button read-only
 * @param {function} onClick The function to call when the button is clicked
 * @param {string} href The link to redirect when the button is clicked
 * @param {string} to The link to redirect when the button is clicked
 * @param {string} tooltip The text of the tooltip
 * @param {string} tooltipType The type of the tooltip
 * @param {string} className The class name of the button
 * @returns {JSX.Element} The UI of the component
 * @example <Button id="button-id" onClick={handleClick}>Button</Button>
 */
const Button = forwardRef(
    (
        {
            children,
            id,
            action,
            actionData,
            design,
            narrow,
            disabled,
            readOnly,
            onClick,
            className,
            href,
            to,
            tooltip,
            tooltipType,
        },
        ref
    ) => {
        const buttonRef = useRef(null);

        if (!id) {
            throw new Error("The button must have an id");
        }
        if (onClick && typeof onClick !== "function") {
            throw new Error("The onClick prop must be a function");
        }
        if (href && typeof href !== "string") {
            throw new Error("The href prop must be a string");
        }

        useImperativeHandle(ref, () => ({
            focus: () => {
                if (buttonRef.current) {
                    buttonRef.current.focus();
                }
            },
            click: () => {
                if (buttonRef.current) {
                    buttonRef.current.click();
                }
            },
        }));

        const isSolid = design && !["link", "classic-link"].some((d) => d === design);

        const buttonClass = classNames({
            // Flex and spacing
            flex: true,
            "space-x-2": !className?.includes("space-x-"),
            "items-center": !className?.includes("items-"),
            "whitespace-no-wrap": design,

            // Blue
            "bg-blue-300 text-white hover:bg-blue-200 active:bg-blue-400": design === "blue" && !disabled,

            // Blue outline
            "bg-white text-blue-300 border border-blue-300 hover:border-blue-200 hover:bg-gray-200 active:bg-gray-300":
                design === "blue-outline" && !disabled,

            // Red
            "bg-red-100 text-white hover:bg-red-600 active:bg-red-800": design === "red" && !disabled,

            // Basic
            "text-black hover:text-gray-900": design === "basic" && !disabled,

            // Dark blue
            "bg-blue-800 text-white hover:bg-blue-700 active:bg-blue-900": design === "dark-blue" && !disabled,

            // White
            "bg-white text-blue-300 hover:border hover:border-gray-100": design === "white" && !disabled,

            // Disabled
            "bg-gray-300 text-white": disabled && ["red", "blue", "dark-blue"].some((d) => d === design),
            "border border-gray-400 bg-white text-gray-700":
                disabled && ["blue-outline", "basic", "white"].some((d) => d === design),

            // Links
            "font-bold": design === "link",
            "inline-block flex items-center justify-start": ["link", "classic-link"].some((d) => d === design),
            "text-zafiro-600 hover:text-zafiro-400 active:text-zafiro-800":
                ["link", "classic-link"].some((d) => d === design) && !disabled,
            "text-gray-700": ["link", "classic-link"].some((d) => d === design) && disabled,

            "hover:underline": design === "classic-link" && !disabled,
            underline: design === "classic-link" && disabled,

            // Solid button
            "rounded px-5 inline-block flex items-center justify-center": isSolid,
            "active:shadow-inner": design && isSolid && design !== "basic" && !disabled,

            // Cursor states
            "cursor-pointer": !disabled && !readOnly,
            "cursor-default": disabled || readOnly,

            // Custom className
            [className]: className,
        });

        const buttonStyle = {
            ...(isSolid
                ? {
                      letterSpacing: "0.005em",
                      paddingTop: "0.3933rem",
                      paddingBottom: "0.3933rem",
                  }
                : null),
            ...(isSolid && !narrow ? { minWidth: "8.858rem" } : null),
        };

        const buttonProps = {
            id,
            ref: buttonRef,
            className: buttonClass,
            style: buttonStyle,
            href: disabled || readOnly ? "#" : href,
            onClick: (...args) => {
                if (disabled || readOnly) {
                    return;
                }
                if (onClick) {
                    onClick(...args);
                }
            },
            ...(tooltip
                ? {
                      "data-tip": tooltip,
                      "data-for": tooltipType || "default-tooltip",
                  }
                : {}),
        };

        if (action === "csv") {
            return (
                <CSVLink {...buttonProps} {...actionData}>
                    {children}
                </CSVLink>
            );
        }

        if (to) {
            return (
                <NavLink {...buttonProps} to={to}>
                    {children}
                </NavLink>
            );
        }

        if (href) {
            return (
                <a {...buttonProps} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            );
        }

        return <button {...buttonProps}>{children}</button>;
    }
);
Button.displayName = "Button";

export default Button;
