import Table, { stringValue } from "../../ZafiroTable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useModal } from "components/Modal";
import Button from "components/Button";
import { Session } from "../../../hooks/Utils/Session";
import Select from "components/Select";
import { escapeSpecialChars } from "../../../hooks/Utils/Utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate as useHistory } from "react-router-dom";
import TextInput from "components/TextInput";

const accessesQuery = gql`
    {
        accesses {
            results {
                name
                category
            }
        }
    }
`;

const UsersAssignedModal = ({ role }) => {
    const { t } = useTranslation();
    const [searchInput, setSearchInput] = useState("");
    const isCorporate = Session.getSessionProp("projectRef") === "CORPORATE";
    const [filteredUsers, setFilteredUsers] = useState([]);
    const usersQuery = gql`
        query getusers($roles: [Int64]) {
            users(filter: { roles: $roles }) {
                results {
                    id
                    fullName
                    properties {
                        name
                        id
                    }
                    email
                }
            }
        }
    `;
    const {
        loading,
        error,
        data = { users: { results: [] } },
    } = useQuery(usersQuery, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        variables: { roles: [role.id] },
    });

    useEffect(() => {
        if (!loading && !error && data) {
            setFilteredUsers(
                data.users.results.filter((x) => x.fullName.startsWith(searchInput) || x.email.startsWith(searchInput))
            );
        }
    }, [data]);

    const handleSearchInput = (ev) => {
        setSearchInput(ev.target.value);
    };

    return (
        <div style={{ minWidth: "560px" }}>
            <div className="flex mb-4">
                <div className="w-1/2 flex">
                    <div className="w-full mr-8 relative">
                        <TextInput
                            required={true}
                            onChange={(value) => setSearchInput(value)}
                            placeholder={t("select-an-option")}
                        />
                        <span className="field-icon icon-search"></span>
                    </div>
                </div>
                <div className="w-1/2 mt-auto ml-auto block text-right">
                    {filteredUsers.length} {t("users")}
                </div>
            </div>

            <div
                style={{ minHeight: "21.45rem", maxHeight: "21.45rem", overflowY: "scroll" }}
                className=" border rounded border-gray-200"
            >
                {filteredUsers.map((user) => {
                    return (
                        <div key={user.fullName} className=" flex justify-between border-b mx-4 border-gray-200 py-4 ">
                            <span className="block">{user.email}</span>
                            {isCorporate ? (
                                <span
                                    className="block text-blue-600 font-bold"
                                    data-for={"default-tooltip"}
                                    data-tip={user.properties.map((x) => x.name).join("\n")}
                                >
                                    {user.properties.length} {t("properties")}
                                </span>
                            ) : null}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const UpdateRoleModal = ({ role, close, refresh }) => {
    const [updateData, setUpdateData] = useState({ name: role.name, accesses: role.accesses.map((x) => x.name) });
    const {
        loading,
        called,
        error,
        data = { accesses: { results: [] } },
    } = useQuery(accessesQuery, { fetchPolicy: "network-only", errorPolicy: "all" });
    const [initialAccesses, setInitialAccesses] = useState(role.accesses.map((x) => x.name));
    const { t } = useTranslation();
    const [groupedAccesses, setGroupedAccesses] = useState({});
    const [accesses, setAccesses] = useState([]);
    const { langStrings } = useSelector((state) => state.ui);
    useEffect(() => {
        if (called && !loading && !error && data.accesses) {
            setGroupedAccesses(
                data.accesses.results.reduce((acc, cur) => {
                    acc[cur.category] = acc[cur.category] ? acc[cur.category].concat(cur.name) : [cur.name];
                    return acc;
                }, {})
            );
        }
    }, [loading, error, data]);
    useEffect(() => {
        setAccesses(
            Object.entries(groupedAccesses).map(([group, accesses]) => {
                return {
                    label: group,
                    options: accesses.map((x) => ({ label: t(x), value: x })),
                };
            })
        );
    }, [groupedAccesses]);
    const updateRoleMutation = gql`
    mutation{
        updatePredefinedRole(
            id:  ${role.id}
            name: "${escapeSpecialChars(updateData.name)}"
            accesses: ["${updateData.accesses.join('","')}"]
       ){ error ok }
    }`;
    const [mutate] = useMutation(updateRoleMutation);

    return (
        <div style={{ minWidth: "560px" }}>
            <div className="mt-4">
                <div className="pb-5 ">
                    <div className="block">{t("add-role-desc")}</div>
                </div>
                <div className="pb-5">
                    <span className="block pb-2 first-capital">{t("name")} *</span>
                    <TextInput
                        required={true}
                        value={updateData.name}
                        onChange={(value) => setUpdateData((prev) => ({ ...prev, name: value }))}
                        placeholder={t("select-an-option")}
                    />
                </div>
                <div className="pb-5">
                    <span className="block pb-2 first-capital">{t("accesses")} *</span>
                    <Select
                        id={"accesses-select"}
                        placeholder={t("select-accesses")}
                        multiple={true}
                        search={false}
                        value={initialAccesses}
                        onChange={(selected) => {
                            return setUpdateData((prev) => ({ ...prev, accesses: selected }));
                        }}
                        options={accesses}
                    />
                </div>
            </div>
            <div id="modal-buttons" className="pt-8 text-center flex justify-end">
                <div onClick={close} data-action="close" id="cancel-modal-button">
                    <button id="modal-button-cancel" className="btn-white p-4 rounded btn-blue-outline">
                        <div className="first-capital">{t("cancel")}</div>
                    </button>
                </div>
                <div
                    onClick={() =>
                        mutate()
                            .then(() => {
                                refresh();
                                close();
                                toast.success(langStrings["operation-successful"]);
                            })
                            .catch(() => toast.error(langStrings["mutation-error"]))
                    }
                    className="ml-4"
                    data-action="edit-role"
                    id="edit-role-modal-button"
                >
                    <button id="modal-button-edit-role" className="btn-blue p-4 rounded">
                        <div className="first-capital">{t("edit-role")}l</div>
                    </button>
                </div>
            </div>
        </div>
    );
};

const CreateRoleModal = ({ close, refresh }) => {
    const { t } = useTranslation();
    const [createData, setCreateData] = useState({ name: "", accesses: [] });

    const [getAccesses, { loading, error, called, data = { accesses: { results: [] } } }] = useLazyQuery(
        accessesQuery,
        {
            fetchPolicy: "network-only",
            errorPolicy: "all",
        }
    );
    const [accessesOptions, setAccessesOptions] = useState({});
    const { langStrings } = useSelector((state) => state.ui);

    useEffect(() => {
        getAccesses();
    }, []);

    useEffect(() => {
        if (called && !loading && !error && data.accesses) {
            const groupedAccesses = data.accesses.results.reduce((acc, cur) => {
                acc[cur.category] = acc[cur.category] ? acc[cur.category].concat(cur.name) : [cur.name];
                return acc;
            }, {});
            setAccessesOptions(
                Object.entries(groupedAccesses).map(([group, accesses]) => ({
                    label: group,
                    options: accesses,
                }))
            );
        }
    }, [called, loading, error, data]);

    const createRoleMutation = gql`
    mutation{
        createPredefinedRole(
            name: "${escapeSpecialChars(createData.name)}"
            accesses: ["${(createData.accesses || []).join('","')}"]
       ){ error ok }
    }`;
    const [createRole] = useMutation(createRoleMutation);

    return (
        <div style={{ minWidth: "560px" }}>
            <div className="mt-4">
                <div className="pb-5 ">
                    <div className="block">{t("add-role-desc")}</div>
                </div>
                <div className="pb-5">
                    <span className="block pb-2 first-capital">{t("name")} *</span>
                    <TextInput
                        required={true}
                        onChange={(value) => setCreateData((prev) => ({ ...prev, name: value }))}
                        placeholder={t("select-an-option")}
                    />
                </div>
                <div className="pb-5">
                    <span className="block pb-2 first-capital">{t("accesses")} *</span>
                    <Select
                        id={"accesses-select"}
                        placeholder={t("select-accesses")}
                        multiple={true}
                        onChange={(selected) => setCreateData((prev) => ({ ...prev, accesses: selected }))}
                        options={accessesOptions}
                    />
                </div>
            </div>
            <div id="modal-buttons" className="pt-8 text-center flex justify-end">
                <div onClick={close} data-action="close" id="cancel-modal-button">
                    <button id="modal-button-cancel" className="p-4 rounded font-bold text-gray-900 btn-white">
                        <div className="first-capital">{t("cancel")}</div>
                    </button>
                </div>
                <div
                    onClick={() =>
                        createRole()
                            .then(() => {
                                refresh();
                                close();
                                toast.success(langStrings["operation-successful"]);
                            })
                            .catch(() => toast.error(langStrings["mutation-error"]))
                    }
                    className="ml-4"
                    data-action="edit-role"
                    id="edit-role-modal-button"
                >
                    <button id="modal-button-edit-role" className="btn-blue p-4 rounded">
                        <div className="first-capital">{t("add")}</div>
                    </button>
                </div>
            </div>
        </div>
    );
};
const DeleteRoleModal = ({ close, refresh, role }) => {
    const { t } = useTranslation();
    const { langStrings } = useSelector((state) => state.ui);
    const deleteRoleMutation = gql`
    mutation{
        delete${role.type === "PREDEFINED" ? "Predefined" : "Customised"}Role(
            id: ${role.id}
       ){ error ok }
    }`;
    const [deleteRole] = useMutation(deleteRoleMutation);
    return (
        <div style={{ minWidth: "560px" }}>
            <div>{t("delete-role-confirm")}</div>
            <div id="modal-buttons" className="pt-8 text-center flex justify-end">
                <div onClick={close} data-action="close" id="cancel-modal-button">
                    <button id="modal-button-cancel" className="p-4 rounded font-bold text-gray-900 btn-white">
                        <div className="first-capital">{t("cancel")}</div>
                    </button>
                </div>
                <div
                    onClick={() =>
                        deleteRole()
                            .then(() => {
                                refresh();
                                close();
                                toast.success(langStrings["operation-successful"]);
                            })
                            .catch(() => toast.error(langStrings["mutation-error"]))
                    }
                    className="ml-4"
                    data-action="edit-role"
                    id="edit-role-modal-button"
                >
                    <button id="modal-button-edit-role" className="btn-red p-4 rounded">
                        <div className="first-capital">{t("delete")}</div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default () => {
    const { open, close } = useModal();
    const history = useHistory();
    let [page, setPage] = useState(1);
    let [modalCrud, setModalCrud] = useState(null);
    let [accesses, setAccesses] = useState([]);
    const { langStrings } = useSelector((state) => state.ui);
    const isSuperUser = Session.getSessionProp("superUser") === "true";
    const duplicateRoleMutation = gql`
        mutation duplicatePredefinedRole($id: Int64!) {
            duplicatePredefinedRole(id: $id) {
                error
                ok
                __typename
            }
        }
    `;
    const query = gql`{
        roles(filter: {type: PREDEFINED}, size: 8, page: ${page}){
            results {
                id
                name
                type
                users
                accesses {
                    name,
                    category
                }
            }
        },
        accesses (orderBy:{field:"category",criteria:"asc"}){
            results { id:name name category }
        }
    }`;
    const [duplicateRole] = useMutation(duplicateRoleMutation);
    const { t } = useTranslation();
    const closeModal = () => close() && setModalCrud(null);
    const [executeQuery, { loading, error, data = { roles: { results: [] } } }] = useLazyQuery(query, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
    });
    useEffect(() => {
        if (data?.accesses?.results) {
            setAccesses(data.accesses.results.map((x) => x.name));
        }
    }, [data]);
    useEffect(() => {
        executeQuery();
    }, []);
    useEffect(() => {
        if (modalCrud?.type === "read") {
            open({
                id: "assigned-users-modal",
                title: t("assigned-users"),
                children: <UsersAssignedModal role={modalCrud.role} />,
                footer: (
                    <Button id="btn-exit" design="blue" onClick={closeModal}>
                        {t("close")}
                    </Button>
                ),
            });
        } else if (modalCrud?.type === "update") {
            open({
                id: "update-role-modal",
                title: `${modalCrud.role.name} - ${t("edit-role")}`,
                children: <UpdateRoleModal refresh={executeQuery} close={closeModal} role={modalCrud.role} />,
            });
        } else if (modalCrud?.type === "delete") {
            open({
                id: "delete-role-modal",
                title: `${modalCrud.role.name} - ${t("delete-role")}`,
                children: <DeleteRoleModal refresh={executeQuery} close={closeModal} role={modalCrud.role} />,
            });
        }
        return closeModal;
    }, [modalCrud]);
    return (
        <div>
            <div style={{ height: "72px" }} className=" w-full flex h-48 shadow-sm bg-white">
                <button
                    onClick={() => history(-1)}
                    style={{ width: "10%" }}
                    className=" focus:outline-none flex items-center justify-center border-r border-gray-200 text-gray-800  "
                >
                    <span className=" icon icon-chevron-left mt-1 pr-2 text-xl "></span>
                    <span className=" text-lg first-capital">{t("back")}</span>
                </button>
                <div style={{ width: "90%" }} className=" px-6 h-full font-bold text-lg flex items-center">
                    {t("manage-of-predefined-roles")}
                </div>
            </div>
            <div className={"my-8 ml-10 text-lg flex"}>
                <span className={"icon-warning mr-2"} style={{ fontSize: "22px", color: "#FF991F" }}></span>
                {t("predefined-roles-warning")}
            </div>
            <div className="px-10">
                <Table
                    id={"predefined-roles-table"}
                    search={true}
                    showCount={true}
                    forceInlineFilters={true}
                    paginate={true}
                    loading={loading}
                    filters={[
                        {
                            id: "filter-by-accesses",
                            title: t("accesses"),
                            multiple: true,
                            options: accesses.map((x) => ({ id: x, value: x, label: t(x) })),
                            onFilter: (values, row) => {
                                return values.some((access) => row.accesses.includes(access));
                            },
                        },
                    ]}
                    className="w-full main-container"
                    topRightCorner={
                        isSuperUser ? (
                            <Button
                                onClick={() =>
                                    open({
                                        id: "add-role-modal",
                                        title: t("add-role"),
                                        children: <CreateRoleModal refresh={executeQuery} close={closeModal} />,
                                    })
                                }
                                id={"new-role-button"}
                                design={"blue"}
                            >
                                {t("add-role")}
                            </Button>
                        ) : null
                    }
                    cols={["one", "two", "three"]}
                    header={{
                        one: {
                            title: t("name"),
                            sortable: true,
                        },
                        two: {
                            title: t("accesses"),
                            sortable: true,
                        },
                        three: {
                            title: t("assigned"),
                            sortable: true,
                            align: "center",
                        },
                    }}
                    rows={data.roles.results.map((x) => ({
                        rowConfig: {
                            actions: [
                                {
                                    label: t("edit-role"),
                                    onClick: () => setModalCrud({ type: "update", role: x }),
                                },
                                {
                                    label: t("duplicate"),
                                    onClick: () =>
                                        duplicateRole({ variables: { id: x.id } }).then(
                                            () => toast.success(langStrings["operation-successful"]) && executeQuery()
                                        ),
                                },
                                {
                                    label: t("delete-role"),
                                    disabled: x.users > 0,
                                    tooltip: x.users > 0 ? t("role-assigned") : null,
                                    onClick: () => x.users === 0 && setModalCrud({ type: "delete", role: x }),
                                },
                            ],
                        },
                        one: x.name,
                        two: (
                            <div alt={x.accesses.length} className={"gap-x-5 whitespace-nowrap w-full"}>
                                {x.accesses.slice(0, 7).map((x) => (
                                    <div
                                        className={
                                            "rounded py-1 px-4 m-1 text-white inline-block bg-blue-100 first-capital"
                                        }
                                    >
                                        {x.name}
                                    </div>
                                ))}
                                {x.accesses.length > 7 ? (
                                    <span className={"font-bold text-zafiro-600 ml-1"}>{`+ ${
                                        x.accesses.length - 7
                                    }`}</span>
                                ) : null}
                            </div>
                        ),
                        three: (
                            <div
                                alt={x.users}
                                className={"text-blue-600 font-bold cursor-pointer"}
                                onClick={() => setModalCrud({ type: "read", role: x })}
                            >
                                {x.users} {t("users")}
                            </div>
                        ),
                        accesses: x.accesses.map((x) => x.name),
                    }))}
                />
            </div>
        </div>
    );
};
